import React from 'react'
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";


interface UploadImageButtonProps {
    loading: boolean
}

const UploadImageButton: React.FC<UploadImageButtonProps> = ({loading}) => {
    return (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );
}

export default UploadImageButton