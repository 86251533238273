import {AxiosResponse} from "axios";

import Api, {getAuthorizationHeader} from "../api";
import {AiChatMessage} from "../../types";
import {getBaseUrl} from "../../config";

export interface AiChatMessageInResponse {
    message: AiChatMessage
}

export interface ListOfAiChatMessagesInResponse {
    messages: AiChatMessage[]
}

export interface AiChatMessageInCreate {
    threadId: number;
    content: string;
}

export const getAllMessagesByThreadId = (threadId: number): Promise<AxiosResponse<ListOfAiChatMessagesInResponse>> => {
    return Api.get(`/ai-chat/messages/?thread_id=${threadId}`);
};

export const createMessage = (threadId: number, messageData: AiChatMessageInCreate): Promise<AxiosResponse<AiChatMessageInResponse>> => {
    const request = {
        message: messageData
    }
    return Api.post(`/admin/ai-chat/messages/?thread_id=${threadId}`, request);
};

export const deleteMessage = (messageId: number): Promise<AxiosResponse<void>> => {
    return Api.delete(`/ai-chat/messages/${messageId}`);
};

interface CreateMessageStreamInterface {
    content: string,
    threadId: number | null,
    signal?: AbortSignal | null
}
export const sendMessage = (props: CreateMessageStreamInterface): Promise<Response> => {
    const request = {
        message: {
            "threadId": props.threadId,
            "content": props.content
        }
    }
    const baseUrl = getBaseUrl()
    return fetch(`${baseUrl}/ai-chat/messages/`, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'text/event-stream',
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
            Authorization: getAuthorizationHeader()
        },
        body: JSON.stringify(request),
        signal: props.signal
    });
};

export const sendAudioToServer = async (audioBlob: Blob, threadId?: number | null): Promise<Response> => {
    const formData = new FormData();
    formData.append('audio_file', audioBlob);
    formData.append('thread_id', threadId ? threadId.toString() : '');

    const baseUrl = getBaseUrl()
    return fetch(`${baseUrl}/ai-chat/messages/audio`, {
        method: 'POST',
        body: formData,
        headers: {
            // 'Content-Type': 'text/event-stream',
            // 'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
            Authorization: getAuthorizationHeader()
        },
    });
};

export default {
    getAllMessagesByThreadId,
    createMessage,
    deleteMessage,
    createMessageStream: sendMessage,
    sendAudioToServer
};
