import React from 'react'
import {Breadcrumb} from "antd";
import {ItemType} from "antd/lib/breadcrumb/Breadcrumb";

interface CustomBreadcrumbProps {
    list: string[]
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({list}) => {

    const formatTitle = (title: string) => {
        if (!title || title.length <= 0) return ''
        if (title.length === 1) return title.toUpperCase()
        return title[0].toUpperCase() + title.slice(1, title.length).toLowerCase()
    }

    const breadcrumbItems: ItemType[] = list.map(item => ({
        key: `HeaderBreadcrumb-${item}`,
        title: formatTitle(item)
    }))

    return (
        <Breadcrumb style={{margin: '16px 0'}} items={breadcrumbItems}/>
    )
}

export default CustomBreadcrumb