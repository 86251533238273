import React from 'react';
import {
    CategoryScale,
    Chart, Legend,
    LinearScale,
    Title,
    BarElement,
    Tooltip
} from "chart.js";
import {Bar} from 'react-chartjs-2';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {blue} from '@ant-design/colors';
// ----- Plugins -----
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
dayjs.extend(relativeTime);

// ----- Local calls -----
import {Rating} from "types";
import {max} from "lodash";

// ----- Global variables -----
const ChartOptions = {
    plugins: {
        legend: {
            display: true,
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Ratings Distribution',
        }
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
}

// ----- Types -----
type NewRating = Rating & {
    newStars1: number;
    newStars2: number;
    newStars3: number;
    newStars4: number;
    newStars5: number;
}

interface RatingsDistributionProps {
    data: Rating[];
    loading: boolean;
    lastUpdatedAt: Date | null;
}

// ----- Components -----
const RatingsDistribution: React.FC<RatingsDistributionProps> = ({data}) => {
    const sourceProductRatings: {
        // key = itemKey(item)
        [key: string]: NewRating[];
    } = {}
    // For each dat, ratingSource and productName get the previous rating and calculate the difference
    const itemKey = (item: Rating) => `${item.ratingSource}-${item.productId}`;
    // Remove the duplication on the same date for the same product
    const grouped = data
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .reduce((acc, item) => {
            const key = `${item.date}-${item.ratingSource}-${item.productName}`;
            if (!acc[key]) {
                // new entry
                acc[key] = item;
            } else {
                // update if the new entry is newer
                if (new Date(item.createdAt) > new Date(acc[key].createdAt)) {
                    acc[key] = item;
                }
            }
            return acc;
        }, {} as { [key: string]: Rating });

    // Convert to array and sort by date and generate the mapping.
    Object
        .values(grouped)
        .sort((a, b) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        })
        .forEach((item) => {
            // Add the element to previousRating
            const sourceProductKey = itemKey(item);
            if (!sourceProductRatings[sourceProductKey]) {
                sourceProductRatings[sourceProductKey] = [item as NewRating];
            } else {
                sourceProductRatings[sourceProductKey].push(item as NewRating);
            }
            return item
        });

    // For each combination of date, ratingSource, and productName,
    // calculate the difference between the current rating and the previous rating.
    const dataWithNewRating: NewRating[] = Object
        .keys(sourceProductRatings)
        .reduce((acc: NewRating[], key: string) => {
            const items: NewRating[] = sourceProductRatings[key];
            const newItems = items.map((item, index) => {
                // Get the previous item
                const previousItem = items[index - 1];
                if (!previousItem) {
                    return {
                        ...item,
                        newStars1: 0,
                        newStars2: 0,
                        newStars3: 0,
                        newStars4: 0,
                        newStars5: 0,
                    }
                }
                // todo: move this logic to the backend
                // Handle the case where we start onboarding amazon data for the first time
                if (new Date(item.date) <= new Date('2024-02-13') && item.ratingSource == 'amazon') {
                    return {
                        ...item,
                        newStars1: 0,
                        newStars2: 0,
                        newStars3: 0,
                        newStars4: 0,
                        newStars5: 0,
                    }
                }
                return {
                    ...item,
                    newStars1: max([item.stars1 - previousItem.stars1, 0]) || 0,
                    newStars2: max([item.stars2 - previousItem.stars2, 0]) || 0,
                    newStars3: max([item.stars3 - previousItem.stars3, 0]) || 0,
                    newStars4: max([item.stars4 - previousItem.stars4, 0]) || 0,
                    newStars5: max([item.stars5 - previousItem.stars5, 0]) || 0,
                }
            })
            return [...acc, ...newItems]
        }, [] as NewRating[])

    // Aggregate the data by date
    const aggregatedByDate = dataWithNewRating.reduce((acc, curr) => {
        const dateKey = dayjs(curr.date).format('YYYY-MM-DD');
        if (acc[dateKey]) {
            // For each rating calculate current date's total stars
            acc[dateKey].newStars1 += curr.newStars1;
            acc[dateKey].newStars2 += curr.newStars2;
            acc[dateKey].newStars3 += curr.newStars3;
            acc[dateKey].newStars4 += curr.newStars4;
            acc[dateKey].newStars5 += curr.newStars5;
        } else {
            // Clone curr to avoid mutating original data
            acc[dateKey] = {...curr};
        }
        return acc;
    }, {} as { [date: string]: NewRating });
    // Convert the object to array and sort by date.
    const aggregatedDataArray = Object.values(aggregatedByDate).sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    // ---- Chart Data ----
    const chartData = {
        labels: aggregatedDataArray.map(d => d.date),
        datasets: [
            {
                label: '1 Star',
                data: aggregatedDataArray.map(d => d.newStars1),
                backgroundColor: 'rgba(0, 62, 102,1)',
            },
            {
                label: '2 Stars',
                data: aggregatedDataArray.map(d => d.newStars2),
                backgroundColor: 'rgba(7, 101, 160,1)',
            },
            {
                label: '3 Stars',
                data: aggregatedDataArray.map(d => d.newStars3),
                backgroundColor: 'rgba(0, 139, 235,1)',
            },
            {
                label: '4 Stars',
                data: aggregatedDataArray.map(d => d.newStars4),
                backgroundColor: 'rgba(80, 180, 244,1)',
            },
            {
                label: '5 Stars',
                data: aggregatedDataArray.map(d => d.newStars5),
                backgroundColor: 'rgba(224, 242, 254,1)',
            }
        ],
    };

    return (
        <Bar data={chartData} height={150} options={ChartOptions}/>
    )
}

export default RatingsDistribution
