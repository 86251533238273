import React, {useEffect, useMemo, useState} from 'react';
import {BarElement, CategoryScale, Chart, Legend, LinearScale, PointElement, TimeSeriesScale, Title} from "chart.js";
import {Line} from 'react-chartjs-2';
import {ClockCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {ChartOptions} from "chart.js/dist/types";
import {Button, Card, Col, Row, Select, Tooltip} from "antd";
import 'chartjs-adapter-date-fns';
import moment from 'moment';
import dayjs, {Dayjs} from 'dayjs';

// ----- Local calls -----
import {FirmwareAdoption, FirmwareAdoptionItem} from "../../../../types";
import {getAnalyticFirmwareAdoption} from "../../../../services/analytics";
import {weekendDatesHighlighter} from "utils/graphweekends";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from "../../../../helpers/dates-helper";
import DateRangeSelector from "../../../../components/DateRangeSelector";
import {isDateInSelectedDateRange} from "../../../../utils/datetime";
import {generateColors} from "../../../../utils/colors";


// ----- Plugins -----
Chart.register(BarElement, PointElement, LinearScale, CategoryScale, Title, TimeSeriesScale, Legend);

// ----- Global variables -----
const {Option} = Select;

// ----- Types -----

// ----- Components -----
const FirmwareAdoptionMetrics: React.FC = () => {
    // todo: refactor
    // ----- data -----
    const [data, setData] = useState<FirmwareAdoption[]>([]);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [loading, setLoading] = useState(false);
    const [showPercentage, setShowPercentage] = useState(true);
    const [hardwareList, setHardwareList] = useState<string[]>([]);

    // ----- Filters -----
    const [hardwareFilter, setHardwareFilter] = useState<string>()
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[3]
    );

    // ----- Memoized data -----
    const filteredData = useMemo(() => {
        return data.filter(item => {
            const itemDate = dayjs(item.date, 'YYYY-MM-DD');

            const dateCondition = isDateInSelectedDateRange(selectedDateRange.value, itemDate, true);

            const hardwareCondition = hardwareFilter ? item.hardwareGroup.includes(hardwareFilter) : true;

            return dateCondition && hardwareCondition;
        })
    }, [data, selectedDateRange, hardwareFilter]);


    const chartDatasets = useMemo(() => {
        if (!hardwareFilter || !filteredData) return [];
        const topFirmwareArray: FirmwareAdoptionItem[] = getTopFirmwareVersions(filteredData, hardwareFilter);
        // Extract firmware versions and adopters counts for chart data
        const topFirmwareVersions = topFirmwareArray.map((firmwareAdoptionItem: FirmwareAdoptionItem, colorIndex: number) => {
            // Generate labels and data for the chart, label: firmware version, data: adopters count
            const label = firmwareAdoptionItem.firmwareVersion;

            // Generate the data for the firmware version
            const firmwareData = filteredData.map((item: FirmwareAdoption) => {
                // Calculate the daily some
                const total = item.firmwaresAdoption.reduce((sum, firmware) => sum + firmware.adoptersCount, 0);
                // Find the firmware record for the firmware version
                const firmwareRecord = item.firmwaresAdoption.find((firmware) => firmware.firmwareVersion === label);
                // if showPercentage is true, calculate the adoption rate
                if (showPercentage) {
                    return firmwareRecord ? firmwareRecord.adoptersCount / total : 0;
                }
                return firmwareRecord ? firmwareRecord.adoptersCount : 0;
            });

            // Generate colors for the chart
            const allColors = generateColors(3);

            // Return the dataset for the firmware version
            return {
                label: label,
                data: firmwareData,
                backgroundColor: '#fff', // Consider using semi-transparent colors for better layering visualization
                borderColor: allColors[colorIndex++ % allColors.length],
                borderWidth: 2,
                pointRadius: 1,
                pointHoverRadius: 5,
            };
        });

        return topFirmwareVersions
    }, [filteredData, hardwareFilter, showPercentage]);

    // ----- Effects -----
    useEffect(() => {
        fetchData();
    }, []);

    // ----- Fetchers -----
    const fetchData = () => {
        setLoading(true)
        getAnalyticFirmwareAdoption()
            .then(res => {
                const responseData = res?.data?.data || []
                setData(responseData) // Save the original data for filtering
                // Extract hardware names from the data
                const hardwareNames = responseData.flatMap((item: FirmwareAdoption) => item.hardwareGroup);
                setHardwareList(Array.from(new Set(hardwareNames)));
                if (hardwareNames.length > 0)
                    setHardwareFilter(hardwareNames[0])
                // Set the last updated time
                if (responseData.length > 0) {
                    const lastUpdatedTime = responseData[responseData.length - 1].updatedAt;
                    setLastUpdated(new Date(lastUpdatedTime));
                }
            })
            .catch(err => {
                console.log("Error fetching data, message: ", err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        const range = {
            label: label as DateRangeFilterSelectorOptionsLabel,
            value: dates
        }
        setSelectedDateRange(range);
    };

    const handleHardwareChange = (value: string) => {
        setHardwareFilter(value)
    }


    // ----- Functions -----
    function getTopFirmwareVersions(records: FirmwareAdoption[], hardwareGroup: string, topCount = 10): typeof firmwareArray {
        // Filter data by hardware group and sort by date.
        const filteredSortedRecords = records
            .filter((record) => record.hardwareGroup === hardwareGroup)
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        // For the last day, calculate the adoption rate for each firmware version
        const lastDate = filteredSortedRecords[0]?.date;

        // Get the records for the last date
        const lastDateRecords = filteredSortedRecords.filter((item: FirmwareAdoption) => item.date === lastDate);

        // Create a map to store the adopters count for each firmware version
        const firmwareMap = new Map<string, number>();
        lastDateRecords.forEach((record: FirmwareAdoption) => {
            // Iterate over the firmware versions for the hardware group
            record.firmwaresAdoption.forEach((firmware: FirmwareAdoptionItem) => {
                const version = firmware.firmwareVersion;
                const count = firmware.adoptersCount || 0;
                if (firmwareMap.has(version)) {
                    const prevCount = firmwareMap.get(version) || 0;
                    firmwareMap.set(version, prevCount + count);
                } else {
                    firmwareMap.set(version, count);
                }
            });
        });
        // E.g. {"Firmware1" => 10, "Firmware2" => 20}
        // Convert the map to an array of objects and sort it
        const firmwareArray = Array.from(firmwareMap, ([key, value]) => ({
            firmwareVersion: key,
            adoptersCount: value,
            adoptionRate: 0,
        })).sort((a, b) => b.adoptersCount - a.adoptersCount);

        const topN = firmwareArray.slice(0, topCount);
        // If there are more than 'topCount' firmware versions, create an 'Others' entry
        if (firmwareArray.length > topCount) {
            // Calculate the total adopters count for 'Others'
            const othersCount = firmwareArray
                .slice(topCount)
                .reduce((sum, item) => sum + item.adoptersCount, 0);
            // Add others to the topN array
            topN.push({
                firmwareVersion: 'Others',
                adoptersCount: othersCount,
                adoptionRate: 0
            } as FirmwareAdoptionItem)
        }

        // Return the modified array including 'Others' if applicable
        return topN;
    }

    // Day by day Chart data
    const chartData = {
        labels: filteredData.map((item) => item?.date), // Assuming date is unique for each data point
        datasets: chartDatasets,
    };

    const options: ChartOptions<any> = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Use the point style instead of the default box
                    pointStyle: 'circle', // Set the point style to circle
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += showPercentage ? `${100 * context.parsed.y.toFixed(2)}%` : context.parsed.y;
                        }
                        return label;
                    }
                }
            }
        },
        tension: 0.4,
        scales: {

            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd MMM yyyy',
                    displayFormats: {
                        day: 'dd MMM'
                    }
                },
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: any) {
                        return showPercentage ? `${value * 100}%` : value;
                    }
                }
            }
        },
    };

    return (
        <Card title="Firmware versions Adoption"
              loading={loading}
              extra={
                  <>
                      <Tooltip title={lastUpdated ? `Updated ${moment(lastUpdated).fromNow()}` : 'Never'}>
                          <ClockCircleOutlined style={{marginRight: 10}}/>
                      </Tooltip>


                      <Select
                          defaultValue={showPercentage ? "Rate" : "Count"}
                          style={{width: 100, marginRight: 8}}
                          onChange={(value) => setShowPercentage(value === "Rate")}
                      >
                          <Select.Option value="Count">Count</Select.Option>
                          <Select.Option value="Rate">Rate %</Select.Option>
                      </Select>


                      <Select style={{width: 140, marginRight: 8}}
                              placeholder="Select hardware"
                              onChange={handleHardwareChange}
                              value={hardwareFilter}
                              loading={loading}
                      >
                          {
                              hardwareList.map((item, index) => <Option key={"Hardware-" + index}
                                                                        value={item}>{item}</Option>)
                          }
                      </Select>

                      <DateRangeSelector defaultDateRange={selectedDateRange}
                                         onDateRangeChange={handleDateRangeChange}/>

                      <Button
                          size="middle"
                          loading={loading}
                          onClick={fetchData}
                          icon={<ReloadOutlined/>}
                          style={{marginRight: 8}}
                      />

                  </>
              }

        >
            <p>The adoption of different firmware versions over time. </p>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Line data={chartData} height={150} options={options} plugins={[weekendDatesHighlighter]}/>
                </Col>
            </Row>

        </Card>
    )
}

export default FirmwareAdoptionMetrics