import React from "react";
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {history} from 'utils/history';
import {RootState} from "redux/state";


interface PrivateRouteProps {
    children: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({children}) => {
    const isAuthenticated = useSelector((state: RootState) => state?.auth?.isAuthenticated);

    // console.log('PrivateRoute isAuthenticated: ', isAuthenticated)

    if (!isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{from: history.location}}/>
    }

    // authorized so return child components
    return children;
}

export default PrivateRoute