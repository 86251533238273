import React, {useEffect, useState} from "react";
import {Card, Col, Row, Statistic} from "antd";

import CustomBreadcrumb from "components/CustomBreadcrumb";

import PavlokWatch from 'assets/images/pavlok-watch.png'
import {getRevenueCat} from "../../services/analytics";
import {RevenueCat} from "../logs/types";
import './style.css'


const cardStyle = {
    width: '200px', // Adjust the width to your preference
    height: '140px', // Adjust the height to your preference
};

const HomePage = () => {
    //----- data -----
    const [metrics, setMetrics] = useState<RevenueCat[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        // again;
        fetchMetrics()
    }, [])

    const fetchMetrics = async () => {
        setLoading(true)
        try {
            const response = await getRevenueCat()
            // console.log('response', response)
            const data = response.data.metrics || []
            // todo: remove this in other place using diffrent logic
            setMetrics(data.filter(item => item?.id !== 'num_tx_last_28_days'))
        } catch (err) {
            console.error("Error fetching cat Revenue metrics, message:", err)
        }
        setLoading(false)
    }
    return (
        <>
            <CustomBreadcrumb list={['Home']}/>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div style={{height: '470px'}}>
                        <div className="home-background-container">
                            <div className="home-background" style={{backgroundImage: `url(${PavlokWatch})`}}/>
                            <h2 className="pt-2 pb-2 h2">Subscriptions statistics</h2>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                                    <Row gutter={[16, 16]}>
                                        {
                                            metrics?.map(item => (
                                                <Col xs={24} sm={24} md={12} lg={8} xl={8} key={item.id}>
                                                    <Card bordered={false} style={cardStyle} loading={loading}>
                                                        <Statistic
                                                            title={item?.name}
                                                            value={item?.value}
                                                            loading={loading}
                                                            // precision={2}
                                                            valueStyle={{color: '#3f8600'}}
                                                            // prefix={<ArrowUpOutlined/>}
                                                            suffix={item?.unit === '#' ? '' : item?.unit}
                                                        ></Statistic>
                                                        <p>{item?.description}</p>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default HomePage;
