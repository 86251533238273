import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import {Avatar, Card, Col, Row, Space, Radio} from 'antd';
import {UserOutlined} from "@ant-design/icons";
import {RadioChangeEvent} from "antd/es/radio/interface";

// ----- Local calls -----
import {User} from "../../../types";
import {getUserById} from "services/users";
import DevicesList from "components/DevicesList";
import BgProfile from 'assets/images/bg-profile.jpg';
import SubscriptionsList from "../../../components/SubscriptionsList";
import PhoneDevicesList from "./components/PhoneDevicesList";
import './style.css'
import StimulusList from "./components/StimulusList";
import AiChat from "./components/AiChat";
import AppContentTab from "./components/Tabs/AppContent";
import UserInfo from "./components/UserInfo";

// ----- Global variables -----
// Define size configurations
const sizeExpanded = { xs: 24, sm: 24, md: 20, lg: 20 };
const sizeCollapsed = { xs: 24, sm: 24, md: 16, lg: 16 };

const userInfoSizeExpanded = { xs: 24, sm: 24, md: 8, lg: 8 };
const userInfoSizeCollapsed = { xs: 8, sm: 8, md: 4, lg: 4 };

// ----- Types -----
type RouteParams = {
    userId: string;
};

const UserDetailsPage: React.FC = () => {
    // ----- Navigation -----
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {userId} = useParams<RouteParams>();
    const [tab, setTab] = useState<string>(decodeURIComponent(searchParams.get('tab') || '') || 'devices')

    // ----- Data -----
    const [user, setUser] = useState<User | null>(null);
    const [volts, setVolts] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [isCollapsedUserInfo, setIsCollapsedUserInfo] = useState<boolean>(true)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        getUserById(userId)
            .then(res => {
                const response_user = res?.data?.user
                const voltsResponse = res?.data?.volts
                if (response_user) {
                    setUser(response_user)
                    setVolts(voltsResponse || 0)
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    const handleUserInfoToggle = (value: boolean) => {
        setIsCollapsedUserInfo(value)
    }

    function onTabChange(tab: RadioChangeEvent) {
        setTab(tab.target.value)
        navigate(`?tab=${encodeURIComponent(tab.target.value)}`);
    }


    const StimulusContent: React.FC = () => (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <StimulusList userId={userId}/>
        </Space>
    )

    const DevicesContent: React.FC = () => (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            {/* Devices content */}
            <DevicesList userId={userId}/>
            <PhoneDevicesList userId={userId}/>
        </Space>
    );


    const SubscriptionsContent: React.FC = () => (
        <>
            {/* SUBSCRIPTIONS content */}
            <SubscriptionsList userId={userId}/>
        </>
    );

    const ContentMap: Record<string, JSX.Element> = {
        default: <DevicesContent/>,
        devices: <DevicesContent/>,
        app: <AppContentTab userId={userId}/>,
        subscriptions: <SubscriptionsContent/>,
        stimulus: <StimulusContent/>,
        coach: <AiChat/>
    }

    const userInfoGridSettings = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24
    }

    return (
        <>
            <div
                className="profile-nav-bg"
                style={{backgroundImage: `url(${BgProfile})`}}
            />
            <Card
                className="card-profile-head"
                bodyStyle={{display: 'none'}}
                title={(
                    <Row justify="space-between" align="middle" gutter={[24, 16]}>
                        <Col span={24} md={12} className="col-info">
                            <Avatar.Group>
                                <Avatar size={74}
                                        shape="square"
                                        src={user?.profilePictureUrl}
                                        icon={<UserOutlined/>}
                                        style={{backgroundColor: '#01013d'}}/>

                                <div className="avatar-info">
                                    <h4 className="font-semibold m-0">{`${user?.firstName || ''} ${user?.lastName || ''}`}</h4>
                                    <p>Role: {user?.role || 'user'}</p>
                                </div>
                            </Avatar.Group>
                        </Col>
                        <Col
                            span={24}
                            md={12}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Radio.Group onChange={onTabChange} defaultValue={tab}>
                                <Radio.Button value="devices">Devices</Radio.Button>
                                <Radio.Button value="app">App</Radio.Button>
                                <Radio.Button value="subscriptions">Subscriptions</Radio.Button>
                                <Radio.Button value="stimulus">Stimulus</Radio.Button>
                                <Radio.Button value="coach">Ai Chat</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                )}
            />
            <Row gutter={[16, 16]}>
                {
                    tab === "coach" ?
                        <Col span={24}>
                            {ContentMap[tab || 'default']}
                        </Col>
                        :
                        <>
                            <Col {...(isCollapsedUserInfo? sizeExpanded : sizeCollapsed)}>
                                {ContentMap[tab || 'default']}
                            </Col>
                            <Col {...(isCollapsedUserInfo? userInfoSizeCollapsed: userInfoSizeExpanded )}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <UserInfo user={user}
                                                  loading={loading}
                                                  userId={userId}
                                                  volts={volts}
                                                  onToggle={handleUserInfoToggle}
                                                  isCollapsed={isCollapsedUserInfo}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </>
                }
            </Row>
        </>

    )

};

export default UserDetailsPage;