import React, {useMemo} from 'react';
import {
    CategoryScale,
    Chart, Legend,
    LinearScale,
    PointElement,
    TimeSeriesScale,
    Title,
    BarElement
} from "chart.js";
import {Line} from 'react-chartjs-2';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// ----- Plugins -----
Chart.register(BarElement, PointElement, LinearScale, CategoryScale, Title, TimeSeriesScale, Legend);
dayjs.extend(relativeTime);

// ----- Local calls -----
import {generateColors} from "utils/colors";
import {Rating, ReviewSource} from "types";

// ----- Global variables -----
const COLORS = generateColors(3);

// ----- Types -----
interface AppRatingsMetricsProps {
    data: Rating[];
    loading: boolean;
    lastUpdatedAt: Date | null;
}

// ----- Components -----
const AppRatingsMetrics: React.FC<AppRatingsMetricsProps> = ({data}) => {
    // ---- Chart Data ----
    const {labels, datasets} = useMemo(() => {
        // Filter only ios and android data
        const allowedSources = [ReviewSource.IOS, ReviewSource.ANDROID];
        // Filter the data
        const filteredData = data.filter((rating: Rating) => allowedSources.includes(rating.ratingSource));

        // Group by month and source, then select the latest entry for each group
        interface GroupedBySource {
            [productName: string]: Rating; //{ date: Date; averageRating: number }[];
        }

        const groupedByMonthAndSource = filteredData.reduce((acc, rating: Rating) => {
            const month = dayjs(rating.date).format('YYYY-MM'); // Group by month
            const key = `${rating.ratingSource}-${month}`;
            if (!acc[key]) {
                acc[key] = rating;
            } else {
                // Keep the rating with the latest date in the month
                if (dayjs(rating.date).isAfter(dayjs(acc[key].date))) {
                    acc[key] = rating;
                }
            }
            return acc;
        }, {} as GroupedBySource);

        // Convert the object back to an array
        const latestRatingsPerMonth: Rating[] = Object.values(groupedByMonthAndSource);

        // Sort the latest entries by date.
        latestRatingsPerMonth.sort((a: Rating, b: Rating) => dayjs(a.date).diff(dayjs(b.date)));

        // Extract unique months for labels
        const labels = Array.from(new Set(latestRatingsPerMonth.map(rating => dayjs(rating.date).format('YYYY-MM'))));
        // const labels = [...new Set(latestRatingsPerMonth.map((rating:Rating) => dayjs(rating.date).format('YYYY-MM')))];

        // Create datasets
        const datasets = allowedSources.map((source, index) => {
            const colorIndex = index % COLORS.length; // Cycle through colors if there are more products than colors
            const sourceData = latestRatingsPerMonth.filter(rating => rating.ratingSource === source);
            return {
                label: source,
                data: labels.map(label => {
                    const rating = sourceData.find(r => dayjs(r.date).format('YYYY-MM') === label);
                    return rating ? rating.averageRating : null;
                }),
                fill: false,
                // borderColor: source === ReviewSource.IOS ? COLORS[0]: COLORS[1],
                borderColor: COLORS[colorIndex],
                backgroundColor: '#fff',
                borderWidth: 2,
            };
        });

        return {labels, datasets};
    }, [data]);

    const chartData = {labels, datasets};

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: 5, // Assuming rating scale is 1-5
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Average App Rating Over Time',
            }
        },
        responsive: true,
    };


    return (
        <Line data={chartData} height={150} options={chartOptions}/>
    )
}

export default AppRatingsMetrics