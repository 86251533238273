import React, {useEffect, useState} from 'react';
import {Select} from "antd";


import {UsersService} from "services";
import {User} from "types";

interface UsersSelect {
    onlyStaff?: boolean
    roles?: string[]
    multiple?: boolean
}

const UsersSelect: React.FC<UsersSelect> = ({onlyStaff = false, multiple = false, ...otherProps}) => {
    const [data, setData] = useState<User[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        console.log("onlyStaff", onlyStaff)
        UsersService.getStaff()
            .then(res => {
                let response = res?.data?.users || []
                // add key to the list
                response = response?.map((item: User) => ({
                    key: `user-${item?.id}`,
                    ...item
                }))
                if (response) {
                    setData((response))
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const options = data?.map((item: User) => ({
        label: `${item.firstName} (${item.email}) - ${item.role}`,
        value: item.id
    }))
    return <>
        <Select loading={loading} options={options} allowClear {...otherProps} mode="multiple"/>
    </>
}

export default UsersSelect