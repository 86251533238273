import axios, {AxiosResponse} from 'axios';

import Api from "./api";
import {
    DeviceEngagementRateData,
    FeatureUsageData,
    FirmwareAdoption,
    GetPaginatedFilteredDataParams, GetPaginatedFilteredDataRequest,
    Rating,
    ReviewsData
} from "../types";
import {RevenueCat} from "../pages/logs/types";
import config from "../config";
import {prepareQueryParams} from "../utils/requests-utils";

interface GetDeviceEngagementRate {
    data: DeviceEngagementRateData[]
}

interface GetFirmwareAdoption {
    data: FirmwareAdoption[]
}

export interface GetAnalyticFeatureUsageRequest {
    usagePeriod: "daily" | "weekly"
}

interface GetAnalyticFeatureUsageResponse {
    data: FeatureUsageData[]
}


export const getAnalyticDeviceEngagementRate = (): Promise<AxiosResponse<GetDeviceEngagementRate>> => {
    return Api.get('/admin/analytics/engagement-rate');
}

export const getAnalyticFirmwareAdoption = (): Promise<AxiosResponse<GetFirmwareAdoption>> => {
    return Api.get('/admin/analytics/firmware-adoption');
}

export const getAnalyticFeatureUsage = (params: GetAnalyticFeatureUsageRequest): Promise<AxiosResponse<GetAnalyticFeatureUsageResponse>> => {
    return Api.get('/admin/analytics/features-usage', {
        params: {
            usage_period: params.usagePeriod
        }
    });
}

export interface GetReviewsRequest extends GetPaginatedFilteredDataRequest {
    startDate?: string,
    endDate?: string
}

interface GetPaginatedFilteredReviewsParams extends GetPaginatedFilteredDataParams {
    start_date?: string,
    end_date?: string,
}

interface GetReviewsResponse {
    lastUpdate: Date,
    reviews: ReviewsData[]
}

export const getReviews = (params: GetReviewsRequest): Promise<AxiosResponse<GetReviewsResponse>> => {
    let req: GetPaginatedFilteredReviewsParams = prepareQueryParams(params)
    req = {
        ...req,
        ...(params.startDate ? {start_date: params.startDate} : {}),
        ...(params.endDate ? {end_date: params.endDate} : {}),
    }
    return Api.get(
        '/admin/analytics/reviews',
        {
            params: req
        }
    )
}

interface ReviewTopicRequest {
    topic: string;
    impression: boolean;
}

interface updateReviewTopicsRequest {
    id: number,
    topics: ReviewTopicRequest[]
}

export const updateReviewTopics = (request: updateReviewTopicsRequest): Promise<AxiosResponse<GetReviewsResponse>> => {
    const req = {
        topics: request.topics.map(topic => ({impression: topic.impression, topic: topic.topic}))
    }
    return Api.put(`/admin/analytics/reviews/${request.id}`, req);
}


interface GetGoogleAnalyticsRequest {
    dimensions: string[],
    metrics: string[],
    start_date: string | null | undefined,
    end_date: string | null | undefined,
}

type GetGoogleAnalyticsResponse<T> = T[]

export function getGoogleAnalytics<T>(request: GetGoogleAnalyticsRequest): Promise<AxiosResponse<GetGoogleAnalyticsResponse<T>>> {
    return Api.post('/admin/google-analytics/', request);
}

interface RevenueCatResponse {
    metrics: RevenueCat[]
}

export const getRevenueCat = (): Promise<AxiosResponse<RevenueCatResponse>> => {
    const options = {
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${config.revenuecat.token}`
        }
    };
    if (config.revenuecat.token) {
        return axios.get('https://api.revenuecat.com/v2/projects/3e3f08a5/metrics/overview', options)
    }
    return Promise.reject()
};

interface GetRatingsResponse {
    lastUpdate: Date
    ratings: Rating[]
}

interface GetRatingsRequest {
    sources: string[]
    startDate?: string
    endDate?: string
}

export const getRating = (req: GetRatingsRequest): Promise<AxiosResponse<GetRatingsResponse>> => {
    const sources = "sources=" + req.sources.join('&sources=')
    // Add sources to the request
    const url = `/admin/analytics/ratings?${sources}`
    return Api.get(url, {
        params: {
            // sources: req.sources,
            ...(req.startDate ? {start_date: req.startDate} : {}),
            ...(req.endDate ? {end_date: req.endDate} : {}),
        }
    });
}

export default {
    getAnalyticDeviceEngagementRate,
    getRevenueCat,
    getGoogleAnalytics,
    getReviews,
    getAnalyticFeatureUsage,
    getAnalyticFirmwareAdoption,
    getRating
}
