import {AxiosResponse} from "axios";

import Api from "./api";

interface AddVoltsRequest {
    user_id: number;
    volts: number;
}

interface AddVoltsResponse {
    volts: number
}
export const addVolts = (addVoltsRequest: AddVoltsRequest): Promise<AxiosResponse<AddVoltsResponse>> => {
    return Api.put(`/admin/wallets/add-volts`, {
        wallet: addVoltsRequest
    });
}


export default {
    addVolts
}