import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Card, Table, Image} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DotChartOutlined, MinusOutlined,
    RadarChartOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {UserHabit} from "types";
import {getColumnSearchProps} from "../TableSearch";
import {UserHabitsService} from "services";


interface UserHabitsListProps {
    userId?: string
}

const UserHabitsList: React.FC<UserHabitsListProps> = ({userId}) => {
    const [habits, setHabits] = useState<UserHabit[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        UserHabitsService.getUserHabits({
            userId: Number(userId),
        })
            .then(res => {
                const habitsResponse = res?.data?.userHabits || []
                // todo:  create function that add a the key to array
                const habitsList = habitsResponse?.map((item: UserHabit, index: number) => ({
                    ...item,
                    key: `key-${index}`
                }))
                setHabits(habitsList || [])
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const columns: ColumnsType<UserHabit> = [
        {
            title: 'Icon',
            dataIndex: 'habitIcon',
            key: 'habitIcon',
            // width: '30%',
            render: (iconUrl: string | null) =>
                (
                    <div className="container-center">
                        {
                            iconUrl ?
                                <Image src={iconUrl} width={35}/> :
                                <Avatar style={{backgroundColor: '#73d13d'}} icon={<RadarChartOutlined/>}/>
                        }
                    </div>
                )

        },
        {
            title: 'Name',
            dataIndex: 'habitName',
            key: 'habitName',
            // width: '30%',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'habitName'
            }),
        },
        {
            title: 'type',
            dataIndex: 'habitType',
            key: 'habitType',
            // width: '20%',
            filters: [
                {
                    text: 'Positive',
                    value: 'positive',
                }, {
                    text: 'Negative',
                    value: 'negative',
                }, {
                    text: 'Neutral',
                    value: 'neutral',
                }
            ],
            onFilter: (value: string | number | boolean, record: UserHabit) => {
                return record.habitType.toLowerCase().indexOf((value + "").toLowerCase()) === 0
            },
            render: (value) =>{
                const type = value?.toLowerCase()
                switch (type){
                    case 'positive':
                        return <><ArrowUpOutlined style={{color: '#bae637'}}/>{value}</>
                    case 'negative':
                        return <><ArrowDownOutlined style={{color: '#ff4d4f'}}/>{value}</>
                    case 'neutral':
                        return <><MinusOutlined style={{color: '#4096ff'}}/>{value}</>
                    default:
                        return value
                }
            }
        }
    ];

    return (
        <Card title="Habits list"
              extra={
                  <Button 
                          loading={loading}
                          onClick={() => fetchData()}
                          icon={<ReloadOutlined/>}/>
              }
        >
            <Table<UserHabit>
                columns={columns}
                dataSource={habits}
                loading={loading}
                size="small"
                bordered
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                }}
                scroll={{x: 400}}
            />
        </Card>
    )

};

export default UserHabitsList;