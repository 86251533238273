import React, {useEffect, useState} from "react";
import {Button, Col, message, Row, Space, Spin, Typography, Tooltip, Select, DatePicker, Card} from "antd";
import {ClockCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {
    Chart,
    LineElement,
    PointElement,
    LinearScale,
    Title as ChartTitle,
    CategoryScale,
    BarElement,
    Legend,
    TimeSeriesScale,
    ChartData,
    Point,
} from 'chart.js';
import dayjs, {Dayjs} from "dayjs";
import {blue, red, green, yellow, geekblue, purple, magenta, grey, gold, orange} from '@ant-design/colors';
import {Line} from "react-chartjs-2";
import type {ChartOptions} from "chart.js/dist/types";


import {AnalyticsService} from "../../../../services";
import {weekendDatesHighlighter} from "utils/graphweekends";
import {DATE_RANGE_FILTER_SELECTOR_OPTIONS} from "../../../../helpers/dates-helper";


Chart.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, ChartTitle, TimeSeriesScale, Legend);


type AppActiveUsersMetricProps = any


interface MetricType {
    operatingSystem: string
    date: string
    activeUsers: number
    adoptionRate?: number // only for second chart
}

const {Title} = Typography
const colorShade = 3 // colors shade
const allColors = [
    blue[colorShade],
    red[colorShade],
    green[colorShade],
    yellow[colorShade],
    geekblue[colorShade],
    purple[colorShade],
    magenta[colorShade],
    grey[colorShade],
    gold[colorShade],
    orange[colorShade]
]; // antd colors pallet


const AppActiveUsersMetric: React.FC<AppActiveUsersMetricProps> = () => {
    // todo: refactor
    const [data, setData] = useState<MetricType[]>()
    const [loading, setLoading] = useState<boolean>(false)

    // Used to switch between absolute values and percentage 
    const [showPercentage, setShowPercentage] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);


    const defaultDateRange = DATE_RANGE_FILTER_SELECTOR_OPTIONS[1];
    const defaultDateRangeDates = DATE_RANGE_FILTER_SELECTOR_OPTIONS.find(option => option.label === defaultDateRange.label);

    const [selectedDateRangeLabel, setSelectedDateRangeLabel] = useState<string>(defaultDateRange.label);
    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(defaultDateRangeDates?.value || null);


    useEffect(() => {
        fetchData()
    }, []);


    // useEffect that listens for changes in daterange
    useEffect(() => {
        fetchData()
    }, [dateRange]); // Dependency array


    const fetchData = () => {
        setLoading(true)
        const request = {
            dimensions: ["operatingSystem", "date"],
            metrics: ["activeUsers"],
            start_date: dateRange?.[0].format("YYYY-MM-DD"),
            end_date: dateRange?.[1].format("YYYY-MM-DD"),
        }
        AnalyticsService.getGoogleAnalytics<MetricType>(request)
            .then(res => {
                // console.log(res)
                setData(res.data)
            })
            .catch(err => {
                console.error("Error fetching data, message: ", err)
                message.error("Error fetching data")
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const formatDate = (dd: string) => {
        return dayjs(dd, "YYYYMMDD").format("YYYY-MM-DD")
    }


    const handleDateRangeChange = (selectedLabel: string, dates: (Dayjs | null)[] | Dayjs) => {
        if (!Array.isArray(dates)) return;

        if (selectedLabel === 'Custom Range') {
            setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
            const label = `${dates?.[0]?.format('YYYY-MM-DD')} - ${dates?.[1] ? dates?.[1].format('YYYY-MM-DD') : ''}`;
            setSelectedDateRangeLabel(label);
        } else {
            const selectedOption = DATE_RANGE_FILTER_SELECTOR_OPTIONS.find(option => option.label === selectedLabel);
            // If the selectedOption exists, update the dateRange state
            if (selectedOption) {
                setDateRange(selectedOption.value as [dayjs.Dayjs, dayjs.Dayjs])
                setSelectedDateRangeLabel(selectedOption.label)
            }
        }
    };


    // ------ Users By Operating System over time ------


    const calculateTotalUsersPerDay = (data: MetricType[]): { [k: string]: number } => {
        const totalsPerDay: { [k: string]: number } = {};
        data.forEach((item: MetricType) => {
            totalsPerDay[formatDate(item.date)] = (totalsPerDay[formatDate(item.date)] || 0) + Number(item.activeUsers);
        });

        return totalsPerDay;
    };


    const filterData = (rawData: MetricType[]): MetricType[] => {
        const totalUsersPerDay = calculateTotalUsersPerDay(rawData);
        // filter last 1 day
        // get top 10 based on operating system 
        const endDate = dateRange?.[1]?.add(-1, 'd').format("YYYY-MM-DD") || ''
        const topN: string[] = rawData.filter(item => formatDate(item.date).localeCompare(endDate) >= 0)
            .sort((a: MetricType, b: MetricType) => {
                return Number(b.activeUsers) - Number(a.activeUsers)
            })
            .slice(0, 10)
            .map(item => item.operatingSystem)
        const filteredData = rawData.filter(item => topN.includes(item.operatingSystem)).sort((a: MetricType, b: MetricType) => {
                return Number(a.date) - Number(b.date)
            }
        )

        filteredData.forEach(item => {
            item.adoptionRate = Number(item.activeUsers) / totalUsersPerDay[formatDate(item.date)];

        });
        return filteredData

    }

    const usersByOperatingSystemsOverTimeData = data ? filterData(data) : []
    let colorIndex = 0;
    const uniqueDates = Array.from(new Set(usersByOperatingSystemsOverTimeData?.map(d => formatDate(d.date))));

    // Group Data by App Version
    interface GroupedDataType {
        [key: string]: {
            date: string,
            metric: number | undefined
        }[]
    }

    const groupedData = usersByOperatingSystemsOverTimeData.reduce((acc: GroupedDataType, curr) => {

        const operatingSystem = curr.operatingSystem;
        if (!acc[operatingSystem]) {
            acc[operatingSystem] = [];
        }
        acc[operatingSystem].push({
            date: curr.date,
            metric: showPercentage ? curr.adoptionRate : curr.activeUsers
        });
        return acc;
    }, {});

    // Step 2: Create Chart Datasets
    const datasets = Object.keys(groupedData).map(operatingSystem => {
        return {
            label: operatingSystem,
            data: groupedData[operatingSystem].map(d => ({x: formatDate(d.date), y: d.metric})),
            fill: false,
            borderColor: allColors[colorIndex++ % allColors.length],
            backgroundColor: '#fff',
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 5,
        };
    });


    const lastDayValues: { [key: string]: number } = {};
    usersByOperatingSystemsOverTimeData.forEach(metric => {
        if (formatDate(metric.date) === uniqueDates[uniqueDates.length - 1]) {
            lastDayValues[metric.operatingSystem] = metric.activeUsers;
        }
    });


    const chartData: ChartData<"line", (number | Point | null)[], string> = {
        labels: uniqueDates || [],
        datasets: datasets
    };

    const chartOptions: ChartOptions<any> = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd MMM yyyy',
                    displayFormats: {
                        day: 'dd MMM'
                    }
                },
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: any) {
                        return showPercentage ? `${value * 100}%` : value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Use the point style instead of the default box
                    pointStyle: 'circle', // Set the point style to circle
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += showPercentage ? `${100 * context.parsed.y.toFixed(2)}%` : context.parsed.y;
                        }
                        return label;
                    }
                }
            }
        },
        tension: 0.4,
    };

    // ------ End Users By Operating System over time ------

    // ------ All active users ------
    const sumOfActiveUsers: { [k: string]: number } = {};
    usersByOperatingSystemsOverTimeData.forEach(metric => {
        const date = formatDate(metric.date);
        if (!sumOfActiveUsers[date]) {
            sumOfActiveUsers[date] = 0;
        }
        sumOfActiveUsers[date] += Number(metric.activeUsers);
    });

    chartData.datasets.push({
        label: 'All active users',
        data: uniqueDates.map((date: string) => showPercentage ? 1 : sumOfActiveUsers[date]),
        fill: false,
        borderColor: allColors[colorIndex++ % allColors.length],
        backgroundColor: '#fff',
        borderWidth: 2,
        pointRadius: 2,
        pointHoverRadius: 5,
    });
    // ------ End All active users ------
    const handleDropdownVisibleChange = (flag: boolean) => {
        setDropdownOpen(flag);
    };


    return (
        <div>


            <Card title="App Active users"
                  loading={loading}
                  extra={
                      <>
                          <Tooltip title="Updated Now">
                              <ClockCircleOutlined style={{marginRight: 10}}/>
                          </Tooltip>

                          <Select
                              defaultValue={showPercentage ? "Rate" : "Count"}
                              style={{width: 100, marginRight: 8}}
                              onChange={(value) => setShowPercentage(value === "Rate")}
                          >
                              <Select.Option value="Count">Count</Select.Option>
                              <Select.Option value="Rate">Rate %</Select.Option>
                          </Select>


                          <Select
                              style={{marginRight: 8, minWidth: 150}}
                              value={selectedDateRangeLabel}
                              defaultValue={defaultDateRange.label}
                              onChange={handleDateRangeChange}
                              onDropdownVisibleChange={handleDropdownVisibleChange}
                              open={dropdownOpen}
                              dropdownRender={(menu) => (
                                  <>
                                      {menu}
                                      <div style={{padding: '8px', borderBottom: '1px solid #f0f0f0'}}
                                           onMouseDown={(e) => e.stopPropagation()}>
                                          Custom:
                                      </div>
                                      <div style={{display: 'flex', padding: '8px'}}
                                           onMouseDown={(e) => e.stopPropagation()}>
                                          <DatePicker
                                              defaultValue={dateRange?.[0]}
                                              style={{flex: 1}}
                                              allowClear={false}
                                              onChange={(startDate) => {
                                                  handleDateRangeChange('Custom Range', [startDate, dateRange?.[1] || null])
                                                  setDropdownOpen(false);
                                              }}
                                          />
                                      </div>
                                      <div style={{display: 'flex', padding: '8px'}}
                                           onMouseDown={(e) => e.stopPropagation()}>

                                          <DatePicker
                                              defaultValue={dateRange?.[1]}
                                              style={{flex: 1}}
                                              allowClear={false}
                                              onChange={(endDate) => {
                                                  handleDateRangeChange('Custom Range', [dateRange?.[0] || null, endDate])
                                                  setDropdownOpen(false);
                                              }}
                                          />
                                      </div>
                                  </>
                              )}
                          >
                              {DATE_RANGE_FILTER_SELECTOR_OPTIONS.map((option) => (
                                  <Select.Option key={option.label} value={option.label}>
                                      {option.label}
                                  </Select.Option>
                              ))}
                          </Select>


                          <Button
                              size="middle"
                              loading={loading}
                              onClick={fetchData}
                              icon={<ReloadOutlined/>}
                              style={{marginRight: 8}}
                          />

                      </>
                  }>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Space direction="horizontal">
                                    <Title level={5}>Active Users By Operating System Over Time</Title>
                                </Space>
                                <Spin spinning={loading}>
                                    <div className="w-100 d-bloc" style={{minHeight: 400}}>
                                        {
                                            loading ?
                                                <p>Loading...</p> :
                                                data && <Line data={chartData} height={150} options={chartOptions}
                                                              plugins={[weekendDatesHighlighter]}/>
                                        }
                                    </div>
                                </Spin>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Card>


        </div>
    )

}

export default AppActiveUsersMetric  