import React, {useMemo} from 'react';
import {
    CategoryScale,
    Chart, Legend,
    LinearScale,
    PointElement,
    TimeSeriesScale,
    Title,
    BarElement
} from "chart.js";
import {Line} from 'react-chartjs-2';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// ----- Plugins -----
Chart.register(BarElement, PointElement, LinearScale, CategoryScale, Title, TimeSeriesScale, Legend);
dayjs.extend(relativeTime);

// ----- Local calls -----
import {generateColors} from "utils/colors";
import {Rating, ReviewSource} from "types";

// ----- Global variables -----
const COLORS = generateColors(3);

// ----- Types -----
interface ProductsRatingsProps {
    data: Rating[];
    loading: boolean;
    lastUpdatedAt: Date | null;
}

// ----- Components -----
const ProductsRatings: React.FC<ProductsRatingsProps> = ({data}) => {
    // ---- Chart Data ----
    const {labels, datasets} = useMemo(() => {
        // Filter only Amazon data
        const allowedSources = [ReviewSource.AMAZON];
        // Filter only the ios and android data
        const filteredData = data.filter((rating: Rating) => allowedSources.includes(rating.ratingSource));

        // Group by month and product.
        interface GroupedByProduct {
            [productName: string]: Rating; // { date: Date; averageRating: number }[];
        }

        const groupedByMonthAndProduct = filteredData.reduce((acc, rating: Rating) => {
            const month = dayjs(rating.date).format('YYYY-MM'); // Group by month
            const name = rating.productName || 'Unknown'; // Group by product
            const key = `${name}-${month}`;
            if (!acc[key]) {
                acc[key] = rating;
            } else {
                // Keep the rating with the latest date in the month
                if (dayjs(rating.date).isAfter(dayjs(acc[key].date))) {
                    acc[key] = rating;
                }
            }
            return acc;
        }, {} as GroupedByProduct);

        // Extract unique products
        const products = Array.from(new Set(filteredData.map(rating => rating.productName || 'Unknown')));
        // Extract unique months for labels, sorted
        const allDates = Array.from(new Set(filteredData.map(rating => dayjs(rating.date).format('YYYY-MM')))).sort();
        // Create a dataset for each product
        const datasets = products.map((product, index) => {
            const data = allDates.map(date => {
                const key = `${product}-${date}`;
                const rating = groupedByMonthAndProduct[key];
                return rating ? rating.averageRating : null;
            });

            // Example color assignment, modify as needed
            const borderColor = `hsl(${(index * 360) / products.length}, 70%, 50%)`; // Diversify colors

            return {
                label: product,
                data,
                fill: false,
                borderColor,
                borderWidth: 2,
                backgroundColor: '#fff',
            };
        });

        return {labels: allDates, datasets};
    }, [data]);

    const chartData = {labels, datasets};

    const chartOptions = {
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Average Product Rating Over Time',
            }
        },
        responsive: true,
    };


    return (
        <Line data={chartData} height={150} options={chartOptions}/>
    )
}

export default ProductsRatings
