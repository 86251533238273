import React, { useState } from "react";
import {Select, Space, Spin, Typography} from "antd";
import {
    BarElement,
    CategoryScale,
    Chart,
    ChartData,
    Legend,
    LinearScale,
    LineElement,
    Point,
    PointElement,
    TimeSeriesScale,
    Title as ChartTitle,
    Tooltip,
} from 'chart.js';
import {blue, geekblue, gold, green, grey, magenta, orange, purple, red, yellow} from '@ant-design/colors';
import {Line} from "react-chartjs-2";
import type {ChartOptions} from "chart.js/dist/types";

import {FeatureUsageData} from "../../../../../types";
import { he } from "date-fns/locale";
import {weekendDatesHighlighter} from "utils/graphweekends";


Chart.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, ChartTitle, TimeSeriesScale, Tooltip, Legend);


type MetricType = {
    date: string;
    allActiveUsersCount: string;
    appUsersCount : string;
    habitFeatureUsersCount: string;
    pokesFeatureUsersCount: string;
    integrationsFeatureUsersCount: string;
    alarmsFeatureUsersCount: string;
    sleepTrackingFeatureUsersCount: string;
    timersFeatureUsersCount: string;
    stepsFeatureUsersCount: string;
}

const {Title} = Typography
const colorShade = 3 // colors shade
const allColors = [
    blue[colorShade],
    red[colorShade],
    green[colorShade],
    yellow[colorShade],
    geekblue[colorShade],
    purple[colorShade],
    magenta[colorShade],
    grey[colorShade],
    gold[colorShade],
    orange[colorShade]
]; // antd colors pallet

// ----- Types -----
interface FeaturesUsageOverTimeProps {
    dailyData: FeatureUsageData[] | null
    weeklyData?: FeatureUsageData[] | null
    loading: boolean
    fetchData?: () => void
}

// ----- Component -----
const FeaturesUsageOverTime: React.FC<FeaturesUsageOverTimeProps> = ({dailyData, weeklyData, loading, fetchData}) => {


    // Used to switch between absolute values and percentage 
    const [showPercentage, setShowPercentage] = useState(true); 
    const [showWeekly, setShowWeekly] = useState(true);



    const filterData = (rawData: FeatureUsageData[]): MetricType[] => {
        const filteredData =rawData.map((item) => {
            const {
                updatedAt,
                ...other
            } = item
            return other
        })
        return filteredData

    }

    const filteredData = showWeekly ?  (weeklyData ? filterData(weeklyData) : []) : (dailyData ? filterData(dailyData) : [])
    const uniqueDates = Array.from(new Set(filteredData?.map(item => item.date)));
    let colorIndex = 0;

    const features = [
        { key: 'appUsersCount', label: 'App Users' },
        { key: 'alarmsFeatureUsersCount', label: 'Alarms ' },
        { key: 'habitFeatureUsersCount', label: 'Habits' },
        { key: 'pokesFeatureUsersCount', label: 'Pokes' },
        { key: 'integrationsFeatureUsersCount', label: 'Integrations IFTTT/Zapier' },
        { key: 'sleepTrackingFeatureUsersCount', label: 'Sleep Tracking ' },
        { key: 'timersFeatureUsersCount', label: 'Timers ' },
        { key: 'stepsFeatureUsersCount', label: 'Steps Tracking' },
    ];

    const generateDataset = (feature: { key: string, label: string }, data: MetricType[]) => {
        return {
            label: feature.label,
            data: data.map((item: MetricType) => {
                const featureCount = Number(item[feature.key as keyof MetricType]);
                const allActiveUsersCount = Number(item.allActiveUsersCount);
                return showPercentage ? (allActiveUsersCount ? featureCount / allActiveUsersCount : 0) : featureCount;
            }),
            borderColor: allColors[colorIndex++ % allColors.length],
            backgroundColor: '#fff',
            fill: false,
            borderWidth: 2,
            pointRadius: 1, 
            pointHoverRadius: 5, 

        };
    };       
    


    
    const chartData: ChartData<"line", (number | Point | null)[], string> = {
        labels: uniqueDates || [],
        datasets: features.map(feature => generateDataset(feature, filteredData))
    };

    
    const chartOptions: ChartOptions<any> = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Use the point style instead of the default box
                    pointStyle: 'circle', // Set the point style to circle
                },
            },
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += showPercentage ? `${100*context.parsed.y.toFixed(2)}%` : context.parsed.y  ; 
                        }
                        return label;
                    }
                }
            }, 
            
        },
        tension: 0.4,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd MMM yyyy',
                    displayFormats: {
                        day: 'dd MMM'
                    }
                },
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: any) {
                        return showPercentage ? `${value*100}%` : value;
                    }
                }
            }
        },
        
    };
    

    return (
        <div>

            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={4}>Features usage over time</Title>
                <div >

                    <Select
                        defaultValue= {showWeekly ? "7days" : "Daily"}
                        style={{ marginRight: 8 }}
                        onChange={(value) => setShowWeekly(value === "7days")}
                    >
                        <Select.Option value="Daily">Daily Active users</Select.Option>
                        <Select.Option value="7days">7days Active users</Select.Option>
                    </Select>


                    <Select
                        defaultValue= {showPercentage ? "Rate" : "Count"}
                        style={{ width: 100, marginRight: 8 }}
                        onChange={(value) => setShowPercentage(value === "Rate")}
                    >
                        <Select.Option value="Count">Count</Select.Option>
                        <Select.Option value="Rate">Rate %</Select.Option>
                    </Select>

                    
                </div>
            </Space>



            <Spin spinning={loading}>
                <div className="w-100 d-bloc">
                    {
                        loading ? 
                            <p>Loading...</p> :
                            dailyData && <Line data={chartData} height={150} options={chartOptions} plugins={[weekendDatesHighlighter]}/>
                    }
                </div>
            </Spin>
        </div>
    )
}

export default FeaturesUsageOverTime