import React from "react";
import {Card, Typography, Space, Avatar, Tooltip, Divider} from "antd";

// ----- Local calls -----
import {Participant, Team} from "../../../../../types";
import {AntDesignOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

// ----- Global Variables -----
const {Title} = Typography

// ----- Types -----
interface TeamsRankingProps {
    teams: Team[] | null
    loading: boolean
}

// ----- Components -----
const TeamsRanking: React.FC<TeamsRankingProps> = ({teams, loading}) => {
    const teamsWithScore: Team[] = teams?.map(team => ({
        ...team,
        score: team.participants.reduce((previousValue, currentValue) => previousValue + currentValue.points, 0)
    })) || []

    const rankedTeams = teamsWithScore?.sort((a: Team, b: Team) => Number(b?.score) - Number(a?.score))
    return (
        <>
            <Space direction="vertical" className="w-100">
                {
                    rankedTeams?.map((team: Team, index: number) => (
                        <Card key={`team-${team.id}`} className="w-100" bodyStyle={{padding: 16}}>
                            <div>
                                <Title level={5}>#{Number(index + 1)} {team?.name}</Title>
                                <div>Members: {team?.participants?.length || 0} Points: {team?.score}</div>
                            </div>
                            <Divider/>
                            <div>
                                <Avatar.Group>
                                    {
                                        team?.participants?.map((item: Participant) => (
                                            <Link to={`/users/${item.userId}`}>
                                                <Tooltip title={item?.firstName} placement="top">
                                                    <Avatar src={item?.profilePictureUrl}/>
                                                </Tooltip>
                                            </Link>
                                        ))
                                    }
                                </Avatar.Group>
                            </div>
                        </Card>

                    ))
                }
            </Space>
        </>
    )
}

export default TeamsRanking