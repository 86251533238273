import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Space, Table} from 'antd';
import {EditOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';


import {BaseModel, VoltsAction} from "types";
import {VoltsActionsService} from "services";
import {history} from 'utils/history'
import {getColumnSearchProps} from "components/TableSearch";
import CustomBreadcrumb from "components/CustomBreadcrumb";


type DataIndex = keyof BaseModel | string;


const VoltsActionsListPage: React.FC = () => {
    const [voltsActions, setVoltsActions] = useState<VoltsAction[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        VoltsActionsService.getAllVoltsActions()
            .then(res => {
                const voltsActionsResponse = res?.data?.voltsActions || []
                // console.log('voltsActionsList', voltsActionsList)
                setVoltsActions(voltsActionsResponse)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const columns: ColumnsType<VoltsAction> = [
        {
            title: 'key',
            dataIndex: 'key',
            key: 'key',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'key'
            }),
        },
        {
            title: 'description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'description'
            }),
        },
        {
            title: 'Volts',
            dataIndex: 'volts',
            key: 'volts'
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (value: number) => (
                <Space>
                    <Button
                        type="primary"
                        onClick={() => history?.navigate?.(`/volts-actions/edit/${value}`)}
                        icon={<EditOutlined/>}
                    />
                </Space>
            )
        }
    ];

    return (
        <>
            <CustomBreadcrumb list={['VoltsActions', 'LiST',]}/>
            <Card title="VoltsActions list"
                  extra={
                      <Space>
                          <Button 
                                  loading={loading}
                                  onClick={() => fetchData()}
                                  icon={<ReloadOutlined/>}/>
                          <Button onClick={() => history?.navigate?.('/volts-actions/create')}
                                  icon={<PlusOutlined/>}>
                              Create voltsAction
                          </Button>
                      </Space>
                  }
            >
                <Table<VoltsAction>
                    columns={columns}
                    dataSource={voltsActions}
                    loading={loading}
                    size="small"
                    bordered
                    pagination={{
                        showSizeChanger: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                    }}
                />
            </Card>
        </>
    )

};

export default VoltsActionsListPage;