import {blue, geekblue, gold, green, grey, magenta, orange, purple, red, yellow} from "@ant-design/colors";

export const generateColors = (colorShade: number) => {
    // These colors are chosen to stand out against both light and dark backgrounds
    const baseColors = [
        `hsl(205, 70%, ${50 + colorShade}%)`, // Blue
        `hsl(0, 70%, ${50 + colorShade}%)`,   // Red
        `hsl(120, 40%, ${50 + colorShade}%)`, // Green
        `hsl(60, 70%, ${50 + colorShade}%)`,  // Yellow
        `hsl(30, 100%, ${50 + colorShade}%)`, // Orange
        `hsl(280, 50%, ${50 + colorShade}%)`, // Purple
        `hsl(340, 70%, ${50 + colorShade}%)`, // Magenta
        `hsl(210, 10%, ${40 + colorShade}%)`, // Grey (darker for contrast)
        `hsl(50, 60%, ${50 + colorShade}%)`,  // Gold
        `hsl(10, 30%, ${50 + colorShade}%)`,  // Brown (alternative to orange)
    ];

    // If more colors are needed, generate them by adjusting the lightness
    const additionalColors = baseColors.map(color =>
        color.replace(`${50 + colorShade}%`, `${60 + colorShade}%`)
    );

    return [...baseColors, ...additionalColors];
};

export const getAtndColor = (index: number, colorShade?: number) => {
    const list = getAtndColors(colorShade);
    return list[index % list.length];
}
export const getAtndColors = (colorShade?: number) => {
    colorShade = colorShade || 3;
    const allColors = [
        blue[colorShade],
        red[colorShade],
        green[colorShade],
        purple[colorShade],
        magenta[colorShade],
        yellow[colorShade],
        grey[colorShade],
        geekblue[colorShade],
        gold[colorShade],
        orange[colorShade]
    ]; // antd colors pallet

    return allColors;
}

export const getRandomColor = (colorShade?: number) => {
    const allColors = generateColors(colorShade || 3);
    return allColors[Math.floor(Math.random() * allColors.length)];
}

export const getRandomColorName = () => {
    const colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]
    return colors[Math.floor(Math.random() * colors.length)];
}
export const getRandomColorNameByIndex = (index: number) => {
    const colors = ["magenta", "red", "volcano", "orange", "gold", "lime", "green", "cyan", "blue", "geekblue", "purple"]
    return colors[index % colors.length];
}