import DiagnosticLogsApi from "./diagnostic-logs-api";
import {BatteryGraphPointBatteryGraphPoint, BatteryInfo, DiagnosticLogsRecord} from "../types";
import {AxiosResponse} from "axios";


export const getBatteryInfo = (macAddress: string): Promise<AxiosResponse<BatteryInfo>> => {
    return DiagnosticLogsApi.get('/diagnostic_logs/battery-info', {
        params: {
            mac_addr: macAddress
        }
    })
}

interface GetBatteryGraphResponse {
    batteryGraphData: BatteryGraphPointBatteryGraphPoint[]
}

export const getBatteryGraph = (macAddress: string): Promise<AxiosResponse<GetBatteryGraphResponse>> => {
    return DiagnosticLogsApi.get('/diagnostic_logs/battery-graph', {
        params: {
            mac_addr: macAddress
        }
    })
}

interface GetBatteryGraphByIntervalProps {
    macAddress: string
    startDate: string
    endDate: string
}

export const getBatteryGraphByInterval = (params: GetBatteryGraphByIntervalProps): Promise<AxiosResponse<GetBatteryGraphResponse>> => {
    return DiagnosticLogsApi.get('/diagnostic_logs/battery-graph-by-interval', {
        params: {
            mac_addr: params.macAddress,
            start_date: params.startDate,
            end_date: params.endDate
        }
    })
}

// todo: make this code shared
type PageRecordResponse = {
    items: DiagnosticLogsRecord[];
    count: number;
    page: number;
    pageSize: number;
};

export type GetDiagnosticLogsRecordsParams = {
    mac_address: string;
    types?: string[];
    start_date?: string;
    end_date?: string;
    page?: number;
    page_size?: number;
};

export type GetDiagnosticLogsByUserIdParams = {
    user_id: number;
    types?: string[];
    start_date?: string;
    end_date?: string;
    page?: number;
    page_size?: number;
};

export const getDiagnosticLogsRecords = async (
    params: GetDiagnosticLogsRecordsParams
): Promise<PageRecordResponse> => {
    // format: link
    const {types, ...otherParams} = params
    const typesString = (types || [])?.map(type => `types=${type}`).join('&') + ""
    const response: AxiosResponse<PageRecordResponse> = await DiagnosticLogsApi.get(
        `/diagnostic_logs/records-by-mac-address?${typesString}`,
        {
            params: otherParams,
        }
    );

    return response.data;
};

export const getDiagnosticLogsByUserId = async (params: GetDiagnosticLogsByUserIdParams): Promise<PageRecordResponse> => {
    // convert the types array to a query string
    const {types, ...otherParams} = params
    const typesString = (types || [])?.map(type => `types=${type}`).join('&') + ""
    const response: AxiosResponse<PageRecordResponse> = await DiagnosticLogsApi.get(
        `/diagnostic_logs/records-by-userid?${typesString}`,
        {
            params: otherParams,
        }
    );

    return response.data;
}

export default {
    getBatteryInfo, getBatteryGraph, getDiagnosticLogsByUserId
}