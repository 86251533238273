import React, {useEffect, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Avatar, Image, Layout, Menu, Space, theme, Typography} from 'antd';
import {
    UsergroupAddOutlined,
    HomeOutlined,
    LogoutOutlined,
    UserOutlined,
    PicCenterOutlined,
    ThunderboltOutlined,
    CrownOutlined,
    DollarOutlined,
    CloudDownloadOutlined,
    DotChartOutlined,
    MobileOutlined, RadarChartOutlined, TrophyOutlined, BarChartOutlined, StarOutlined, NotificationOutlined
} from '@ant-design/icons';
import {MenuProps} from 'antd/lib/menu';
import {useDispatch, useSelector} from 'react-redux';

// ----- Local calls -----
import {logout} from 'redux/authSlice';
import {LocalStorage} from 'utils'
import {RootState} from "redux/state";
import logo from 'assets/images/pavlok-logo.svg';
import './style.css'

// Variables
const {Header, Content, Sider, Footer} = Layout;

const sideMenuItems: MenuProps['items'] = [
    {
        key: "sider:home",
        icon: <HomeOutlined/>,
        label: <NavLink to="/">Home</NavLink>,
    },
    {
        key: "sider:metrics",
        icon: <StarOutlined/>,
        label: <NavLink to="/metrics">Metrics</NavLink>,
    },
    {
        key: "sider:users",
        icon: <UsergroupAddOutlined/>,
        label: <NavLink to="/users">Users</NavLink>,
    },
    {
        key: 'sider:device',
        icon: <ThunderboltOutlined/>,
        label: 'Devices',
        children: [
            {
                key: "sider:devices",
                icon: <ThunderboltOutlined/>,
                label: <NavLink to="/devices">Devices</NavLink>,
            },
            {
                key: "sider:firmwares",
                icon: <CloudDownloadOutlined/>,
                label: <NavLink to="/firmwares">Firmware versions</NavLink>,
            },
            {
                key: "sider:diagnostic-logs",
                icon: <DotChartOutlined/>,
                label: <NavLink to="/diagnostic-logs">Diagnostic logs explorer</NavLink>,
            },
            {
                key: "sider:user-logs",
                icon: <BarChartOutlined/>,
                label: <NavLink to="/user-logs">User logs explorer</NavLink>,
            },
        ]
    },
    {
        key: 'sider:app',
        icon: <MobileOutlined/>,
        label: 'App',
        children: [
            {
                key: "sider:campaigns",
                icon: <NotificationOutlined/>,
                label: <NavLink to="/campaigns">Campaigns</NavLink>,
            },
            {
                key: "sider:banners",
                icon: <PicCenterOutlined/>,
                label: <NavLink to="/banners">Banners</NavLink>,
            },
            {
                key: "sider:habits",
                icon: <RadarChartOutlined/>,
                label: <NavLink to="/habits">Habits</NavLink>,
            },
            {
                key: "sider:challenges",
                icon: <TrophyOutlined/>,
                label: <NavLink to="/challenges">Challenges</NavLink>,
            },
            {
                key: "sider:badges",
                icon: <CrownOutlined/>,
                label: <NavLink to="/badges">Badges</NavLink>,
            },
            {
                key: "sider:volts-actions",
                icon: <DollarOutlined/>,
                label: <NavLink to="/volts-actions">Volts Actions</NavLink>,
            },
        ]
    }
]

// Types
interface AppLayoutProps {
    children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({children}) => {
    const authenticatedUser = useSelector((state: RootState) => state?.auth?.authenticatedUser);
    const dispatch = useDispatch();
    const location = useLocation();

    // 2. Manage the selected keys in state
    const [selectedKey, setSelectedKey] = useState<string>('sider:device');

    // 3. Map paths to menu keys
    const pathToKeyMap: { [key: string]: string } = {
        '/': 'sider:home',
        '/metrics': 'sider:metrics',
        '/users': 'sider:users',
        '/users-devices': 'sider:users-devices',
        '/firmwares': 'sider:firmwares',
        '/diagnostic-logs': 'sider:diagnostic-logs',
        '/user-logs': 'sider:user-logs',
        '/campaigns': 'sider:campaigns',
        '/banners': 'sider:banners',
        '/habits': 'sider:habits',
        '/challenges': 'sider:challenges',
        '/badges': 'sider:badges',
        '/volts-actions': 'sider:volts-actions'
    };

    useEffect(() => {
        // Update selectedKey when location changes
        setSelectedKey(pathToKeyMap[location.pathname] || '');
    }, [location.pathname]);

    const handleLogout = () => {
        // console.log('handleLogout')
        LocalStorage.deleteItem('token')
        dispatch(logout());
    }

    const navbarMenuItems: MenuProps['items'] = [
        {
            key: "navbar:home",
            icon: <HomeOutlined/>,
            label: <NavLink to="/">Home</NavLink>,
        },
        {
            key: "navbar:logout",
            icon: <LogoutOutlined/>,
            label: "Logout",
            onClick: () => handleLogout(),
            // label: <span onClick={() => handleLogout()}>Logout</span>,
        },
    ]

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return (
        <Layout>
            <Header className="header">
                <div className="logo">
                    <Image src={logo} alt="Pavlok" preview={false}/>
                </div>
                <div style={{float: 'right'}}>
                    <Space>
                        <Link type="primary" to={`/users/${authenticatedUser?.id}`}>
                            <Space>
                                {
                                    authenticatedUser?.profilePictureUrl ?
                                        <Avatar src={authenticatedUser.profilePictureUrl}/> :
                                        <Avatar style={{backgroundColor: '#1677ff'}}
                                                icon={<UserOutlined/>}></Avatar>
                                }
                                <div style={{display: "flex", flexDirection: "column",}}>
                                    <Typography.Text style={{color: "#fefefe"}}>
                                        {authenticatedUser?.firstName} {authenticatedUser?.lastName}
                                        {(!authenticatedUser?.firstName && !authenticatedUser?.lastName) ? authenticatedUser?.email : null}
                                    </Typography.Text>
                                    <Typography.Text style={{
                                        color: "#fefefe",
                                        fontSize: 11
                                    }}>{authenticatedUser?.role}</Typography.Text>
                                </div>
                            </Space>
                        </Link>
                        <Menu theme="dark"
                              mode="horizontal"
                              items={navbarMenuItems}
                        >
                        </Menu>
                    </Space>
                </div>

            </Header>
            <Layout>
                <Sider width={200} style={{background: colorBgContainer}}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['sider:home']}
                        selectedKeys={[selectedKey]}
                        defaultOpenKeys={['sider:app', 'sider:device']}
                        style={{height: '100%', borderRight: 0}}
                        items={sideMenuItems}
                    />
                </Sider>
                <Layout style={{padding: '0 24px 24px'}}>
                    <Content style={{padding: '0', minHeight: '120vh'}}>
                        <div style={{padding: 0, minHeight: '100%'}}>
                            {children}
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Pavlok ©2023</Footer>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default AppLayout