import React from "react";
import {Avatar, Card, List, Space} from "antd";
import {Link} from "react-router-dom";
// import {useParams} from "react-router-dom";
// import {InputRef} from "antd";

// ----- Local calls -----
import {Participant, User} from "../../types";
// ----- Global variables -----
// ----- Types -----
/*type RouteParams = {
    userId?: string
}
 */
interface UsersListProps {
    users: User[]
    loading: boolean
}

// ----- Components -----
const UsersList: React.FC<UsersListProps> = ({users, loading}) => {
    // ----- Params -----
    // const {userId} = useParams<RouteParams>();
    // ----- Data -----
    // ----- References -----
    // const inputRef = useRef<InputRef | null>(null);

    // ----- Calls -----

    // ----- Handlers -----

    return (
        <Card title="Users">
            <List<User>
                className="participants-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={users}
                renderItem={(user: User, index: number) => (
                    <List.Item
                        actions={[
                            <span key="list-participant-pending-habit-count">

                        </span>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Space>
                                    <Avatar src={user.profilePictureUrl}/>
                                </Space>}
                            title={
                                <Link to={`/users/${user?.id}`}>
                                    {user?.firstName} {user?.lastName}
                                </Link>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>

    )
}

export default UsersList
