import React from "react";
import {Avatar} from "antd";
import {RobotOutlined, UserOutlined} from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

// ----- Local calls -----
import TypingIndicator from "./TypingIndicator";
import {AiChatMessage, AiChatMessageRole} from "types";


// ----- Global variables -----
const RENDERERS = {
    // Custom image renderer
    img: ({node: _node, ...props}: { node?: unknown }) => (
        <img alt={"ra"} {...props} style={{maxWidth: '100%', width: 300, height: 'auto'}}/>
    ),
};

// ----- Types -----
interface MessageProps {
    message: AiChatMessage
}

// ----- Components -----
const Message: React.FC<MessageProps> = ({message}) => {
    const assistantRoles = ['assistant', 'typing'];
    const isAssistantOrTyping = assistantRoles.includes(message.role) || false;


    function formatMessage(content: string) {
        return <ReactMarkdown
            components={RENDERERS}
        >
            {content}
        </ReactMarkdown>;
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '10px'
        }}>
            {message.role === AiChatMessageRole.USER &&
                <Avatar icon={<UserOutlined/>} style={{marginRight: '10px', backgroundColor: '#1677ff'}}/>
            }
            {isAssistantOrTyping &&
                <Avatar icon={<RobotOutlined/>} style={{marginRight: '10px', backgroundColor: '#8338ec',}}/>
            }
            <div style={{
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: isAssistantOrTyping ? '#ffe7ba' : '#e6f7ff',
                maxWidth: '75%',
                wordWrap: 'break-word',
            }}>
                {
                    message.role === AiChatMessageRole.TYPING ?
                        <TypingIndicator/>
                        :
                        <p style={{margin: 0}}>
                            {formatMessage(message.content)}
                        </p>
                }
            </div>

        </div>
    );
}

export default Message