import { AxiosResponse } from "axios";

import Api from "./api";
import { PhoneDevice } from "../types";

interface GetPhoneDevicesResponse {
    phoneDevices: PhoneDevice[];
}

export const getPhoneDevicesByUserId = (userId: number): Promise<AxiosResponse<GetPhoneDevicesResponse>> => {
    return Api.get(`/admin/phone-devices/`, {
        params: {
            user_id: userId
        }
    });
};

export default {
    getPhoneDevicesByUserId
};
