import React, {useEffect, useState} from "react"
import {Avatar, Card, Col, Row, Skeleton} from "antd";
import {useParams} from "react-router-dom";
import {MobileOutlined} from "@ant-design/icons";


import {getDeviceType} from "../../../utils/devices";
import {Device, User} from "../../../types";
import {DevicesService} from "../../../services";
import pavlok_device_v2 from "../../../assets/images/pavlok_device_v2.png";
import pavlok_device_v3 from "../../../assets/images/pavlok_device_v3.png";
import BgDevice from "../../../assets/images/bg-device.jpg";
import BatteryInfo from "../../../components/BatteryInfo";
import BatteryGraph from "../../../components/BatteryGraph";
import BatteryIssuesList from "../../../components/BatteryIssuesList";
import ZapIssuesList from "../../../components/ZapIssuesList";
import UserSmallCard from "../../../components/UserSmallCard";
import './style.css'
import UsersList from "../../../components/UsersList";

type RouteParams = {
    deviceId: string;
};

const DeviceDetailsPage: React.FC = () => {
    const {deviceId} = useParams<RouteParams>();

    const [loading, setLoading] = useState<boolean>(false)
    const [device, setDevice] = useState<Device | null>(null)
    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (!deviceId) return
        setLoading(true)
        // console.log("Get deviceId:", deviceId)
        DevicesService.getDeviceById(Number(deviceId))
            .then(res => {
                console.log('get deviceId:', res)
                if (res && res.data && res.data.device) {
                    setDevice(res.data.device)
                }
                setUsers(res.data.users || [])
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <div
                className="user-device-nav-bg"
                style={{backgroundImage: `url(${BgDevice})`}}
            />
            <Card
                className="card-user-device-head"
                bodyStyle={{display: 'none'}}
                title={(
                    <Row justify="space-between" align="middle" gutter={[24, 16]}>
                        <Col span={24} md={12} className="col-info">
                            <Skeleton loading={loading}>
                                <Avatar.Group>
                                    {device && getDeviceType(device) == 2 &&
                                        <Avatar src={pavlok_device_v2} style={{width: 60, height: 110}}/>}
                                    {device && getDeviceType(device) == 3 &&
                                        <Avatar src={pavlok_device_v3} style={{width: 60, height: 110}}/>}
                                    {
                                        (!device || (device && getDeviceType(device) != 3 && getDeviceType(device) != 2)) &&
                                        <Avatar style={{backgroundColor: '#1677ff', width: 60, height: 110}}
                                                icon={<MobileOutlined/>}></Avatar>
                                    }
                                    <div className="avatar-info">
                                        <h4 className="font-semibold m-0">{device?.name}</h4>
                                        <p>{device?.firmwareVersion}</p>
                                        <p>{device?.hardwareVersion}</p>
                                        <p>{device?.macAddress}</p>
                                    </div>
                                </Avatar.Group>
                            </Skeleton>
                        </Col>
                    </Row>
                )}
            />
            <Row gutter={[16, 16]}>
                {/* <Col span={24}>
                    <Title level={2}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</Title>
                    <Text type="secondary">{user?.username && `@${user?.username}`}</Text>
                </Col>*/}
                <Col xs={24} sm={24} md={16} lg={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <BatteryGraph macAddress={device?.macAddress}/>
                        </Col>

                        {/* 
                        <Col span={24} >
                            <BatteryIssuesList macAddress={device?.macAddress}/>
                        </Col>
                        <Col span={24}>
                            <ZapIssuesList macAddress={device?.macAddress}/>
                        </Col>
                        */}

                    </Row>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <UsersList loading={loading} users={users}/>
                        </Col>
                        <Col span={24}>
                            <BatteryInfo device={device} macAddress={device?.macAddress}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default DeviceDetailsPage;
