import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    message,
    Card,
    InputNumber
} from "antd";
import {useParams} from "react-router-dom";

import {VoltsActionsService} from "services";
import {VoltsAction} from "types";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {
    VoltsActionInCreate,
    VoltsActionInUpdate
} from "services/volts-actions";


type RouteParams = {
    voltsActionId?: string
}

const formLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 8},
};

const CreateVoltsActionPage: React.FC = () => {
    // ----- params -----
    const {voltsActionId} = useParams<RouteParams>();

    // ----- data -----
    const [voltsAction, setVoltsAction] = useState<VoltsAction | null>(null)
    const [loading, setLoading] = useState(false);

    // ----- form -----
    const [form] = Form.useForm();

    // ----- Init -----
    useEffect(() => {
        // if voltsAction id then Edit case
        if (voltsActionId) {
            fetchVoltsAction()
        }
    }, [voltsActionId])

    useEffect(() => {
        const initialValues = {
            key: voltsAction?.key || "",
            description: voltsAction?.description || "",
            volts: voltsAction?.volts || null,
        };
        form.setFieldsValue(initialValues)
    }, [voltsAction])


    // ----- Actions -----
    const fetchVoltsAction = () => {
        if (!voltsActionId) return
        setLoading(true)
        VoltsActionsService.getVoltsActionById(Number(voltsActionId))
            .then(res => {
                const voltsActionResponse = res?.data?.voltsAction
                if (voltsActionResponse) {
                    setVoltsAction(voltsActionResponse)
                }
            })
            .catch(err => {
                console.error('Error fetching voltsAction, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = async (values: VoltsActionInCreate) => {
        setLoading(true);
        try {
            const voltsAction: VoltsActionInCreate = {
                key: values.key,
                description: values.description,
                volts: values.volts,
            };
            // Make the API request to create or update the voltsAction
            if (voltsActionId) {
                const voltsActionUpdate: VoltsActionInUpdate = {
                    id: Number(voltsActionId),
                    ...voltsAction
                }
                await VoltsActionsService.updateVoltsAction(voltsActionUpdate);
            } else {
                await VoltsActionsService.createVoltsAction(voltsAction);
                // Reset form fields and image state
                form.resetFields();
            }
            // Show success message
            message.success(`VoltsAction ${voltsActionId ? 'updated' : 'created'} successfully`);
        } catch (error) {
            // Show error message
            message.error(`Failed to ${voltsActionId ? 'update' : 'create'} voltsAction`);
        }
        setLoading(false);
    };


    const cardTitle = voltsActionId ? "Edit volts action" : "Create volts action";
    // console.log('initialValues', initialValues)

    return (
        <>
            <CustomBreadcrumb list={['VoltsActions', 'create']}/>
            <Card title={cardTitle}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    {...formLayout}
                    // initialValues={initialValues}
                >
                    <Form.Item name="key" label="Key" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="description" rules={[{required: true}]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item name="volts" label="volts" rules={[{required: true}]}>
                        <InputNumber min={0}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {voltsActionId ? "Update VoltsAction" : "Create VoltsAction"}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default CreateVoltsActionPage;
