import moment from 'moment';
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// ----- Plugins -----
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:MM';

interface FormatDurationOptions {
    showSeconds: boolean
    showMinutes: boolean
}

export function formatDuration(
    durationInSeconds: number,
    options?: FormatDurationOptions
): string {
    const duration = moment.duration(durationInSeconds, 'seconds');
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let formattedDuration = '';
    if (days > 0) {
        formattedDuration += `${days} days, `;
    }
    if (hours > 0) {
        formattedDuration += `${hours} hours, `;
    }
    if (minutes > 0 && options?.showMinutes) {
        formattedDuration += `${minutes} minutes, `;
    }
    if (seconds > 0 && options?.showSeconds) {
        formattedDuration += `${seconds} seconds`;
    }

    // Remove trailing comma and space if applicable
    if (formattedDuration.endsWith(', ')) {
        formattedDuration = formattedDuration.slice(0, -2);
    }

    return formattedDuration;
}

export const timeDifference = (startTime: string, endTime: string): string => {
    const start = moment(startTime);
    const end = moment(endTime);

    const diff = moment.duration(start.diff(end));

    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();

    let formattedDuration = '';
    if (days > 0) {
        formattedDuration += `${days} days, `;
    }
    if (hours > 0) {
        formattedDuration += `${hours} hours, `;
    }
    if (minutes > 0) {
        formattedDuration += `${minutes} minutes, `;
    }
    if (seconds > 0) {
        formattedDuration += `${seconds} seconds`;
    }

    // Remove trailing comma and space if applicable
    if (formattedDuration.endsWith(', ')) {
        formattedDuration = formattedDuration.slice(0, -2);
    }

    return formattedDuration;

}


export function isIsoDateString(value: any): boolean {
    return typeof value === 'string' && !isNaN(Date.parse(value));
}

// Function to generate list of dates
export const generateDateList = (startDataString?: string) => {
    const startDate = dayjs(startDataString || '2023-01-01');
    const currentDate = dayjs();
    const dateList = [];

    let tempDate = startDate;
    while (tempDate.isBefore(currentDate) || tempDate.format('MMM-YYYY') === currentDate.format('MMM-YYYY')) {
        dateList.push(tempDate.format('MMM-YYYY'));
        tempDate = tempDate.add(1, 'month');
    }

    return dateList;
};

export const isDateInSelectedDateRange = (range: [dayjs.Dayjs | null, dayjs.Dayjs | null], dateValue: dayjs.Dayjs, inclusive = true) => {
    const [startDate, endDate] = range;

    // If the date range is 'All time', return true
    // For example, if 'All time' means no filtering on dates
    if (startDate === null && endDate === null) {
        return true;
    }

    // if only start date is selected
    if (startDate && endDate === null) {
        if (inclusive) {
            return dayjs(dateValue).isSameOrAfter(startDate);
        }
        return dayjs(dateValue).isAfter(startDate);
    }

    // if only end date is selected
    if (startDate === null && endDate) {
        // Check if the date is within the range (inclusive)
        if (inclusive) {
            return dayjs(dateValue).isSameOrBefore(endDate);
        }
        return dayjs(dateValue).isBefore(endDate);
    }

    // Check if the date is within the range (inclusive)
    if (inclusive) {
        return dayjs(dateValue).isSameOrAfter(startDate) && dayjs(dateValue).isSameOrBefore(endDate);
    }
    return dayjs(dateValue).isAfter(startDate) && dayjs(dateValue).isBefore(endDate);
}
export default {
    formatDuration,
    generateDateList
}