import React, {useEffect, useState} from "react";
import {Avatar, Button, List, message, Modal} from "antd";


// ----- Local calls -----
import {ChallengesService} from "services"
import {UserHabit} from "types";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

// ----- Types -----
interface ChallengeParticipantLinkedUserHabitsModalProps {
    challengeId: number
    userId: number | null
    open: boolean
    onOk?: () => void
    onCancel?: () => void
}

// ----- Components -----
const ChallengeParticipantLinkedUserHabitsModal: React.FC<ChallengeParticipantLinkedUserHabitsModalProps> = (
    {challengeId, userId, open, onOk, onCancel}
) => {
    // ----- data -----
    const [userHabits, setUserHabits] = useState<UserHabit[]>([])
    const [loading, setLoading] = useState(false); // for all the fetches

    // ----- Init -----
    useEffect(() => {
        // if challenge id then Edit case
        fetchData()
        return () => {
            setUserHabits([])
        }
    }, [challengeId, userId])

    const fetchData = () => {
        if (!challengeId || !userId) return
        setLoading(true)
        ChallengesService.getUserHabitsLinkedWithChallenge(challengeId, userId)
            .then(res => {
                const response: UserHabit [] = res?.data?.userHabits?.map((item: UserHabit) => ({key: item.habitId + '', ...item}))
                console.log("getUserHabitsLinkedWithChallenge", response)
                setUserHabits(response || [])
            })
            .catch(err => {
                console.error('Error fetching participants, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    const handleUpdateLinkStatus = (challengeUserHabitId: number, status: boolean) => {
        if (!challengeId || !userId) return
        setLoading(true)
        ChallengesService.updateChallengeUserHabitLinkStatus(challengeUserHabitId, status)
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus === 200 || responseStatus === 204) {
                    message.success("User habit link changed successfully")
                    fetchData()
                } else {
                    message.error("Ooups something went wrong try again later!")
                }
            })
            .catch(err => {
                console.error('Error fetching participants, message: ', err)
                message.error("Ooups something went wrong try again later!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Modal title="Linked user habits" open={open} onOk={onOk} onCancel={onCancel}>
            <List<UserHabit>
                className="participants-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={userHabits}
                renderItem={(userHabit: UserHabit) => (
                    <List.Item
                        actions={[
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<CheckCircleOutlined style={{color: 'green'}}/>}
                                hidden={userHabit?.validationStatus}
                                onClick={() => userHabit?.challengeUserHabitId && handleUpdateLinkStatus(userHabit?.challengeUserHabitId, true)}
                            />,
                            <Button type="dashed"
                                    shape="circle"
                                    icon={<CloseCircleOutlined style={{color: 'red'}}/>}
                                    hidden={userHabit?.validationStatus}
                                    onClick={() => userHabit?.challengeUserHabitId && handleUpdateLinkStatus(userHabit?.challengeUserHabitId, false)}
                                    danger/>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={userHabit.habitIcon}/>}
                            title={userHabit?.habitName}
                            description={
                                <div>
                                    {userHabit?.goal?.goalValue} {userHabit?.habitUnit} per day
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default ChallengeParticipantLinkedUserHabitsModal
