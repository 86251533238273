import React from 'react';
import Highlighter from "react-highlight-words";
import {Button, Tag, Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";

// ----- Local Calls -----
import EditTopics from "../../EditTopics";
import {Review} from "types";
import {ReviewsMetricsFilter} from "pages/metrics/components/ReviewsMetrics";


// ----- Types -----
interface ReviewContentProps {
    review: Review;
    filters: ReviewsMetricsFilter;
    topics: string[];
}

// ----- Component -----
const ReviewContent: React.FC<ReviewContentProps> = ({review, filters, topics}) => {
    const [editTopics, setEditTopics] = React.useState(false);

    return (
        <>
            <Highlighter
                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                searchWords={filters?.search?.split(' ') || []}
                autoEscape={true}
                textToHighlight={review.review}
            />
            {editTopics ? (
                <div className="mt-3">
                    <EditTopics review={review} topics={topics} onCancel={() => setEditTopics(false)}/>
                </div>
            ) : (
                <div style={{marginTop: '10px'}}>
                    {review?.topics?.map((topic, index) => (
                        <Tag color={topic.impression ? 'green' : 'red'} key={index}>
                            {topic.topic}
                        </Tag>
                    ))}
                    <Tooltip title="Edit">
                        <Button size="small"
                                icon={<EditOutlined/>}
                                onClick={() => setEditTopics(true)}/>
                    </Tooltip>
                </div>
            )
            }
        </>
    )
}

export default ReviewContent;