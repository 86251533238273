export type BaseModel = {
    key?: string;
}

export interface PaginationResponsePage<T> {
    items: T[];
    count: number;
    page: number;
    pageSize: number;
}

export type TimestampsModel = {
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
}

export type DateTimeModelMixin = {
    createdAt: Date;
    updatedAt: Date;
    deletedAt?: Date | null;
}

export type IDModelMixin = {
    id: number;
}

interface UserSetting extends BaseModel {
    settingKey: string;
    settingValue: string;
    settingType: string;
    settingMeta: object;
}

export interface User extends BaseModel {
    username: string;
    email: string;
    phone: string | null;
    countryCode: string | null;
    phoneConfirmed: boolean;
    firstName: string;
    lastName: string;
    birthDate: string | null;
    weight: number | null;
    weightMeasurementUnit: string | null;
    height: number | null;
    heightMeasurementUnit: string | null;
    resetPasswordToken: string | null;
    resetPasswordSentAt: string | null;
    profilePictureId: number | null;
    profilePictureUrl: string | null;
    emailConfirmed: boolean;
    locked: boolean;
    role: string | null;
    token: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    id: number;
    userSettings: {
        [key: string]: UserSetting
    }
}


export interface Friend extends BaseModel {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string | null;
    phone: string | null;
    role: string | null;

}

export interface PokePermission extends BaseModel {
    key?: string;
    id: number;
    userId: number;
    friendId: number;
    canVibrate: boolean;
    canChime: boolean;
    canZap: boolean;
    maxZapValue: number;
    friend: Friend
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
}


export interface UserHabitPeriod extends BaseModel {
    id: number;
    userHabitId: number;
    startDate: string;
    endDate: string;
    repetitionDays: number[];
    startValue: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
}

export interface UserHabitGoal {
    userHabitId: number,
    date: Date,
    goalValue: number,
    currentValue: number
}

export interface UserHabit extends BaseModel, TimestampsModel {
    id: number;
    userId: number;
    habitId: number;
    price: number;
    reminderTime: string;
    answers: number[];
    enabled: boolean;
    period: UserHabitPeriod;

    habitName: string;
    habitType: string;
    habitUnit: string;
    habitIcon: string;
    habitContentsCategoryId: number;

    // used in challenge context
    goal?: UserHabitGoal,
    challengeUserHabitId?: number,
    validationStatus?: boolean
}

export interface Device extends BaseModel, DateTimeModelMixin {
    id: number;
    name: string,
    deviceModel: string,
    macAddress: string,
    hardwareVersion: string,
    hardwareId: number;
    firmwareVersion: string,
    meta: string | null,
    locked: string | null,
    averageBatteryLife: number | null,
    lastSync?: string,
}

export interface BatteryInfo extends BaseModel {
    averageBatteryLife: number
    disconnectedTs: string
    disconnectedBatteryPercentage: number
    lastTs: string
    lastBatteryPercentage: number
}

export interface BatteryGraphPointBatteryGraphPoint extends BaseModel {
    ts: string
    batteryPercentage: number
    charging: number
}

export interface BatteryIssue extends BaseModel {
    id: number;
    userId: number;
    macAddr: string;
    start: string
    startV: number
    end: string
    endV: number
    duration: number
    difference: number
    maxCount: number
    maxPercentage: number
    maxActivity: number
    alarmCount: number
    vibeCount: number
    zapCount: number
    beepCount: number
    totalActivity: number
    issue: boolean
    highActivity: boolean
}

export interface ZapIssue extends BaseModel {
    id: number;
    macAddr: string;
    ts: string;

    battery: number;
    target: number;
    release: number;
    delivery: boolean;
    skip: string;
}


export interface Banner extends BaseModel {
    id: number;
    title: string;
    url: string;
    text: string;
    storeItemId: number;
    backgroundColor: string;
    backgroundImageFileName: string;
    backgroundImageContentType: string;
    backgroundImageFileSize: number;
    backgroundImageUpdatedAt: string;
    live: boolean;
    audience: string;
    appName: string;
    expiryTime: string;
    isOnboarding: boolean;
    relativeExpiry: number;
    imageId: number;
    backgroundImageUrl: string;
}

export interface VoltsAction {
    id: number;
    key: string;
    description: string;
    volts: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface Badge extends BaseModel {
    id: number;
    title: string;
    key: string;
    description: string;
    image: string;
    voltsAction: VoltsAction;
    achievedAt?: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}


export interface Challenge extends BaseModel, TimestampsModel {
    id: number;
    name: string;
    type: string;
    description: string;
    startDate: string;
    endDate: string;
    ownerId: number;
    entitlements: string[];
    chatGroupLink: string;
    maxPoints: number;
    personalHabitChallenge: boolean;
    finalGoal: number;
    globalChallenge: boolean;
    iconId: number;
    iconUrl: string;
    isCoach: boolean
}

export interface Team extends BaseModel, TimestampsModel {
    id: number,
    challengeId: number,
    name: string,
    chatGroupLink: string,
    score?: number
    participants: Participant[]
}

export interface Leaderboard {
    challenge: Challenge
    teams: Team[]
}

export interface Participant extends BaseModel {
    id: number,
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    profilePictureUrl: string,
    teamName: string,
    teamId: number,
    approvedUserHabitsCount: number,
    totalUserHabitsCount: number,
    rank: number,
    points: number
    lastPointAchievedAt?: string
}


// ------- Habit -------
export interface PossibleAnswer extends BaseModel {
    id: number;
    habitQuestionId: number;
    text: string;
    iconId: number;
    iconUrl: string;

    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface Question extends BaseModel {
    id?: number;
    habitId?: number;
    multiselect: boolean;
    text: string;
    possibleAnswers: PossibleAnswer[];

    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export enum HabitType {
    Positive = 'Positive',
    Neutral = 'Neutral',
    Negative = 'Negative',
    Saving = 'Saving',
}

export interface Habit {
    id: number;
    name: string;
    description: string;
    type: string;
    unit: string;
    goalDefaultValue: number;
    goalValueIncrements: number;
    interfaceKey: string;
    hasDailyRecords: boolean;
    hidden: boolean;
    individual: boolean;
    iconId: number;
    iconGoalId: number;
    iconUrl: string;
    iconGoalUrl: string;
    contentsCategoryId?: number;
    hasEnabledUserHabit?: boolean;

    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string | null;
}

interface AnswerInCreate {
    text: string;
    icon: string;
}

interface QuestionInCreate {
    multiselect: boolean;
    text: string;
    possibleAnswers: AnswerInCreate[];
}

export interface HabitInRequest {
    name: string;
    description: string;
    type: string;
    unit: string;
    goalDefaultValue: number;
    goalValueIncrements: number;
    interfaceKey: string;
    hasDailyRecords: boolean;
    hidden: boolean;
    contentsCategoryId: number;
}

export interface HabitInUpdate extends HabitInRequest {
    id: number;
    icon?: string;
    iconGoal?: string;
}

export interface HabitInCreate extends HabitInRequest {
    icon: string;
    iconGoal: string;
    questionsWithAnswers: QuestionInCreate[];
}

export interface HabitWithQuestions extends Habit {
    questions: Question[];
}

// ------- End Habit -------

export interface ContentCategory extends BaseModel {
    id: number;
    name: string;

    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string | null;
}

export interface Content extends BaseModel, DateTimeModelMixin {
    id: number;
    categoryId?: number;
    categoryName?: string;
    title: string;
    link?: string;
    reference?: string;
    image?: string;
}

export interface Entitlement extends BaseModel, TimestampsModel {
    id: number;
    name: string;
    displayName: string;
    lookupKey: string;
    object: string;
    projectId: string;
}

export interface UserEntitlement extends Entitlement {
    expiresAt: Date
}

export interface DeviceEngagementRateData {
    date: string;
    updatedAT: string;
    activeUsersPeriodEnd: number,
    engagedUsers: number,
    lostUsers: number,
    deviceEngagementRate: number
}

export interface FirmwareAdoptionItem {
    firmwareVersion: string
    adoptersCount: number
    adoptionRate: number
}

export interface FirmwareAdoption {
    date: Date;
    updatedAt: Date;
    firmwaresAdoption: FirmwareAdoptionItem[];
    hardwareGroup: string
}

export interface Firmware extends BaseModel {
    id: number;
    versionNumber?: number;
    versionString: string;
    fileFileName: string;
    fileContentType: string;
    fileFileSize: number;
    fileUpdatedAt: string;
    body: string;
    test: boolean;
    archived: boolean;
    minimalAppVersion: string;
    fileId: number;
    fileUrl: string;
    hardwares: Hardware[];

    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface UserLogsRecord extends BaseModel {
    id: number;
    sectorId: number;
    userId: number;
    macAddr: string;
    firmwareVersion: string;
    name: string;
    reason: string;
    text: string;
    inode: number;
    offset: number;
    ts: string;
    tz: number;
    type: number;
    data: string;
    delta: number;
    header: string;
    length: number;
    rawLength: number;
    url: string;
    parsedJson?: object;
}

export type DiagnosticLogsRecord = UserLogsRecord

export interface StimulusLogCombinedType extends BaseModel, IDModelMixin {
    timestamp: string;
    type: string; // Beep, Vibe, Zap
    value: number;
    stimulus?: Stimulus | null;
    diagnosticLogsRecord?: DiagnosticLogsRecord | null;
}


export interface RevenueCatSubscription extends BaseModel {
    productIdentifier: string;
    autoResumeDate?: string; // '?' denotes that the field might be optional
    billingIssuesDetectedAt?: string;
    expiresDate: string;
    gracePeriodExpiresDate?: string;
    isSandbox: boolean;
    originalPurchaseDate: string;
    periodType: string;
    productPlanIdentifier: string;
    purchaseDate: string;
    refundedAt?: string;
    store: string;
    unsubscribeDetectedAt?: string;
    entitlements: string[];
}

export interface VoltsTransaction extends BaseModel, TimestampsModel {
    id: number;
    userId: number;
    oldVolts: number;
    volts: number;
    transactionType: string;
    voltsTransactionableId: number;
    voltsTransactionableType: string;
}

export interface PhoneDevice extends BaseModel, TimestampsModel {
    id: number;
    userId: number;
    hardwareIdentifier: string;
    fcmToken: string;
    deviceName: string;
    osName: string;
    osVersion: string;
    appVersion: string;
}

export interface StimulusMetaDeviceItem extends BaseModel {
    delivered: boolean
    fcm_token: string
    device_name: string
    os_name: "android" | "ios"
}

export interface StimulusMeta {
    status?: {
        devices?: StimulusMetaDeviceItem[]
    }
}

export interface Stimulus extends BaseModel {
    id: number;
    type: string;
    value: number;
    sentAt: Date;
    sentBy: number;
    sentTo: number;
    createdAt: Date;
    updatedAt: Date;
    via: string;
    message: string;
    pushedAt: Date;
    failedAt: Date;
    failureMessage: string;
    sentByApp: number;
    reason: string;
    pattern: string;
    repeat: number;
    deleted: boolean;
    userDate: Date;
    recent: boolean;
    meta: string;
    metaParsed?: StimulusMeta
}

export interface Campaign extends BaseModel {
    id: number;
    name?: string;
    sentCount: number;
    readCount: number;
    openedCount?: number;
    title?: string;
    content?: string;
    icon?: string;
    url?: string;
    urlType?: string;
    category?: string;
    bottomSheets?: boolean;
    data?: string;
    createdAt: string
}

export interface CampaignInCreate {
    title: string;
    content: string;
    icon?: string;
    url: string;
    urlType: string;
    category: string;
    bottomSheets?: boolean;
    data?: Record<string, any>;
    campaignName: string;
    topicsNames: string[];
    silent: boolean;
}

export interface FcmTopic {
    topicName: string;
    usersCount: number;
}

export interface FcmTopicsResponse {
    totalUsersCount: number;
    fcmTopics: FcmTopic[];
}


// ------- Metrics -------
export interface FeatureUsageData {
    appUsersCount: any;
    date: string;
    allActiveUsersCount: string;
    habitFeatureUsersCount: string;
    pokesFeatureUsersCount: string;
    integrationsFeatureUsersCount: string;
    alarmsFeatureUsersCount: string;
    sleepTrackingFeatureUsersCount: string;
    timersFeatureUsersCount: string;
    stepsFeatureUsersCount: string;
    updatedAt: string;
}

export interface ReviewTopic {
    topic: string;
    impression: boolean;
    setBy: string;
    setAt: Date;
}

export interface ReviewsData {
    id: string;
    review: string;
    reviewSource: ReviewSource;
    reviewerName: string;
    topics: ReviewTopic[];
    date: string;
    rating: number;
    appVersion: string;
    addedAt: string;
}

export interface Review extends BaseModel, IDModelMixin, DateTimeModelMixin {
    originalId: string;
    title?: string | null;
    review: string;
    reviewSource: ReviewSource;
    reviewerName: string | null;
    topics: ReviewTopic[];
    date: Date; // Assuming date is in ISO format (YYYY-MM-DD)
    lastEditedAt?: Date; // same assumption as 'date'
    displayDate?: Date; // same assumption as 'date'
    rating?: number | null;
    appVersion?: string | null;
    deviceModel?: string | null; // Assuming this is a string representation of the device model
    reviewLink?: string | null;
    developerReply?: string | null;
    rawReview?: { [key: string]: unknown } | null; // Use 'any' for the values or a more specific type if the structure is known
}


export interface Hardware extends BaseModel, DateTimeModelMixin {
    id: number
    versionNumber: number
    versionString: string
    convenienceName: string
}

// --- Chat system ---
export enum AiChatMessageRole {
    USER = 'user',
    SYSTEM = 'system',
    ASSISTANT = 'assistant',
    TYPING = 'typing'
}

export interface AiChatMessage extends BaseModel, DateTimeModelMixin {
    id: number;
    threadId: number;
    role: AiChatMessageRole;
    content: string;
    displayContent?: string;
    hidden: boolean;
}

export interface AiChatResponse {
    event: StreamEventType,
    data: any,
    id: number,
    audio_data?: string
    status?: 'loading' | 'success' | 'error' | 'done' | 'unknown'
    function_name?: string
}

export interface AiChatThread extends BaseModel, DateTimeModelMixin {
    id: number;
    userId: number;
    title: string;
    gptModel: string;
    messages?: AiChatMessage[];  // Or whatever structure your messages have
}


export enum StreamEventTypeEnum {
    TOOL_CALL = "tool_call",
    TOOL_CALL_RESPONSE = "tool_call_response",
    REPLY = "reply",
    SUGGESTIONS = "suggestions",
    RELATED_CONTENT_TAGS = "related_content_tags",
    ERROR = "error",
    AI_CHAT_MESSAGE = "ai_chat_message",
    AUDIO_MESSAGE = "audio_message",
    END_OF_STREAM = "end_of_stream",
    USER_MESSAGE = "user_message",
    THREAD = "thread"
}

// This type will convert all the values of a given type to lowercase
type LowercaseStringValues<T> = {
    [K in keyof T]: T[K] extends string ? Lowercase<T[K]> : T[K]
};

// StreamEventType is a union of all the values of the StreamEventTypeEnum
export type StreamEventType = LowercaseStringValues<typeof StreamEventTypeEnum>[keyof typeof StreamEventTypeEnum];


// export type StreamEventType = keyof typeof StreamEventTypeEnum;


export interface SleepDataset extends BaseModel, DateTimeModelMixin {
    id: number;
    sleepData?: string;
    night?: Date;
    userId: number;
    deepSleep: number;
    lightSleep: number;
    remSleep?: number;
    restless: number;
    wakeUp?: Date;
    snoozes?: number;
    alarmType?: string;
    snoozeLock?: boolean;
    validated?: boolean;
    archived?: boolean;
    plotData: string[];
    convertedPlotData?: number[];
    inode?: string;
    deviceId?: number;
    userDate?: Date;
    sleepDataAttachmentFileName?: string;
    sleepDataAttachmentContentType?: string;
    sleepDataAttachmentFileSize?: number; // Assuming this should be a number
    wakeupScore?: number;

    // New fields
    sleepDuration?: number; // Sleep duration in hours
    deepSleepDuration?: number;
    lightSleepDuration?: number;
    remSleepDuration?: number;
    restlessDuration?: number;
    awakeningsCount?: number;
    deepScore?: number;
    lightScore?: number;
    remScore?: number;
    sleepCompositionScore?: number;
    sleepDurationScore?: number;
    restlessScore?: number;
    overallScore?: number;

    device?: Device | null;
    trackingType: string
    hidden?: boolean;
}

export enum ReviewSource {
    IOS = "ios",
    ANDROID = "android",
    AMAZON = "amazon",
}

export interface Rating extends BaseModel, IDModelMixin, DateTimeModelMixin {
    date: Date;
    ratingSource: ReviewSource;
    productId?: string; // Optional
    productName?: string; // Optional
    stars1: number;
    stars2: number;
    stars3: number;
    stars4: number;
    stars5: number;
    averageRating: number;
}


// ------- Shared -------
// technical types
export type FilterParams = {
    [key: string]: null | string[] | number[] | boolean;
}

export interface GetItemsResponse<T> {
    count: number
    items: T[]
    page: number
    pageSize: number
}

// We use for internal purposes like function arguments
export interface GetPaginatedFilteredDataRequest {
    page?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: 'ascend' | 'descend' | string;
    filters?: FilterParams;
    search?: string
}

// We use for API requests params
export interface GetPaginatedFilteredDataParams {
    page?: number;
    page_size?: number;
    sort_field?: string;
    sort_order?: 'ASC' | 'DESC' | string;
    filter_fields?: string;
    filter_values?: string;
    search?: string
    show_deleted?: boolean
}

export type TableFilters = {
    [key: string]: any;
}