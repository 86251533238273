import React, {useEffect, useState} from "react";
import {AutoComplete, Button, DatePicker, Form, Select, Spin} from "antd";
import dayjs from "dayjs";

import Devices from "services/devices";
import {Device} from "types";
import {DEFAULT_DATETIME_FORMAT} from "utils/datetime";
import {UserLogsFilter} from "../../types";

const {Option} = Select
const {RangePicker} = DatePicker

interface UserLogsFiltersProps {
    onSubmit: (values: any) => void;
    loading: boolean
    filters?: UserLogsFilter
}

const UserLogsFilters: React.FC<UserLogsFiltersProps> = ({onSubmit, filters, loading}) => {
    // ----- Data -----
    const [device, setDevices] = useState<Device[]>([]);
    const [loadingDevices, setLoadingDevices] = useState(false);
    // ----- Form -----
    const [form] = Form.useForm();

    useEffect(() => {
        if (!filters) return
        const types = Array.isArray(filters?.types) ? filters?.types : (filters?.types ? [filters?.types] : [])
        form.setFieldsValue({
            types: types,
            dateRange: filters?.startDate && filters?.endDate && [dayjs(filters?.startDate), dayjs(filters?.endDate)],
            macAddress: filters?.macAddress
        })
    }, [filters])

    const fetchMacAddresses = async (value: any) => {
        if (!value || value.length < 3) {
            setDevices([]);
            return
        }
        setLoadingDevices(true);
        try {
            const params = {
                filters: {
                    mac_address: value
                },
            }
            const response = await Devices.getAllUsersDevices(params);
            // console.log("response", response)
            const responseData = response.data.items || [];
            setDevices(responseData);
        } catch (err) {
            // console.error('Error fetching')
        }
        setLoadingDevices(false);
    };

    const handleFinish = (values: any) => {
        const startDate = values?.dateRange && values?.dateRange[0] || null
        const endDate = values?.dateRange && values?.dateRange[1] || null
        const filters: UserLogsFilter = {
            types: values?.types || null,
            startDate: startDate && dayjs(startDate).format(DEFAULT_DATETIME_FORMAT) || null,
            endDate: endDate && dayjs(endDate).format(DEFAULT_DATETIME_FORMAT) || null,
            macAddress: values?.macAddress || null
        }
        onSubmit(filters);
    }

    return (
        <>
            <Form layout="vertical" form={form} onFinish={handleFinish}
                  initialValues={{macAddress: 'FDC6E5B9CFD1'}}
            >
                <Form.Item
                    name="macAddress"
                    label="MAC Address"
                    rules={[{required: true, message: "Please input mac a valid address!"}]}
                >
                    <AutoComplete
                        options={device.map(item => ({value: item.macAddress, key: item.macAddress}))}
                        onSearch={fetchMacAddresses}
                    />
                </Form.Item>
                <Spin size="small" spinning={loadingDevices}/>
                <Form.Item name="types" label="Types">
                    <Select allowClear mode="multiple">
                        <Option key="SelectFilterTimestamp" value="TimestampRecord">Timestamp</Option>
                        <Option key="SelectFilterReboot" value="RebootRecord">Reboot</Option>
                        <Option key="SelectFilterTimeDelta" value="TimeDeltaRecord">TimeDelta</Option>
                        <Option key="SelectFilterMidnight" value="MidnightRecord">Midnight</Option>
                        <Option key="SelectFilterTimeJump" value="TimeJumpRecord">TimeJump</Option>
                        <Option key="SelectFilterTimer" value="TimerRecord">Timer</Option>
                        <Option key="SelectFilterFinding" value="FindingRecord">Finding</Option>
                        <Option key="SelectFilterButton" value="ButtonRecord">Button</Option>
                        <Option key="SelectFilterZap" value="ZapRecord">Zap</Option>
                        <Option key="SelectFilterEnergy" value="EnergyRecord">Energy</Option>
                        <Option key="SelectFilterSleep" value="SleepRecord">Sleep</Option>
                        <Option key="SelectFilterSteps" value="StepsRecord">Steps</Option>
                        <Option key="SelectFilterMark" value="MarkRecord">Mark</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                    <RangePicker showTime/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Filter</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default UserLogsFilters