import React, {useEffect, useState} from 'react';
import {Select, DatePicker} from 'antd';
import {Dayjs} from 'dayjs';

// ----- Local Imports -----
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from 'helpers/dates-helper';

// ----- Global Variables -----
const {Option} = Select;

// ----- Types -----
interface DateRangeSelectorProps {
    defaultDateRange: DateRangeFilterSelectorOption;
    onDateRangeChange: (label: string, dates: [Dayjs | null, Dayjs | null]) => void;
}

// ----- Component -----
const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({defaultDateRange, onDateRangeChange}) => {
    // ----- Local State -----
    const [selectedDateRangeLabel, setSelectedDateRangeLabel] = useState<DateRangeFilterSelectorOptionsLabel>(defaultDateRange.label);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>(defaultDateRange.value);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // ----- Effect -----
    useEffect(() => {
        setSelectedDateRangeLabel(defaultDateRange.label);
        setDateRange(defaultDateRange.value);
    }, [defaultDateRange]);

    // ----- Event Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRangeLabel(label as DateRangeFilterSelectorOptionsLabel);
        setDateRange(dates);
        onDateRangeChange(label, dates);
    };

    const handleDropdownVisibleChange = (open: boolean) => {
        setDropdownOpen(open);
    };

    return (
        <Select
            style={{marginRight: 8, minWidth: 150}}
            value={selectedDateRangeLabel}
            defaultValue={defaultDateRange.label}
            onChange={(value: string) => {
                const selectedOption = DATE_RANGE_FILTER_SELECTOR_OPTIONS.find(option => option.label === value);
                if (selectedOption) {
                    handleDateRangeChange(selectedOption.label, selectedOption.value);
                }
            }}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            open={dropdownOpen}
            dropdownRender={menu => (
                <>
                    {menu}
                    <div style={{padding: '8px', borderBottom: '1px solid #f0f0f0'}}
                         onMouseDown={e => e.stopPropagation()}>
                        Custom:
                    </div>
                    <div style={{display: 'flex', padding: '8px'}} onMouseDown={e => e.stopPropagation()}>
                        <DatePicker
                            defaultValue={dateRange[0] || undefined} // Adjusted to ensure undefined is used instead of null
                            style={{flex: 1}}
                            allowClear={false}
                            onChange={startDate => handleDateRangeChange('Custom Range', [startDate, dateRange[1]])}
                        />
                    </div>
                    <div style={{display: 'flex', padding: '8px'}} onMouseDown={e => e.stopPropagation()}>
                        <DatePicker
                            defaultValue={dateRange[1] || undefined} // Adjusted to ensure undefined is used instead of null
                            style={{flex: 1}}
                            allowClear={false}
                            onChange={endDate => handleDateRangeChange('Custom Range', [dateRange[0], endDate])}
                        />
                    </div>
                </>
            )}
        >
            {DATE_RANGE_FILTER_SELECTOR_OPTIONS.map(option => (
                <Option key={option.label} value={option.label}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default DateRangeSelector;
