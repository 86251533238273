import React from "react"
import {Avatar, Button, Card, Col, Image, Row} from "antd";
import {CalendarOutlined, UserAddOutlined, UserOutlined} from "@ant-design/icons";

// ----- local calls -----
import {Challenge} from "../../../../../types";

// ----- Types -----
interface ChallengeDetailsCardProps {
    challenge: Challenge | null
    loading: boolean
}

// ----- Components -----
const ChallengeDetailsCard: React.FC<ChallengeDetailsCardProps> = (
    {
        challenge,
        loading
    }
) => {

    const getInviteUserUrl = (): string => {
        if (!challenge) return '#'
        // const url = new URL("https://example.com");
        const url = "/campaigns/create";
        const params = new URLSearchParams();

        //Add a second foo parameter.
        params.append("title", `Join ${challenge?.name} challenge`);
        params.append("content", `Join ${challenge?.name} challenge`);
        params.append("urlType", 'internal');
        // params.append("category", 'campaign');
        params.append("campaignName", challenge?.name || '');
        // const add this to CONSTANTS file
        console.log("challenge.entitlements", challenge.entitlements)
        if (challenge.entitlements?.[0]) {
            const topicName = `entitlement_${challenge.entitlements?.[0]}`
            params.append("topicName", topicName);
        }
        params.append("disabledCampaignUrl", 'true');
        const campaignUrl = encodeURI(`/challenges/?id=${challenge.id}`)
        params.append("url", campaignUrl || '');
        params.append("cardTitle", 'Invite users to challenge');
        // console.log('params', params.toString())
        // todo: check is better way
        return `${url}?${params.toString()}`
    }

    return (
        <Card
            className="card-profile-head"
            bodyStyle={{display: 'none'}}
            loading={loading}
            title={(
                <Row justify="space-between" align="middle" gutter={[24, 16]}>
                    <Col span={24} md={12} className="col-info">
                        <Avatar.Group>
                            <Image //size={74}
                                //shape="square"
                                src={challenge?.iconUrl}
                                // icon={<UserOutlined/>}
                                width={200}
                                preview={false}
                                style={{backgroundColor: '#01013d'}}/>
                            <div className="avatar-info">
                                <h4 className="font-semibold m-0">{challenge?.name}</h4>
                                <p>{challenge?.description}</p>
                                <div>
                                    <CalendarOutlined/> Start date: <span>{challenge?.startDate}</span>
                                </div>
                                <div>
                                    <CalendarOutlined/> End date: <span>{challenge?.endDate}</span>
                                </div>
                                <div>
                                    <UserOutlined/> owner:
                                    <Button type="link"
                                            href={`/users/${challenge?.ownerId}`}
                                            disabled={!challenge?.ownerId}
                                    >{challenge?.ownerId}</Button>
                                </div>
                            </div>
                        </Avatar.Group>
                    </Col>
                    <Col
                        span={24}
                        md={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<UserAddOutlined/>}
                            href={getInviteUserUrl()}
                        >
                            Invite users
                        </Button>
                    </Col>
                </Row>
            )}
        />
    )
}

export default ChallengeDetailsCard