import React from "react";
import {Space} from "antd";

// ----- Local calls -----
import UserHabitsList from "components/UserHabitsList";
import PokePermissionsList from "components/PokePermissionsList";
import FriendsList from "components/FriendsList";
import SleepDatasetsList from "components/SleepDatasets";

// ----- Types -----
interface UserDetailsAppContentProps {
    userId?: string
}

// ----- Components -----
const AppContentTab: React.FC<UserDetailsAppContentProps> = ({userId}) => {

    return (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            {/* APP content */}
            <UserHabitsList userId={userId}/>
            <PokePermissionsList userId={userId}/>
            <FriendsList userId={userId}/>
            <SleepDatasetsList userId={userId}/>
        </Space>
    )
}

export default AppContentTab
