import React from "react";
import {Alert} from "antd";

// ----- Local calls -----
import {AiChatResponse} from "types";

// ----- Global variables -----
// ----- Types -----
interface ToolLoadingProps {
    eventResponse: AiChatResponse | null
}

// ----- Components -----
const ToolLoading: React.FC<ToolLoadingProps> = ({eventResponse}) => {
    // ----- Params -----
    // const {userId} = useParams<RouteParams>();
    // ----- Data -----
    // const [data, setData] = useState<string>('')
    // ----- References -----
    // const inputRef = useRef<InputRef | null>(null);

    // ----- Calls -----

    // ----- Handlers -----

    const EventPlugin = () => {
        switch (eventResponse?.event) {
            case "tool_call":
                // eslint-disable-next-line no-case-declarations
                const {data: toolName, status} = eventResponse
                // eslint-disable-next-line no-case-declarations
                let message = ""
                // eslint-disable-next-line no-case-declarations
                let type: 'success' | 'info' | 'warning' | 'error' = "info"
                switch (status) {
                    case "loading":
                        message = "Calling " + toolName + " service."
                        type = "info"
                        break;
                    case "error":
                        message = "Error calling " + toolName
                        type = "error"
                        break;
                    case "done":
                        message = "Done calling " + toolName
                        type = "success"
                        break;
                    default:
                        message = "..."
                        type = "warning"
                        break;
                }
                return <Alert
                    message={message}
                    type={type}
                    showIcon
                    closable
                />
        }
    }
    return (
        <div className="articlesContainer">
            {EventPlugin()}
        </div>
    )
}

export default ToolLoading
