import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Space, Table} from 'antd';
import {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';

// ----- Local calls -----
import {VoltsTransaction} from "types"; // Update this import
import {VoltsTransactionsService} from "services"; // Update this import
import {getColumnSearchProps} from "components/TableSearch";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {formatNumberWithCommas} from "utils/strings_utils";
import AddVoltsModal from "components/Modals/AddVoltsModal";

// ----- Types -----
type RouteParams = {
    userId?: string
}

// ----- Component -----
const VoltsTransactionsListPage: React.FC = () => {
    // ----- params -----
    const {userId} = useParams<RouteParams>();

    // ----- data -----
    const [transactions, setTransactions] = useState<VoltsTransaction[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- Modal -----
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ----- Table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        setLoading(true)
        VoltsTransactionsService.getVoltsTransactionsByUserId(Number(userId)) // Update service call
            .then(res => {
                const transactionsResponse = res?.data?.voltsTransactions || [] // Update this based on the API response structure
                const transactionsList = transactionsResponse?.map((item: VoltsTransaction) => ({
                    ...item,
                    key: item.id + ""
                }));
                setTransactions(transactionsList || []);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // ----- Modal -----
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleAddVoltsSuccess = () => {
        setIsModalOpen(false);
        fetchData();
    }


    const columns: ColumnsType<VoltsTransaction> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            sorter: (a, b) => a.userId - b.userId,
            render: (value) => (
                <Button type="link" href={`/users/${value}`} target="_blank">{value}</Button>
            )
        },
        {
            title: 'Old Volts',
            dataIndex: 'oldVolts',
            key: 'oldVolts',
            sorter: (a, b) => a.oldVolts - b.oldVolts,
            render: (value) => formatNumberWithCommas(value)
        },
        {
            title: 'Volts',
            dataIndex: 'volts',
            key: 'volts',
            sorter: (a, b) => a.volts - b.volts,
            render: (value) => formatNumberWithCommas(value)
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
            sorter: (a, b) => a?.transactionType.localeCompare(b?.transactionType),
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'transactionType'
            }),
        },
        {
            title: 'Transactionable ID',
            dataIndex: 'voltsTransactionableId',
            key: 'voltsTransactionableId',
            sorter: (a, b) => b.voltsTransactionableId - a.voltsTransactionableId,
            render: (value, record: VoltsTransaction) => {
                switch (record.voltsTransactionableType) {
                    case 'users':
                        return <Button type="link" href={`/users/${value}`} target="_blank">{value}</Button>
                    case 'badges':
                        // todo: navigate to details page instance of edit page
                        // todo: edit page should return empty when not found instance of empty form
                        return <Button type="link" href={`/badges/edit/${value}`} target="_blank">{value}</Button>
                    default:
                        return value
                }
            }
        },
        {
            title: 'Transactionable Type',
            dataIndex: 'voltsTransactionableType',
            key: 'voltsTransactionableType',
            sorter: (a, b) => a?.voltsTransactionableType.localeCompare(b?.voltsTransactionableType),
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'voltsTransactionableType'
            }),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a?.createdAt.localeCompare(b?.createdAt),
            render: (value: string) => value && dayjs(value).format('YYYY-MM-DD HH:MM'),
            defaultSortOrder: 'descend'
        },
    ];


    return (
        <>
            <CustomBreadcrumb list={['Volts Transactions', 'list',]}/>
            <Card title="Volts Transactions list"
                  extra={
                      <Space>
                          <Button
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                          <Button
                              disabled={!userId}
                              onClick={() => setIsModalOpen(true)}
                              type="primary"
                              icon={<PlusOutlined/>}
                          >
                              Add Volts
                          </Button>
                      </Space>
                  }
            >
                <Table<VoltsTransaction>
                    columns={columns}
                    dataSource={transactions}
                    loading={loading}
                    size="small"
                    bordered
                    pagination={{
                        showSizeChanger: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                    }}
                />
            </Card>
            <AddVoltsModal userId={userId} open={isModalOpen} onOk={handleOk} onCancel={handleOk} onSuccess={handleAddVoltsSuccess}/>
        </>
    )
};

export default VoltsTransactionsListPage;
