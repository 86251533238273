import {AxiosResponse} from "axios";

import {Challenge, Leaderboard, Participant, UserHabit} from "types";
import Api from "./api";
import {Dayjs} from 'dayjs';


interface GetAllChallengesResponse {
    challenges: Challenge[];
}

export interface ChallengeInCreate {
    name: string;
    type: string;
    description: string;
    startDate: string;
    endDate: string;
    ownerId: number;
    entitlements: string[];
    chatGroupLink: string;
    maxPoints: number;
    personalHabitChallenge: boolean;
    finalGoal: number;
    globalChallenge: boolean;
    icon?: string;
    // for the form
    dateRange?: Dayjs[]
}

export interface ChallengeInUpdate extends ChallengeInCreate {
    challengeId: number
}


interface GetChallengeByIdResponse {
    challenge: Challenge;
}

interface GetChallengeParticipantsResponse {
    participants: Participant[]
}

type GetChallengeLeaderboardResponse = Leaderboard

interface GetUserHabitsLinkedWithChallengeResponse {
    userHabits: UserHabit[]
}

interface UpdateChallengeUserHabitLinkStatusResponse {
    userHabits: UserHabit[]
}

export const getAllChallenges = (): Promise<AxiosResponse<GetAllChallengesResponse>> => {
    return Api.get("/admin/challenges/");
}

export const createChallenge = (challenge: ChallengeInCreate): Promise<AxiosResponse<GetChallengeByIdResponse>> => {
    const request = {
        challenge: challenge,
    };

    return Api.post("/admin/challenges/", request);
}

export const updateChallenge = (challenge: ChallengeInUpdate): Promise<AxiosResponse<GetChallengeByIdResponse>> => {
    const request = {
        challenge: challenge,
    };

    // todo: on backend change url to respect convention
    return Api.put(`/admin/challenges/`, request);
}

export const getChallengeById = (challengeId: number): Promise<AxiosResponse<GetChallengeByIdResponse>> => {
    return Api.get(`/admin/challenges/${challengeId}`);
}

export const getChallengeParticipants = (challengeId: number): Promise<AxiosResponse<GetChallengeParticipantsResponse>> => {
    const request = {
        params: {challenge_id: challengeId},
    }
    return Api.get(`/challenges/coaching/participants/`, request);
}

export const getChallengeLeaderboard = (challengeId: number): Promise<AxiosResponse<GetChallengeLeaderboardResponse>> => {
    return Api.get(`/challenges/${challengeId}/leaderboard`);
}

export const getUserHabitsLinkedWithChallenge = (challengeId: number, userId: number): Promise<AxiosResponse<GetUserHabitsLinkedWithChallengeResponse>> => {
    return Api.get(`/challenges/coaching/challenge/${challengeId}/user/${userId}/user-habits`)
}

export const updateChallengeUserHabitLinkStatus = (challengeUserHabitId: number, status: boolean): Promise<AxiosResponse<UpdateChallengeUserHabitLinkStatusResponse>> => {
    const request = {
        challenge_user_habit: {
            status: status
        }
    }
    return Api.patch(`/challenges/coaching/challenge-user-habit/${challengeUserHabitId}`, request)
}

export const assignCoachesToChallenge = (challengeId: number, usersIds: number[]): Promise<AxiosResponse> => {
    const request = {
        coaches_ids: usersIds,
    };

    return Api.post(`/admin/coaching/${challengeId}`, request);
}


export default {
    getAllChallenges,
    createChallenge,
    updateChallenge,
    getChallengeById,
    getChallengeLeaderboard,
    getUserHabitsLinkedWithChallenge,
    updateChallengeUserHabitLinkStatus,
    assignCoachesToChallenge
};
