import Api from "./api";
import {User} from "../types";
import {AxiosResponse} from "axios";

interface GetUserFriendsParams {
    userId: string | number;
}

interface GetUserFriendsResponse{
    users: User[]
}

export const getUserFriends = (params: GetUserFriendsParams): Promise<AxiosResponse<GetUserFriendsResponse>> => {
    return Api.get(
        '/admin/friendships/get-friends',
        {
            params: {
                user_id: params.userId
            }
        }
    )
}

export default {
    getAllFriends: getUserFriends
}