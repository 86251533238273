import React, {useEffect, useState} from 'react';
import {ReloadOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {Button, Card, Tooltip, Select, Space, Row, Col} from "antd";
import dayjs, {Dayjs} from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// ----- Plugins -----
dayjs.extend(relativeTime);

// ----- Local calls -----
import {Rating, ReviewSource} from "types";
import {getRating} from "services/analytics";
import DateRangeSelector from "components/DateRangeSelector";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from "helpers/dates-helper";
import AppRatingsMetrics from "./AppRatings";
import ProductsRatings from "./ProductsRatings";
import RatingsDistribution from "./RatingsDistribution";

// ----- Global variables -----

// ----- Components -----
const RatingsMetrics: React.FC = () => {
    // ----- data -----
    const [data, setData] = useState<Rating[]>([]);
    const [loading, setLoading] = useState(false);
    const [lastUpdatedAt, setLastUpdatedAt] = useState<Date | null>(null);
    // ----- Filters -----
    const [sources, setSources] = useState<ReviewSource[]>(
        [ReviewSource.IOS, ReviewSource.ANDROID, ReviewSource.AMAZON]
    );
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[3]
    );

    // ----- Effects -----
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // refresh data when date range or sources change
        fetchData();
    }, [selectedDateRange, sources]);

    // ----- Fetch Data -----
    const fetchData = () => {
        setLoading(true)
        const dateRange = selectedDateRange.value;
        const request = {
            startDate: dateRange?.[0]?.format('YYYY-MM-DD'),
            endDate: dateRange?.[1]?.format('YYYY-MM-DD'),
            sources: sources
        }
        getRating(request)
            .then(res => {
                const responseData = res?.data?.ratings || []
                setData(responseData)
                setLastUpdatedAt(res?.data?.lastUpdate || null)
            })
            .catch(err => {
                console.log("Error fetching data, message: ", err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    const handleSourcesChange = (selectedValues: ReviewSource[]) => {
        // Enforce at least one selection
        if (selectedValues.length === 0) {
            // Prevent deselecting the last item by doing nothing.
            console.warn("At least one item must be selected.");
            return;
        }
        setSources(selectedValues);
    };

    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };

    return (
        <Card title="Ratings Metrics"
              loading={loading}
              extra={
                  <Space>
                      <Tooltip title={lastUpdatedAt ? `Updated ${dayjs(lastUpdatedAt).fromNow()}` : 'Never'}>
                          <ClockCircleOutlined/>
                      </Tooltip>

                      <Select
                          value={sources} // Directly use the sources array
                          style={{width: 250}}
                          onChange={handleSourcesChange}
                          allowClear={true}
                          mode="multiple"
                      >
                          <Select.Option value={ReviewSource.IOS}>
                              {ReviewSource.IOS.toLocaleLowerCase()}
                          </Select.Option>
                          <Select.Option value={ReviewSource.ANDROID}>
                              {ReviewSource.ANDROID.toLocaleLowerCase()}
                          </Select.Option>
                          <Select.Option value={ReviewSource.AMAZON}>
                              {ReviewSource.AMAZON.toLocaleLowerCase()}
                          </Select.Option>
                      </Select>

                      <DateRangeSelector defaultDateRange={selectedDateRange}
                                         onDateRangeChange={handleDateRangeChange}/>

                      <Button
                          size="middle"
                          loading={loading}
                          onClick={fetchData}
                          icon={<ReloadOutlined/>}
                          style={{marginRight: 8}}
                      />
                  </Space>
              }

        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <AppRatingsMetrics data={data} loading={loading} lastUpdatedAt={lastUpdatedAt}/>
                </Col>
                <Col span={24}>
                    <RatingsDistribution data={data} loading={loading} lastUpdatedAt={lastUpdatedAt}/>
                </Col>
                <Col span={24}>
                    <ProductsRatings data={data} loading={loading} lastUpdatedAt={lastUpdatedAt}/>
                </Col>
            </Row>
        </Card>
    )
}

export default RatingsMetrics