import {AxiosResponse} from "axios";

import Api from "./api";
import {PokePermission} from "../types";

interface GetUserPokePermissionsParams {
    userId: number | string;
}

interface GetUserPokePermissionsResponse {
    pokePermissions: PokePermission[]
}

export const getUserGrantedPokePermissions = (params: GetUserPokePermissionsParams): Promise<AxiosResponse<GetUserPokePermissionsResponse>> => {
    return Api.get(
        '/admin/poke-permissions/granted',
        {
            params: {
                user_id: params.userId,
            }
        }
    )
}

export const getUserReceivedPokePermissions = (params: GetUserPokePermissionsParams): Promise<AxiosResponse<GetUserPokePermissionsResponse>> => {
    return Api.get(
        '/admin/poke-permissions/received',
        {
            params: {
                user_id: params.userId,
            }
        }
    )
}


export default {
    getUserGrantedPokePermissions,
    getUserReceivedPokePermissions
}