import React from "react";

const handDetect = ({hd_ctrl, hd_select}: any) => {
    return `✋ -> (${hd_ctrl || ''}-${hd_select || ''})`;
};

const TableRecordConfig: React.FC<any> = (props) => {
    const {button, zap_level, motor_level, piezo_level} = props
    return (<>
            {button || ''} ⚡{zap_level || ''}% 📳{motor_level || ''}% 🔊{piezo_level || ''}% {handDetect(props)}
        </>
    );
};

export default TableRecordConfig;