export const convertToDashCase = (value: string | undefined): string | null => {
    if (!value)
        return null;
    return value.replace(/[A-Z]/g, char => "_" + char.toLowerCase());
}

const uuidv4 = (): string => {
    return ([1e7] + '' + -1000 + -4e3 + -8e3 + -1e11)
        .replace(/[018]/g, (c: string) =>
            (
                Number(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> Number(c) / 4)
                .toString(16
                )
        );
}

export const generateRandomString = (prefix: string | null = null): string => {
    return (!!prefix || '') + uuidv4()
}

export const generateSlug = (text: string): string => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word characters except hyphens
        .replace(/-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}

export const formatNumber = (num: number) => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    }
    if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'k';
    }
    return num.toString();
}

export function formatNumberWithCommas(number: string) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function rankFormat(rank: number) {
    switch (rank) {
        case 1:
            return '1 st'
        case 2:
            return '2 nd'
        case 3:
            return '3 rd'
        default:
            return `${rank} th`
    }
}

export default {
    convertToDashCase, generateRandomString, generateSlug
}