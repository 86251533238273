import React, {useEffect, useState} from "react"
import {User} from "../../../../types";
import {Button, Card, Form, Input, message} from "antd";

import {UsersService} from 'services'

// ----- Variables -----
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};

// ----- Props -----
interface UserPersonalInfoCardProps {
    cardId: string
    user: User | null
    loading: boolean
    setUser?: (user: User) => void
}

// ----- Component -----
const UserPersonalInfoCard: React.FC<UserPersonalInfoCardProps> = ({cardId, user, setUser, loading: userLoading}) => {
    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- I,nit ----
    useEffect(() => {
        if (user) {
            form.setFieldsValue(user)
        }
    }, [user])

    // ----- Handlers -----
    const handleFinish = (values: any) => {
        // Handle form submission logic
        console.log('values, ', values);
        if (!user || !user.id) return
        setLoading(true)
        const updateUser = {
            id: Number(user.id),
            firstName: values?.firstName,
            lastName: values?.lastName,
        }
        UsersService.updateUser(updateUser)
            .then(res => {
                const responseUser = res?.data?.user
                if (responseUser) {
                    setUser?.(responseUser)
                    message.success('User updated')
                } else {
                    message.error('Error while updating user')
                }
            })
            .catch(err => {
                console.error('Error while updating user, message:', err)
                message.error('Error while updating user')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <Card id={cardId || "personal-info"} title="Personal Info" loading={userLoading}>
            <Form
                {...formLayout}
                form={form}
                onFinish={handleFinish}
            >
                <Form.Item name="firstName"
                           label="First Name"
                           rules={[{required: true, message: 'Please input the first name!'}]}
                >
                    <Input placeholder="Please input first name"/>
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label="First Name"
                    rules={[{required: true, message: 'Please input the last name!'}]}
                >
                    <Input placeholder="Please input last name"/>
                </Form.Item>
                <Button htmlType="submit"
                        type="primary"
                        className="float-right mt-3"
                        loading={loading}
                >
                    Update personal info
                </Button>
            </Form>
        </Card>
    )

}

export default UserPersonalInfoCard