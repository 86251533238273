import React, {RefObject, useEffect, useRef, useState} from 'react';
import {Button, Flex, Form, Input, message, notification, Space, Spin, Tooltip} from "antd";
import {
    ArrowUpOutlined,
    AudioOutlined,
    BorderOutlined,
    CloseOutlined,
    DeleteOutlined,
    LoadingOutlined
} from "@ant-design/icons";


// ----- Local calls -----
import {sendAudioToServer, sendMessage} from "services/ai-chat";
import {AiChatMessage, AiChatMessageRole, AiChatResponse, StreamEventTypeEnum} from "types";
import {generateRandomId} from "utils/number";

// ----- Global variables -----
const boxStyle: React.CSSProperties = {
    width: '100%',
    // Todo: Change the padding to be responsive
    padding: '10px 60px',
};

// ----- Types -----
export interface AiChatInputProps {
    threadId: number | null
    assistantIsTyping?: boolean
    setAssistantIsTyping: (value: boolean | ((value: boolean) => boolean)) => void;
    setMessages: (value: AiChatMessage[] | ((currentMessages: AiChatMessage[]) => AiChatMessage[])) => void;
    handleMessageResponse: (response: AiChatResponse) => void
    handleSendTextMessage: (text: string) => void
    suggestions?: string[]
    setSuggestions?: (value: string[] | ((value: string[]) => string[])) => void;
    handleNewThread?: () => void
}


const AiChatInput: React.FC<AiChatInputProps> = (
    {
        threadId,
        setMessages,
        handleMessageResponse,
        assistantIsTyping,
        setAssistantIsTyping,
        handleSendTextMessage,
        suggestions,
        setSuggestions,
    }) => {
    // ----- States -----
    // ----- Text State -----
    const [form] = Form.useForm();

    // ----- Audio State -----
    // todo: convert this to a state machine
    const [isRecording, setIsRecording] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    // Current index state to track which audio is playing
    const [currentIndex, setCurrentIndex] = useState(0);

    // ----- Audio Data -----
    const [audioData, setAudioData] = useState<Blob | null>();  // Input audio data
    const [audioUrl, setAudioUrl] = useState<string | null>(null); // Output audio URL.
    const [audioList, setAudioList] = useState<string[]>([]); // List of audio URLs

    // ----- References -----
    const mediaRecorderRef = useRef<MediaRecorder>();
    const audioRef: RefObject<HTMLAudioElement> = useRef<HTMLAudioElement>(null);

    // ----- Effects -----
    const resetChat = () => {
        // Clear audio data
        setAudioData(null)
        setAudioList([])
        setCurrentIndex(0)
        setIsSending(false)
        setIsPlaying(false)
        setIsRecording(false);
        // Clear the suggestions
        setSuggestions?.([])
        // Clear the input
        form.setFieldsValue({message: ''})
    }
    useEffect(() => {
        resetChat()
    }, [threadId])
    /*----- Handle Send audio -----*/
    const stopAudio = () => {
        setAudioList([]);
        setIsPlaying(false);
        setCurrentIndex(0); // Reset the index to start from the first audio if playback resumes
    }
    // Function to play the next audio in the list
    const playAudio = (index: number) => {
        // Make sure the index is within bounds
        if (index < audioList.length) {
            console.log("Playing audio: ", index)
            // When we receive new audio, we change the src of the audio element
            if (audioRef?.current?.src)
                audioRef.current.src = audioList[index];
            // Play the new audio
            audioRef?.current?.play?.()
                .catch((e) => console.error("Playback failed:", e));

            // Update the current index
            setCurrentIndex(index);
        }
    };
    // Effect to set up the audio ended listener
    useEffect(() => {
        // Set up the audio ended listener
        const audio = audioRef.current;
        const handleEnded = () => {
            // console.log("Create Event Listener on audio: ", currentIndex)
            // Play the next audio when the current one ends
            const nextIndex = currentIndex + 1;
            // console.log("nextIndex", nextIndex)
            if (nextIndex < audioList.length) {
                playAudio(nextIndex);
                setCurrentIndex(nextIndex);
            }
        };

        audio?.addEventListener('ended', handleEnded);
        return () => audio?.removeEventListener('ended', handleEnded);
    }, [currentIndex, audioList.length, isPlaying]);

    // Play audio when a new URL is added, and it's the next audio in sequence
    useEffect(() => {
        // Play the first audio when the component mounts
        if (audioList.length === 1 && currentIndex === 0) {
            playAudio(0);
        }
    }, [audioList.length]);

    const startRecording = async () => {
        // Clear previous audio data
        setAudioData(null);
        setAudioUrl(null);

        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        // Specify the MIME type for the recording
        const options = {mimeType: 'audio/webm'};

        mediaRecorderRef.current = new MediaRecorder(stream, options);
        mediaRecorderRef.current.ondataavailable = (event) => {
            console.log("ondataavailable", event.data)
            setAudioData(event.data);
            setAudioUrl(URL.createObjectURL(event.data));
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current?.stop();
        setIsRecording(false);
    };

    const handleClearAudio = () => {
        setAudioData(null)
        // todo: Move this code to stopRecording??
        // Set the sending state
        setIsSending(false);
        // Clear the error
        // setError(null)
        // Clear the audio list
        setAudioList([])
        // Reset the current index for the audio list
        setCurrentIndex(0)
    }
    /*----- End Handle Send audio -----*/


    const handleSendAudio = async () => {
        if (!audioData) return;
        // Set the sending state
        setIsSending(true);
        // Clear the audio list
        setAudioList([])
        // Reset the current index for the audio list
        setCurrentIndex(0)
        try {
            const response = await sendAudioToServer(audioData, threadId);
            // Clear the audio data
            setIsSending(false);
            setAudioData(null)
            message.success('Audio sent successfully');
            handleResponse(response)
        } catch (error) {
            message.error('Error sending audio');
            console.error('Error sending audio:', error)
        }
    };

    const handleSendText = async (text?: string | null) => {
        // todo: Get value from state or from the input choose one
        const inputValue = form.getFieldValue('message')
        // If the message is null, get the message from the input
        const messageContent = text || inputValue
        if (!messageContent) return
        // todo: Use
        // Clear the suggestions
        setSuggestions?.([])
        // Clear the input
        form.setFieldsValue({message: ''})
        // Clear the audio data
        setAudioData(null)
        // Clear the audio list
        setAudioList([])
        // Reset the current index for the audio list
        setCurrentIndex(0)
        // Set the sending state
        setIsSending(true);
        // Clear the suggestions, active event, etc.
        handleSendTextMessage(messageContent)
        // Show user message on UI
        const ui_message: AiChatMessage = {
            id: generateRandomId(),
            key: generateRandomId() + "",
            threadId: threadId || 99999,
            content: messageContent,
            role: AiChatMessageRole.USER,
            hidden: false,
            createdAt: new Date(),
            updatedAt: new Date()
        }
        // Add the user new message
        setMessages((currentMessages) => [...currentMessages, ui_message]);
        // Loading.
        setAssistantIsTyping(true)
        try {
            const response = await sendMessage({
                threadId: threadId,
                content: messageContent,
            });
            setIsSending(false);
            message.success('Message sent successfully');
            handleResponse(response)
        } catch (error) {
            message.error('Error sending audio');
            console.error('Error sending audio:', error)
        } finally {
            // setIsSending(false);
            setAssistantIsTyping(false)
        }
    };

    const handleSuggestionClick = async (message: string) => {
        // todo: Move to input component
        setSuggestions?.([])
        handleSendText(message)
    }

    const handleResponse = async (response: any) => {
        // todo: move this code to the parent component
        setAssistantIsTyping(true)
        // Handle streaming
        const reader = response?.body?.pipeThrough?.(new TextDecoderStream()).getReader();
        if (!reader) {
            console.error("Reader is not available");
            message.error('Connection error! Error reading messages');
            return;
        }
        const alpha = true;
        while (alpha) {
            const {value, done} = await reader.read();
            if (done) break;
            try {
                // const {value, done} = await reader.read();
                const formattedValue = value.replaceAll("\"}{\"", "\"},{\"")
                // console.log('formattedValue', formattedValue)
                const parsedValue = JSON.parse(`[${formattedValue}]`);
                parsedValue.forEach((obj: AiChatResponse) => {
                    const event = obj?.event
                    console.log("Handling event: ", event)
                    switch (event) {
                        case StreamEventTypeEnum.REPLY:
                            // todo: move code to here or to the parent component
                            handleMessageResponse(obj)
                            break;
                        case StreamEventTypeEnum.SUGGESTIONS:
                            // todo: move code to here or to the parent component
                            handleMessageResponse(obj)
                            break;
                        case StreamEventTypeEnum.TOOL_CALL:
                            // todo: move code to here or to the parent component
                            console.log("WIP - ToolCall event")
                            handleMessageResponse(obj)
                            break;
                        case StreamEventTypeEnum.TOOL_CALL_RESPONSE:
                            // todo: move code to here or to the parent component
                            console.log("WIP - ToolCall response event")
                            handleMessageResponse(obj)
                            break;
                        case StreamEventTypeEnum.AUDIO_MESSAGE:
                            handleAudioMessageEvent(obj)
                            break;
                        case StreamEventTypeEnum.USER_MESSAGE:
                            handleUserMessageEvent(obj)
                            break;
                        case StreamEventTypeEnum.END_OF_STREAM:
                            notification.success({message: "End of stream"})
                            break;
                        default:
                            // console.log("Unknown event: ", event)
                            handleMessageResponse(obj)
                            break;
                    }

                })
            } catch (error) {
                console.error('Error receiving messages:', error)
            }
        }
        setAssistantIsTyping(false)
    }

    const handleSendMessage = async () => {
        const messageType = audioData ? 'audio' : 'text';
        switch (messageType) {
            case "audio":
                // Send the audio to the server
                await handleSendAudio();
                break;
            case "text":
                // Send the text to the server
                await handleSendText();
                break;
            default:
                break;
        }
    }

    // ----- Handle Response Events -----
    const handleUserMessageEvent = async (objectResponse: AiChatResponse) => {
        if (typeof objectResponse.data !== 'object') return;
        // Add user message to the messages list
        const messageResponse: AiChatMessage = objectResponse.data;
        setMessages((currentMessages: AiChatMessage[]) => [...currentMessages, messageResponse]);
    }

    const handleAudioMessageEvent = (response: AiChatResponse): void => {
        // Handle the audio message response
        const audioSrc: string = response?.audio_data || '';
        const text: string = response?.data as string;
        console.log("Receiving audio number: ", response.id)
        if (audioSrc) {
            setAudioList((oldList) => [...oldList, audioSrc]);
        }
        if (text) {
            // Add the assistant message to the messages list
            handleAddAssistantMessage(text);
        }
    }

    const handleAddAssistantMessage = (messageContent: string): void => {
        // Add the assistant message to the messages list
        setMessages((prevMessages: AiChatMessage[]) => {
            // Check if the last message is from the assistant
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.role === AiChatMessageRole.ASSISTANT) {
                // Update the last assistant message
                return prevMessages.map((msg, idx) =>
                    idx === prevMessages.length - 1 ?
                        {...msg, content: msg.content + messageContent}
                        :
                        msg
                );
            } else {
                // Add a new assistant message
                const newAssistantMessage: AiChatMessage = {
                    id: generateRandomId(),
                    role: AiChatMessageRole.ASSISTANT,
                    content: messageContent,
                    hidden: false,
                    threadId: generateRandomId()
                };
                return [...prevMessages, newAssistantMessage];
            }
        });
    }
    // ----- End Handle Response Events -----


    return (
        <div>
            <div className="suggestionsContainer">
                {suggestions?.map((item, key) => (
                    <Button key={key}
                            type="default"
                            shape="round"
                            className="suggestionItem"
                            onClick={() => handleSuggestionClick(item)}>
                        {item}
                    </Button>
                ))}
            </div>
            <Flex vertical justify="center" align="center" gap={4}>
                <Space>
                    {audioList.length > 0 && (
                        <>
                            <audio
                                controls
                                src={audioList[currentIndex]}
                                ref={audioRef}
                                autoPlay
                                className="audio"
                            />
                            <Tooltip title="Stop">
                                <Button
                                    danger
                                    shape="circle"
                                    type="primary"
                                    onClick={stopAudio}
                                    icon={<CloseOutlined/>}
                                    // style={{border: '1px solid #c5c5c5'}}
                                    // disabled={!audioUrl || isSending || !isPlaying || !audioList.length}
                                />
                            </Tooltip>
                            <span>{currentIndex + 1}/{audioList.length}</span>
                        </>
                    )}
                </Space>
                <Flex style={boxStyle} justify="center" align="center" gap={12}>
                    <Tooltip title={isRecording ? "Stop recording" : "Record"}>
                        <Button
                            icon={isRecording ? <BorderOutlined/> : <AudioOutlined/>}
                            type="primary"
                            // size="large"
                            danger={!isRecording}
                            shape="circle"
                            style={{border: '1px solid #c5c5c5'}}
                            disabled={isSending}
                            onClick={isRecording ? stopRecording : startRecording}/>
                    </Tooltip>
                    <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}
                          spinning={isRecording || isSending}/>
                    {
                        audioData ?
                            (
                                <>
                                    <audio
                                        src={audioUrl || ''}
                                        controls
                                        className="audio"
                                    />
                                    <Tooltip title="Delete audio">
                                        <Button
                                            type="dashed"
                                            shape="circle"
                                            danger
                                            onClick={handleClearAudio}
                                            icon={<DeleteOutlined/>}
                                        />
                                    </Tooltip>
                                </>
                            ) :
                            (
                                <Form
                                    form={form}
                                >
                                    <Form.Item
                                        label={null}
                                        name="message"
                                        rules={[{required: true, message: 'Please select a topic!'}]}
                                        noStyle
                                    >
                                        <Input.TextArea
                                            rows={1}
                                            onPressEnter={() => !assistantIsTyping && handleSendMessage()} // Handle Enter press
                                            style={{maxWidth: 400, minWidth: 300, borderRadius: 20, border: '1px solid #c5c5c5'}}
                                            disabled={isRecording}
                                            placeholder={"Typing"}
                                        />
                                    </Form.Item>
                                </Form>
                            )
                    }
                    <Tooltip title="Send message">
                        <Button
                            type="primary"
                            shape="circle"
                            disabled={isRecording || isSending || assistantIsTyping}
                            onClick={handleSendMessage}
                            icon={<ArrowUpOutlined/>}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </div>
    );
};

export default AiChatInput;
