import React from "react"

import "./style.css"

const Index = () => {
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
            <span>Typing</span>
            <div style={{display: 'inline-block', marginLeft: '5px'}}>
                <span className="dot" style={{animationDelay: '0s'}}></span>
                <span className="dot" style={{animationDelay: '0.2s'}}></span>
                <span className="dot" style={{animationDelay: '0.4s'}}></span>
            </div>
        </div>
    );
};

export default Index