import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react"
import {Button, Card, Form, message, Select} from "antd";

import {UsersService} from 'services'
import {User} from "../../../../types";
import {upperFirst} from "lodash";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/state";

// ----- Variables -----
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};
const ROLES = ["super-admin", "admin", "coach", "support", "user"];
const ROLES_MAPPING: { [key: string]: number } = {
    "root": 1,
    "super-admin": 2,
    "admin": 3,
    "coach": 4,
    "support": 5,
    "user": 6,
}
const ROLE_PERMISSION_MAPPING: { [key: string]: string[] } = {
    //  "root" : [ "super-admin": >
    "root": ["super-admin", "admin", "coach", "support", "user"],
    "super-admin": ["super-admin", "admin", "coach", "support", "user"],
    "admin": ["admin", "coach", "support", "user"],
    "coach": [],
    "support": ["coach", "support", "user"],
    "user": [],
}

// ----- Props -----
interface UserRoleCardProps {
    cardId: string
    user: User | null
    loading: boolean
    setUser?: Dispatch<SetStateAction<User | null>>;
}

// ----- Component -----
const UserRoleCard: React.FC<UserRoleCardProps> = ({cardId, user, loading: userLoading}) => {
    // ----- Store -----
    const authenticatedUser = useSelector((state: RootState) => state?.auth?.authenticatedUser);

    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- Mimo -----
    function canUpdateRole(currentUserRole: string | null | undefined, oldRole: string | null | undefined, newRole: string | null | undefined): boolean {
        currentUserRole = currentUserRole || 'user';
        oldRole = oldRole || 'user';
        newRole = newRole || 'user';

        return ROLE_PERMISSION_MAPPING[currentUserRole].indexOf(newRole) != -1 &&
            ROLE_PERMISSION_MAPPING[currentUserRole].indexOf(oldRole) != -1
    }

    const options = useMemo(() => {
        return ROLES?.map((item: string) => {
            // console.log('Can', authenticatedUser?.role, ', item:', item, canUpdateRole(authenticatedUser?.role || '', item))
            return (
                {
                    value: item,
                    label: upperFirst(item),
                    disabled: !canUpdateRole(authenticatedUser?.role, user?.role, item)
                }
            )
        })
    }, [authenticatedUser, user?.role])

    // ----- I,nit ----
    useEffect(() => {
        if (user) {
            form.setFieldsValue(user)
            if (!user?.role) {
                form.setFieldsValue({role: 'user'})
            }
        }
    }, [user])

    // ----- Handlers -----
    const handleFinish = (values: any) => {
        if (!user || !user.id) return
        if (!ROLES_MAPPING[values?.role]) return

        setLoading(true)
        const updateUser = {
            userId: Number(user.id),
            roleId: ROLES_MAPPING[values.role],
        }

        // set the permission logic
        UsersService.updateUserRole(updateUser)
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus === 200) {
                    /*setUser?.(user => ({
                        ...user,
                        role: values?.role
                    }))*/
                    message.success('Role updated successfully')
                } else {
                    message.error('Error while updating user')
                }
            })
            .catch(err => {
                console.error('Error while updating user, message:', err)
                message.error('Error while updating user')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <Card id={cardId || "role-info"} title="Role Info" loading={userLoading}>
            <Form
                {...formLayout}
                form={form}
                onFinish={handleFinish}
                initialValues={{role: 'user'}}
            >
                <Form.Item name="role"
                           label="role"
                           rules={[{required: true, message: 'Please select a role!'}]}
                >
                    <Select placeholder="Please select a role" options={options}>
                        {/*<Option key="root" value="root" >Root</Option>*/}
                    </Select>
                </Form.Item>
                <Button htmlType="submit"
                        type="primary"
                        className="float-right mt-3"
                        loading={loading}
                >
                    Update role
                </Button>
            </Form>
        </Card>
    )

}

export default UserRoleCard