import React, {useEffect, useRef, useState} from 'react';
import { EditOutlined, EyeOutlined, ReloadOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Card, Col, Input, Row, Space, Table, Tooltip} from 'antd';
import moment from 'moment';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {SorterResult} from "antd/es/table/interface";

import {User} from "../../../types";
import {getAllUsers} from "../../../services/users";
import {getColumnSearchProps} from "../../../components/TableSearch";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";


type Filters = {
    [key: string]: any;
}

const DEFAULT_PAGE_SIZE = 20
const {Search} = Input;

const UsersListPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [search, setSearch] = useState<string>('')
    const [sortField, setSortField] = useState<string | undefined>()
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>()

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [filters, setFilters] = useState<Filters>({})
    // ----- End table -----

    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage, sortField, sortOrder, filters])

    const fetchData = () => {
        setLoading(true)
        getAllUsers({
            page: currentPage,
            pageSize,
            search,
            sortField,
            sortOrder,
            filters
        })
            .then(res => {
                const items = res?.data?.items || []
                const count = res?.data?.count || 0
                const usersList = items?.map((item: User, index: number) => ({...item, key: `key-${index}`}))
                setUsers(usersList || [])
                setTotalItems(count);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        fetchData()
        // todo: add the search to the url
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Avatar',
            dataIndex: 'profilePictureUrl',
            key: 'avatar',
            // width: '30%',
            render: (profilePictureUrl: string | null) =>
                <Row justify="center">
                    <Col>
                        {
                            profilePictureUrl ? <Avatar src={profilePictureUrl}/> :
                                // <Avatar style={{backgroundColor: '#1890ff'}}>{user?.email?.at(0) || ''}</Avatar>,
                                <Avatar style={{backgroundColor: '#1890ff'}} icon={<UserOutlined/>}></Avatar>
                        }
                    </Col>
                </Row>
            ,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // width: '30%',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'email'
            }),
            sorter: (a, b) => a?.email?.localeCompare(b?.email)
        },
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
            // width: '20%',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'firstName'
            }),
            sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName)
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
            // width: '20%',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'lastName'
            }),
            sorter: (a, b) => a?.lastName?.localeCompare(b?.lastName)
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value) =>
                <>
                    {value && moment(value).format('MMMM Do YYYY, h:mm:ss a')}
                </>
            // sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_: number, record: User) => (
                <Space>
                    <Tooltip title="Details page">
                        <Button type="primary" href={`/users/${record.id}`} icon={<EyeOutlined/>}/>
                    </Tooltip>
                    <Tooltip title="Edit page">
                        <Button type="primary" href={`/users/edit/${record.id}`} icon={<EditOutlined />}/>
                    </Tooltip>
                </Space>
            )
        }
    ];


    return (
        <>
            <CustomBreadcrumb list={['users', 'LiST',]}/>
            <Card title="Users list"
                  extra={
                      <Button 
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                  }
            >
                <Row gutter={[16, 16]} justify="end">
                    <Col span={5}>
                        <Search
                            loading={loading}
                            placeholder="Search"
                            onSearch={onSearch}
                            onChange={(e) => setSearch(e?.target?.value)}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col span={24}>
                        <Table<User>
                            columns={columns}
                            dataSource={users}
                            loading={loading}
                            size="small"
                            bordered
                            pagination={{
                                pageSize: pageSize,
                                current: currentPage,
                                total: totalItems,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30', '50', '100'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                            }}
                            onChange={(pagination, filters, sorter: SorterResult<User> | SorterResult<User>[],) => {
                                setFilters(filters)
                                setPageSize(pagination.pageSize || DEFAULT_PAGE_SIZE);
                                setCurrentPage(pagination.current || 1);
                                if (typeof sorter === 'object') {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setSortField(sorter?.field?.toString())
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setSortOrder(sorter?.order?.toString())
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    )

};

export default UsersListPage;