import React, {useEffect, useState} from "react"
import {User} from "../../../../types";
import {Badge, Button, Card, Col, Form, Typography, message, Switch} from "antd";

import {UsersService} from 'services'

// ----- Variables -----
const {Text, Paragraph} = Typography
const formLayout = {
    // labelCol: {span: 6},
    // wrapperCol: {span: 8},
};

// ----- Props -----
interface UserDeleteAccountCardProps {
    cardId: string
    user: User | null
    loading: boolean
    setUser?: (user: User) => void
}

// ----- Component -----
const UserDeleteAccountCard: React.FC<UserDeleteAccountCardProps> = ({cardId, user, loading: userLoading}) => {
    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- I,nit ----
    useEffect(() => {
        if (user) {
            form.setFieldsValue(user)
        }
    }, [user])

    // ----- Handlers -----
    const handleDeleteAccount = () => {
        // Handle form submission logic
        if (!user || !user.id) return
        setLoading(true)
        const params = {
            userId: user.id
        }
        UsersService.deleteUserAccount(params)
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus === 200) {
                    message.success('User account deleted')
                    // todo: navigate to users page
                    // todo: if delete current account logout the user
                } else {
                    message.error('Error while updating user')
                    //
                }
            })
            .catch(err => {
                console.error('Error while updating user, message:', err)
                message.error('Error while updating user')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const handleDeactivateAccount = () => {
        // Handle form submission logic
        if (!user || !user.id) return
        setLoading(true)
        const params = {
            userId: user.id
        }
        UsersService.deactivateUserAccount(params)
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus === 200) {
                    message.success('User account deleted')
                    // todo: navigate to users page
                    // todo: if delete current account logout the user
                } else {
                    message.error('Error while updating user')
                    //
                }
            })
            .catch(err => {
                console.error('Error while updating user, message:', err)
                message.error('Error while updating user')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <Badge.Ribbon text="Under development" color="red">
            <Card id={cardId || "delete-account"} title="Delete Account" loading={userLoading}>
                <Text className="mb-6">
                    Once you delete your account, there is no going back. Please be certain.
                </Text>
                <Form {...formLayout} form={form}>
                    <Form.Item noStyle name="confirmDeleteAccount" label="Confirm" valuePropName="checked">
                        <Col
                            span={24}
                            md={12}
                            className="mt-5"
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Switch className="mt-1"/>
                            <div>
                                <Paragraph strong
                                           style={{marginLeft: '12px'}}
                                           className="mb-0"
                                >
                                    Confirm
                                </Paragraph>
                                <p style={{marginLeft: '12px'}}>
                                    I want to delete my account.
                                </p>
                            </div>
                        </Col>
                    </Form.Item>
                    <div className="d-flex align-items-center justify-content-space-between">
                        <Col
                            span={24}
                            className="text-right"
                            style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                            <Button className="mr-3"
                                    onClick={handleDeactivateAccount}
                                    loading={loading}
                                    disabled
                            >
                                DEACTIVATE
                            </Button>

                            <Button
                                className="px-25"
                                type="primary"
                                onClick={handleDeleteAccount}
                                loading={loading}
                                danger
                                disabled
                            >
                                DELETE ACCOUNT
                            </Button>
                        </Col>
                    </div>
                </Form>
            </Card>
        </Badge.Ribbon>
    )

}

export default UserDeleteAccountCard