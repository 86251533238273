import React, {useEffect, useState} from "react";
import {Form, Input, Button, message, Card, Upload, Switch, InputNumber, Spin} from "antd";
import {ReloadOutlined, UploadOutlined} from '@ant-design/icons';
import {useParams} from "react-router-dom";
import type {UploadProps} from "antd/es/upload/interface";
import {RcFile, UploadFile} from "antd/es/upload/interface";


// ----- Local calls -----
import {
    createFirmware,
    FirmwareInCreate,
    FirmwareInUpdate,
    getAllFirmwares,
    getFirmwareById,
    updateFirmware
} from "services/firmwares";
import {Firmware} from "types";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import HardwareSelect from "../../../components/HardwareSelect";
import {getBase64} from "../../../utils/upload";

// ----- Global variables -----
const formLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 8},
};

// ----- Types -----
type RouteParams = {
    firmwareId?: string
}

type MinAppVersion = {
    minimalAppVersionNumber: number,
    minimalAppVersionString: string
}

// ----- Components -----
const CreateFirmwarePage: React.FC = () => {
    // ----- params -----
    const {firmwareId} = useParams<RouteParams>();

    // ----- data -----
    const [firmware, setFirmware] = useState<Firmware | null>(null)
    const [loading, setLoading] = useState(false);
    const [loadingMinAppVersion, setLoadingMinAppVersion] = useState(false)
    const [minAppVersion, setMinAppVersion] = useState<string>("")

    // ----- form -----
    const [form] = Form.useForm();

    // ----- File  -----
    const [fileBase64, setFileBase64] = useState<string>("");

    // ----- Init -----
    useEffect(() => {
        // if firmware id then Edit case
        if (firmwareId) {
            fetchFirmware()
        }
    }, [firmwareId])

    useEffect(() => {
        // set the form values
        console.log("Setting form values", firmware?.minimalAppVersion)
        form.setFieldsValue({
            versionNumber: firmware?.versionNumber || 0,
            // versionString: firmware?.versionString || '',
            versionString: firmware?.versionString || '',
            body: firmware?.body || '',
            test: firmware?.test || false,
            archived: false,
            file: firmware?.fileUrl || null,
            hardwareIds: firmware?.hardwares?.map(hardware => hardware.id) || [],
            minimalAppVersion: firmware?.minimalAppVersion || '',
        })
    }, [firmware])

    const fetchFirmware = () => {
        if (!firmwareId) return
        setLoading(true)
        getFirmwareById(Number(firmwareId))
            .then(res => {
                console.log('Firmware fetched: ', res.data.firmware)
                const firmwareResponse = res.data.firmware
                if (firmwareResponse) {
                    setFirmware(firmwareResponse)
                    if (!firmwareResponse.minimalAppVersion) {
                        handleHardwareChange(firmwareResponse.hardwares.map(hardware => hardware.id))
                    }
                }
            })
            .catch(err => {
                console.error('Error fetching firmware, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = async (values: FirmwareInCreate) => {
        setLoading(true);

        try {
            const firmware: FirmwareInCreate = {...values, file: fileBase64 || null};
            // console.log("handleSubmit firmware:", firmware)

            if (firmwareId) {
                const firmwareUpdate: FirmwareInUpdate = {
                    firmwareId: Number(firmwareId),
                    ...firmware,
                    // file: fileBase64 || null
                }

                await updateFirmware(firmwareUpdate);
            } else {
                await createFirmware(firmware);
                // Reset form fields and image state
                form.resetFields();
                //,setImageBase64(null);
            }
            // Show success message
            message.success(`Firmware ${firmwareId ? 'updated' : 'created'} successfully`);

        } catch (error) {
            // Show error message
            message.error(`Failed to ${firmwareId ? 'update' : 'create'} firmware`);
        }
        setLoading(false);
    };

    const handleBeforeUpload = (file: RcFile) => {
        const updateFile = (fileStr: string) => {
            // Remove the
            setFileBase64(fileStr);
            form.setFieldValue('file', fileStr)
        }
        getBase64(file as RcFile, updateFile)
        return false; // Prevent default upload behavior
    };

    const handleFileRemove = (file: UploadFile) => {
        // console.log("handleFileRemove", file)
        return
    }

    const handleHardwareChange = async (value: number[]) => {
        // Get the firmwares list
        setLoadingMinAppVersion(true)
        try {
            const response = await getAllFirmwares();
            const firmwares = response.data.firmwares
            if (!firmwares) {
                console.error("No firmwares found")
                return
            }
            // get the max of minAppVersion
            const minAppVersionObject = firmwares
                .filter((firmware: Firmware) => {
                    return firmware.minimalAppVersion != null && firmware.hardwares.some(hardware => value.includes(hardware.id))
                })
                .reduce((prev: MinAppVersion, firmware: Firmware) => {
                    // Get the number of the minAppVersion
                    const minAppVersionNumber = parseInt(firmware.minimalAppVersion.split('.').join(''));
                    // We take the max of the minAppVersion
                    if (minAppVersionNumber > prev.minimalAppVersionNumber) {
                        return {
                            minimalAppVersionNumber: minAppVersionNumber,
                            minimalAppVersionString: firmware.minimalAppVersion
                        }
                    }
                    return prev
                }, {
                    minimalAppVersionNumber: 0,
                    minimalAppVersionString: ''
                } as MinAppVersion)

            setMinAppVersion(minAppVersionObject.minimalAppVersionString)

            //todo: set a constraint that the min app version should always bigger then the max of minAppVersion
        } catch (err) {
            console.error("Error fetching firmwares", err)
        } finally {
            setLoadingMinAppVersion(false)
        }
    }

    return (
        <>
            <CustomBreadcrumb list={['Firmwares', firmwareId ? "Edit" : "Create"]}/>
            <Card
                title={firmwareId ? "Edit firmware" : "Create firmware"}
                loading={loading}
                extra={
                    <Button type="primary"
                            loading={loading}
                            onClick={() => fetchFirmware()}
                            disabled={loading || !firmwareId}
                            icon={<ReloadOutlined/>}/>
                }
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    {...formLayout}
                    initialValues={{
                        test: false,
                        archived: false,
                    }}
                >
                    <Form.Item name="versionString" label="Version string" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="body" label="Body" rules={[{required: true}]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item name="test" label="Draft" valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    <Form.Item name="hardwareIds" label="Hardware" rules={[{required: true}]}>
                        <HardwareSelect mode="multiple" placeholder="Select hardware" onChange={handleHardwareChange}/>
                    </Form.Item>
                    <Form.Item name="minimalAppVersion" label="Minimal app version" rules={[
                        {required: true},
                        () => ({
                            validator(_, value) {
                                // Convert the version to a number
                                if (!value) {
                                    return Promise.resolve();
                                }
                                const valueInt = parseInt(value.split('.').join('')) || 0;
                                const minAppVersionInt = parseInt(minAppVersion.split('.').join('')) || 0;
                                if (valueInt >= minAppVersionInt) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(`Minimal app version should be greater or equal to ${minAppVersion}`));
                            },
                        }),
                        () => ({
                            validator(_, value) {
                                const regex = /^([0-9]{1,3}\.){2}[0-9]{1,3}$/;
                                if (regex.test(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Invalid format. Format should be X.X.X where X is a number between 0 and 999'));
                            },
                        }),
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="file" label="File" rules={[{required: true}]}>
                        <Upload
                            name="file"
                            // beforeUpload={() => false}
                            beforeUpload={handleBeforeUpload}
                            maxCount={1} // Allow only one file
                            onRemove={handleFileRemove}
                            accept=".zip"
                        >
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {firmwareId ? "Update Firmware" : "Create Firmware"}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default CreateFirmwarePage;
