import React, {useEffect, useState} from "react"
import {User} from "../../../../types";
import {Button, Card, Form, Input, message, Space} from "antd";

import {UsersService} from 'services'

// ----- Variables -----
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};

// ----- Props -----
interface UserContactInfoCardProps {
    cardId: string
    user: User | null
    loading: boolean
    setUser?: (user: User) => void
}

// ----- Component -----
const UserContactInfoCard: React.FC<UserContactInfoCardProps> = ({cardId, user, setUser, loading: userLoading}) => {
    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- I,nit ----
    useEffect(() => {
        if (user) {
            console.log('u', user)
            console.log("user?.email", user?.email)
            form.setFieldsValue({
                email: user?.email,
                phone: user?.phone
            })
        }
    }, [user])

    // ----- Handlers -----
    const handleFinish = (values: any) => {
        // Handle form submission logic
        if (!user || !user.id) return
        setLoading(true)
        const updateUser = {
            id: Number(user.id),
            email: values?.email,
            phone: values?.phone,
        }
        UsersService.updateUser(updateUser)
            .then(res => {
                const responseUser = res?.data?.user
                if (responseUser) {
                    setUser?.(responseUser)
                    message.success('User updated')
                } else {
                    message.error('Error while updating user')
                }
            })
            .catch(err => {
                console.error('Error while updating user, message:', err)
                message.error('Error while updating user')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const handleResendConfirmationEmail = () => {
        // Handle form submission logic
        if (!user || !user.id) return
        setLoading(true)
        const updateUser = {
            userId: Number(user.id),
        }
        UsersService.resendConfirmationEmail(updateUser)
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus == 200) {
                    message.success('Email sent')
                } else {
                    message.error('Error while sending email')
                }
            })
            .catch(err => {
                console.error('Error while sending email, message:', err)
                message.error('Error while sending email')
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <Card id={cardId || "contact-info"} title="Contact Info" loading={userLoading}>
            <Form form={form}
                  onFinish={handleFinish}
                  {...formLayout}>
                <Form.Item name="email"
                           label="Email"
                           rules={[{required: true, message: 'Please input the email!'}]}
                >
                    {
                        !user?.emailConfirmed &&
                        <Space direction="horizontal">
                            <Input placeholder="Please input email" value={user?.email} disabled/>
                            <Button htmlType="button"
                                    type="primary"
                                    onClick={handleResendConfirmationEmail}
                                    disabled={user?.emailConfirmed}
                            >
                                Resend confirmation email
                            </Button>
                        </Space>
                        || <Input placeholder="Please input email" disabled/>
                    }

                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[{required: true, message: 'Please input the phone number!'}]}
                >
                    <Input placeholder="Please input phone number" disabled/>
                </Form.Item>
                <Button type="primary"
                        className="float-right mt-3"
                        loading={loading}
                        disabled
                >
                    Update contact info
                </Button>
            </Form>
        </Card>
    )

}

export default UserContactInfoCard