import {AxiosResponse} from "axios";

import Api from "../api";
import {AiChatThread} from "../../types";


export interface AiChatThreadInCreate {
    userId: number;
    title: string;
    sleepDatasetId?: number | null;
}

export interface AiChatThreadInUpdate {
    title: string;
}

export interface ListOfAiChatThreadsResponse {
    threads: AiChatThread[];
}

export interface AiChatThreadResponse {
    thread: AiChatThread;
}

export const getAllThreads = (userId: number): Promise<AxiosResponse<ListOfAiChatThreadsResponse>> => {
    return Api.get(`/ai-chat/threads/?user_id=${userId}`);
}

export const getThreadById = (threadId: number): Promise<AxiosResponse<AiChatThreadResponse>> => {
    return Api.get(`/ai-chat/threads/${threadId}`);
}

export const createThread = (threadData: AiChatThreadInCreate): Promise<AxiosResponse<AiChatThreadResponse>> => {
    return Api.post("/ai-chat/threads/", {thread: threadData});
}

export const updateThread = (threadId: number, threadData: AiChatThreadInUpdate): Promise<AxiosResponse<AiChatThreadResponse>> => {
    return Api.put(`/ai-chat/threads/${threadId}`, {thread: threadData});
}

export const deleteThread = (threadId: number): Promise<AxiosResponse<void>> => {
    return Api.delete(`/ai-chat/threads/${threadId}`);
}


export default {
    getAllThreads,
    getThreadById,
    createThread,
    updateThread,
    deleteThread,
};
