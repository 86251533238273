import React, {useEffect, useState} from 'react';
import {Button, Card, Descriptions} from "antd";
import {ReloadOutlined} from "@ant-design/icons";


import {DiagnosticLogsService} from "services";
import {BatteryInfo, Device} from 'types'
import {formatDuration, timeDifference} from "utils/datetime";

interface BatteryInfoProps {
    macAddress?: string
    device: Device | null
}

const BatteryInfoCard: React.FC<BatteryInfoProps> = ({device, macAddress}) => {
    const [batteryInfo, setBatteryInfo] = useState<BatteryInfo | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [macAddress])

    const fetchData = () => {
        if (!macAddress) return;
        setLoading(true)
        DiagnosticLogsService.getBatteryInfo(macAddress)
            .then(res => {
                if (res && res.data) {
                    setBatteryInfo(res.data)
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return <>
        <Card title="Battery Info"
            // loading={loading}
              extra={<Button type="primary"
                             loading={loading}
                             onClick={() => fetchData()}
                             icon={<ReloadOutlined/>}/>}
        >
            <Descriptions column={1}>
                <Descriptions.Item label={`Average battery life`}>
                    {device && device?.averageBatteryLife && formatDuration(device?.averageBatteryLife, {
                        showMinutes: false,
                        showSeconds: false
                    })}
                </Descriptions.Item>
                <Descriptions.Item label={`Last charged to ${batteryInfo?.disconnectedBatteryPercentage}%`}>
                    {
                        batteryInfo?.lastTs &&
                        batteryInfo?.disconnectedTs &&
                        timeDifference(batteryInfo.lastTs, batteryInfo?.disconnectedTs)
                    }
                </Descriptions.Item>
            </Descriptions>
        </Card>
    </>
}

export default BatteryInfoCard