import React, {useEffect, useState} from "react";
import {AutoComplete, Button, DatePicker, Form, Select, Spin} from "antd";
import dayjs from "dayjs";

import Devices from "services/devices";
import {Device} from "types";
import {DEFAULT_DATETIME_FORMAT} from "utils/datetime";
import {UserLogsFilter} from "../../types";

const {Option} = Select
const {RangePicker} = DatePicker

interface UserLogsFiltersProps {
    onSubmit: (values: any) => void;
    loading: boolean
    filters?: UserLogsFilter | null
}

const DiagnosticLogsFilters: React.FC<UserLogsFiltersProps> = ({onSubmit, filters, loading}) => {
    // ----- Data -----
    const [device, setDevices] = useState<Device[]>([]);
    const [loadingDevices, setLoadingDevices] = useState(false);

    // ----- Form -----
    const [form] = Form.useForm();

    useEffect(() => {
        if (!filters) return
        const types = Array.isArray(filters?.types) ? filters?.types : (filters?.types ? [filters?.types] : [])
        form.setFieldsValue({
            types: types,
            dateRange: filters?.startDate && filters?.endDate && [dayjs(filters?.startDate), dayjs(filters?.endDate)],
            macAddress: filters?.macAddress
        })
    }, [filters])

    const fetchMacAddresses = async (value: any) => {
        // todo: make this component shared
        if (!value || value.length < 3) {
            setDevices([]);
            return
        }
        setLoadingDevices(true);
        try {
            const params = {
                filters: {
                    mac_address: value
                },
            }
            const response = await Devices.getAllUsersDevices(params);
            // console.log("response", response)
            const responseData = response.data.items || [];
            setDevices(responseData);
        } catch (err) {
            // console.error('Error fetching')
        }
        setLoadingDevices(false);
    };

    const handleFinish = (values: any) => {
        const startDate = values?.dateRange && values?.dateRange[0] || null
        const endDate = values?.dateRange && values?.dateRange[1] || null
        const types = Array.isArray(values?.types) ? values?.types : (values?.types ? [values?.types] : null)
        const filters: UserLogsFilter = {
            types: types || null,
            startDate: startDate && dayjs(startDate).format(DEFAULT_DATETIME_FORMAT) || null,
            endDate: endDate && dayjs(endDate).format(DEFAULT_DATETIME_FORMAT) || null,
            macAddress: values?.macAddress || null
        }
        console.log("filters", filters)
        onSubmit(filters);
    }

    return (
        <>
            <Form layout="vertical" form={form} onFinish={handleFinish}
                  initialValues={{macAddress: 'FDC6E5B9CFD1'}}
            >
                <Form.Item
                    name="macAddress"
                    label="MAC Address"
                    rules={[{required: true, message: "Please input mac a valid address!"}]}
                >
                    <AutoComplete
                        options={device.map(item => ({value: item.macAddress, key: item.macAddress}))}
                        onSearch={fetchMacAddresses}
                    />
                </Form.Item>
                <Spin size="small" spinning={loadingDevices}/>
                <Form.Item name="types" label="Types">
                    <Select allowClear mode="multiple">
                        <Option key="AlarmEnd" value="AlarmEnd">AlarmEnd</Option>
                        <Option key="AlarmLoad" value="AlarmLoad">AlarmLoad</Option>
                        <Option key="AlarmMisc" value="AlarmMisc">AlarmMisc</Option>
                        <Option key="AlarmSnooze" value="AlarmSnooze">AlarmSnooze</Option>
                        <Option key="AlarmTrigger" value="AlarmTrigger">AlarmTrigger</Option>
                        <Option key="Ancs" value="Ancs">Ancs</Option>
                        <Option key="Battery" value="Battery">Battery</Option>
                        <Option key="Beep" value="Beep">Beep</Option>
                        <Option key="Bluetooth" value="Bluetooth">Bluetooth</Option>
                        <Option key="BluetoothError" value="BluetoothError">BluetoothError</Option>
                        <Option key="Button" value="Button">Button</Option>
                        <Option key="Cat" value="Cat">Cat</Option>
                        <Option key="ConfigPin" value="Config">ConfigPin</Option>
                        <Option key="Connect" value="Connect">Connect</Option>
                        <Option key="Disconnect" value="Disconnect">Disconnect</Option>
                        <Option key="Doubletap" value="Doubletap">Doubletap</Option>
                        <Option key="Energy" value="Energy">Energy</Option>
                        <Option key="Fflags" value="Fflags">Fflags</Option>
                        <Option key="FileDump" value="FileDump">FileDump</Option>
                        <Option key="Reboot" value="Reboot">Reboot</Option>
                        <Option key="Reconnect" value="Reconnect">Reconnect</Option>
                        <Option key="Shutdown" value="Shutdown">Shutdown</Option>
                        <Option key="Sleep" value="Sleep">Sleep</Option>
                        <Option key="Stats" value="Stats">Stats</Option>
                        <Option key="Steps" value="Steps">Steps</Option>
                        <Option key="Temperature" value="Temperature">Temperature</Option>
                        <Option key="TimeDelta" value="TimeDelta">TimeDelta</Option>
                        <Option key="Timestamp" value="Timestamp">Timestamp</Option>
                        <Option key="Trace" value="Trace">Trace</Option>
                        <Option key="Trigger" value="Trigger">Trigger</Option>
                        <Option key="Unused" value="Unused">Unused</Option>
                        <Option key="Vibe" value="Vibe">Vibe</Option>
                        <Option key="Zap" value="Zap">Zap</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                    <RangePicker showTime format="YYYY-MM-DD HH:MM"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Filter</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default DiagnosticLogsFilters