import {AxiosResponse} from "axios";

import DiagnosticLogsApi from "./diagnostic-logs-api";
import {UserLogsRecord} from "../types";

export type GetUserLogsRecordsParams = {
    mac_address: string;
    types?: string[];
    start_date?: string;
    end_date?: string;
    page?: number;
    page_size?: number;
};

export type GetUserLogsRecordsByUserIdParams = {
    user_id: number;
    types?: string[];
    start_date?: string;
    end_date?: string;
    page?: number;
    page_size?: number;
};

// replace this with the actual shape of a record based on your API

type PageRecordResponse = {
    items: UserLogsRecord[];
    count: number;
    page: number;
    pageSize: number;
};


export const getUserLogsRecords = async (
    params: GetUserLogsRecordsParams
): Promise<PageRecordResponse> => {
    // format: link
    const {types, ...otherParams} = params
    const typesString = (types || [])?.map(type => `types=${type}`).join('&') + ""
    const response: AxiosResponse<PageRecordResponse> = await DiagnosticLogsApi.get(
        `/userlogs/records-by-mac-address?${typesString}`,
        {
            params: otherParams,
        }
    );

    return response.data;
};

export const getUserLogsRecordsByUserId = async (
    params: GetUserLogsRecordsByUserIdParams
): Promise<PageRecordResponse> => {
    // format: link
    const {types, ...otherParams} = params
    const typesString = (types || [])?.map(type => `types=${type}`).join('&') + ""
    const response: AxiosResponse<PageRecordResponse> = await DiagnosticLogsApi.get(
        `/userlogs/records-by-userid?${typesString}`,
        {
            params: otherParams,
        }
    );

    return response.data;
};

export default {
    getUserLogsRecords
}