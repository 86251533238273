import {getRevenueCat} from "../services/analytics";

const config = {
    app:{
        environment: process.env.REACT_APP_APP_ENV || ""
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL || ""
    },
    diagnosticLogs: {
        baseUrl: process.env.REACT_APP_DIAGNOSTIC_LOGS_API_BASE_URL || ""
    },
    revenuecat:{
        token: process.env.REACT_APP_REVENUECAT_TOKEN || "sk_iQjTJaAduTGAddlhIadfzzOdeqThD"
    }
}


export const getBaseUrl = (): string => {
    return config.api.baseUrl
}

export default config