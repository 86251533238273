import React from "react";

// ----- Local calls -----
import {Participant, Team} from "../../../../../types";
import {Card, Typography, Space, List, Avatar} from "antd";
import {Link} from "react-router-dom";
import {rankFormat} from "../../../../../utils/strings_utils";

// ----- Global Variables -----
const {Title} = Typography

// ----- Types -----
interface GlobalRankingProps {
    teams: Team[] | null
    loading: boolean
}

// ----- Components -----
const GlobalRanking: React.FC<GlobalRankingProps> = ({teams, loading}) => {
    const allParticipants: Participant[] = teams?.reduce(
        (previousValue: Participant[], currentValue: Team) => {
            return [...previousValue, ...currentValue.participants]
        }, []
    ) || []

    const rankedParticipants = allParticipants.sort((a: Participant, b: Participant) => {
            const pointsDiff = b?.points - a?.points
            if (pointsDiff !== 0)
                return pointsDiff

            // Convert dates to timestamps, or null if date is missing
            const timestampA = a.lastPointAchievedAt ? new Date(a.lastPointAchievedAt).getTime() : null;
            const timestampB = b.lastPointAchievedAt ? new Date(b.lastPointAchievedAt).getTime() : null;

            if (timestampA === null && timestampB === null) {
                return 0; // Both are null, considered equal
            } else if (timestampA === null) {
                return 1; // Nulls are sorted to the end
            } else if (timestampB === null) {
                return -1; // Nulls are sorted to the end
            }

            return timestampA - timestampB; // Normal numeric comparison
        }
    )

    return (
        <>
            <List<Participant>
                className="participants-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={rankedParticipants}
                renderItem={(participant: Participant, index: number) => (
                    <List.Item
                        actions={[
                            <span key="list-participant-pending-habit-count">
                                {participant.points}
                            </span>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Space>
                                    <span> {rankFormat(index + 1)}</span>
                                    <Avatar src={participant.profilePictureUrl}/>
                                </Space>}
                            title={
                                <Link to={`/users/${participant?.id}`}>
                                    {participant?.firstName} {participant?.lastName}
                                </Link>
                            }
                            description={participant?.teamName}
                        />
                    </List.Item>
                )}
            />
        </>
    )
}

export default GlobalRanking