
export const batteryToPercent = (rawVolt: string): number | null => {
    const volt = parseFloat(rawVolt) * 1000;
    const LEVELS = [0, 0, 1, 2, 4, 13, 38, 66, 82, 94, 100]
    let percentage: number;
    if (volt < 3200) {
        percentage = 0;
    } else if (volt >= 4200) {
        percentage = 100;
    } else {
        const i = parseInt(((volt - 3200) / 100).toString());
        const base = LEVELS[i];
        percentage = (LEVELS[i+1] - base) * (volt % 100) / 100 + base;
    }

    return parseInt(percentage.toString());
};

export const releaseToPercent = (rawRelease: string): number => {
    return Math.round((parseFloat(rawRelease) / 450) * 100);
};


export default {
    batteryToPercent, releaseToPercent
}