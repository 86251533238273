import React, {useState} from "react"
import {User} from "../../../../types";
import {Button, Card, message, Typography} from "antd";

import {UsersService} from 'services'

// ----- Variables -----
const {Title, Paragraph} = Typography

// ----- Props -----
interface PasswordInfoCardProps {
    cardId: string
    user: User | null
    loading: boolean
    setUser?: (user: User) => void
}

// ----- Component -----
const PasswordInfoCard: React.FC<PasswordInfoCardProps> = ({cardId, user, loading: userLoading}) => {
    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Form -----
    // const [form] = Form.useForm();

    // ----- Init ----

    // ----- Handlers -----
    const handleFinish = () => {
        if (!user || !user.id) return
        setLoading(true)
        UsersService.sendResetPassword({userId: user.id})
            .then(res => {
                const responseStatus = res?.status
                if (responseStatus == 200) {
                    message.success('Email sent')
                } else {
                    message.error('Error while sending email')
                }
            })
            .catch(err => {
                console.error('Error while sending email, message:', err)
                message.error('Error while sending email')
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <Card id={cardId || "password-info"} title="Update Password" loading={userLoading}>
            <Title level={5}>Password Requirements:</Title>
            <Paragraph>Please follow this guide for a strong password:</Paragraph>
            <ul>
                <li>One special character</li>
                <li>Min 6 characters</li>
                <li>One number (2 are recommended)</li>
                <li>Change it often</li>
            </ul>
            <Button type="primary" onClick={handleFinish} loading={loading}
                    style={{marginTop: '12px', float: 'right'}}>
                Sent reset password email
            </Button>
        </Card>
    )

}

export default PasswordInfoCard