import React, {useEffect, useState} from 'react';
import {Button, Card, Empty, Space} from "antd";
import {Line} from 'react-chartjs-2';
import {CalendarOutlined, ReloadOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {Chart, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip} from 'chart.js';
import {ChartOptions} from "chart.js/dist/types";

Chart.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip);


import {DiagnosticLogsService} from "services";
import {BatteryGraphPointBatteryGraphPoint} from 'types'
import DatepickerModel from "./DatepickerModel";
import {weekendDatesHighlighter} from "utils/graphweekends";


interface BatteryInfoProps {
    macAddress?: string
}

const chartOptions: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time', // Changed from 'string'
            time: {
                displayFormats: {
                    hour: 'MMM d, ha', // Adjusted according to date-fns tokens
                    day: 'MMM d'
                },
                tooltipFormat: 'MMM d, yyyy, h:mm a', // Example of a detailed tooltip format
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 20 // Adjust based on your preference
            }
        },
        y: {
            min: 0,
            max: 100,
            beginAtZero: true,
            ticks: {
                stepSize: 10 // Adjust the step size as needed
            }
        }
    }
};

const BatteryGraph: React.FC<BatteryInfoProps> = ({macAddress}) => {
    const [, setBatteryGraph] = useState<BatteryGraphPointBatteryGraphPoint[]>([])
    const [chartData, setChartData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [isModalVisible, setIsModalVisible] = useState(false);

    //---- form
    const [startDate, setStartDate] = useState<string | null>(null)
    const [endDate, setEndDate] = useState<string | null>(null)

    const handleDateChange = (dates: any, dateStrings: [string, string]) => {
        const startDate = moment(dateStrings[0]).format("YYYY-MM-DD");
        const endDate = moment(dateStrings[1]).format("YYYY-MM-DD");
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        // Handle the form submit here
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchData()
            handleOk()
        } else {
            fetchToLast100PercentChargeData()
        }
    }, [macAddress, startDate, endDate])

    const formatChartData = (batteryData: BatteryGraphPointBatteryGraphPoint[]) => {
        // Extract the timestamps and battery percentages from the data
        const labels = batteryData.map(item => moment(item.ts).format("YYYY-MM-DD HH:mm"));
        const dataPoints = batteryData.map(item => item.batteryPercentage);
        // Create an array to store the border colors
        const borderColors = batteryData.map(item => item.charging !== null ? '#73d13d' : '#ff4d4f');
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Battery Percentage',
                    data: dataPoints,
                    fill: false,
                    tension: 0.1,
                    // Add a function to dynamically change the borderColor
                    borderColor: (context: any) => borderColors[context.dataIndex],
                    // Ensure that each segment in the line has its own color
                    segment: {
                        borderColor: (context: any) => borderColors[context.p0DataIndex],
                    },
                }
            ]
        };
        setChartData(data)
    }

    const fetchToLast100PercentChargeData = () => {
        if (!macAddress) return;
        setLoading(true)
        setStartDate(null)
        setEndDate(null)
        DiagnosticLogsService.getBatteryGraph(macAddress)
            .then(res => {
                if (res && res.data && res.data.batteryGraphData) {
                    formatChartData(res.data.batteryGraphData)
                    setBatteryGraph(res.data.batteryGraphData)
                }

            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchData = () => {
        if (!macAddress || !startDate || !endDate) return;
        setLoading(true)
        DiagnosticLogsService.getBatteryGraphByInterval({
            macAddress,
            startDate,
            endDate
        })
            .then(res => {
                if (res && res.data && res.data.batteryGraphData) {
                    formatChartData(res.data.batteryGraphData)
                    setBatteryGraph(res.data.batteryGraphData)
                }

            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return <>
        <Card title="Battery Graph"
              loading={loading}
              extra={
                  <Space>
                      <Button onClick={() => showModal()} icon={<CalendarOutlined/>}>
                          {
                              (startDate && endDate) ?
                                  (<>{startDate} - {endDate}</>)
                                  :
                                  (<>Since last 100% charge</>)
                          }
                      </Button>
                      <Button type="primary"
                              loading={loading}
                              onClick={() => fetchToLast100PercentChargeData()}
                              icon={<ReloadOutlined/>}/>
                  </Space>
              }
        >
            {!loading && !chartData && <Empty description="Device not found"/>}
            {!loading && chartData &&
                <Line data={chartData} options={chartOptions} plugins={[weekendDatesHighlighter]}/>}
            <DatepickerModel
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                handleOk={handleOk}
                fetchData={fetchData}
                fetchToLast100PercentChargeData={fetchToLast100PercentChargeData}
                handleDateChange={handleDateChange}
            />
        </Card>
    </>
}

export default BatteryGraph