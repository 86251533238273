import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Anchor,
    Card,
    Col,
    Row,
    Space,
    Affix,
    Avatar,
} from "antd";
import {
    AppstoreFilled,
    CrownOutlined,
    DeleteFilled,
    IdcardOutlined,
    SnippetsFilled,
    UnlockFilled
} from "@ant-design/icons";

import CustomBreadcrumb from "components/CustomBreadcrumb";
import {User} from 'types'
import {UsersService} from 'services'
import UserPersonalInfoCard from "./UserPersonalInfoCard";
import UserContactInfoCard from "./UserContactInfoCard";
import UserDeleteAccountCard from "./UserDeleteAccountCard";
import PasswordInfoCard from "./PasswordInfoCard";
import './style.css';
import UserRoleCard from "./UserRoleCard";

// ----- Types -----
type RouteParams = {
    userId?: string
}

// ----- Variables -----
const AnchorMenuItems = [
    {
        key: 'profile',
        href: '#profile',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <AppstoreFilled className="text-lg text-gray-6"/>
                <h4>Profile</h4>
            </div>
        ),
    },
    {
        key: 'personal-info',
        href: '#personal-info',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <SnippetsFilled className="text-lg text-gray-6"/>
                <h4>Personal Info</h4>
            </div>
        ),
    },
    {
        key: 'role-info',
        href: '#role-info',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <CrownOutlined className="text-lg text-gray-6"/>
                <h4>Role Info</h4>
            </div>
        ),
    },
    {
        key: 'contact-info',
        href: '#contact-info',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <IdcardOutlined className="text-lg text-gray-6"/>
                <h4>Contact Info</h4>
            </div>
        ),
    },
    {
        key: 'password-info',
        href: '#password-info',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <UnlockFilled className="text-lg text-gray-6"/>
                <h4>Change Password</h4>
            </div>
        ),
    },
    {
        key: 'delete-account',
        href: '#delete-account',
        title: (
            <div className="ant-anchor-link-menu" key={0}>
                <DeleteFilled className="text-lg text-gray-6"/>
                <h4>Delete Account</h4>
            </div>
        ),
    },
]
const handleAnchorClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    // Additional logic or navigation can be added here
};

// ----- Component -----
const EditUserPage: React.FC = () => {
    // ----- params -----
    const {userId} = useParams<RouteParams>();

    // ----- data -----
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Init -----
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        UsersService.getUserById(userId)
            .then(res => {
                const responseUser = res?.data?.user
                if (responseUser) {
                    setUser(responseUser)
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----

    return <>
        <CustomBreadcrumb list={['users', 'edit',]}/>
        <h1>Create/Edit User</h1>
        <Row gutter={16}>
            <Col span={6}>
                <Affix offsetTop={24}>
                    <Card>
                        <Anchor onClick={handleAnchorClick}
                                affix={false}
                                items={AnchorMenuItems}
                                className="custom-anchor-link fixed"/>
                    </Card>
                </Affix>
            </Col>
            <Col span={18}>
                <Space direction="vertical" style={{display: 'flex'}} size="middle">
                    <Card
                        id="profile"
                        className="card-profile-head m-0"
                        bordered={false}
                        bodyStyle={{display: 'none'}}
                        title={(
                            <Row
                                justify="space-between"
                                align="middle"
                                gutter={[24, 0]}
                            >
                                <Col span={24} md={12} className="col-info">
                                    <Avatar.Group>
                                        <Avatar size={74} shape="square" src={user?.profilePictureUrl}/>

                                        <div className="avatar-info">
                                            <h4 className="font-semibold m-0">
                                                {`${user?.firstName || ''} ${user?.lastName || ''}`}
                                            </h4>
                                            <p>{user?.email}</p>
                                            <p>Role: {user?.role || 'user'}</p>
                                        </div>
                                    </Avatar.Group>
                                </Col>{/*
                                    <Col
                                        span={24}
                                        md={12}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <small className="font-regular mr-5">
                                            Switch to invisible
                                        </small>
                                        <Switch defaultChecked onChange={handleSwitchToInvisible}/>
                                    </Col>*/}
                            </Row>
                        )}
                    />
                    <UserPersonalInfoCard cardId="personal-info"
                                          user={user}
                                          setUser={setUser}
                                          loading={loading}/>

                    <UserRoleCard cardId="role-info"
                                  user={user}
                                  setUser={setUser}
                                  loading={loading}/>

                    <UserContactInfoCard cardId="contact-info"
                                         user={user}
                                         setUser={setUser}
                                         loading={loading}/>

                    <PasswordInfoCard cardId="password-info"
                                      user={user}
                                      setUser={setUser}
                                      loading={loading}/>

                    <UserDeleteAccountCard cardId="delete-account"
                                           user={user}
                                           setUser={setUser}
                                           loading={loading}/>

                    {/* <Card id="physical-info" title="Physical Info" loading={loading}>
                            <Form.Item name="weight" label="Weight">
                                <Input/>
                            </Form.Item>
                            <Form.Item name="weightMeasurementUnit"
                                       label="Weight measurement unit">
                                <Select>
                                    <Option value="kg">Kilogram (kg)</Option>
                                    <Option value="g">Gram (g)</Option>
                                    <Option value="lb">Pound (lb)</Option>
                                    <Option value="oz">Ounce (oz)</Option>
                                    <Option value="mg">Milligram (mg)</Option>
                                    <Option value="t">Ton (t)</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="height" label="Height">
                                <Input/>
                            </Form.Item>
                            <Form.Item name="heightMeasurementUnit" label="Height measurement unit">
                                <Select>
                                    <Option value="cm">Centimeter (cm)</Option>
                                    <Option value="m">Meter (m)</Option>
                                    <Option value="in">Inch (in)</Option>
                                    <Option value="ft">Foot (ft)</Option>
                                </Select>
                            </Form.Item>
                        </Card>*/}


                </Space>
            </Col>
        </Row>
    </>
}

export default EditUserPage