import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    message,
    Row,
    Space,
    Spin,
    Typography,
    Select,
    Divider,
    Card
} from "antd";
import {
    BarChartOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import {
    Chart,
    LineElement,
    PointElement,
    LinearScale,
    Title as ChartTitle,
    CategoryScale,
    BarElement,
    Legend,
    TimeSeriesScale,
    ChartData,
    Point,
} from 'chart.js';
import {Dayjs} from "dayjs";
import {blue, red, green, yellow, geekblue, purple, magenta, grey, gold, orange} from '@ant-design/colors';
import {Bar, Line} from "react-chartjs-2";
import type {ChartOptions} from "chart.js/dist/types";

// ----- Local calls -----
import {AnalyticsService} from "services";
import {Review} from "types";
import {GetReviewsRequest} from "services/analytics";
import DateRangeSelector from "components/DateRangeSelector";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOptionsLabel,
    DateRangeFilterSelectorOption
} from "helpers/dates-helper";
import FilterModal from "./components/FilterModal";
import {deepCamelCaseKeys} from "../../../../utils/objects";

// ----- Plugins -----
Chart.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, ChartTitle, TimeSeriesScale, Legend);

// ----- Types -----
export interface ReviewsMetricsFilter {
    search?: string | null,
    topicName: string | null,
    month: string | null,
    reviewSource: string | null,
    impression: boolean | null
}

// ----- Global variables -----
export const initialFilters = {
    topicName: null,
    month: null,
    reviewSource: null,
    impression: null
}
const {Title} = Typography
const colorShade = 3 // colors shade
const allColors = [
    blue[colorShade],
    red[colorShade],
    green[colorShade],
    yellow[colorShade],
    geekblue[colorShade],
    purple[colorShade],
    magenta[colorShade],
    grey[colorShade],
    gold[colorShade],
    orange[colorShade]
]; // antd colors pallet


// ----- Components -----
const ReviewsMetrics: React.FC = () => {
    // ----- State -----
    const [data, setData] = useState<Review[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    // ----- Modals -----
    const [isModalVisible, setIsModalVisible] = useState(false);

    // ----- Filters -----
    const [filters, setFilters] = useState<ReviewsMetricsFilter>(initialFilters);

    // ----- Date Range -----
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[3]
    );

    // ----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };

    const handleResetFilters = () => {
        setFilters(initialFilters)
    }

    // ----- Effects -----
    useEffect(() => {
        fetchData()
    }, []);

    // ----- Fetcher -----
    const fetchData = () => {
        setLoading(true)
        const dateRange = selectedDateRange.value;
        const request: GetReviewsRequest = {
            // page: currentPage,
            // pageSize: pageSize,
            // sortField
            // sortOrder
            /*
            filters: {
                topicName: filters.topicName ? [filters.topicName] : null,
                month: filters.month ? [filters.month] : null,
                reviewSource: filters.reviewSource ? [filters.reviewSource] : null,
                impression: filters.impression ? filters.impression : null
            },
            search: search,
             */
            endDate: dateRange?.[1]?.format('YYYY-MM-DD'),
            startDate: dateRange?.[0]?.format('YYYY-MM-DD'),
        }

        AnalyticsService.getReviews(request)
            .then(res => {
                const {reviews} = res.data

                const resultData = deepCamelCaseKeys<Review[]>(reviews)

                resultData.forEach(item => {
                    item.displayDate = item.lastEditedAt ? item.lastEditedAt : item.date;
                });

                setData(resultData)

            })
            .catch(err => {
                console.error("Error fetching data, message: ", err)
                message.error("Error fetching data")
            })
            .finally(() => {
                setLoading(false)
            })

    }

    // ---- todo: refactor this ----

    function formatMonth(dateString: string | Date): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // JavaScript months are 0-based
        return `${year}-${month.toString().padStart(2, '0')}`; // padStart ensures the month is always 2 digits
    }


    function getPreviousDate(date: string): string | null {
        const [year, month] = date.split('-').map(Number);
        if (month === 1) {
            return `${year - 1}-12`;
        } else {
            return `${year}-${(month - 1).toString().padStart(2, '0')}`;
        }
    }

    const Sentiment: { [date: string]: { [topic: string]: { score: number, positive: number, negative: number, reviewSources: { [reviewSource: string]: { positive: number, negative: number } } } } } = {};
    const lastDate: { [topic: string]: string } = {};


    if (data) {
        data?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach(review => {
            const date = formatMonth(review.date);
            const reviewSource = review.reviewSource;
            review?.topics?.forEach(topic => {
                const topicName = topic.topic;
                const impression = topic.impression ? 1 : -1;
                if (!Sentiment[date]) {
                    Sentiment[date] = {};
                }
                if (!Sentiment[date][topicName]) {
                    // Initialize score with the score from the last date, if it exists
                    const previousDate = lastDate[topicName];
                    let previousScore = previousDate && Sentiment[previousDate] && Sentiment[previousDate][topicName] ? Sentiment[previousDate][topicName].score : 0;
                    previousScore = 0 // Set to 0 to ignore the previous score (Non cumulative)
                    Sentiment[date][topicName] = {score: previousScore, positive: 0, negative: 0, reviewSources: {}};
                }
                if (!Sentiment[date][topicName].reviewSources[reviewSource]) {
                    Sentiment[date][topicName].reviewSources[reviewSource] = {positive: 0, negative: 0};
                }
                Sentiment[date][topicName].score += impression;
                if (impression === 1) {
                    Sentiment[date][topicName].positive += 1;
                    Sentiment[date][topicName].reviewSources[reviewSource].positive += 1;
                } else {
                    Sentiment[date][topicName].negative += 1;
                    Sentiment[date][topicName].reviewSources[reviewSource].negative += 1;
                }
                // Update the last date for this topic
                lastDate[topicName] = date;
            });
        });
    }

    const labels = Object.keys(Sentiment).sort();
    let colorIndex = 0;


    let datasets: any[] = [];
    let allTopics = labels.flatMap(label =>
        Sentiment[label] ? Object.keys(Sentiment[label]) : []
    );

    allTopics = Array.from(new Set(allTopics));


    if (labels.length > 0) {
        if (labels.length > 0) {
            datasets = allTopics.map(topicName => {
                const data = labels.map(date => {
                    if (Sentiment[date] && Sentiment[date][topicName]) {
                        return Sentiment[date][topicName].score;
                    } else {
                        const previousDate = getPreviousDate(date);
                        return previousDate && Sentiment[previousDate] && Sentiment[previousDate][topicName] ? Sentiment[previousDate][topicName].score : 0;
                    }
                });

                return {
                    label: `${topicName}`,
                    data,
                    fill: false,
                    borderColor: allColors[colorIndex++ % allColors.length],
                    backgroundColor: '#fff',
                    borderWidth: 2,
                    pointRadius: 2,
                    pointHoverRadius: 5,
                };
            });
        }
    }


    const chartData: ChartData<"line", (number | Point | null)[], string> = {
        labels,
        datasets
    };


    const chartOptions: ChartOptions<any> = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: any) {
                        return value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Use the point style instead of the default box
                    pointStyle: 'circle', // Set the point style to circle
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const date = labels[context.dataIndex];
                        const topicName = context.dataset.label;
                        const topicData = Sentiment[date] ? Sentiment[date][topicName] : undefined;
                        let score;
                        if (!topicData) {
                            // If the topic data is undefined, get the score from the previous month
                            const previousDate = getPreviousDate(date);
                            score = previousDate && Sentiment[previousDate] && Sentiment[previousDate][topicName] ? Sentiment[previousDate][topicName].score : 0;
                        } else {
                            score = topicData.score;
                        }
                        let label = `${topicName}: ${score}`;
                        if (topicData && (topicData.positive > 0 || topicData.negative > 0)) {
                            label += '\n __ (New:';
                            if (topicData.positive > 0) {
                                label += ` 👍 ${topicData.positive}`;
                            }
                            if (topicData.negative > 0) {
                                label += ` 👎 ${topicData.negative}`;
                            }
                            label += ' )';
                        }


                        return label;
                    }
                }
            }
        },
        tension: 0.4,
        onClick: function (this: any, evt: any, activeElements: any) {
            if (activeElements.length > 0) {
                const firstPoint = this.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true)[0];
                if (firstPoint) {
                    const month = this.data.labels[firstPoint.index];
                    const topicName = this.data.datasets[firstPoint.datasetIndex].label;


                    // Display the filtered reviews in a modal
                    setFilters({
                        topicName,
                        month,
                        reviewSource: null,
                        impression: null
                    });
                    // Set Filters
                    setIsModalVisible(true);
                }
            }
        },

    };


    // used to select a topic for chart 2
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null);


    // oneTopicDatasets for chart 2 that has only one topic and the lables are per reviewSource per imporession (positive or negative) example of label: "Android Positive"
    let oneTopicDatasets: any[] = [];
    if (selectedTopic && labels.length > 0) {
        oneTopicDatasets = Object.entries(Sentiment[labels[0]][selectedTopic].reviewSources).flatMap(([reviewSourceName]) => {
            const positiveData = labels.map(date => {
                if (Sentiment[date] && Sentiment[date][selectedTopic] && Sentiment[date][selectedTopic].reviewSources[reviewSourceName]) {
                    return Sentiment[date][selectedTopic].reviewSources[reviewSourceName].positive;
                } else {
                    return 0; // Set to 0 if no data for the reviewSource
                }
            });
            const negativeData = labels.map(date => {
                if (Sentiment[date] && Sentiment[date][selectedTopic] && Sentiment[date][selectedTopic].reviewSources[reviewSourceName]) {
                    return -Sentiment[date][selectedTopic].reviewSources[reviewSourceName].negative;
                } else {
                    return 0; // Set to 0 if no data for the reviewSource
                }
            });
            return [
                {
                    label: `${reviewSourceName}`,
                    data: positiveData,
                    stack: 'Positive',
                    fill: false,
                    backgroundColor: "#16a085",
                    borderWidth: 2,
                    pointRadius: 2,
                    pointHoverRadius: 5,
                },
                {
                    label: `${reviewSourceName}`,
                    data: negativeData,
                    stack: 'Negative',
                    fill: false,
                    backgroundColor: "#e74c3c",
                    borderWidth: 2,
                    pointRadius: 2,
                    pointHoverRadius: 5,
                }
            ];
        });
    }


    const oneTopicData: ChartData<"bar", (number | Point | null)[], string> = {
        labels,
        datasets: oneTopicDatasets
    };


    const oneTopicChartOptions: ChartOptions<"bar"> = {
        scales: {
            x: {stacked: true}, // enable stacking for the x-axis
            y: {stacked: true}  // enable stacking for the y-axis
        },
        plugins: {
            legend: {
                display: false
            },

        },

        animation: {
            onComplete: function () {
                const ctx = this.ctx;

                if (ctx) {
                    this.data.datasets.forEach((dataset, datasetIndex) => {
                        const meta = this.getDatasetMeta(datasetIndex);
                        if (!meta.hidden) {
                            meta.data.forEach((bar, index) => {
                                const label = dataset.data[index] !== 0 ? dataset.label : ''; // Don't show label if value is 0
                                // let text = '';
                                const getSymbolForLabel = (label?: string) => {
                                    switch (label) {
                                        case 'android':
                                            return '🤖'; // Android emoji
                                        case 'amazon':
                                            return '🅰'; // A letter emoji
                                        case 'ios':
                                            return ''; // Apple emoji
                                        default:
                                            return '';
                                    }
                                };

                                const text = getSymbolForLabel(label);

                                const barPosition = bar?.getCenterPoint?.(); // Get the center point of the bar

                                if (text) {
                                    ctx.fillStyle = 'white'; // Set the color of the text
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'middle'; // Align text in the middle
                                    ctx.fillText(text, barPosition.x, barPosition.y);
                                }
                            });
                        }
                    });
                }
            }
        }


    };


    return (
        <div>
            <Card title="Customer reviews"
                  loading={loading}
                  extra={
                      <Space>
                          <Button
                              size="middle"
                              icon={<BarChartOutlined/>}
                              style={{marginRight: 8}}
                              onClick={() => {
                                  setIsModalVisible(true);
                              }}
                          >
                              Data
                          </Button>

                          <DateRangeSelector defaultDateRange={selectedDateRange}
                                             onDateRangeChange={handleDateRangeChange}/>

                          <Button
                              size="middle"
                              loading={loading}
                              onClick={fetchData}
                              icon={<ReloadOutlined/>}
                              style={{marginRight: 8}}
                          />

                      </Space>
                  }>


                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Space direction="horizontal">
                                    <Title level={5}>Reviews Topics Sentiment Over Time</Title>
                                </Space>
                                <Spin spinning={loading}>
                                    <div className="w-100 d-bloc" style={{minHeight: 400}}>
                                        {
                                            loading ?
                                                <p>Loading...</p> :
                                                data && <Line data={chartData} height={150} options={chartOptions}/>
                                        }
                                    </div>
                                </Spin>
                            </Col>

                        </Row>
                    </Col>

                    <Divider/>

                    <Col span={24}>

                        <Space direction="horizontal" style={{justifyContent: 'space-between'}}>
                            <Title level={4}>Reviews for Topic : ➡️ </Title>
                            <div>
                                <Select
                                    style={{marginRight: 8, minWidth: 250}}
                                    value={selectedTopic}
                                    onChange={(value) => setSelectedTopic(value)}
                                    placeholder="Select a topic"
                                >
                                    {labels[0] && Sentiment[labels[0]] && Object.keys(Sentiment[labels[0]]).map((topic) => (
                                        <Select.Option key={topic} value={topic}>
                                            {topic}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </Space>

                        <Spin spinning={loading}>
                            <div className="w-100 d-bloc" style={{minHeight: 400}}>
                                {
                                    loading ?
                                        <p>Loading...</p> :
                                        data && <Bar data={oneTopicData} height={150} options={oneTopicChartOptions}/>
                                }
                            </div>
                        </Spin>

                    </Col>


                </Row>
            </Card>

            <FilterModal
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                data={data}
                filters={filters}
                setFilters={setFilters}
                handleResetFilters={handleResetFilters}
            />

        </div>
    )

}

export default ReviewsMetrics  

