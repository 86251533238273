import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    message,
    Card,
    Upload,
    Select
} from "antd";
import {useParams} from "react-router-dom";
import {
    DollarCircleFilled,
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import ImgCrop from "antd-img-crop";

import {BadgesService, VoltsActionsService} from "services";
import {BadgeInCreate, BadgeInUpdate} from "services/badges";
import {getBase64} from "utils/upload";
import {Badge, VoltsAction} from "types";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";


type RouteParams = {
    badgeId?: string
}

const formLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 8},
};

const CreateBadgePage: React.FC = () => {
    // ----- params -----
    const {badgeId} = useParams<RouteParams>();

    // ----- data -----
    const [badge, setBadge] = useState<Badge | null>(null)
    const [voltsActions, setVoltsActions] = useState<VoltsAction[]>([])
    const [loading, setLoading] = useState(false);

    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);


    // ----- Init -----
    useEffect(() => {
        fetchVoltsActions()
    }, [])

    useEffect(() => {
        // if badge id then Edit case
        if (badgeId) {
            fetchBadge()
        }
    }, [badgeId])

    useEffect(() => {
        const initialValues = {
            title: badge?.title || "",
            key: badge?.key || "",
            description: badge?.description || "",
            voltsActionId: badge?.voltsAction?.id || null,
        };
        form.setFieldsValue(initialValues)
    }, [badge])

    const fetchBadge = () => {
        if (!badgeId) return
        setLoading(true)
        BadgesService.getBadgeById(Number(badgeId))
            .then(res => {
                const badgeResponse = res?.data?.badge
                if (badgeResponse) {
                    setBadge(badgeResponse)
                    // setImageBase64(badgeResponse.image)
                    setImage(badgeResponse.image)
                    // setColorHex(badgeResponse.backgroundColor)
                }
            })
            .catch(err => {
                console.error('Error fetching badge, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchVoltsActions = () => {
        setLoading(true)
        VoltsActionsService.getAllVoltsActions()
            .then(res => {
                const voltsActionsResponse = res?.data?.voltsActions
                // console.log("voltsActionsResponse: ", voltsActionsResponse)
                if (voltsActionsResponse) {
                    setVoltsActions(voltsActionsResponse)
                }
            })
            .catch(err => {
                console.error('Error fetching badge, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = async (values: BadgeInCreate) => {
        setLoading(true);

        try {
            const badge: BadgeInCreate = {
                title: values.title,
                key: values.key,
                description: values.description,
                voltsActionId: values.voltsActionId,
                ...(imageBase64 ? {image: imageBase64} : {})// Use the base64 image value
            };
            console.log(badge)

            // Make the API request to create or update the badge
            if (badgeId) {
                const badgeUpdate: BadgeInUpdate = {
                    badgeId: Number(badgeId),
                    ...badge
                }
                await BadgesService.updateBadge(badgeUpdate);
            } else {
                await BadgesService.createBadge(badge);
                // Reset form fields and image state
                form.resetFields();
                // setImageFile(null);
                setImageBase64(null);
            }
            // Show success message
            message.success(`Badge ${badgeId ? 'updated' : 'created'} successfully`);
        } catch (error) {
            // Show error message
            message.error(`Failed to ${badgeId ? 'update' : 'create'} badge`);
        }
        setLoading(false);
    };

    const handleBeforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
            return false
        }
        getBase64(file as RcFile, setImageBase64)
        return false; // Prevent default upload behavior
    };


    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam<UploadFile>) => {
        console.log('image changed', e)
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const cardTitle = badgeId ? "Edit badge" : "Create badge";
    // console.log('initialValues', initialValues)

    return (
        <>
            <CustomBreadcrumb list={['Badges', 'create',]}/>
            <Card title={cardTitle}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    {...formLayout}
                    // initialValues={initialValues}
                >
                    <Form.Item name="title" label="Title" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="key" label="Key" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="description" label="description" rules={[{required: true}]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item name="voltsActionId" label="Volts Action">
                        <Select
                            // defaultValue="core"
                            style={{width: '100%', height: '30px'}}
                            // onChange={handleChange}
                            allowClear
                            options={voltsActions.map(item => ({
                                label: <>{item.volts} <DollarCircleFilled/> {item.key}</>,
                                value: item.id
                            }))}
                        />
                    </Form.Item>

                    <Form.Item label="Image">
                        <ImgCrop showGrid showReset aspect={1 / 1}>
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                onChange={handleImageChange}
                                accept="image/*"
                                showUploadList={false}
                                listType="picture-card"
                                disabled={loading}
                            >
                                {
                                    (imageBase64 || image) ?
                                        <img src={imageBase64 || image || ''}
                                             alt="Preview"
                                             style={{maxWidth: "100%", borderRadius: 7}}/> :
                                        uploadButton
                                }
                            </Upload>
                        </ImgCrop>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {badgeId ? "Update Badge" : "Create Badge"}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default CreateBadgePage;
