import {AxiosResponse} from "axios";

import Api from "./api";
import {VoltsTransaction} from "../types";

interface GetVoltsTransactionsResponse {
    voltsTransactions: VoltsTransaction[]
}

export const getVoltsTransactionsByUserId = (userId: number): Promise<AxiosResponse<GetVoltsTransactionsResponse>> => {
    return Api.get(`/admin/volts-transactions/`, {
        params: {
            user_id: userId
        }
    });
}

export default {
    getVoltsTransactionsByUserId
}