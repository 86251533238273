import React from "react";

const TableRecordBeep: React.FC<any> = ({count, freq, duration}) => {
    let level = null;
    if (typeof freq === 'number') {
        // Convert the frequency to a percentage.
        level = ((freq / 80) + 7.5).toString();
    }
    return (
        <>
            🔊{count || ''}x{level ? `${level}%` : ''} ⌛{duration || ''}ms
        </>
    );
};

export default TableRecordBeep;