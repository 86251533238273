import {AxiosResponse} from "axios";

import Api from "./api";
import {VoltsAction} from "types";

interface GetAllVoltsActionsResponse {
    voltsActions: VoltsAction[]
}

interface GetVoltsActionResponse {
    voltsAction: VoltsAction
}

export interface VoltsActionInCreate {
    key: string;
    description: string;
    volts: number;
}

export interface VoltsActionInUpdate {
    id: number
    key: string;
    description: string;
    volts: number;
}

export const createVoltsAction = (voltAction: VoltsActionInCreate): Promise<AxiosResponse<GetVoltsActionResponse>> => {
    const request = {
        volts_action: voltAction,
    };
    return Api.post("/admin/volts-actions/", request);
}

export const updateVoltsAction = (voltAction: VoltsActionInCreate): Promise<AxiosResponse<GetVoltsActionResponse>> => {
    const request = {
        volts_action: voltAction,
    };
    return Api.put("/admin/volts-actions/", request);
}

export const getVoltsActionById = (voltActionById: number): Promise<AxiosResponse<GetVoltsActionResponse>> => {
    return Api.get(`/admin/volts-actions/get-by-id/${voltActionById}`)
}

export const getAllVoltsActions = (): Promise<AxiosResponse<GetAllVoltsActionsResponse>> => {
    return Api.get('/admin/volts-actions/')
}


export default {
    getAllVoltsActions
}