import React, {useEffect, useState} from "react";
import {Button, Empty, Flex, notification, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";

// ----- Local calls -----
import {AiChatService} from "services"
import {AiChatThread} from "types";
import "./style.css"

// ----- Global variables -----

// ----- Types -----
interface AiChatThreadsProps {
    userId: number
    threads: AiChatThread[]
    setThreads: (value: AiChatThread[] | ((value: AiChatThread[]) => AiChatThread[])) => void;
    activeThreadId: number | null
    setActiveThreadId: (value: number | null) => void;
}

// ----- Components -----
const AiChatThreads: React.FC<AiChatThreadsProps> = (
    {
        userId,
        threads,
        setThreads,
        activeThreadId,
        setActiveThreadId
    }
) => {
    // ----- Data -----
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // call get all threads
        fetchThreads();
    }, [userId])

    // ----- Fetchers -----
    const fetchThreads = async () => {
        if (!userId) return;

        setLoading(true);
        try {
            const response = await AiChatService.getAllThreads(userId);
            console.log("Response", response.data)
            setThreads(response.data.threads);
        } catch (err) {
            // setError("Failed to fetch threads");
            notification.error({message: "Failed to fetch threads"})
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // ----- Handlers -----
    const handleSelectThread = (id: number) => {
        if (!threads) return
        const selectedThread = threads.find(thread => thread.id === id)
        if (!selectedThread) return
        setActiveThreadId(id)
    }

    const handleCreateNewThread = () => {
        setActiveThreadId(null)
    };

    const sortThreadsByDate = (threads: AiChatThread[]) => {
        return threads
            .filter(thread => thread.createdAt) // Filter out threads without a createdAt date
            .sort((a, b) => {
            // Handle potential undefined values in createdAt
            const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
            const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
            return dateB - dateA; // For descending order
        });
    };


    const sortedThreads = sortThreadsByDate(threads)

    return (
        <div className="w-100">
            <Button
                type="primary"
                onClick={handleCreateNewThread}
                icon={<PlusOutlined/>}
            >
                New Thread
            </Button>
            <Spin spinning={loading} className="mt-4">
                {
                    !threads?.length && (
                        <div className="text-center">
                            <Empty description="No threads found"/>
                        </div>
                    )
                }
                <div className="threads mt-4">
                    <Flex vertical gap={8} align="start">
                        {
                            threads && sortedThreads.map(thread => (
                                <div
                                    key={thread.id}
                                    className={`thread-item ${activeThreadId === thread.id ? 'active' : ''}`}
                                    onClick={() => handleSelectThread(thread.id)}
                                >
                                    <Button type="link">
                                        {thread.title}
                                    </Button>
                                </div>
                            ))
                        }
                    </Flex>
                </div>
            </Spin>
        </div>
    )
}

export default AiChatThreads
