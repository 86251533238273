import React from "react";
import {Button, Tooltip} from "antd";
import {BellOutlined, EyeOutlined} from "@ant-design/icons";
import {upperFirst} from "lodash";


// ----- Local calls -----
import {StimulusLogCombinedType} from "./index";
import {Stimulus} from "../../../../../types";
// ----- Global variables -----
// ----- Types -----
interface StimulusRecordInfoProps {
    record: StimulusLogCombinedType
    showStimulusItem?: (stimulus: Stimulus) => void
}

// ----- Components -----
/**
 * This component is used to display the details of a stimulus record.
 *
 *
 */
const StimulusRecordInfo: React.FC<StimulusRecordInfoProps> = ({record, showStimulusItem}) => {

    // ----- Data -----
    // const [data, setData] = useState()
    // ----- References -----
    // const inputRef = useRef<InputRef | null>(null);

    // ----- Calls -----

    // ----- Handlers -----

    // ----- Variables -----
    const log = record?.diagnosticLogsRecord
    const stimulus = record?.stimulus
    const countOfPhones = record?.stimulus?.metaParsed?.status?.devices?.length || 0
    const countOfDeliveredPhones = record?.stimulus?.metaParsed?.status?.devices?.filter((device: { delivered: boolean }) => device?.delivered).length || 0
    const infoItems: JSX.Element[] = []

    // ----- Render -----

    if (stimulus) {
        // Get the source of the stimulus notification
        infoItems.push(
            <>
                {upperFirst(stimulus.via)} ({stimulus.reason})
            </>
        )

        // Case: Stimulus is not available
        if (countOfDeliveredPhones === 0) {
            infoItems.push(
                <>
                    <Tooltip title={"Push notification was sent to user"}>
                        <BellOutlined style={{color: '#eb2f96'}}/>
                    </Tooltip> No phones received notification out of <strong>{countOfPhones}</strong> phones
                </>
            )
        }
        // Case: Stimulus is available
        else {
            infoItems.push(
                <>
                    <BellOutlined style={{color: '#52c41a'}}/>
                    <strong>{countOfDeliveredPhones}</strong> phones received notification out
                    of <strong>{countOfPhones}</strong> phones {' '}
                    {
                        stimulus && <Button
                            onClick={() => record.stimulus && showStimulusItem?.(record.stimulus)}
                            icon={<EyeOutlined/>}
                            disabled={!stimulus?.meta}
                        />
                    }
                </>
            )
        }
    }
    // Case 1: Log is not available
    if (!log) {
        // ff no log
        infoItems.push(
            <>
                ❌ Device did not receive stimulus (No log)
            </>
        )
    }

    // Case 2: Log is available
    if (log) {
        infoItems.push(
            <>
                Stimulus was received by the device
            </>
        )
        const logType = log.name.toLowerCase()
        const skippedFlag = log.reason.toLowerCase().includes('skipped')
        // Case 2.1: Stimulus was skipped.
        if (skippedFlag) {
            if (log.reason.includes('LOW_BATT')) {
                infoItems.push(
                    <>
                        🚫 Stimulus was skipped due to low battery
                    </>
                )
            } else if (log.reason.includes('BUTTON')) {
                infoItems.push(
                    <>
                        🚫 Stimulus was skipped due to button press
                    </>
                )
            } else if (log.reason.includes('CHARGING')) {
                infoItems.push(
                    <>
                        🚫 Stimulus was skipped due to charging
                    </>
                )
            } else {
                infoItems.push(
                    <>
                        🚫 Stimulus was {log.reason}
                    </>
                )
            }
        }
            // Case 2.2: Log type is (beep or vibe). No need to check the delivery status.
        // Case 2.3: Log type is zap. Need to check the delivery status.
        else if (logType === 'zap') {
            // Zap case
            const zap = log.parsedJson
            const actualRelease = ((zap && 'trel' in zap) ? zap.trel : 0) as number;
            if (actualRelease > 0) {
                infoItems.push(
                    <>
                        ✅ Zap stimulus delivered
                    </>
                )
            } else {
                infoItems.push(
                    <>
                        ❌ Zap stimulus did not breach the skin
                    </>
                )
            }
        } else {
            // Beep or Vibe case
            infoItems.push(
                <>
                    ✅ {upperFirst(logType)} stimulus was delivered
                </>
            )
        }


    }


    return (
        <>
            <ul>
                {
                    infoItems.map((item, index) => <li key={`info-${index}`}>{item}</li>)
                }
            </ul>
        </>
    )
}

export default StimulusRecordInfo
