import axios from 'axios';

import {getBaseUrl} from "../config";
import {LocalStorage} from "../utils";
import {history} from "../utils/history";


const Api = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
    },
});

const getAuthToken = (): string => {
    try {
        return LocalStorage.getItem('token') || ''
    } catch (e) {
        return ''
    }
}

export const getAuthorizationHeader = (): string => {
    const token = getAuthToken()
    return `Bearer ${token}`;
}

// Set up the interceptor to add the JWT token to each request
Api.interceptors.request.use(
    (config) => {
        // todo: uncomment code
        // const token = localStorage.getItem("token");
        const token = getAuthToken()
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// intercept response
axios.interceptors.response.use(
    function (response) {
        // If the response is successful, just return it
        return response;
    },
    function (error) {
        // If the response status is 401 (Unauthorized), then we should log out
        if (error.response.status === 401) {
            // We'll just redirect to a logout route for simplicity
            // window.location = "/logout";
            history?.navigate?.('/logout');
        }

        // If the error was something else, we might want to do something different
        else {
            return Promise.reject(error);
        }
    }
);


export const setAuthToken = (token: string) => {
    if (token) {
        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete Api.defaults.headers.common['Authorization'];
    }
};


export default Api;