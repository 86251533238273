import axios from 'axios';

import config from "../config";

const DiagnosticLogsApi = axios.create({
    baseURL: config.diagnosticLogs.baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});


export default DiagnosticLogsApi;