import {Device} from "../types";

const HARDWARE_VERSION_MATCHING: { [key: string]: string } = {
    "1.0.0": "2.0.0",  // first pavlok prototype
    "2.0.0": "2.0.0",  // regular pavlok 1
    "3.0.0": "2.0.0",  // weird in between version
    "4.0.0": "4.0.0",  // Shock clock. If upgraded, treat it as regular pavlok 1
    "5.0.0": "5.0.0",  // Regular Pavlok 2
    "052781-9B": "5.0.0",  // lots of p2 units with bad naming
    "Pavlok-S": "5.0.0",  // first P2 units with bad naming
    "6.0.0": "6.0.0",  // Pavlok 3
    "9999.0.0": "9999.0.0",  // For unit tests
}

const PAVLOK_VERSION_MAPPING: { [key: string]: number } = {
    "2.0.0": 2,
    "4.0.0": 2,
    "5.0.0": 2,
    "6.0.0": 3,
    "9999.0.0": 3,
}

const clearDeviceHardwareVersion = (version: string): string => {
    return version.replace("v", "")
        .replace("V", "")
        .replace(" ", "")
}

const getStandardizedHardwareVersion = (device: Device): string => {
    /*
        Fix the hardware version issue
        :return:
    */
    if (device.hardwareVersion) {
        const clearedVersion = clearDeviceHardwareVersion(device.hardwareVersion)
        // if upgraded shock clock then it's considered a P2 device
        //if (clearedVersion == HARDWARE_VERSION_MATCHING["4.0.0"] && false) //userDevice.upgrade)
        //    return HARDWARE_VERSION_MATCHING["2.0.0"]
        // else
        if (Object.keys(HARDWARE_VERSION_MATCHING).indexOf(clearedVersion) >= 0) {
            return HARDWARE_VERSION_MATCHING[clearedVersion]
        }
    }
    // Assume pavlok 2 if non standard
    return HARDWARE_VERSION_MATCHING["5.0.0"]
}


export const getDeviceType = (userDevice: Device): number => {
    const standardizedHardwareVersion = getStandardizedHardwareVersion(userDevice)
    return PAVLOK_VERSION_MAPPING[standardizedHardwareVersion]
}