import {StringsUtils} from "utils";
import {FilterParams, GetPaginatedFilteredDataParams, GetPaginatedFilteredDataRequest} from "types";

// Utility function to prepare query params for API calls with filters, search, sort, and pagination
export const prepareQueryParams = (params: GetPaginatedFilteredDataRequest): GetPaginatedFilteredDataParams => {
    const sortOrder = params.sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const sortField = params.sortField ? StringsUtils.convertToDashCase(params.sortField) : null;

    let queryParams: GetPaginatedFilteredDataParams = {
        page: params.page,
        page_size: params.pageSize,
        search: params.search,
    };

    if (params.filters) {
        const filters = filterNonNullValues(params.filters);
        const filterFields = Object.keys(filters).map(StringsUtils.convertToDashCase).join('|');
        const filterValues = Object.values(filters).map((filter) => filter && filter.join(',')).join('|');

        queryParams = {
            ...queryParams,
            ...(filterFields ? {filter_fields: filterFields} : {}),
            ...(filterFields ? {filter_values: filterValues} : {}),
        };
    }

    queryParams = {
        ...queryParams,
        ...(sortField ? {sort_field: sortField} : {}),
        ...(sortField ? {sort_order: sortOrder} : {}),
    };

    return queryParams;
};

// Helper function to filter out null or empty filter values
const filterNonNullValues = (filters: FilterParams): FilterParams => {
    return Object.entries(filters).reduce((prev, [key, value]) => {
        if (value && value.length) {
            prev[key] = value;
        }
        return prev;
    }, {} as FilterParams);
};
