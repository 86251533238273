import {Avatar, Button, Card, Descriptions, Image, Space, Tooltip, Typography} from "antd";
import {Link} from "react-router-dom";
import {DoubleLeftOutlined, DoubleRightOutlined, EditOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import React from "react";

// ----- Local calls -----
import VerifiedB from "assets/images/verify.png";
import {User} from "types";
import {formatNumber} from "utils/strings_utils";
// ----- Global variables -----
// ----- Types -----
interface UserInfoProps {
    user: User | null
    loading: boolean
    userId?: string
    volts: number | null
    onToggle?: (toggle: boolean) => void;  // Prop to handle visibility toggle
    isCollapsed: boolean
}

const UserInfo: React.FC<UserInfoProps> = ({loading, user, userId, volts, onToggle,isCollapsed}) => {
    const handleToggle = () => {
        // setIsCollapsed(value => !value);
        onToggle?.(!isCollapsed);
    }

    return (
        <Card loading={loading} extra={(
            <Button onClick={handleToggle} style={{marginBottom: '10px'}}>
                {
                    isCollapsed ?
                        <Tooltip title="Show"><DoubleLeftOutlined/></Tooltip>
                        :
                        <Tooltip title="Hide"> <DoubleRightOutlined/></Tooltip>
                }
            </Button>
        )}>
            <Space className="mb-3" direction="vertical" align="center" style={{width: '100%'}}>
                <Avatar size={128} src={user?.profilePictureUrl}/>

                <Space align="center">
                    <Link to={`/users/edit/${userId}`} className="container-center">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            disabled={!user}
                            icon={<EditOutlined/>}
                        >
                            {isCollapsed ? '' : 'Update User'}
                        </Button>
                    </Link>
                </Space>
            </Space>
            {
                isCollapsed ? (
                    // Simplified View
                    <Space direction="vertical" align="center" style={{width: '100%'}}>
                        <Typography.Paragraph copyable>{user?.email}</Typography.Paragraph>
                    </Space>
                ) : (
                    <Descriptions column={1} layout="horizontal">
                        <Descriptions.Item label="Eamail">
                            <Space align="center">
                                <Typography.Paragraph copyable>{user?.email}</Typography.Paragraph>
                                {user?.emailConfirmed && <Image src={VerifiedB} preview={false} width={15}/>}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone">
                            <Space align="center">
                                {user?.phone ? `${user?.phone}` : 'N/A'}
                                {user?.phoneConfirmed && <Image src={VerifiedB} preview={false} width={15}/>}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Birthdate">{user?.birthDate || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Weight">
                            {user?.weight && user?.weightMeasurementUnit ? `${user.weight} ${user.weightMeasurementUnit}` : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Height">
                            {user?.height && user?.heightMeasurementUnit ? `${user.height} ${user.heightMeasurementUnit}` : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Volts">
                            {volts && formatNumber(volts)}
                            <Button className="ml-2"
                                    type="primary"
                                    size="small"
                                    href={`/volts-transactions/${userId}`}>
                                View transactions
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label="Role">{user?.role || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Created At">
                            {user?.createdAt && dayjs(user?.createdAt).format('YYYY-MM-DD HH:mm')}
                        </Descriptions.Item>
                    </Descriptions>
                )
            }
        </Card>
    )
}

export default UserInfo;