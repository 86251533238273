import {AxiosResponse} from "axios";
import Api from "./api";

import {Hardware} from "../types";

interface GetAllHardwareResponse {
    hardwares: Hardware[]
}

export const getAllHardware = (): Promise<AxiosResponse<GetAllHardwareResponse>> => {
    return Api.get('/admin/hardwares/')
}

export default {
    getAllHardware
}

