import React, {useState} from "react"
import {Button, Form, Input, Select, Switch, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import type {UploadChangeParam} from "antd/es/upload";
import {UploadFile, UploadProps} from "antd/es/upload/interface";

// ----- Local calls -----
import UploadImageButton from "components/UploadImageButton";
import {handleBeforeUpload} from "utils/upload";
import {CampaignInCreate} from "types";
import {FormInstance} from "antd/es/form/hooks/useForm";

// ----- Variables -----
const {TextArea} = Input;
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};

// ----- Types -----
interface NotificationStepProps {
    form: FormInstance
    campaign: CampaignInCreate | null
    loading: boolean
    setCampaign: (campaign: any) => void
    handleNext?: () => void
    handlePrev?: () => void
    currentStep?: number
    handleSubmit?: () => Promise<any>
}

// ----- Components -----
const NotificationStep: React.FC<NotificationStepProps> = ({form, loading, setCampaign, handleNext}) => {
    // ----- Form -----
    // const [form] = Form.useForm();

    // ----- Upload image -----
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, _] = useState<string | null>(null);

    // ----- Handlers -----
    const handleFinish = (values: any) => {
        // console.log(values)
        setCampaign((currentValue: CampaignInCreate | null) => ({
            ...(currentValue ? currentValue : {}),
            ...values,
            icon: imageBase64
        }))
        // todo: clear image base 64 on success
        handleNext?.()
    }


    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam<UploadFile>) => {
        console.log('image changed', e)
    };

    return (
        <>
            <Form
                form={form}
                onFinish={handleFinish}
                {...formLayout}
            >
                <Form.Item
                    name="campaignName"
                    label="Campaign name"
                    rules={[{required: true, message: 'Please input name!'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[{required: true, message: 'Please input the title!'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[{required: true, message: 'Please input the content!'}]}
                >
                    <TextArea/>
                </Form.Item>
                <Form.Item label="Icon" name="icon">
                    <ImgCrop showGrid showReset aspect={1 / 1}>
                        <Upload
                            beforeUpload={handleBeforeUpload({
                                config: {allowOnlyImage: true},
                                callback: setImageBase64
                            })}
                            onChange={handleImageChange}
                            accept="image/*"
                            showUploadList={false}
                            onRemove={() => console.log('Remove')}
                            listType="picture-card"
                            disabled={loading}
                        >
                            {
                                (imageBase64 || image) ?
                                    <img src={imageBase64 || image || ''}
                                         alt="Preview"
                                         style={{maxWidth: "100%", borderRadius: 7}}/> :
                                    <UploadImageButton loading={loading}/>
                            }
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    label="URL"
                    name="url"
                    rules={[{required: true, message: 'Please input the URL!'}]}
                >
                    <Input disabled={form.getFieldValue('disabledCampaignUrl') === 'true'}/>
                </Form.Item>
                <Form.Item
                    label="URL Type"
                    name="urlType"
                    rules={[{required: true, message: 'Please select the URL type!'}]}
                >
                    <Select disabled={form.getFieldValue('disabledCampaignUrl') === 'true'}>
                        <Select.Option value="internal">Internal</Select.Option>
                        <Select.Option value="external">External</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="silent" label="Silent" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <div style={{textAlign: 'right'}}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Form.Item>
                </div>
            </Form>

        </>
    )
}

export default NotificationStep