import {AxiosResponse} from "axios";

import Api from "./api";
import {ContentCategory} from "../types";

interface GetAllContentsCategoriesResponse {
    contentsCategories: ContentCategory[];
}

export const getAllContentsCategories = (): Promise<AxiosResponse<GetAllContentsCategoriesResponse>> => {
    return Api.get('/admin/contents-categories/')
}


export default {
    getAllContentsCategories
}