import React from "react";
import {Button, Card, Col, Row, Space, Spin, Typography, Tooltip} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, InfoCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {
    Chart,
    LineElement,
    PointElement,
    LinearScale,
    Title as ChartTitle,
    CategoryScale,
    BarElement,
    Legend,
    TimeSeriesScale,
    ChartData,
    Point,
} from 'chart.js';
import {Line} from "react-chartjs-2";
import type {ChartOptions} from "chart.js/dist/types";
import {weekendDatesHighlighter} from "utils/graphweekends";

import {FeatureUsageData} from "../../../../../types";


Chart.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, ChartTitle, TimeSeriesScale, Legend);


// ----- Global variables -----
const {Title} = Typography
const chartOptions: ChartOptions<any> = {
    tension: 0.4,
    plugins: {
        legend: {
            display: false,
            labels: {
                usePointStyle: true, // Use the point style instead of the default box
                pointStyle: 'circle', // Set the point style to circle
            }
        }
    },
    scales: {   
         x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd MMM yyyy',
                    displayFormats: {
                        day: 'dd MMM'
                    }
                },
                grid: {
                    display: false
                },
            },
    }
};

// ----- Types -----
// todo: ??
type MetricType = {
    date: string;
    habitFeatureUsersCount: string;
    pokesFeatureUsersCount: string;
    integrationsFeatureUsersCount: string;
    alarmsFeatureUsersCount: string;
    sleepTrackingFeatureUsersCount: string;
    timersFeatureUsersCount: string;
}

interface HabitFeatureUsageOverTimeProps {
    dailyData: FeatureUsageData[] | null
    weeklyData: FeatureUsageData[] | null
    loading: boolean
    fetchData?: () => void
}

// ----- Component -----
const HabitFeatureUsageOverTime: React.FC<HabitFeatureUsageOverTimeProps> = (
    {
        dailyData,
        weeklyData,
        loading,
        fetchData
    }
) => {
    const filterData = (rawData: FeatureUsageData[]): MetricType[] => {
        // Add filter here
        return rawData
    }
    const filteredDailyData = dailyData ? filterData(dailyData) : []
    const filteredWeeklyData = weeklyData ? filterData(weeklyData) : []
    const uniqueDates = Array.from(new Set(
        [
            ...(filteredDailyData?.map(item => item.date) || []),
            ...(filteredWeeklyData?.map(item => item.date) || []),
        ]
    ));

    const chartData: ChartData<"line", (number | Point | null)[], string> = {
        labels: uniqueDates || [],
        datasets: [
            {
                label: 'Weekly users',
                // todo: optimize this
                data: uniqueDates.map((dateItem: string) => {
                    const item = filteredWeeklyData.find((item: MetricType) => item.date === dateItem)
                    return item ? Number(item.habitFeatureUsersCount) : null
                }),
                borderColor: 'blue',
                backgroundColor: '#fff',
                fill: false,
                borderWidth: 2,
                pointRadius: 1, 
                pointHoverRadius: 5, 
            },
            {
                label: 'Daily users',
                // todo: optimize this
                data: uniqueDates.map((dateItem: string) => {
                    const item = filteredDailyData.find((item: MetricType) => item.date === dateItem)
                    return item ? Number(item.habitFeatureUsersCount) : null
                }),
                borderColor: 'orange',
                backgroundColor: '#fff',
                fill: false,
                borderWidth: 2,
                pointRadius: 1, 
                pointHoverRadius: 5, 
            },
        ]
    };


    const lastDayDailyUsers = dailyData && dailyData.length > 0 ? dailyData[dailyData.length - 1].habitFeatureUsersCount : 0;
    const lastDayWeeklyUsers = weeklyData && weeklyData.length > 0 ? weeklyData[weeklyData.length - 1].habitFeatureUsersCount : 0;

    const lastWeekDailyUsers = dailyData && dailyData.length >= 7 ? dailyData[dailyData.length - 7].habitFeatureUsersCount : 0;
    const DailyUsersgrowth = ((Number(lastDayDailyUsers) - Number(lastWeekDailyUsers)) / Number(lastWeekDailyUsers)) * 100;


    const lastWeekWeeklyUsers = weeklyData && weeklyData.length >= 7 ? weeklyData[weeklyData.length - 7].habitFeatureUsersCount : 0;
    const WeeklyUsersgrowth = ((Number(lastDayWeeklyUsers) - Number(lastWeekWeeklyUsers)) / Number(lastWeekWeeklyUsers)) * 100;



    return (
        <div>
            <Space direction="horizontal">
                <Title level={4}>Habit feature usage over time</Title>
                <Tooltip title="Counts users who added a habit log manually to one of their habits">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>
            <Row gutter={16} >
                <Col span={12}>
                    <Card bordered={false}>
                        <h4>
                            Weekly Users 
                            <div style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'blue', borderRadius: '50%', marginLeft: '5px' }}></div>
                        </h4>
                        <div style={{ fontSize: '24px' }}>
                            {lastDayWeeklyUsers}
                            <span style={{ fontSize: '14px', marginLeft: '10px', color: WeeklyUsersgrowth >= 0 ? '#3f8600' : '#cf1322' }}>
                                <Tooltip title="Vs last week">
                                    {WeeklyUsersgrowth >= 0 ? 
                                        <ArrowUpOutlined style={{ color: '#3f8600' }} /> : 
                                        <ArrowDownOutlined style={{ color: '#cf1322' }} />}
                                    {WeeklyUsersgrowth.toFixed(2)}%
                                </Tooltip>
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <h4>
                            Daily Users 
                            <div style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'orange', borderRadius: '50%', marginLeft: '5px' }}></div>
                        </h4>
                        <div style={{ fontSize: '24px' }}>
                            {lastDayDailyUsers}
                            <span style={{ fontSize: '14px', marginLeft: '10px', color: DailyUsersgrowth >= 0 ? '#3f8600' : '#cf1322' }}>
                                <Tooltip title="Vs last week">
                                    {DailyUsersgrowth >= 0 ? 
                                        <ArrowUpOutlined style={{ color: '#3f8600' }} /> : 
                                        <ArrowDownOutlined style={{ color: '#cf1322' }} />}
                                    {DailyUsersgrowth.toFixed(2)}%
                                </Tooltip>
                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Spin spinning={loading}>
                <div className="w-100 d-bloc">
                    {
                        loading ?
                            <p>Loading...</p> :
                            dailyData && weeklyData && <Line data={chartData} height={150} options={chartOptions} plugins={[weekendDatesHighlighter]} />
                    }
                </div>
            </Spin>
        </div>
    )
}

export default HabitFeatureUsageOverTime