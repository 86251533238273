import React from "react";
import {Button, Divider, DatePicker, Form, Modal, Typography} from "antd";
import dayjs from "dayjs";
import type { Dayjs } from 'dayjs';


const {RangePicker} = DatePicker

const rangeConfig = {
    rules: [{type: 'array' as const, required: true, message: 'Please select time!'}],
};

const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
}[] = [
    { label: 'Last 24 Hours', value: [dayjs().add(-1, 'd'), dayjs()] },
    { label: 'Last Week', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last Month', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    { label: 'Last Year', value: [dayjs().add(-1, 'y'), dayjs()] },
];

interface DatepickerModelProps {
    isModalVisible: boolean
    fetchToLast100PercentChargeData?: () => void;
    fetchData?: () => void;
    handleDateChange?: (dates: unknown, dateStrings: [string, string]) => void;
    handleOk?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    handleCancel?: (e?: React.MouseEvent<HTMLButtonElement>) => void;

}

const DatepickerModel: React.FC<DatepickerModelProps> = (
    {
        isModalVisible,
        fetchToLast100PercentChargeData,
        fetchData,
        handleOk,
        handleCancel,
        handleDateChange
    }
) => {

    const onFinish = (fieldsValue: any) => {
        const dateInterval = fieldsValue.dateInterval
        if (!dateInterval) {
            console.error('dateInterval is undefined');
            return
        }
        fetchData?.()
        handleOk?.()
    }

    const handToLast100PercentChargeData = () => {
        fetchToLast100PercentChargeData?.()
        handleOk?.()
    }

    return (
        <Modal title={<Typography.Title level={4}>Select Time Range</Typography.Title>}
               open={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={null}
        >
            <Button type="primary" onClick={() => handToLast100PercentChargeData()}>Last 100% charge</Button>
            <Divider plain>Or</Divider> {/* This is the added line separator */}
            <Typography.Paragraph>Select date range</Typography.Paragraph>
            <Form layout="inline"
                  onFinish={onFinish}
                  initialValues={{
                      // dateInterval: [moment('2023-04-26').subtract(7, 'days'), moment('2023-04-26')]
                  }}
            >
                <Form.Item name="dateInterval" required  {...rangeConfig}>
                    <RangePicker onChange={handleDateChange} presets={rangePresets}/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DatepickerModel