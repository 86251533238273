import React, {useEffect, useMemo, useRef, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Modal,
    Form,
    Select,
    Row,
    Col,
    Divider,
    List,
    Skeleton,
    Button,
    Input,
    Typography,
    Switch,
    Space, Rate, Tooltip
} from "antd";
import {
    AmazonCircleFilled,
    AndroidFilled, AppleFilled,
    CalendarOutlined,
    ExportOutlined,
    InfoCircleOutlined,
    MobileOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs, {Dayjs} from "dayjs";

// ----- Local calls -----
import {Review, ReviewSource} from "types";
import {ReviewsMetricsFilter} from "pages/metrics/components/ReviewsMetrics";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS, DateRangeFilterSelectorOption, DateRangeFilterSelectorOptionsLabel,
} from "helpers/dates-helper";
import DateRangeSelector from "components/DateRangeSelector";
import ReviewContent from "../review/ReviewContent";

// ----- Global variables -----
const {Option} = Select
const {Search} = Input;
const formLayout = {}

// ----- Types -----
interface FilterModalProps {
    open: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    data: Review[];
    filters: ReviewsMetricsFilter;
    setFilters: (filters: ReviewsMetricsFilter) => void;
    handleResetFilters: () => void;
}

// ----- Components -----
const FilterModal: React.FC<FilterModalProps> = ({
                                                     open,
                                                     onOk,
                                                     onCancel,
                                                     data,
                                                     setFilters,
                                                     filters,
                                                     handleResetFilters
                                                 }) => {
    // ----- State -----
    const [topics, setTopics] = useState<string[]>([])
    const [reviewSources, setReviewSources] = useState<ReviewSource[]>([])
    const [, setMonths] = useState<string[]>([])
    const [resetKey, setResetKey] = useState<number>(0)

    // ----- References -----
    const scrollableDivRef = useRef<HTMLDivElement>(null);

    // ----- Pagination -----
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize,] = useState<number>(10);
    // total items after doing the search and filter
    const [totalItems, setTotalItems] = useState<number>(0);

    // ----- Filters -----
    const [search,] = useState<string>('')
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[3]
    );

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- Effects -----
    useEffect(() => {
        handleScrollUp()
    }, []); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        handleScrollUp()
        // Reset pagination
        setCurrentPage(1)
        // Reset filters
        handleResetFilters()
        // Set filters inputs values
        const uniqueTopics = new Set(data?.map(review => review?.topics?.map(topic => topic.topic)).flat())
        const uniqueReviewSources = new Set(data?.map(review => review.reviewSource))
        const uniqueMonths = new Set(data?.map(review => dayjs(review.displayDate).format("YYYY-MM")))
        setTopics(Array.from(uniqueTopics));
        setReviewSources(Array.from(uniqueReviewSources));
        setMonths(Array.from(uniqueMonths));
    }, [data])

    useEffect(() => {
        handleScrollUp()
        // Update the form with the correct filters values.
        form.setFieldsValue(filters)
        if (filters.month) {
            // Extracting the start date of the month
            const startDate = dayjs(filters.month).startOf('month');

            // Extracting the end date of the month
            const endDate = dayjs(filters.month).endOf('month');
            // Set the selected date range
            const range: DateRangeFilterSelectorOption = {
                label: DateRangeFilterSelectorOptionsLabel.CustomRange,
                value: [startDate, endDate]
            }
            setSelectedDateRange(range)
        }
    }, [filters])

    // ----- Data -----
    const isReviewInSelectedDateRange = (review: Review) => {
        const [startDate, endDate] = selectedDateRange.value;

        // If the date range is 'All time', you might handle it differently
        // For example, if 'All time' means no filtering on dates
        if (selectedDateRange.label === 'All time') {
            return true;
        }

        // Check if the review date is within the selected start and end dates
        // Adjust the logic if your date ranges are inclusive or exclusive as needed
        return dayjs(review.displayDate).isAfter(startDate) && dayjs(review.displayDate).isBefore(endDate);
    }

    const filteredData: Review[] = useMemo(() => {
        if (!data) return [];
        // Reset current page to 1
        setCurrentPage(1);
        // Filter data based on search criteria and filters

        const filteredReview = data?.sort((a, b) => dayjs(b.displayDate).diff(dayjs(a.displayDate)))
            .filter((review: Review) => {
                // Filter by search
                const searchCondition = filters.search ? (
                    review?.review?.includes?.(filters.search) || review?.title?.includes?.(filters.search)
                ) : true
                // todo: Filter by date range
                // filter by date range
                const dateRangeCondition = isReviewInSelectedDateRange(review)

                // Filter by topic
                const topicCondition = filters.topicName ? review.topics?.some(topic => topic.topic === filters.topicName) : true

                // Filter by review source
                const reviewSourceCondition = filters.reviewSource ? review.reviewSource === filters.reviewSource : true

                // Filter by impression
                const impressionCondition = filters.impression ? review.topics?.some(topic => topic.impression === filters.impression) : true

                // Combine all conditions
                return searchCondition && topicCondition && reviewSourceCondition && impressionCondition && dateRangeCondition
                // && dateRangeCondition
            });

        // Update totalItems state based on filtered data length
        setTotalItems(filteredReview.length);

        // return a filteredData
        return filteredReview;
    }, [data, search, filters, selectedDateRange])

    const paginatedData: Review[] = useMemo(() => {
        // We suppose pageSize is 10
        const startIndex = 0
        const endIndex = currentPage * pageSize // page 1, and pageSize= 10 =>
        return filteredData.slice(startIndex, endIndex)
    }, [filteredData, currentPage, pageSize])


    // ----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };

    const handleValuesChange = (changedValues: unknown, allValues: ReviewsMetricsFilter) => {
        setFilters(allValues)
    };

    const handleFetchMore = () => {
        // increment current page
        setCurrentPage((currentValue) => currentValue + 1)
    }

    const handleResetForm = () => {
        form.resetFields()
        handleResetFilters()
        setSelectedDateRange(DATE_RANGE_FILTER_SELECTOR_OPTIONS[3])
    }

    const handleScrollUp = () => {
        setResetKey(prev => prev + 1);
        // Reset scroll to top
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = 0;
        }
    }

    // ----- Render -----
    const ReviewSourceItem = ({review}: { review: Review }) => {
        switch (review.reviewSource) {
            case ReviewSource.ANDROID:
                return (<><AndroidFilled className="mr-1"/> Android</>)
            case ReviewSource.AMAZON:
                return (<><AmazonCircleFilled className="mr-1"/> Amazon</>)
            case ReviewSource.IOS:
                return (<><AppleFilled className="mr-1"/> iOS</>)
            default:
                return (<>{review.reviewSource}</>)
        }
    }

    return (
        <Modal
            title="Customer Reviews for"
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            width={1000}
        >
            <Row>
                <Col span={6}>
                    <Typography.Title level={5}>Filters</Typography.Title>
                    <Form<ReviewsMetricsFilter> form={form}
                                                layout="vertical"
                                                className="pr-3"
                                                {...formLayout}
                                                initialValues={filters}
                                                onValuesChange={handleValuesChange}
                    >
                        <Form.Item name="search" label="Search">
                            <Search placeholder="input search text"/>
                        </Form.Item>
                        <Form.Item label="Topic" name="topicName">
                            <Select
                                placeholder="Select Topic"
                                allowClear
                                showSearch
                            >
                                <Option value={null}>All</Option>
                                {
                                    topics.map((topic: string, index: number) => <Option key={index}
                                                                                         value={topic}>{topic}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Period">
                            <DateRangeSelector defaultDateRange={selectedDateRange}
                                               onDateRangeChange={handleDateRangeChange}/>
                        </Form.Item>
                        <Form.Item label="Source" name="reviewSource">
                            <Select
                                placeholder="Select source"
                                allowClear
                                showSearch
                            >
                                <Option value={null}>All</Option>
                                {
                                    reviewSources.map((source: string, index: number) => <Option key={index}
                                                                                                 value={source}>{source}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Impression"
                            name="impression"
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button onClick={handleResetForm}> Reset</Button>
                        </Form.Item>
                    </Form>


                </Col>
                <Col span={18}>
                    <Typography.Title level={5}>Reviews ({totalItems}/{data?.length})</Typography.Title>
                    <div
                        id="scrollableDiv"
                        ref={scrollableDivRef}
                        key={resetKey}
                        style={{
                            height: 480,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={paginatedData.length}
                            next={handleFetchMore}
                            hasMore={paginatedData.length < totalItems}
                            loader={<Skeleton avatar paragraph={{rows: 1}} active/>}
                            endMessage={<Divider plain>It is all, nothing more</Divider>}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={paginatedData}
                                renderItem={(review: Review) => (
                                    <List.Item
                                        key={review.id}
                                        extra={
                                            review.reviewLink && (
                                                <Button type="link" href={review.reviewLink} target="_blank"
                                                        rel="noopener noreferrer">
                                                    <ExportOutlined/>
                                                </Button>
                                            )
                                        }
                                    >
                                        <List.Item.Meta
                                            title={
                                                <>
                                                    {
                                                        review.reviewSource === 'android' &&
                                                        <Tooltip title="AI generated title">✨ </Tooltip>
                                                    }
                                                    <Highlighter
                                                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                                                        searchWords={filters?.search?.split(' ') || []}
                                                        autoEscape={true}
                                                        textToHighlight={review.title || ''}
                                                    />
                                                </>
                                            }
                                            description={
                                                <Space size="large">
                                                    <div>
                                                        <Rate rootClassName="rating"
                                                              disabled
                                                              defaultValue={Number(review.rating)}/>
                                                    </div>
                                                    <div>
                                                        <CalendarOutlined className="mr-1"/>
                                                        {review?.displayDate && dayjs(review?.displayDate).format("YYYY-MM-DD")}
                                                    </div>
                                                    {review.appVersion && <div>
                                                        <InfoCircleOutlined className="mr-1"/>{review.appVersion}
                                                    </div>}
                                                    <div><ReviewSourceItem review={review}/></div>
                                                    {
                                                        review.deviceModel &&
                                                        <div><MobileOutlined className="mr-1"/> {review.deviceModel}
                                                        </div>
                                                    }
                                                </Space>
                                            }
                                        />
                                        <ReviewContent review={review} filters={filters} topics={topics}/>
                                        <Divider/>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
            <Divider/>

        </Modal>
    )
}

export default FilterModal;