import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Card, Col, Row} from "antd";

// ----- Local calls -----
import {AiChatThread} from "../../../../../types";
import AiChatThreads from "./Threads";
import AiChatMessages from "./Messages";

// ----- Global variables -----

// ----- Types -----
type RouteParams = {
    userId?: string
}
// ----- Components -----
const AiChat: React.FC = () => {
    // ----- Params -----
    const {userId} = useParams<RouteParams>();

    // ----- Data -----
    const [threads, setThreads] = useState<AiChatThread[]>([]);
    const [activeThreadId, setActiveThreadId] = useState<number | null>(null);

    const activeAiChatThread = threads.find(thread => thread.id === activeThreadId) || null;

    return (
        <Card title="AI Coach assistant" style={{minHeight: 550}}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={4}>
                    <AiChatThreads
                        userId={Number(userId)}
                        threads={threads}
                        setThreads={setThreads}
                        activeThreadId={activeThreadId}
                        setActiveThreadId={setActiveThreadId}
                    />
                </Col>
                <Col xs={24} sm={20}>
                    <AiChatMessages
                        userId={Number(userId)}
                        threads={threads}
                        setThreads={setThreads}
                        activeThreadId={activeThreadId}
                        setActiveThreadId={setActiveThreadId}
                        // todo: move to AiChatMessages
                        thread={activeAiChatThread}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default AiChat
