import React from "react";
import {batteryToPercent, releaseToPercent} from "helpers";


const adapter = (item: any) => {
    return {
        battv: item?.battv,
        target: item?.target && parseInt(item?.target),
        release: item?.release ? item?.release : undefined,
        skipped: item?.skipped || item?.skip || (item?.v && item?.v?.skip),
        reason: item?.reason || (item?.v && item?.v?.reason),
        actualRelease: item?.trel
    };
};


const TableRecordZap: React.FC<any> = (props) => {
    const zap = adapter(props);
    const battery = batteryToPercent(zap.battv);
    const target = parseInt(zap.target);
    const release = releaseToPercent(zap.release);
    const delivery = zap.actualRelease > 0 ? "🟢" : "🔴";
    return (
        <>
            🔋{battery}% ⚡{release}% of {target}% {delivery}
        </>
    );
};

export default TableRecordZap;