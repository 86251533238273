import {AxiosResponse} from "axios";

import Api from "./api";
import {SleepDataset} from "../types";

interface GetAllSleepDatasetsResponse {
    sleepDatasets: SleepDataset[];
}

export const getAllSleepDatasets = (userId: number): Promise<AxiosResponse<GetAllSleepDatasetsResponse>> => {
    return Api.get(`/admin/sleep-datasets/?user_id=${userId}`);
};


export default {
    getAllSleepDatasets,
};
