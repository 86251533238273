import React, {useEffect, useState} from 'react';
import {Select} from "antd";

// ----- Local calls -----
import {HardwareService} from "services";
import {Hardware} from "types";

// ----- Components -----
const HardwareSelect: React.FC<any> = (props: any) => {
    // ----- State -----
    const [data, setData] = useState<Hardware[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        // todo: optimize
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        HardwareService.getAllHardware()
            .then(res => {
                let response = res?.data?.hardwares || []
                response = response?.map((item: Hardware) => ({
                    key: `entitlement-${item?.id}`,
                    ...item
                }))
                if (response) {
                    setData((response))
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const options = data?.map((item: Hardware) => ({
        label: `${item.convenienceName} (${item.versionString})`,
        value: item.id
    }))
    return (
        <Select loading={loading} options={options} allowClear {...props}/>
    )
}

export default HardwareSelect