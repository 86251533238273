import React from "react";
import {batteryToPercent} from "helpers";

const TableRecordBattery: React.FC<any> = ({voltage, vusb, charged}) => {
    return (
        <>
            {vusb ? "🔌" : ""}🔋{batteryToPercent(voltage)}%
        </>
    );
};

export default TableRecordBattery;