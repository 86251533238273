import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {User} from "../types";

export interface AuthState {
    isAuthenticated: boolean;
    loading: boolean;
    token: string | null;
    authenticatedUser: User | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    loading: false,
    token: null,
    authenticatedUser: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{ token: string, authenticatedUser: User | null }>) => {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.authenticatedUser = action.payload.authenticatedUser
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            state.authenticatedUser = null;
        },
        setLoading: (state, action: PayloadAction<{ loading: false }>) => {
            state.loading = action.payload.loading
        }
    },
});

export const {login, logout} = authSlice.actions;

export default authSlice.reducer;
