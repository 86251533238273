import dayjs from "dayjs";

export enum DateRangeFilterSelectorOptionsLabel {
    Last7Days = 'Last 7 Days',
    Last30Days = 'Last 30 Days',
    Last90Days = 'Last 90 Days',
    Last6Months = 'Last 6 months',
    Last12Months = 'Last 12 months',
    YearToDate = 'Year to date',
    LastYear = 'Last year',
    AllTime = 'All time',
    CustomRange = 'Custom Range', // If you need to add this as an option
}
export interface DateRangeFilterSelectorOption {
    label: DateRangeFilterSelectorOptionsLabel;
    value: [dayjs.Dayjs | null, dayjs.Dayjs | null];
}

export const DATE_RANGE_FILTER_SELECTOR_OPTIONS: DateRangeFilterSelectorOption[] = [
    {label: DateRangeFilterSelectorOptionsLabel.Last7Days, value: [dayjs().subtract(7, 'day'), dayjs()]},
    {label: DateRangeFilterSelectorOptionsLabel.Last30Days, value: [dayjs().subtract(30, 'day'), dayjs()]},
    {label: DateRangeFilterSelectorOptionsLabel.Last90Days, value: [dayjs().subtract(90, 'day'), dayjs()]},
    {label: DateRangeFilterSelectorOptionsLabel.Last6Months, value: [dayjs().subtract(6, 'month'), dayjs()]},
    {label: DateRangeFilterSelectorOptionsLabel.Last12Months, value: [dayjs().subtract(12, 'month'), dayjs()]},
    {label: DateRangeFilterSelectorOptionsLabel.YearToDate, value: [dayjs().startOf('year'), dayjs()]},
    // Uncomment or adjust the LastYear value as needed based on your requirements
    // {label: DateRangeFilterSelectorOptionsLabel.LastYear, value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')]},
    {label: DateRangeFilterSelectorOptionsLabel.AllTime, value: [null, dayjs()]},
    // Add CustomRange or other options as needed
];