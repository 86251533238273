import Api from "./api";
import {FilterParams, GetItemsResponse, GetPaginatedFilteredDataParams, User} from "../types";
import {AxiosResponse} from "axios";
import {prepareQueryParams} from "../utils/requests-utils";

interface GetUsersParams {
    page?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: 'ascend' | 'descend' | string;
    filters?: FilterParams;
    search?: string
    showDeleted?: boolean
}

/*
export interface GetTableDataParams {
    page?: number;
    page_size?: number;
    search?: string
    sort_field?: string;
    sort_order?: 'ASC' | 'DESC' | string;
    filter_fields?: string;
    filter_values?: string;
    show_deleted?: boolean
}
 */

interface GetUserResponse {
    user: User
    volts?: number
}

interface GetStaffResponse {
    users: User[]
}

interface DefaultSuccessResponse {
    success: boolean
}

export interface UserInUpdate {
    id?: number
    username?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    weight?: number;
    weightMeasurementUnit?: string;
    height?: number;
    heightMeasurementUnit?: string;
    profilePicture?: string;
    anonymous?: boolean;
    timezone?: string;
}

export interface UserPasswordInUpdate {
    userId: number | string
}

export interface ResendConfirmationEmail {
    userId: number | string
}

export interface UserRoleInUpdate {
    userId: number | string
    roleId: number
}

export interface DeleteUserAccountInRequest {
    userId: number | string
}

export const getAllUsers = (params: GetUsersParams = {}): Promise<AxiosResponse<GetItemsResponse<User>>> => {
    const req: GetPaginatedFilteredDataParams = prepareQueryParams(params)
    return Api.get(
        '/admin/users/',
        {
            params: req
        }
    )
}

export const getUserById = (userId: string | number): Promise<AxiosResponse<GetUserResponse>> => {
    return Api.get(`/admin/users/${userId}`)
}

export const updateUser = (user: UserInUpdate): Promise<AxiosResponse<GetUserResponse>> => {
    const request = {
        user: user
    }
    return Api.put(`/admin/users/${user.id}`, request)
}

export const resendConfirmationEmail = (params: ResendConfirmationEmail): Promise<AxiosResponse<DefaultSuccessResponse>> => {
    return Api.post(`/admin/users/${params.userId}/resend-confirm-email`)
}

export const updateUserRole = (params: UserRoleInUpdate): Promise<AxiosResponse<GetUserResponse>> => {
    const request = {
        "user-role": {
            roleId: params?.roleId,
            userId: params?.userId
        }
    }
    return Api.put('/admin/users/update-user-role', request)
}


export const updateUserPassword = (params: UserPasswordInUpdate): Promise<AxiosResponse<GetUserResponse>> => {
    return Api.post(`/admin/users/password/${params.userId}`)
}

export const sendResetPassword = (parms: UserPasswordInUpdate): Promise<AxiosResponse<GetUserResponse>> => {
    return Api.post(`/admin/users/${parms.userId}/reset-password-email`)
}

export const deleteUserAccount = (parms: DeleteUserAccountInRequest): Promise<AxiosResponse<GetUserResponse>> => {
    // todo: change endpoint
    return Api.post(`/admin/users/${parms.userId}/reset-password-email`)
}

export const deactivateUserAccount = (parms: DeleteUserAccountInRequest): Promise<AxiosResponse<GetUserResponse>> => {
    // todo: change endpoint
    return Api.post(`/admin/users/${parms.userId}/reset-password-email`)
}

export const getStaff = (): Promise<AxiosResponse<GetStaffResponse>> => {
    return Api.get(`/admin/users/staff`)
}

export default {
    getAllUsers, getUserById, getStaff
}