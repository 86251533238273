import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Card, Col, Input, Row, Table, Image, Typography, Tooltip, Space} from 'antd';
import {BarChartOutlined, DotChartOutlined, EyeOutlined, MobileOutlined, ReloadOutlined} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {SorterResult} from "antd/es/table/interface";

import {TableFilters, Device} from "types";
import {getColumnSearchProps} from "components/TableSearch";
import {DevicesService} from "services";
import {getDeviceType} from "utils/devices";

import pavlok_device_v2 from 'assets/images/pavlok_device_v2.png';
import pavlok_device_v3 from 'assets/images/pavlok_device_v3.png';
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";


const {Search} = Input;

const DevicesListPage: React.FC = () => {
    const [device, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [search, setSearch] = useState<string>('')
    const [sortField, setSortField] = useState<string | undefined>()
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>()

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [filters, setFilters] = useState<TableFilters>({})
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage, sortField, sortOrder, filters])

    const fetchData = () => {
        setLoading(true)
        DevicesService.getAllUsersDevices({
            page: currentPage,
            pageSize,
            search,
            sortField,
            sortOrder,
            filters
        })
            .then(res => {
                // console.log('get data:', res)
                const items = res?.data?.items || []
                const count = res?.data?.count || 0
                const deviceList = items?.map((item: Device, index: number) => ({
                    ...item,
                    key: `key-${index}`
                }))
                setDevices(deviceList || [])
                setTotalItems(count);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        fetchData()
        // todo: add the search to the url
    }


    const columns: ColumnsType<Device> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_: string | null, record: Device) =>
                <>
                    {getDeviceType(record) == 2 && <Image src={pavlok_device_v2} width={40}/>}
                    {getDeviceType(record) == 3 && <Image src={pavlok_device_v3} width={40}/>}
                    {
                        (getDeviceType(record) != 3 && getDeviceType(record) != 2) &&
                        <Avatar style={{backgroundColor: '#1677ff'}} icon={<MobileOutlined/>}></Avatar>
                    }
                    <Typography.Text>{record?.id}</Typography.Text>
                </>,
            sorter: (a: Device, b: Device) => a?.firmwareVersion?.localeCompare(b?.firmwareVersion)
        },
        {
            title: 'Firmware Version',
            dataIndex: 'firmwareVersion',
            key: 'firmwareVersion',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'firmwareVersion'
            })
        },
        {
            title: 'Mac Address',
            dataIndex: 'macAddress',
            key: 'macAddress',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'macAddress'
            }),
            sorter: (a: Device, b: Device) => a?.firmwareVersion?.localeCompare(b?.firmwareVersion)
        },
        {
            title: 'Device Model',
            dataIndex: 'deviceModel',
            key: 'deviceModel',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'deviceModel'
            }),
            sorter: (a: Device, b: Device) => a?.deviceModel?.localeCompare(b?.deviceModel)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'name'
            }),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_: number, record: Device) => (
                <Space>
                    <Tooltip title="Details page">
                        <Button type="primary" href={`/devices/${record.id}`} icon={<EyeOutlined/>}/>
                    </Tooltip>
                    <Tooltip title="User Logs">
                        <Button type="primary"
                                href={`/user-logs/?macAddress=${encodeURIComponent(record.macAddress)}`}
                                icon={<BarChartOutlined/>}
                        />
                    </Tooltip>
                    <Tooltip title="Diagnostic Logs">
                        <Button type="primary"
                                href={`/diagnostic-logs/?macAddress=${encodeURIComponent(record.macAddress)}`}
                                icon={<DotChartOutlined/>}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <>
            <CustomBreadcrumb list={['devices', 'LiST',]}/>
            <Card title="User Devices list"
                  extra={
                      <Button 
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                  }
            >
                <Row gutter={[16, 16]} justify="end">
                    <Col span={5}>
                        <Search
                            loading={loading}
                            placeholder="Search"
                            onSearch={onSearch}
                            onChange={(e) => setSearch(e?.target?.value)}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col span={24}>
                        <Table<Device>
                            columns={columns}
                            dataSource={device}
                            loading={loading}
                            pagination={{
                                pageSize: pageSize,
                                current: currentPage,
                                total: totalItems,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30', '50', '100'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                            }}
                            onChange={(pagination, filters, sorter: SorterResult<Device> | SorterResult<Device>[],) => {
                                setFilters(filters)
                                setPageSize(pagination.pageSize || 10);
                                setCurrentPage(pagination.current || 1);
                                if (typeof sorter === 'object') {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setSortField(sorter?.field?.toString())
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setSortOrder(sorter?.order?.toString())
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    )

};

export default DevicesListPage;