import React, {useEffect, useState} from 'react';
import {Select} from "antd";


import {ContentsService} from "services";
import {ContentCategory} from "types";

interface ContentCategorySelectProps {
    props?: any
}

const ContentCategorySelect: React.FC<any> = (props: any) => {
    const [data, setData] = useState<ContentCategory[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        ContentsService.getAllContentsCategories()
            .then(res => {
                let response = res?.data?.contentsCategories || []
                response = response?.map((item: ContentCategory) => ({
                    key: `contents-category-${item?.id}`,
                    ...item
                }))
                if (response) {
                    setData((response))
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const options = data?.map((item: ContentCategory) => ({label: item.name, value: item.id}))
    return <>
        <Select loading={loading} options={options} allowClear {...props}/>
    </>
}

export default ContentCategorySelect