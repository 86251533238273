// custom history object to allow navigation outside react components
import {NavigateFunction} from "react-router-dom";
import {Location} from "@remix-run/router";

export const history:{
    navigate: NavigateFunction | null
    location: Location | null
} = {
    navigate: null,
    location: null
};