import React, {useEffect, useState} from "react";
import {
    Form,
    message,
    Card,
    Steps, Row, Col
} from "antd";
import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {z} from "zod";


// ---- local calls
import {CampaignsService} from "services";
import {CampaignInCreate} from "types";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {RootState} from "redux/state";
import NotificationStep from "./steps/NotificationStep";
import TargetStep from "./steps/TargetStep";


// ----- Types -----
type RouteParams = {
    campaignId?: string
}

// ----- Component -----
const CreateCampaignPage: React.FC = () => {
    // ----- Store -----
    const authenticatedUser = useSelector((state: RootState) => state?.auth?.authenticatedUser);

    // ----- Params -----
    const {campaignId} = useParams<RouteParams>();
    const [searchParams,] = useSearchParams();

    // ----- data -----
    const [campaign, setCampaign] = useState<CampaignInCreate | null>(null)
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    // ----- form -----
    const [form] = Form.useForm();
    // ----- upload image -----
    useEffect(() => {
        const encodedUrl: string = searchParams.get("url") || '';
        const url = (encodedUrl && decodeURI(encodedUrl)) || ''
        const defaultCampingValues = {
            title: searchParams.get("title") || '',
            content: searchParams.get("content") || '',
            url: url,
            urlType: searchParams.get("urlType") || '',
            category: searchParams.get("category") || 'campaign',
            campaignName: searchParams.get("campaignName") || '',
            topicsNames: [searchParams.get("topicName") || ''],
            disabledCampaignUrl: searchParams.get("disabledCampaignUrl") || false,
            silent: false,
        }
        form.setFieldsValue(defaultCampingValues)
        setCampaign(defaultCampingValues)
    }, [searchParams])


    const handleStepChange = (current: number) => {
        setCurrentStep(current);
    };

    const handleSubmit = async () => {
        console.log("submit:", campaign)
        setLoading(true);
        // Validation
        const CampaignInCreateSchema = z.object({
            title: z.string().min(3),
            content: z.string().min(3),
            icon: z.string().optional(),
            url: z.string(),
            urlType: z.string(),
            // category: z.string(),
            // data? :;
            campaignName: z.string(),
            topicsNames: z.array(z.string().min(1)),
            silent: z.boolean(),
        });
        try {
            if (!campaign) return
            // get values from the form
            CampaignInCreateSchema.parse(campaign);
            // const values: CampaignInCreate
            const campaignRequest: CampaignInCreate = {
                title: campaign?.title || '',
                content: campaign?.content || '',
                url: campaign?.url || '',
                urlType: campaign?.urlType || '',
                category: campaign?.category || 'campaign',
                // bottomSheets: campaign?.bottomSheets || false,
                // data: values?.data || false,
                campaignName: campaign?.title || '',
                topicsNames: campaign.topicsNames,
                silent: campaign?.silent || false,
                ...(campaign?.icon ? {icon: campaign?.icon} : {}) // Use the base64 image value
            };

            console.log("campaignRequest: ", campaignRequest)
            await CampaignsService.createCampaignNotification(campaignRequest);
            // Reset form fields and image state
            form.resetFields();
            // setImageBase64(null);
            // setImage(null);
            // Show success message
            message.success("Campaign created successfully");
        } catch (error) {
            // Show error message
            console.error("failed to create camping, message: ", error)
            message.error(`Failed to ${campaignId ? 'update' : 'create'} campaign`);
        }
        setLoading(false);
    };


    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const steps = [
        {
            title: 'Notification',
            description: "General information",
            content: <NotificationStep
                form={form}
                campaign={campaign}
                loading={loading}
                setCampaign={setCampaign}
                handleNext={handleNext}
                handlePrev={handlePrev}
                currentStep={currentStep}
                handleSubmit={handleSubmit}
            />
        },
        {
            title: 'Target',
            description: "Target users",
            content: <TargetStep
                form={form}
                campaign={campaign}
                loading={loading}
                setCampaign={setCampaign}
                handleNext={handleNext}
                handlePrev={handlePrev}
                currentStep={currentStep}
                handleSubmit={handleSubmit}
            />
        },
    ];


    const onStepChange = (value: number) => {
        // todo: activate this on local
        setCurrentStep(value);
    };

    return (
        <>
            <CustomBreadcrumb list={['Campaigns', 'create']}/>
            <Card title={searchParams.get("cardTitle") || "Create Campaign"}>
                <Row gutter={[16, 16]}>
                    <Col span={4}>
                        <Steps onChange={onStepChange}
                               current={currentStep}
                               items={steps.map(step => step)}
                               direction="vertical"/>
                    </Col>
                    <Col span={18}>
                        {steps[currentStep].content}
                    </Col>
                </Row>
            </Card>
        </>

    );
};

export default CreateCampaignPage;
