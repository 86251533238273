import {AxiosResponse} from "axios";

import Api from "./api";
import {PaginationResponsePage, Stimulus, StimulusLogCombinedType} from "../types";

export type GetStimulusResponse = PaginationResponsePage<Stimulus>

export interface GetStimulusFilters {
    filter_fields?: string;
    filter_values?: string;
    sort_field?: string;
    sort_order?: string;
    search?: string;
    page?: number;
    page_size?: number;
}

export const getAllStimulusByFiltersPaginated = (filters: GetStimulusFilters): Promise<AxiosResponse<GetStimulusResponse>> => {
    return Api.get("/admin/stimulus/all/", {
        params: filters
    });
};

export interface GetAllStimulusWithLogsResponse {
    stimulusList: StimulusLogCombinedType[]
}

export interface GetAllStimulusWithLogsParams {
    user_id: string;
    start_date: string;
    end_date: string;
}

export const getAllStimulusWithLogs = (params: GetAllStimulusWithLogsParams): Promise<AxiosResponse<GetAllStimulusWithLogsResponse>> => {
    return Api.get("/admin/stimulus/with-logs", {
        params: params
    });
};

export default {
    getAllStimulusByFiltersPaginated,
    getAllStimulusWithLogs
};
