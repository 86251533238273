import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Space, Switch, Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import UploadImageButton from "components/UploadImageButton";
import {HabitInCreate, Question} from "types";
import {getBase64, handleBeforeUpload} from "utils/upload";


interface HabitsQuestionsSetupProps {
    habit: HabitInCreate | null
    loading: boolean
    setHabit: (step: any) => void
    handleNext?: () => void
    handlePrev?: () => void
    currentStep?: number
    handleSubmit?: () => void
}

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {
        span: 8, offset: 0
    },
};

const addAnswerButtonLayout = {
    labelCol: {span: 4},
    wrapperCol: {
        span: 6,
        offset: 6
    }
}
const addQuestionLayout = {
    labelCol: {span: 4},
    wrapperCol: {
        span: 6,
        offset: 6
    }
}

type possibleAnswersImagesType = {
    [key: number]: {
        [nestedKey: number]: string;
    };
};

const HabitsQuestionsSetup: React.FC<HabitsQuestionsSetupProps> = (
    {
        habit,
        loading,
        setHabit,
        handleSubmit,
        handlePrev
    }
) => {
    // ----- Data -----

    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    // const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [possibleAnswersImages, setPossibleAnswersImages] = useState<possibleAnswersImagesType | null>(null);

    useEffect(() => {
        form.setFieldsValue(habit)
        // Set default value for images
        const images: possibleAnswersImagesType = {}
        habit?.questionsWithAnswers?.forEach((question, qstIndex) => {
            const {possibleAnswers} = question || {};
            possibleAnswers?.forEach((answer, ansIndex) => {
                const {icon} = answer || {};
                if (!images[qstIndex]) {
                    images[qstIndex] = {};
                }
                images[qstIndex][ansIndex] = icon;
            });
        });

        setPossibleAnswersImages(images)
        // set images if we already have it
    }, [])

    const addPossibleAnswerImage = (qstIndex: number, ansIndex: number, image: string) => {
        /**
         *
         * Question 0: Answer 0: {0: 0: 'base64...'}}
         * */
        setPossibleAnswersImages(prevState => ({
            ...prevState,
            [qstIndex]: {
                ...prevState?.[qstIndex],
                [ansIndex]: image
            }
        }))
    }

    const getPossibleAnswerImage = (qstIndex: number, ansIndex: number): string | null => {
        if (!possibleAnswersImages) return null
        return possibleAnswersImages?.[qstIndex]?.[ansIndex]
    }

    const handleFinish = () => {
        handleSave()
        handleSubmit?.()
    }

    const handleBack = () => {
        handleSave()
        handlePrev?.()
    }

    const handleSave = () => {
        // Save item in state
        const values = form.getFieldsValue()
        setHabit((currentHabit: HabitInCreate | null) => ({
            ...(currentHabit ? currentHabit : {}),
            ...values
        }))
        message.success('Item saved locally')
    }

    const handleImageChange = (questionIndex: number, answerIndex: number): UploadProps['onChange'] => (e: UploadChangeParam<UploadFile>) => {

        const file = e.file
        if (!file) return
        getBase64(file as RcFile, (img: string) => {
            // Create an object with the path to the image you want to set.
            // Get the current form data
            const currentData = form.getFieldsValue();
            // Update the relevant part immutably
            const updatedData = {
                ...currentData,
                questionsWithAnswers: currentData.questionsWithAnswers.map((question: Question, qIndex: number) =>
                    qIndex === questionIndex ? {
                        ...question,
                        possibleAnswers: question.possibleAnswers.map((answer, aIndex) =>
                            aIndex === answerIndex ? {...answer, icon: img} : answer
                        )
                    } : question
                )
            };
            form.setFieldsValue(updatedData);
            addPossibleAnswerImage(questionIndex, answerIndex, img)
            // console.log("img", img)
            // const path = {};
            // path[`questionsWithAnswers[${questionIndex}].possibleAnswers[${answerIndex}].icon`] = img;
            // console.log('handleImageChange path: ', path)
            // form.setFieldsValue(path);
        })

    };

    const initialValues = {
        hidden: false
    }

    return (
        <>
            <Form form={form}
                  onFinish={handleFinish}
                // onFieldsChange={handleFormChange}
                  initialValues={initialValues}
                  {...formLayout}
            >
                <Form.List name="questionsWithAnswers">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({
                                             key: questionKey,
                                             name: questionName,
                                             ...questionRestField
                                         }, questionIndex) => (
                                <div key={`question-${questionKey}-${questionIndex}`} className="w-100 dashed-border">
                                    <div className="d-flex justify-content-end mb-3">
                                        <Button type="default"
                                                danger
                                                onClick={() => remove(questionName)}
                                                icon={<MinusCircleOutlined/>}
                                        >
                                            Remove question
                                        </Button>
                                    </div>
                                    <Form.Item
                                        {...questionRestField}
                                        name={[questionName, 'text']}
                                        label="Text"
                                        rules={[{required: true, message: 'Please input the text!'}]}
                                    >
                                        <Input placeholder="Name"/>
                                    </Form.Item>
                                    <Form.Item
                                        {...questionRestField}
                                        label="Multiselect"
                                        name={[questionName, 'multiselect']}
                                        rules={[{required: true}]}
                                        initialValue={false}
                                        className="mb-2"
                                    >
                                        <Switch/>
                                    </Form.Item>
                                    {/*------ Answers nested Form.List ------*/}
                                    <Form.List name={[questionName, 'possibleAnswers']}>
                                        {(answerFields, {add: addAnswer, remove: removeAnswer}) => (
                                            <>
                                                {answerFields.map(({
                                                                       name: answerName,
                                                                       key: answerKey,
                                                                       ...answerRestField
                                                                   }, answerIndex) => (
                                                    <div key={`question_${questionKey}-answer_${answerIndex}`}
                                                         className="w-100 mt-3 mb-3">
                                                        <Form.Item label={"Answer " + (answerKey + 1)}>
                                                            <Space align="center"
                                                                   style={{display: "flex", marginBottom: 12}}>
                                                                <Form.Item
                                                                    noStyle
                                                                    {...answerRestField}
                                                                    name={[answerName, 'icon']}
                                                                    // label="Answer icon"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Please add answer icon!'
                                                                    }]}
                                                                >
                                                                    <ImgCrop showGrid showReset aspect={1 / 1}>
                                                                        <Upload
                                                                            key={"image-" + questionIndex + "-" + answerIndex}
                                                                            beforeUpload={handleBeforeUpload({
                                                                                config: {allowOnlyImage: true},
                                                                                // TODO: Add image to the form
                                                                                callback: (url) => {
                                                                                    //answerField
                                                                                    // console.log('beforeUpload callback', url)
                                                                                    return false
                                                                                }
                                                                            })}
                                                                            onChange={handleImageChange(questionIndex, answerIndex)}
                                                                            accept="image/*"
                                                                            showUploadList={false}
                                                                            onRemove={() => console.log('Remove')}
                                                                            listType="picture-card"
                                                                            disabled={loading}
                                                                        >
                                                                            {
                                                                                getPossibleAnswerImage(questionIndex, answerIndex) ?
                                                                                    <img
                                                                                        src={getPossibleAnswerImage(questionIndex, answerIndex) || ''}
                                                                                        alt="Preview"
                                                                                        style={{
                                                                                            maxWidth: "100%",
                                                                                            borderRadius: 7
                                                                                        }}/>
                                                                                    :
                                                                                    <UploadImageButton
                                                                                        loading={loading}/>
                                                                            }
                                                                        </Upload>
                                                                    </ImgCrop>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    noStyle
                                                                    {...answerRestField}
                                                                    name={[answerName, 'text']}
                                                                    // label="Answer text"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: 'Please input the answer!'
                                                                    }]}
                                                                >
                                                                    <Input placeholder="Answer"/>
                                                                </Form.Item>
                                                                <Form.Item noStyle>
                                                                    <Button type="default"
                                                                            danger
                                                                            onClick={() => removeAnswer(answerName)}
                                                                            icon={<MinusCircleOutlined/>}
                                                                    />
                                                                </Form.Item>
                                                            </Space>
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                                <Form.Item {...addAnswerButtonLayout}>
                                                    <Button
                                                        className="mb-2"
                                                        type="dashed" onClick={() => addAnswer()} block
                                                        icon={<PlusOutlined/>}>
                                                        Add Answer
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                    {/*------ Answers nested Form.List ------*/}
                                    {/*<Divider className="w-100"/>*/}
                                </div>
                            ))}
                            <Form.Item {...addQuestionLayout}>
                                <Button type="primary"
                                        onClick={() => add()}
                                        block icon={<PlusOutlined/>}>
                                    Add Question
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="button" onClick={handleBack}>
                            Back
                        </Button>
                        {/*<Button hidden={showSave} type="primary" htmlType="button" onClick={() => handleSave()}>
                            Save
                        </Button>*/}
                        <Button type="primary" htmlType="submit">
                            Finish
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default HabitsQuestionsSetup