import React, {useEffect, useRef, useState} from 'react';
import type {InputRef} from 'antd';
import {Button, Card, Col, Row, Space, Table, Tooltip, Typography} from 'antd';
import {EyeOutlined, ReloadOutlined} from '@ant-design/icons';
import type {ColumnsType} from 'antd/es/table';
import dayjs, {Dayjs} from "dayjs";
import {upperFirst} from "lodash";

// ----- Local calls -----
import {Stimulus, StimulusLogCombinedType} from "types";
import {StimulusService} from "services";
import {getColumnSearchProps} from "components/TableSearch";
import StimulusMetaModal from "./StimulusMetaModal";
import {addKeyToObjectArray} from "../../../../../utils/array.utils";
import StimulusRecordInfo from "./StimulusRecordInfo";
import DateRangeSelector from "../../../../../components/DateRangeSelector";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from "../../../../../helpers/dates-helper";

// ----- Types -----
interface StimulusListProps {
    userId?: string
}

// ----- Global Variables -----

// ----- Component -----
const StimulusList: React.FC<StimulusListProps> = ({userId}) => {
    // ----- Data -----
    const [data, setData] = useState<StimulusLogCombinedType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedStimulusItem, setSelectedStimulusItem] = useState<Stimulus | null>(null)

    // ----- Date Range -----
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[1]
    );

    // ----- Modal -----
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // ----- Table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----y

    useEffect(() => {
        fetchData();
    }, [selectedDateRange, userId]);

    const fetchData = async () => {
        if (!userId) return
        setLoading(true);
        const defaultStartDate = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
        const defaultEndDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
        const params = {
            user_id: userId,
            start_date: selectedDateRange?.value?.[0]?.format("YYYY-MM-DD") || defaultStartDate,
            end_date: selectedDateRange?.value?.[1]?.format("YYYY-MM-DD") || defaultEndDate,
        }
        try {
            const response = await StimulusService.getAllStimulusWithLogs(params)
            const parsedResponse = addKeyToObjectArray<StimulusLogCombinedType>(response.data.stimulusList, 'key', 'stimulus-')
            setData(parsedResponse)
        } catch (error) {
            console.error('Error parsing meta:', error);
        } finally {
            setLoading(false);
        }
    };

    const showDetails = (record: StimulusLogCombinedType) => {
        console.log('Diagnostic log', record.diagnosticLogsRecord)
        console.log('Stimulus', record.stimulus)
    }

    const columns: ColumnsType<StimulusLogCombinedType> = [
        {
            title: 'Date',
            dataIndex: 'timestamp',
            key: 'timestamp',
            defaultSortOrder: 'descend',
            render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
            sorter: (a: StimulusLogCombinedType, b: StimulusLogCombinedType) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a: StimulusLogCombinedType, b: StimulusLogCombinedType) => a.type.localeCompare(b.type),
            render: (value) => upperFirst(value),
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'type'
            }),
        },
        {
            title: 'Level',
            dataIndex: 'value',
            key: 'value',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'value'
            }),
            render: (value) => <Typography.Text>{value}%</Typography.Text>
        },
        {
            title: 'Info',
            dataIndex: 'diagnosticLogsRecord',
            key: 'diagnosticLogsRecord',
            render: (value, record: StimulusLogCombinedType) => <StimulusRecordInfo record={record}
                                                                                    showStimulusItem={showStimulusItem}/>,
        },
        {
            title: 'Console log',
            dataIndex: 'action',
            key: 'action',
            render: (_: object, record: StimulusLogCombinedType) => {
                return (
                    <Tooltip title={"Log data"}>
                        <Button
                            onClick={() => showDetails(record)}
                            icon={<EyeOutlined/>}
                        />
                    </Tooltip>
                )
            }
        }
    ];

    // ----- Modal -----
    const showStimulusItem = (stimulis: Stimulus) => {
        // show json on the model
        setSelectedStimulusItem(stimulis);
        setIsModalOpen(true);
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setSelectedStimulusItem(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedStimulusItem(null);
    };

    // ----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };

    return (
        <>
            <Card title="Stimulus List"
                  extra={
                      <Space>
                          <Button
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                      </Space>
                  }>
                <Row gutter={[16, 16]} justify="end">
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <DateRangeSelector defaultDateRange={selectedDateRange}
                                                   onDateRangeChange={handleDateRangeChange}/>
                                <div style={{margin: '10px 0'}}>
                                    <Typography.Text strong>
                                        Start Date:
                                    </Typography.Text>
                                    <Typography.Text>
                                        {selectedDateRange?.value?.[0]?.format("YYYY-MM-DD") || 'Not set'}
                                    </Typography.Text>
                                    <br/>
                                    <Typography.Text strong>
                                        End Date:
                                    </Typography.Text>
                                    <Typography.Text>
                                        {selectedDateRange?.value?.[1]?.format("YYYY-MM-DD") || 'Not set'}
                                    </Typography.Text>
                                </div>
                            </Col>
                            <Col span={24}>

                                <Table<StimulusLogCombinedType>
                                    columns={columns}
                                    dataSource={data}
                                    loading={loading}
                                    size="small"
                                    bordered
                                    pagination={{
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '30', '50', '100', '400'],
                                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                                    }}
                                    scroll={{x: 1000}}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <StimulusMetaModal
                meta={selectedStimulusItem?.meta}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}/>
        </>
    );
};

export default StimulusList;
