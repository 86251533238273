import React from "react";
import {Empty, Spin} from "antd";

import IPhoneSVG from 'assets/images/iphone.svg'
// import { ReactComponent as IPhoneSVG } from './phone.svg';

import './style.css'

interface PhonePreviewLayoutProps {
    children?: React.ReactNode;
    loading: boolean
}

const PhonePreviewLayout: React.FC<PhonePreviewLayoutProps> = ({children, loading}) => {
    return (
        <div className="phone-container">
            <img src={IPhoneSVG} alt="Phone" className="phone-svg"/>
            <div className="phone-content">
                <Spin spinning={loading} wrapperClassName="h-100" >
                    {
                        !children && <div className="mt-6"><Empty description="No content"/></div>
                    }
                    {children}
                </Spin>
            </div>
        </div>
    )
}

export default PhonePreviewLayout