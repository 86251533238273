import {Result, Button} from 'antd';

import {history} from "utils/history";

const PageNotFound = () => {
    const {navigate} = history

    const handleBackHome = () => {
        navigate?.("/");
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={handleBackHome}>Back Home</Button>}
        />
    );
}

export default PageNotFound;