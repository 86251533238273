import React, {useEffect, useState} from "react";
import {List, notification, Spin} from "antd";

// ----- Local calls -----
import {AiChatMessage, AiChatMessageRole, AiChatResponse, AiChatThread, Content, StreamEventTypeEnum} from "types";
import Message from "./components/Message";
import {getAllMessagesByThreadId} from "services/ai-chat";
import {generateRandomId} from "utils/number";
import {history} from 'utils/history';
import ToolLoading from "./components/ToolLoading";
import Articles from "./components/Articles";
import {AiChatService} from "services";
import BackgroundImage from 'assets/images/ai-chat.png';
import AiChatInput from "./components/AiChatInput";
import "./style.css"
import {isAiChatThread} from "utils/objects";

// ----- Global variables -----

// ----- Types -----
interface AiChatMessagesProps {
    userId: number
    threads: AiChatThread[]
    setThreads: (value: AiChatThread[] | ((value: AiChatThread[]) => AiChatThread[])) => void;
    thread: AiChatThread | null
    activeThreadId: number | null
    setActiveThreadId: (value: (number | null)) => void;
}


// ----- Components -----
const AiChatMessages: React.FC<AiChatMessagesProps> = (
    {
        activeThreadId,
        thread,
        setThreads,
        setActiveThreadId,
    }) => {
    // ----- Data -----
    const [messages, setMessages] = useState<AiChatMessage[]>([]);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [articles, setArticles] = useState<Content[] | null>(null)
    const [activeEvent, setActiveEvent] = useState<AiChatResponse | null>(null)

    // ----- States -----
    const [loading, setLoading] = useState<boolean>(false);
    const [assistantIsTyping, setAssistantIsTyping] = useState<boolean>(false); // Assistant is typing
    // ----- Text message -----
    // ----- Audio message -----
    // ----- References -----

    // ----- Effects -----
    useEffect(() => {
        if (!history || !history.location) return;
        const query = new URLSearchParams(history.location.search);
        const threadIdParam = query.get('threadId');

        if (!threadIdParam) return; // Case of new thread
        fetchThread(Number(threadIdParam));
    }, [history]); // Dependency array includes location to trigger effect when URL changes

    // If we have thread set it
    useEffect(() => {
        // When  we load the component we call get thread
        if (thread) return; // If thread is already set we don't need to call get thread
        if (!activeThreadId) return; // If threadId is not set we don't need to call get thread
        handleNewThread()
        fetchThread(Number(activeThreadId));
    }, [activeThreadId])

    useEffect(() => {
        // threadId is set when we create a new thread
        console.log("threadId", thread)
        if (!thread) {
            console.log("New thread")
            handleNewThread()
            return
        }
        // Clear current thread data
        handleNewThread()
        // Get messages.
        fetchMessages();
        return () => {
            handleNewThread()
        }
    }, [thread])

    // ----- Fetchers -----
    const fetchMessages = async () => {
        if (!thread || !thread.id) return;

        setLoading(true);
        try {
            const response = await getAllMessagesByThreadId(thread.id);
            const filteredMessages = response.data.messages?.filter((item: AiChatMessage) => {
                return item.role === 'user' || (item.role === 'assistant' && !!item.content)
            })
            setMessages(filteredMessages);
        } catch (err) {
            // setError("Failed to fetch threads");
            notification.error({message: "Failed to fetch threads"})
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const fetchThread = async (threadId: number) => {
        if (!threadId) return;
        setLoading(true);
        try {
            const response = await AiChatService.getThreadById(threadId);
            if (!response || response.status !== 200 || !response.data.thread) {
                // setError("Failed to fetch thread");
                notification.error({message: "Failed to get thread"})
                return;
            }
            // Add the new thread to threads list
            setThreads((currentThreads) => [...currentThreads, response.data.thread]);
            // if (onThreadChanged) onThreadChanged(response.data);
        } catch (err) {
            // setError("Failed to fetch thread");
            notification.error({message: "Failed to get thread"})
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // ----- Handlers -----
    // Clean messages and other data when we create a new thread
    const handleNewThread = () => {
        setMessages([])
        setActiveEvent(null)
        setArticles(null)
        setSuggestions([])
    }

    const handleSendTextMessage = async () => {
        setSuggestions([])
        setActiveEvent(null)
        setArticles(null)
    };

    // ----- Response Handlers -----
    const handleAiChatResponse = (response: AiChatResponse) => {
        const event = response.event
        const content = response.data
        switch (event) {
            case StreamEventTypeEnum.REPLY:
                handleReplyEvent(content);
                break;
            case StreamEventTypeEnum.SUGGESTIONS:
                setSuggestions(prev => {
                    const uniqueSuggestions = new Set([...prev, content])
                    return Array.from(uniqueSuggestions)
                })
                break;
            case StreamEventTypeEnum.TOOL_CALL:
                setActiveEvent(response)
                break;
            case StreamEventTypeEnum.THREAD:
                if (isAiChatThread(response.data)) {
                    // Add the new thread to threads list
                    setThreads((currentThreads) => [...currentThreads, response.data]);
                    setActiveThreadId(response.data.id)
                } else {
                    console.warn("Invalid thread object", response.data)
                }
                break;
            case StreamEventTypeEnum.TOOL_CALL_RESPONSE:
                // todo: Add type for object
                if (response?.function_name && response?.data) {
                    handleToolCallResponseEvent(response)
                }
                break;
            default:
                console.log("Unknown event: ", event)
                break;
        }
    }

    const handleReplyEvent = (content: string) => {
        setMessages(prevMessages => {
            // Check if the last message is from the assistant
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.role === AiChatMessageRole.ASSISTANT) {
                // Update the last assistant message
                return prevMessages.map((msg, idx) =>
                    idx === prevMessages.length - 1 ?
                        {...msg, content: msg.content + '' + content}
                        :
                        msg
                );
            } else {
                // Add a new assistant message
                const newAssistantMessage: AiChatMessage = {
                    id: generateRandomId(),
                    role: AiChatMessageRole.ASSISTANT,
                    content: content,
                    hidden: false,
                    threadId: activeThreadId || generateRandomId(),
                };
                return [...prevMessages, newAssistantMessage];
            }
        });
    }

    const handleToolCallResponseEvent = (obj: AiChatResponse) => {
        console.log("handleToolCallResponse, function_name:", obj.function_name)
        console.log("handleToolCallResponse, Data:", obj.data)

        const parsedData = JSON.parse(obj.data);

        switch (obj.function_name) {
            case "get_articles_by_category":
                console.log("Render articles", parsedData.map((item: any, idx: number) => ({
                    id: idx,
                    title: item.article_title,
                    link: item.article_url,
                    image: item.article_image_url
                })))
                if (parsedData) {

                    setArticles(
                        parsedData.map((item: any, idx: number) => ({
                            id: idx,
                            title: item.article_title,
                            link: item.article_url,
                            image: item.article_image_url
                        }))
                    );
                }
                break;
            default:
                console.log("Unknown function name?")
                break;
        }
    }

    return (
        <>
            <Spin spinning={loading}>
                <div>
                    <div style={{padding: '10px 0', fontWeight: 'bold', borderBottom: '1px solid #f0f0f0'}}>
                        Thread - {thread?.title || "New thread"}
                    </div>
                    <div className="chatContainer" style={{backgroundImage: `url(${BackgroundImage})`}}>
                        <div className="messageContainer">
                            <List<AiChatMessage>
                                dataSource={messages}
                                renderItem={(message: AiChatMessage) => (
                                    <List.Item style={{
                                        padding: '5px 0',
                                        flexDirection: 'row',
                                        borderBottom: 'none'
                                    }}>
                                        <Message message={message}/>
                                    </List.Item>
                                )}
                                locale={{emptyText: "No messages yet"}}
                            />
                        </div>

                        {
                            // todo: render plugins
                            activeEvent && <ToolLoading eventResponse={activeEvent}/>
                        }
                        <Articles articles={articles}/>

                        <AiChatInput
                            threadId={thread?.id || null}
                            handleSendTextMessage={handleSendTextMessage}
                            handleMessageResponse={handleAiChatResponse}
                            setMessages={setMessages}
                            assistantIsTyping={assistantIsTyping}
                            setAssistantIsTyping={setAssistantIsTyping}
                            suggestions={suggestions}
                            setSuggestions={setSuggestions}
                            handleNewThread={handleNewThread}
                        />
                    </div>
                </div>
            </Spin>

        </>
    )
}

export default AiChatMessages
