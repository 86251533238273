import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, message, Space, Switch, Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import ImgCrop from "antd-img-crop";

import {HabitInCreate} from "types";
import {getBase64, handleBeforeUpload} from "utils/upload";
import UploadImageButton from "components/UploadImageButton";


const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};

interface HabitGoalSetupProps {
    habit: HabitInCreate | null
    loading: boolean
    setHabit: (step: any) => void
    handleNext?: () => void
    handlePrev?: () => void
    currentStep?: number
}

const HabitGoalSetup: React.FC<HabitGoalSetupProps> = ({habit, loading, setHabit, handleNext, handlePrev}) => {
    // ----- Data -----

    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    // todo: check if we can delete imageBase64 and keep only image.
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);


    useEffect(() => {
        form.setFieldsValue(habit)
        // set the default value for icon image
        const icon = habit?.iconGoal || null
        setImage(icon)
    }, [habit])

    const handleSave = () => {
        const values = form.getFieldsValue()
        setHabit((currentHabit: HabitInCreate | null) => ({
            ...(currentHabit ? currentHabit : {}),
            ...values
        }))
        message.success('Item saved locally')
    }

    const handleFinish = () => {
        handleSave()
        handleNext?.()
    }

    const handleBack = () => {
        handleSave()
        handlePrev?.()
    }


    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam<UploadFile>) => {
        console.log('image changed', e)
        const file = e.file
        if (!file) return

        getBase64(file as RcFile, (img: string) => {
            form.setFieldValue('iconGoal', img)
        })
    };

    const initialValues = {
        hasDailyRecords: false
    }

    return (
        <>
            <Form form={form}
                  onFinish={handleFinish}
                  initialValues={initialValues}
                  {...formLayout}>
                <Form.Item name="iconGoal" label="Icon Goal" rules={[{required: true}]}>
                    <ImgCrop showGrid showReset aspect={1 / 1}>
                        <Upload
                            beforeUpload={handleBeforeUpload({
                                config: {allowOnlyImage: true},
                                callback: setImageBase64
                            })}
                            onChange={handleImageChange}
                            accept="image/*"
                            showUploadList={false}
                            onRemove={() => console.log('Remove')}
                            listType="picture-card"
                            disabled={loading}
                        >
                            {
                                (imageBase64 || image) ?
                                    <img src={imageBase64 || image || ''}
                                         alt="Preview"
                                         style={{maxWidth: "100%", borderRadius: 7}}/> :
                                    <UploadImageButton loading={loading}/>
                            }
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    name="unit"
                    label="Unit"
                    rules={[{required: true}]}
                >
                    <Input placeholder="Unit"/>
                </Form.Item>
                <Form.Item
                    name="goalDefaultValue"
                    label="Goal default value"
                    rules={[{required: true}]}
                >
                    <InputNumber className="w-50" placeholder="Goal Default Value" min={0}/>
                </Form.Item>
                <Form.Item
                    name="goalValueIncrements"
                    label="Goal value increments"
                    rules={[{required: true}]}
                >
                    <InputNumber className="w-50" placeholder="Goal Default Value" min={0}/>
                </Form.Item>
                {
                    habit?.type === "Positive" ?
                        <Form.Item
                            name="hasDailyRecords"
                            label="Has daily records"
                            rules={[{required: true}]}
                            valuePropName="checked"
                        >
                            <Switch/>
                        </Form.Item>
                        : null
                }
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="button" onClick={handleBack}>
                            Back
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default HabitGoalSetup