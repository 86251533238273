import {AxiosResponse} from "axios";

import Api from "./api";
import {UserHabit} from "../types";

interface GetUserHabitsParams {
    userId: number;
}

interface GetUserHabitsResponse {
    userHabits: UserHabit[]
}

export const getUserHabits = (params: GetUserHabitsParams): Promise<AxiosResponse<GetUserHabitsResponse>> => {
    return Api.get(
        '/admin/user-habits/',
        {
            params: {
                user_id: params.userId,
            }
        }
    )
}

export default {
    getUserHabits,
}
