import {AxiosResponse} from "axios";
import Api from "./api";
import {Firmware} from "types"; // Replace with actual Firmware type

interface GetFirmwareByIdResponse {
    firmware: Firmware;
}

export interface FirmwareInCreate {
    versionString: string;
    body: string;
    test: boolean;
    archived: boolean;
    file: string | null;
    hardwareIds: number[];
    minimalAppVersion?: string;
}

export interface FirmwareInUpdate extends FirmwareInCreate {
    firmwareId: number;
}

export interface CreateFirmwareRequest {
    firmware: FirmwareInCreate;
}

export interface GetAllFirmwaresResponse {
    firmwares: Firmware[]
}

interface UpdateFirmwareRequest {
    firmware: FirmwareInUpdate;
}

export const getAllFirmwares = (): Promise<AxiosResponse<GetAllFirmwaresResponse>> => {
    return Api.get("/admin/firmwares/");
}

export const createFirmware = (firmware: FirmwareInCreate): Promise<AxiosResponse<GetFirmwareByIdResponse>> => {
    const request: CreateFirmwareRequest = {
        firmware: firmware,
    };

    return Api.post("/admin/firmwares/", request);
}

export const updateFirmware = (firmware: FirmwareInUpdate): Promise<AxiosResponse<GetFirmwareByIdResponse>> => {
    const request: UpdateFirmwareRequest = {
        firmware: firmware,
    };

    return Api.put(`/admin/firmwares/${firmware.firmwareId}`, request);
}

export const getFirmwareById = (firmwareId: number): Promise<AxiosResponse<GetFirmwareByIdResponse>> => {
    return Api.get(`/admin/firmwares/${firmwareId}`);
}

export const deleteFirmware = (firmwareId: number): Promise<AxiosResponse<unknown>> => {
    return Api.delete(`/admin/firmwares/${firmwareId}`);
}

export default {
    getAllFirmwares,
    createFirmware,
    updateFirmware,
    getFirmwareById,
    deleteFirmware
};
