import React, {useEffect, useState} from "react";
import {Card, Col, message, Row, Tooltip, Button} from "antd";
import {ClockCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {Dayjs} from "dayjs";
import moment from "moment";
import {max} from "lodash";


// ----- Local calls -----
import {AnalyticsService} from "services";
import FeaturesUsageOverTime from "./FeaturesUsageOverTime";
import HabitFeatureUsageOverTime from "./HabitFeatureUsageOverTime";
import PokeFeatureUsageOverTime from "./PokeFeatureUsageOverTime";
import AlarmsFeatureUsageOverTime from "./AlarmsFeatureUsageOverTime";
import SleepTrackingFeatureUsageOverTime from "./SleepTrackingFeatureUsageOverTime";
import StepsFeatureUsageOverTime from "./StepsFeatureUsageOverTime";
import TimersFeatureUsageOverTime from "./TimersFeatureUsageOverTime";
import IntegrationsFeatureUsageOverTime from "./IntegrationsFeatureUsageOverTime";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS, DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from "../../../../helpers/dates-helper";
import DateRangeSelector from "../../../../components/DateRangeSelector";
import {GetAnalyticFeatureUsageRequest} from "../../../../services/analytics";
import {FeatureUsageData} from "../../../../types";

// ----- Types -----
interface GAMetricType {
    date: string
    activeUsers: string
    active7DayUsers: string
}

// ----- Component -----
const FeaturesUsageMetrics: React.FC = () => {
    // ----- Data -----
    const [dailyData, setDailyData] = useState<FeatureUsageData[] | null>(null)
    const [originalDailyData, SetOriginalDailyData] = useState<FeatureUsageData[] | null>(null)
    const [weeklyData, setWeeklyData] = useState<FeatureUsageData[] | null>(null)
    const [originalWeeklyData, setOriginalWeeklyData] = useState<FeatureUsageData[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    // ----- Filters -----
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[3]
    );
    const dateRange = selectedDateRange.value;

    // Used to show last updated time
    const [dailyLastUpdated, setDailyLastUpdated] = useState<Date | null>(null);
    const [weeklyLastUpdated, setWeeklyLastUpdated] = useState<Date | null>(null);

    // ----- Effects -----
    // useEffect that listens for changes in date range
    useEffect(() => {
        // Ensure dateRange is defined and is an array before calling filterData
        if (dateRange && Array.isArray(dateRange)) {
            filterData(dateRange, "daily");
            filterData(dateRange, "weekly");
        }
    }, [dateRange]);

    // useEffect that listens for changes in originalDailyData
    useEffect(() => {
        if (originalDailyData !== null) {
            filterData(dateRange, "daily")
        }
    }, [originalDailyData]);

    // useEffect that listens for changes in originalWeeklyData
    useEffect(() => {
        if (originalWeeklyData !== null) {
            filterData(dateRange, "weekly")
        }
    }, [originalWeeklyData]);

    useEffect(() => {
        fetchAll()
    }, [selectedDateRange]);

    // ----- Fetch Data -----
    const fetchAll = () => {
        Promise.all([fetchData("daily"), fetchData("weekly")])
    }

    const fetchData = async (usagePeriod: "daily" | "weekly" = "daily") => {
        setLoading(true)

        try {
            // Call V5 Analytics
            const request: GetAnalyticFeatureUsageRequest = {
                usagePeriod: usagePeriod
            }
            const analyticsResponse = await AnalyticsService.getAnalyticFeatureUsage(request)
            const featureUsageResponseData: FeatureUsageData[] = analyticsResponse.data.data

            // Call Google Analytics
            let activeUsersPeriod = usagePeriod === "daily" ? "activeUsers" : "active7DayUsers"
            const dateRange = selectedDateRange.value;
            const requestGA = {
                dimensions: ["date"],
                metrics: [activeUsersPeriod],
                start_date: dateRange?.[0]?.format("YYYY-MM-DD"),
                end_date: dateRange?.[1]?.format("YYYY-MM-DD"),
            }
            const googleAnalyticsResponse = await AnalyticsService.getGoogleAnalytics<GAMetricType>(requestGA)
            const activeUsersResponseData: GAMetricType[] = googleAnalyticsResponse.data

            // Merge the data
            featureUsageResponseData.forEach((featureUsage: FeatureUsageData) => {
                // Find the active users data for the same date
                const activeUsersData: GAMetricType | undefined = activeUsersResponseData.find(activeUser => activeUser.date === moment(featureUsage.date).format("YYYYMMDD"));
                if (activeUsersData) {
                    if ('activeUsers' in activeUsersData) {
                        // Find the max of the active users
                        featureUsage.allActiveUsersCount = String(max([
                            Number(activeUsersData.activeUsers),
                            Number(featureUsage.allActiveUsersCount)
                        ]));
                        featureUsage.appUsersCount = activeUsersData.activeUsers
                    } else if ('active7DayUsers' in activeUsersData) {
                        featureUsage.allActiveUsersCount = String(max([
                            Number((activeUsersData as any).active7DayUsers),
                            Number(featureUsage.allActiveUsersCount)
                        ]));
                        featureUsage.appUsersCount = (activeUsersData as GAMetricType)?.active7DayUsers
                    }
                }
            });
            if (usagePeriod == "daily") {
                SetOriginalDailyData(featureUsageResponseData)
                if (featureUsageResponseData.length > 0) {
                    const lastUpdatedTime = featureUsageResponseData[featureUsageResponseData.length - 1].updatedAt;
                    setDailyLastUpdated(new Date(lastUpdatedTime));
                }
            } else {
                activeUsersPeriod = "active7DayUsers"
                setOriginalWeeklyData(featureUsageResponseData)
                if (featureUsageResponseData.length > 0) {
                    const lastUpdatedTime = featureUsageResponseData[featureUsageResponseData.length - 1].updatedAt;
                    setWeeklyLastUpdated(new Date(lastUpdatedTime));
                }
            }

        } catch (error) {
            console.error("Error fetching dailyData, message: ", error)
            message.error("Error fetching dailyData")
        } finally {
            setLoading(false)
        }


        // todo: Move this code outside.
    }

    //  filter data based on the date range
    const filterData = (range: [Dayjs | null, Dayjs | null] | null, usagePeriod: "daily" | "weekly" = "daily") => {
        if (!range || range.includes(null)) {
            // Handle the case where range is null or contains null values
            // This could mean resetting the filtered data to its original state or handling it differently
            if (usagePeriod === "daily") {
                setDailyData(originalDailyData || null);
            } else {
                setWeeklyData(originalWeeklyData || null);
            }
            return;
        }

        // Proceed with filtering assuming both range values are not null
        const startDate = range[0]!.valueOf(); // The '!' non-null assertion operator tells TypeScript that we've already handled the null case
        const endDate = range[1]!.valueOf();

        const filterCondition = (item: FeatureUsageData) => {
            const itemDate = moment(item.date, 'YYYY-MM-DD').valueOf();
            return itemDate >= startDate && itemDate <= endDate;
        };

        if (usagePeriod === "daily") {
            const filteredData = originalDailyData?.filter(filterCondition);
            setDailyData(filteredData || null);
        } else {
            const filteredData = originalWeeklyData?.filter(filterCondition);
            setWeeklyData(filteredData || null);
        }

    }


    //----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };

    return (
        <Card title="Features Usage"
              loading={loading}
              extra={
                  <>
                      <Tooltip
                          title={(dailyLastUpdated ? `Daily data updated ${moment(dailyLastUpdated).fromNow()}` : 'Never') + '\n' + (weeklyLastUpdated ? `Weekly data updated ${moment(weeklyLastUpdated).fromNow()}` : 'Never')}>
                          <ClockCircleOutlined style={{marginRight: 10}}/>
                      </Tooltip>


                      <DateRangeSelector defaultDateRange={selectedDateRange}
                                         onDateRangeChange={handleDateRangeChange}/>

                      <Button
                          size="middle"
                          loading={loading}
                          onClick={fetchAll}
                          icon={<ReloadOutlined/>}
                          style={{marginRight: 8}}
                      />

                  </>
              }>
            <Row gutter={[16, 48]}>
                <Col span={24}>
                    <FeaturesUsageOverTime dailyData={dailyData} weeklyData={weeklyData} loading={loading}
                                           fetchData={fetchAll}/>
                </Col>

                <Col span={12}>
                    <AlarmsFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={12}>
                    <SleepTrackingFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={12}>
                    <StepsFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={12}>
                    <TimersFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>

                <Col span={12}>
                    <HabitFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={12}>
                    <PokeFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={12}>
                    <IntegrationsFeatureUsageOverTime
                        dailyData={dailyData}
                        weeklyData={weeklyData}
                        loading={loading}
                        fetchData={fetchAll}/>
                </Col>
                <Col span={24}>
                </Col>
            </Row>
        </Card>
    )
}

export default FeaturesUsageMetrics