import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Card, Table} from 'antd';
import {ReloadOutlined, UserOutlined} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';
import {Link} from "react-router-dom";

import {BaseModel, Friend} from "../../types";
import {getColumnSearchProps} from "../TableSearch";
import {getUserFriends} from "../../services/friends";


type DataIndex = keyof BaseModel | string;

interface FriendsListProps {
    userId?: string
}

const FriendsList: React.FC<FriendsListProps> = ({userId}) => {
    const [friends, setFriends] = useState<Friend[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        getUserFriends({
            userId
        })
            .then(res => {
                // console.log('get data:', res)
                const users = res?.data?.users
                if (!users) {
                    throw Error('users not found')
                    return
                }
                const total = users?.length
                const friendsList = users?.map((item: Friend, index: number) => ({...item, key: `key-${index}`}))
                setFriends(friendsList || [])
                setTotalItems(total);

            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const columns: ColumnsType<Friend> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (_: string | null, record: Friend) =>
                <div className="container-center">
                    {record?.profilePictureUrl ?
                        <Avatar src={record?.profilePictureUrl}/> :
                        // <Avatar style={{backgroundColor: '#1890ff'}}>{user?.email?.at(0) || ''}</Avatar>,
                        <Avatar style={{backgroundColor: '#1677ff'}} icon={<UserOutlined/>}></Avatar>}
                </div>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'email'
            }),
        },
        {
            title: 'Firstname',
            dataIndex: 'firstName',
            key: 'firstName',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'firstName'
            }),
        },
        {
            title: 'Lastname',
            dataIndex: 'lastName',
            key: 'lastName',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'firstName'
            }),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_: number, record: Friend) => (
                <>
                    <Link type="primary" to={`/users/${record.id}`}>Detail</Link>
                </>
            )
        }
    ];

    return (
        <Card title="Friends list"
              extra={
                  <Button 
                          loading={loading}
                          onClick={() => fetchData()}
                          icon={<ReloadOutlined/>}/>
              }
        >
            <Table<Friend>
                columns={columns}
                dataSource={friends}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    total: totalItems,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                }}
                onChange={(pagination) => {
                    setPageSize(pagination.pageSize || 10);
                    setCurrentPage(pagination.current || 1);
                }}
                scroll={{x: 400}}
            />
        </Card>
    )

};

export default FriendsList;