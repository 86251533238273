import {exp} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

export function getLatestValueBasedOnKey<T extends Record<string, any>>(items: T[], key: string): T | undefined {
    // For a given array of items return the latest value based on a key element
    if (items.length === 0) return undefined; // Return undefined if the array is empty
    return items.reduce((latest: T, item: T) => {
        if (!latest) return item; // In case the first item is the only item

        const latestValue = latest[key];
        const currentValue = item[key];

        // Determine the type of the values we're comparing
        if (typeof latestValue === 'number' && typeof currentValue === 'number') {
            // Compare numbers
            return currentValue > latestValue ? item : latest;
        } else if (latestValue instanceof Date && currentValue instanceof Date) {
            // Compare dates
            return currentValue > latestValue ? item : latest;
        } else if (typeof latestValue === 'string' && typeof currentValue === 'string') {
            // Compare strings (e.g., for lexicographical comparison)
            return currentValue > latestValue ? item : latest;
        } else {
            // If the type is not supported or the types do not match, do not change the current latest
            console.warn(`Unsupported or inconsistent types for comparison on key '${key}'.`);
            return latest;
        }
    }, items[0]); // Initialize with the first item in the array
}

// Add key to array of objects
export function addKeyToObjectArray<T extends Record<string, any>>(items: T[], key: string, prefix?: string): T[] {
    return items.map((item, index) => ({...item, [key]: `${prefix || ''}-${index}`}));
}