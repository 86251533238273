import React, {useEffect, useState} from "react";
import {Button, Col, message, Row, Space, Spin, Typography, Tooltip, Select, Card} from "antd";
import {ClockCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {
    Chart,
    LineElement,
    PointElement,
    LinearScale,
    Title as ChartTitle,
    CategoryScale,
    BarElement,
    Legend,
    TimeSeriesScale,
} from 'chart.js';
import dayjs, {Dayjs} from "dayjs";
import {Line} from "react-chartjs-2";
import type {ChartOptions} from "chart.js/dist/types";


// ----- Local calls -----
import {AnalyticsService} from "../../../../services";
import {weekendDatesHighlighter} from "utils/graphweekends";
import {
    DATE_RANGE_FILTER_SELECTOR_OPTIONS,
    DateRangeFilterSelectorOption,
    DateRangeFilterSelectorOptionsLabel
} from "../../../../helpers/dates-helper";
import DateRangeSelector from "../../../../components/DateRangeSelector";
import {getAtndColor} from "../../../../utils/colors";

// ----- Plugins -----
Chart.register(LineElement, BarElement, PointElement, LinearScale, CategoryScale, ChartTitle, TimeSeriesScale, Legend);

// ----- Constants -----
const {Title} = Typography

// ----- Types -----
type AppVersionsMetricsProps = any

interface MetricType {
    appVersion: string
    date: string
    activeUsers: number
    adoptionRate?: number // only for second chart
}

// ----- Component -----
const AppVersionsMetrics: React.FC<AppVersionsMetricsProps> = () => {
    // todo: refactor this page
    // ----- State -----
    const [data, setData] = useState<MetricType[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [showPercentage, setShowPercentage] = useState(true);

    // ----- Filters -----
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterSelectorOption>(
        DATE_RANGE_FILTER_SELECTOR_OPTIONS[1]
    );

    // ----- Effects -----
    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        fetchData()
    }, [selectedDateRange])

    // ----- Fetchers -----
    const fetchData = () => {
        setLoading(true)
        const request = {
            dimensions: ["appVersion", "date"],
            metrics: ["activeUsers"],
            start_date: selectedDateRange?.value?.[0]?.format("YYYY-MM-DD"),
            end_date: selectedDateRange?.value?.[1]?.format("YYYY-MM-DD"),
        }
        AnalyticsService.getGoogleAnalytics<MetricType>(request)
            .then(res => {
                // console.log(res)
                setData(res.data)
            })
            .catch(err => {
                console.error("Error fetching data, message: ", err)
                message.error("Error fetching data")
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const formatDate = (dd: string) => {
        return dayjs(dd, "YYYYMMDD").format("YYYY-MM-DD")
    }


    const calculateTotalUsersPerDay = (items: MetricType[]) => {
        const totalsPerDay: {
            [key: string]: number
        } = {};
        items.forEach((item: MetricType) => {
            totalsPerDay[formatDate(item.date)] = (totalsPerDay[formatDate(item.date)] || 0) + Number(item.activeUsers);
        });

        return totalsPerDay;
    };


    const filterData = (rawData: MetricType[]) => {
        const totalUsersPerDay = calculateTotalUsersPerDay(rawData);
        // Determine the latest date in the dataset
        const latestDate = rawData.reduce((latest, item) => {
            return latest > item.date ? latest : item.date;
        }, "0");

        // Find top 10 app versions based on the latest date
        const top10AppVersions = rawData
            .filter(item => item.date === latestDate)
            .sort((a, b) => b.activeUsers - a.activeUsers)
            .slice(0, 10)
            .map(item => item.appVersion);

        //  filter by top 10
        const filteredData = rawData.map(item => {
            return {...item, adoptionRate: 0};
        })
            .filter(item => top10AppVersions.includes(item.appVersion))
            .sort((a, b) => a.date.localeCompare(b.date));

        // Annotate each item with its adoption rate for the day
        filteredData.forEach(item => {
            item.adoptionRate = Number(item.activeUsers) / totalUsersPerDay[formatDate(item.date)];

        });

        return filteredData;
    };

    // ----- Handlers -----
    const handleDateRangeChange = (label: string, dates: [Dayjs | null, Dayjs | null]) => {
        setSelectedDateRange({label: label as DateRangeFilterSelectorOptionsLabel, value: dates});
    };


    const usersByAppVersionsOverTimeData = data ? filterData(data) : []
    const uniqueDates = Array.from(new Set(usersByAppVersionsOverTimeData?.map(d => formatDate(d.date))));

    // Group Data by App Version
    const groupedData = usersByAppVersionsOverTimeData.reduce((acc, curr) => {
        const version = curr.appVersion;
        if (!acc[version]) {
            acc[version] = [];
        }
        acc[version].push({
            date: curr.date,
            metric: showPercentage ? curr.adoptionRate : curr.activeUsers
        });
        return acc;
    }, {} as { [key: string]: { date: string, metric: number }[] });

    // Step 1: Calculate Last Day Values
    const lastDay = uniqueDates[uniqueDates.length - 1]; // Assuming 'uniqueDates' is sorted

    const lastDayValues = usersByAppVersionsOverTimeData
        .filter(data => formatDate(data.date) === lastDay)
        .reduce((acc, data) => {
            acc[data.appVersion] = showPercentage ? data.adoptionRate : data.activeUsers;
            return acc;
        }, {} as { [key: string]: number });

    // Step 2: Sort App Versions Based on Last Day Values
    const sortedAppVersions = Object.keys(lastDayValues).sort((a, b) => lastDayValues[b] - lastDayValues[a]);

    // Step 3: Create Sorted Chart Datasets
    const sortedDatasets = sortedAppVersions.map((version, index) => {
        return {
            label: version,
            data: groupedData[version].map(d => ({x: formatDate(d.date), y: d.metric})),
            fill: false,
            borderColor: getAtndColor(index, 3),
            backgroundColor: '#fff',
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 5
        };
    });


    const chartData = {
        labels: uniqueDates || [],
        datasets: sortedDatasets
    };

    const chartOptions: ChartOptions<any> = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd MMM yyyy',
                    displayFormats: {
                        day: 'dd MMM'
                    }
                },
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: any) {
                        return showPercentage ? `${value * 100}%` : value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true, // Use the point style instead of the default box
                    pointStyle: 'circle', // Set the point style to circle
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += showPercentage ? `${100 * context.parsed.y.toFixed(2)}%` : context.parsed.y;
                        }
                        return label;
                    }
                }
            }
        },
        tension: 0.4,
    };

    return (
        <div>
            <Card title="App versions Adoption"
                  loading={loading}
                  extra={
                      <>
                          <Tooltip title="Updated Now">
                              <ClockCircleOutlined style={{marginRight: 10}}/>
                          </Tooltip>


                          <Select
                              defaultValue={showPercentage ? "Rate" : "Count"}
                              style={{width: 100, marginRight: 8}}
                              onChange={(value) => setShowPercentage(value === "Rate")}
                          >
                              <Select.Option value="Count">Count</Select.Option>
                              <Select.Option value="Rate">Rate %</Select.Option>
                          </Select>

                          <DateRangeSelector defaultDateRange={selectedDateRange}
                                             onDateRangeChange={handleDateRangeChange}/>

                          <Button
                              size="middle"
                              loading={loading}
                              onClick={fetchData}
                              icon={<ReloadOutlined/>}
                              style={{marginRight: 8}}
                          />

                      </>
                  }>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Space direction="horizontal">
                                    <Title level={5}>Users By App Version Over Time</Title>
                                </Space>
                                <Spin spinning={loading}>
                                    <div className="w-100 d-bloc" style={{minHeight: 400}}>
                                        {
                                            loading ?
                                                <p>Loading...</p> :
                                                data && <Line data={chartData} height={150} options={chartOptions}
                                                              plugins={[weekendDatesHighlighter]}/>
                                        }
                                    </div>
                                </Spin>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>


        </div>
    )

}

export default AppVersionsMetrics  