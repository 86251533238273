import React from "react";

const TableRecordVibration: React.FC<any> = ({count, level, duration}) => {
    return (
        <>
            📳{count || ''}x{level || ''}% ⌛{duration || ''}ms
        </>
    );
};

export default TableRecordVibration;