import {AxiosResponse} from "axios";

import Api from "./api";
import {Entitlement, UserEntitlement} from "../types";

interface GetAllEntitlementsResponse {
    revenueCatEntitlements: Entitlement[];
}

interface GetUserEntitlementsResponse {
    revenueCatUserEntitlements: UserEntitlement[];
}


export const getAllEntitlements = (): Promise<AxiosResponse<GetAllEntitlementsResponse>> => {
    return Api.get('/admin/entitlements/')
}

export const getUserEntitlements = (userId: number): Promise<AxiosResponse<GetUserEntitlementsResponse>> => {
    return Api.get(`/admin/entitlements/${userId}`)
}


export default {
    getAllEntitlements,
    getUserEntitlements
}