import {AxiosResponse} from "axios";

import Api from "./api";
import {Badge} from "types";

interface GetBadgeByIdResponse {
    badge: Badge;
}

export interface BadgeInCreate {
    title: string;
    key: string;
    description: string;
    image?: string;
    voltsActionId: number;
}


export interface BadgeInUpdate extends BadgeInCreate {
    badgeId: number;
}

export interface CreateBadgeRequest {
    badge: BadgeInCreate;
}

export interface GetAllBadgesResponse {
    badges: Badge[]
}

interface UpdateBadgeRequest {
    badge: BadgeInUpdate;
}

export const getAllBadges = (): Promise<AxiosResponse<GetAllBadgesResponse>> => {
    return Api.get("/admin/badges/");
}

export const createBadge = (badge: BadgeInCreate): Promise<AxiosResponse<GetBadgeByIdResponse>> => {
    const request = {
        badge: badge,
    };

    return Api.post("/admin/badges/", request);
}

export const updateBadge = (badge: BadgeInUpdate): Promise<AxiosResponse<GetBadgeByIdResponse>> => {
    const request: UpdateBadgeRequest = {
        badge: badge,
    };

    return Api.put(`/admin/badges/${badge.badgeId}`, request);
}

export const getBadgeById = (badgeId: number): Promise<AxiosResponse<GetBadgeByIdResponse>> => {
    // todo: refactor this backed side
    return Api.get(`/admin/badges/get-one/${badgeId}`);
}

export default {
    getAllBadges,
    createBadge,
    updateBadge,
    getBadgeById,
};
