import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Space, Table, Tooltip} from 'antd';
import {
    CopyOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {PhoneDevice} from "types";
import {PhoneDevicesService} from "services";
import {getColumnSearchProps} from "components/TableSearch";

interface PhoneDevicesListProps {
    userId?: string
}

// ----- Component -----
const PhoneDevicesList: React.FC<PhoneDevicesListProps> = ({userId}) => {
    // ----- data -----
    const [phoneDevices, setPhoneDevices] = useState<PhoneDevice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // ----- Table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (!userId) return
        setLoading(true);
        PhoneDevicesService.getPhoneDevicesByUserId(Number(userId))
            .then(res => {
                const phoneDevicesResponse = res?.data?.phoneDevices || [];
                const phoneDevicesList = phoneDevicesResponse.map((item: PhoneDevice) => ({
                    ...item,
                    key: item.id + ""
                }));
                setPhoneDevices(phoneDevicesList);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns: ColumnsType<PhoneDevice> = [
        {
            title: 'FCM Token',
            dataIndex: 'fcmToken',
            key: 'fcmToken',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'fcmToken'
            }),
            render: (fcmToken: string) => (
                <>
                    <Tooltip title={fcmToken}>
                        <span>{fcmToken.length > 20 ? fcmToken.substring(0, 20) + '...' : fcmToken}</span>
                    </Tooltip>
                    <Button 
                        icon={<CopyOutlined />} 
                        onClick={() => navigator.clipboard.writeText(fcmToken)}
                    />
                </>
            ),
        
        },
        {
            title: 'Device Name',
            dataIndex: 'deviceName',
            key: 'deviceName',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'deviceName'
            }),
        },
        {
            title: 'OS Name',
            dataIndex: 'osName',
            key: 'osName',
        },
        {
            title: 'OS Version',
            dataIndex: 'osVersion',
            key: 'osVersion',
        },
        {
            title: 'App Version',
            dataIndex: 'appVersion',
            key: 'appVersion',
        },
    ];

    return (
        <>
            <Card title="Phone Devices List"
                  extra={
                      <Space>
                          <Button 
                                  loading={loading}
                                  onClick={() => fetchData()}
                                  icon={<ReloadOutlined/>}/>
                      </Space>
                  }>
                <Table<PhoneDevice>
                    columns={columns}
                    dataSource={phoneDevices}
                    loading={loading}
                    size="small"
                    bordered
                    pagination={{
                        showSizeChanger: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                    }}
                />
            </Card>
        </>
    );
};

export default PhoneDevicesList;
