import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Card, Image, Space, Table, Tooltip} from 'antd';
import {BarChartOutlined, DotChartOutlined, EyeOutlined, MobileOutlined, ReloadOutlined} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';

import {BaseModel, Device} from "../../types";
import {getColumnSearchProps} from "../TableSearch";
import {DevicesService} from "../../services";
import {getDeviceType} from "../../utils/devices";

import pavlok_device_v2 from 'assets/images/pavlok_device_v2.png';
import pavlok_device_v3 from 'assets/images/pavlok_device_v3.png';
import dayjs from "dayjs";

type DataIndex = keyof BaseModel | string;

interface DevicesListProps {
    userId?: string
}

const DevicesList: React.FC<DevicesListProps> = ({userId}) => {
    const [device, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        DevicesService.getDevices(Number(userId))
            .then(res => {
                const devices = res?.data?.devices
                const total = devices?.length
                const deviceList = devices?.map((item: Device, index: number) => ({
                    ...item,
                    key: `key-${index}`
                }))
                setDevices(deviceList || [])
                setTotalItems(total);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const columns: ColumnsType<Device> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (_: string | null, record: Device) =>
                <>
                    {getDeviceType(record) == 2 && <Image width={35} src={pavlok_device_v2}/>}
                    {getDeviceType(record) == 3 && <Image width={35} src={pavlok_device_v3}/>}
                    {
                        (getDeviceType(record) != 3 && getDeviceType(record) != 2) &&
                        <Avatar style={{backgroundColor: '#1677ff'}} icon={<MobileOutlined/>}></Avatar>
                    }
                </>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'name'
            }),
        },
        {
            title: 'Device Model',
            dataIndex: 'deviceModel',
            key: 'deviceModel',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'deviceModel'
            }),
            sorter: (a: Device, b: Device) => a?.deviceModel?.localeCompare(b?.deviceModel)
        },
        {
            title: 'FW',
            dataIndex: 'firmwareVersion',
            key: 'firmwareVersion',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'firmwareVersion'
            }),
            sorter: (a: Device, b: Device) => a?.firmwareVersion?.localeCompare(b?.firmwareVersion)
        },
        {
            title: 'Mac Address',
            dataIndex: 'macAddress',
            key: 'macAddress',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'macAddress'
            }),
        },//
        {
            title: 'Last Sync',
            dataIndex: 'lastSync',
            key: 'lastSync',
            sorter: (a: Device, b: Device) => {
                if (a.lastSync && b.lastSync) {
                    return a.lastSync.localeCompare(b.lastSync);
                } else if (a.lastSync) {
                    return -1;
                } else if (b.lastSync) {
                    return 1;
                } else {
                    return 0;
                }
            },
            render: (value) => (
                <>
                    {value && dayjs(value).format('YYYY-MM-DD HH:MM')}
                </>
            )

        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_: number, record: Device) => (
                <Space>
                    <Tooltip title="Details page">
                        <Button type="primary" href={`/devices/${record.id}`} icon={<EyeOutlined/>}/>
                    </Tooltip>
                    <Tooltip title="User Logs">
                        <Button type="primary"
                                href={`/user-logs/?macAddress=${encodeURIComponent(record.macAddress)}`}
                                icon={<BarChartOutlined/>}
                        />
                    </Tooltip>
                    <Tooltip title="Diagnostic Logs">
                        <Button type="primary"
                                href={`/diagnostic-logs/?macAddress=${encodeURIComponent(record.macAddress)}`}
                                icon={<DotChartOutlined/>}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <Card title="User Devices list"
              extra={
                  <Button 
                          loading={loading}
                          onClick={() => fetchData()}
                          icon={<ReloadOutlined/>}/>
              }
        >
            <Table<Device>
                columns={columns}
                dataSource={device}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    total: totalItems,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                }}
                onChange={(pagination) => {
                    setPageSize(pagination.pageSize || 10);
                    setCurrentPage(pagination.current || 1);
                }}
                scroll={{x: 1000}}
            />
        </Card>
    )

};

export default DevicesList;