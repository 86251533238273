import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate} from 'react-router-dom';

import {logout} from 'redux/authSlice';

import LocalStorage from "../../../utils/local-storage";

function LogoutPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
        LocalStorage.deleteItem('token')
    }, [dispatch]);

    return <Navigate to="/login" replace/>;
}

export default LogoutPage;
