import {AxiosResponse} from "axios";

import Api from "./api";
import {StringsUtils} from 'utils'
import {FilterParams, GetItemsResponse, GetPaginatedFilteredDataParams, Device, User} from "types";

interface GetAllUsersDevicesParams {
    page?: number;
    pageSize?: number;
    sortField?: string;
    sortOrder?: 'ascend' | 'descend' | string;
    filters?: FilterParams;
    search?: string
    showDeleted?: boolean
}

interface GetDevicesResponse {
    devices: Device[]
}

interface GetDeviceByIdResponse {
    device: Device
    users: User[]
}

export const getAllUsersDevices = (params: GetAllUsersDevicesParams = {}): Promise<AxiosResponse<GetItemsResponse<Device>>> => {
    // for (key, value) in params.filters
    // const filters =
    const sortOrder = params.sortOrder === 'ascend' ? 'ASC' : 'DESC'
    const sortField = StringsUtils.convertToDashCase(params?.sortField) || null
    let data: GetPaginatedFilteredDataParams = {
        page: params.page,
        page_size: params.pageSize,
        search: params.search,
        show_deleted: params.showDeleted,
    }
    if (params.filters) {
        // todo: use the new function that converts the filter to api format
        let filters: FilterParams = params.filters
        filters = Object.keys(filters).reduce((prev: FilterParams, key: string) => {
            const filterValue: null | string[] | number[] | boolean = filters[key]
            if (filterValue) {
                if (Array.isArray(filterValue) && filterValue.length>0) {
                    return {
                        ...prev,
                        [key]: filterValue
                    }
                }
            }
            return prev
        }, {})
        const filterFields = Object.keys(filters || {}).map(key => StringsUtils.convertToDashCase(key))?.join('|');
        const filterValues = Object.values(filters || {}).map(filter => {
            if (filter instanceof Array){
                return filter?.join(',')
            }
            return filter
        })?.join('|')
        if (filterFields) {
            data = {
                ...data,
                filter_fields: filterFields,
                filter_values: filterValues,
            }
        }
    }
    if (sortField) {
        data = {
            ...data,
            sort_field: sortField,
            sort_order: sortOrder,
        }
    }
    return Api.get(
        '/admin/devices/all',
        {
            params: data
        }
    )
}


export const getDevices = (userId: number): Promise<AxiosResponse<GetDevicesResponse>> => {
    return Api.get('/admin/devices/', {
        params: {
            user_id: userId
        }
    })
}

export const getDeviceById = (deviceId: number): Promise<AxiosResponse<GetDeviceByIdResponse>> => {
    return Api.get(`/admin/devices/${deviceId}`)
}


export default {
    getDevices, getDeviceById, getAllUsersDevices
}