import React, {useEffect, useState} from 'react';
import {Button, Card, Drawer, message, Modal, Space, Table} from "antd";
import {EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import {SorterResult} from "antd/es/table/interface";
import {ColumnsType} from "antd/es/table";
import moment from "moment";

import {DiagnosticLogsService} from 'services'
import {DiagnosticLogsRecord} from "types";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {DiagnosticLogsFilter, UserLogsFilter} from "../types";
import {GetDiagnosticLogsRecordsParams} from "../../../services/diagnostic-logs";
import DiagnosticLogsFilters from "../components/DiagnosticLogsFilters";
import TableRecordZap from "../components/TableRecordZap";
import TableRecordBeep from "../components/TableRecordBeep";
import TableRecordBattery from "../components/TableRecordBattery";
import TableRecordVibration from "../components/TableRecordVibration";
import TableRecordConfig from "../components/TableRecordConfig";
import {useSearchParams} from "react-router-dom";
import {objectToUrlParams, urlParamsToObject} from "../../../utils/url-utils";
import {history} from "../../../utils/history";
import {deepCamelCaseKeys} from "../../../utils/objects";

// ----- Global variables -----
const DEFAULT_PAGE_SIZE = 100


const DiagnosticLogsListPage = () => {
    // ----- Navigation -----
    const [searchParams] = useSearchParams();

    // ----- Data -----
    const [diagnosticLogs, setDiagnosticLogs] = useState<DiagnosticLogsRecord[]>([])
    const [selectedDiagnosticLogId, setSelectedDiagnosticLogId] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    // ----- Filters -----
    const [filters, setFilters] = useState<DiagnosticLogsFilter | null>()

    // ----- Filters -----
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ----- Table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    // todo: add search field in the table
    const [search,] = useState<string>('')
    const [sortField, setSortField] = useState<string | undefined>()
    const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>()

    // ----- Drawer -----
    // todo: if mac id present in the url no drawer is needed
    const [open, setOpen] = useState(true);


    // ----- Hooks -----
    useEffect(() => {
        // fetch data from server
        fetchData()
    }, [currentPage, search, sortField, sortOrder, filters])

    useEffect(() => {
        // Get mac_address value from URL, decode it and if it's not present default it to ''
        // const macAddressInUrl = decodeURIComponent(searchParams.get('mac_address') || '');
        const filtersInUrl = urlParamsToObject<UserLogsFilter>(searchParams)
        const types = Array.isArray(filtersInUrl?.types) ? filtersInUrl?.types : (filtersInUrl?.types ? [filtersInUrl?.types] : [])
        if (filtersInUrl) {
            // Update the filters state
            setFilters(prevFilters => ({
                ...prevFilters,
                ...filtersInUrl,
                types: types,
            }));
        }
        // Run this effect whenever 'searchParams' changes
    }, [searchParams])

    // ----- Drawer -----
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    // ----- End Drawer -----

    // ----- Modal -----
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setSelectedDiagnosticLogId(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedDiagnosticLogId(null);
    };
    // ----- End Modal -----


    const fetchData = async () => {
        if (!filters?.macAddress) return
        setLoading(true)
        try {
            const params: GetDiagnosticLogsRecordsParams = {
                mac_address: filters?.macAddress || '', // "FDC6E5B9CFD1",
                types: filters?.types || [],
                ...(filters?.startDate ? {start_date: filters?.startDate} : {}),
                ...(filters?.endDate ? {end_date: filters?.endDate} : {}),
                page: currentPage,
                page_size: pageSize,
            }
            const response = await DiagnosticLogsService.getDiagnosticLogsRecords(params)
            if (!response)
                throw Error("Invalid response")
            const {items, count} = response

            const formattedDiagnosticLogs = items?.map((item: DiagnosticLogsRecord) => ({
                ...deepCamelCaseKeys<DiagnosticLogsRecord>(item),
                key: `diagnostic-log-record-${item?.id}`,
            }))
            setDiagnosticLogs(formattedDiagnosticLogs)
            setTotalItems(count)
        } catch (err) {
            // Show error message
            console.error('Error getting diagnostic logs')
            message.error(`Failed to get diagnostic-logs`);
        }
        setLoading(false)
    }

    // ----- Handlers -----
    const handleViewRecordDetails = (record: DiagnosticLogsRecord) => {
        setSelectedDiagnosticLogId(record.id)
        showModal()
    }

    const handleOnFilter = (values: DiagnosticLogsFilter) => {
        console.log("handleOnFilter", values)
        setFilters(values)
        setOpen(false)
        // add filters to url
        const url = objectToUrlParams(values);
        history?.navigate?.(url);
    }

    const columns: ColumnsType<DiagnosticLogsRecord> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Type',
            dataIndex: 'name',
            key: 'name',
            render: (value) => value?.replace('Record', '')
        },
        {
            title: 'Timestamp',
            dataIndex: 'ts',
            key: 'ts',
            render: (value) => <>{value && moment(value).format('YYYY-MM-DD HH:MM:SS')}</>
        },
        {
            title: 'Info',
            dataIndex: 'parsedJson',
            key: 'parsedJson',
            render: (value: object, record: DiagnosticLogsRecord) => {
                if (record?.id == 232633863) {
                    console.log('parsedJson', record?.parsedJson, record)
                }
                switch (record?.name) {
                    case 'Zap':
                        return <TableRecordZap {...value} />
                    case 'Battery':
                        return <TableRecordBattery {...value} />
                    case 'Vibe':
                        return <TableRecordVibration {...value} />
                    case 'Beep':
                        return <TableRecordBeep {...value} />
                    case 'ConfigPin':
                        return <TableRecordConfig {...value} />
                    default:
                        return <>{JSON.stringify(value)}</>

                }
            }
        }, {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: object, record: DiagnosticLogsRecord) => {
                return (
                    <Button onClick={() => handleViewRecordDetails(record)} type="primary" icon={<EyeOutlined/>}/>
                )
            }

        }
    ]

    const selectedDiagnosticLog = diagnosticLogs.find(item => item.id === selectedDiagnosticLogId) || null
    console.log(selectedDiagnosticLog)
    return (
        <>
            <CustomBreadcrumb list={['Diagnostic logs', 'List',]}/>
            <Card title="Diagnostic logs"
                  extra={
                      <Space>
                          <Button 
                                  loading={loading}
                                  onClick={() => fetchData()}
                                  icon={<ReloadOutlined/>}/>
                          <Button type="primary"
                                  loading={loading}
                                  onClick={() => showDrawer()}
                          >
                              Filters
                          </Button>
                      </Space>
                  }
            >
                <Table<DiagnosticLogsRecord>
                    columns={columns}
                    dataSource={diagnosticLogs}
                    loading={loading}
                    size="small"
                    bordered
                    pagination={{
                        pageSize: pageSize,
                        current: currentPage,
                        total: totalItems,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '50', '100'],
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                    }}
                    onChange={(pagination, filters, sorter: SorterResult<DiagnosticLogsRecord> | SorterResult<DiagnosticLogsRecord>[],) => {
                        // setFilters(filters)
                        setPageSize(pagination.pageSize || DEFAULT_PAGE_SIZE);
                        setCurrentPage(pagination.current || 1);
                        console.log('pagination.current ', pagination.current);
                        if (typeof sorter === 'object') {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            setSortField(sorter?.field?.toString())
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            setSortOrder(sorter?.order?.toString())
                        }
                    }}
                />
            </Card>
            <Drawer
                title="Filter"
                placement="left"
                closable={false}
                onClose={onClose}
                open={open}
                key={"DrawerFilter"}
            >
                <DiagnosticLogsFilters onSubmit={handleOnFilter} filters={filters} loading={loading}/>
            </Drawer>
            <Modal title="Your JSON Data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <pre>{selectedDiagnosticLog && JSON.stringify(selectedDiagnosticLog, null, 2)}</pre>
            </Modal>
        </>
    )
}

export default DiagnosticLogsListPage