export const objectToUrlParams = (obj: any) => {
    let url = "?";

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            url += `${key}=${encodeURIComponent(obj[key].join(','))}&`;
        } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
            url += `${key}=${encodeURIComponent(obj[key])}&`;
        }
    }

    // Remove trailing ampersand or question mark
    url = url.replace(/[&?]$/, "");

    return url;
}

/**
 * This function converts URL search parameters to an object of a specified type.
 * It accepts URLSearchParams object as input and returns an object that has keys and values derived from the search parameters.
 * The keys are the search parameter names, and the values are the corresponding search parameter values.
 * If a search parameter value includes commas, it is split by the commas and returned as an array.
 * If multiple search parameters have the same name, their values are stored as an array.
 * Note: The type of the output object is specified by the generic parameter T, which should extend Record<string, any>.
 * The function uses the 'any' type because the values can be either a string or an array of strings.
 *
 * @param searchParams - An instance of URLSearchParams, typically obtained from the URL of the current page
 * @returns An object of type T, where each property is a search parameter from the URL
 */
export const urlParamsToObject = <T extends Record<string, any>>(searchParams: URLSearchParams): T => {
    const obj: Partial<T> = {};

    Array.from(searchParams).forEach(([key, value]) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (Array.isArray((obj as any)[key])) {
                (obj as any)[key].push(value);
            } else {
                (obj as any)[key] = [(obj as any)[key], value];
            }
        } else if (value.includes(',')) {
            // Cast to any because TypeScript doesn't know that the key is valid for T
            (obj as any)[key] = value.split(',');
        } else {
            (obj as any)[key] = value;
        }
    });

    // Cast to T because the function must return T
    return obj as T;
}


export default {
    objectToUrlParams, urlParamsToObject
}