import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

// ----- local calls -----
import {ChallengesService} from "services";
import {RootState} from "../../../redux/state";
import {Challenge} from "../../../types";
import BgProfile from "../../../assets/images/bg-profile.jpg";
import ChallengeDetailsCard from "./components/ChallengeDetailsCard";
import {Col, Row} from "antd";
import ChallengeParticipantsCard from "./components/ChallengeParticipantsCard";
import LeaderboardCard from "./components/LeaderboardCard";

// ----- Types -----
type RouteParams = {
    challengeId?: string
}

// ----- Components -----
const ChallengeDetailsPage: React.FC = () => {
    // ----- Store -----
    const authenticatedUser = useSelector((state: RootState) => state?.auth?.authenticatedUser);

    // ----- params -----
    const {challengeId} = useParams<RouteParams>();

    // ----- data -----
    const [challenge, setChallenge] = useState<Challenge | null>(null)
    const [loading, setLoading] = useState(false);

    // ----- Init -----
    useEffect(() => {
        // if challenge id then Edit case
        if (challengeId) {
            fetchChallenge()
        }
    }, [challengeId])

    const fetchChallenge = () => {
        if (!challengeId) return
        setLoading(true)
        ChallengesService.getChallengeById(Number(challengeId))
            .then(res => {
                const challengeResponse = res?.data?.challenge
                if (challengeResponse) {
                    setChallenge(challengeResponse)
                    // we set image which is different from base64
                    // setImage(challengeResponse.iconUrl)
                }
            })
            .catch(err => {
                console.error('Error fetching challenge, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <div
                className="profile-nav-bg"
                style={{backgroundImage: `url(${BgProfile})`}}
            />
            <ChallengeDetailsCard challenge={challenge} loading={loading}/>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <ChallengeParticipantsCard challengeId={Number(challengeId)}/>
                </Col>
                <Col span={12}>
                    <LeaderboardCard challengeId={Number(challengeId)}/>
                </Col>
            </Row>
        </>
    )
}
export default ChallengeDetailsPage;