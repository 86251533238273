import React, {useEffect, useMemo, useRef, useState} from "react"
import {Avatar, Button, Card, Dropdown, Input, InputRef, List, Space} from "antd";
import {FilterOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

// ----- Local calls -----
import {ChallengesService} from "services";
import {Participant} from "types";
import ChallengeParticipantLinkedUserHabitsModal from "./ChallengeParticipantLinkedUserHabitsModal";

// ----- Types -----
interface LeaderboardCardProps {
    challengeId: number
}

// ----- Components -----
const LeaderboardCard: React.FC<LeaderboardCardProps> = (
    {challengeId}
) => {
    // ----- data -----
    const [participants, setParticipants] = useState<Participant[]>([])
    const [loading, setLoading] = useState(false); // for all the fetches
    const [selectedParticipantId, setSelectedParticipantId] = useState<number | null>(null)

    // ----- Modal -----
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // ----- Search -----
    const [searchText, setSearchText] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const filteredParticipants = useMemo(() => {
        // sort and filter the participants
        return participants.filter((item) => {
            return item?.firstName?.toLowerCase().includes(searchText) ||
                item?.lastName?.toLowerCase().includes(searchText) ||
                item?.email?.toLowerCase().includes(searchText);
        }).sort((a: Participant, b: Participant) => ((
            b.totalUserHabitsCount - b?.approvedUserHabitsCount
        ) - (
            a.totalUserHabitsCount - a?.approvedUserHabitsCount
        )))
    }, [participants, searchText])

    // ----- Init -----
    useEffect(() => {
        // if challenge id then Edit case
        if (challengeId) {
            fetchData()
        }
    }, [challengeId])

    const fetchData = () => {
        if (!challengeId) return
        setLoading(true)
        ChallengesService.getChallengeParticipants(challengeId)
            .then(res => {
                const participantsResponse: Participant [] = res?.data?.participants?.map((item: Participant) => ({key: item.id + '', ...item}))
                setParticipants(participantsResponse || [])
            })
            .catch(err => {
                console.error('Error fetching participants, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onLoadMore = () => {
        console.log("onLoadMore",)
    }

    // ----- Handlers -----
    const handleSearch = () => {
        const value = searchInput.current?.input?.value?.toLowerCase() || "";
        console.log("search for participant", value);
        if (!value) return;
        setSearchText(value)
    }

    const handleReset = () => {
        setSearchText('')
        if (searchInput.current && searchInput.current.input) {
            searchInput.current.input.value = '';
        }
    }
    // ----- Modal -----
    const showModal = (participantId: number) => {
        setIsModalOpen(true);
        setSelectedParticipantId(participantId)
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setSelectedParticipantId(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedParticipantId(null);
    };

    // ----- Local components -----
    const loadMore =
        !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >

                {/* 
                ///<Button onClick={onLoadMore}>loading more</Button>
                 */}

            </div>
           
        ) : null;

    const Search: React.FC = () => (
        <Card bodyStyle={{padding: 7}}>
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                Search For: {searchText}
                <Input
                    ref={searchInput}
                    placeholder="Search participant"
                    // value={searchText}
                    // onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={() => handleSearch()}
                    style={{marginBottom: 8, display: 'block'}}
                    // todo:
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset()}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            // confirm({closeDropdown: false});
                            setSearchText("");
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            // close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        </Card>
    )

    return (
        <>

            <Card title="Participants"
                  loading={loading}
                  extra={
                      <Space>
                          <Button 
                                  loading={loading}
                                  onClick={() => fetchData()}
                                  icon={<ReloadOutlined/>}/>
                          <Dropdown
                              trigger={['click']}
                              dropdownRender={() => (
                                  <div><Search/></div>
                              )}>
                              <Button type={searchText ? "primary" : "dashed"}
                                      loading={loading}
                                  // onClick={() => fetchData()}
                                      icon={<FilterOutlined/>}/>
                          </Dropdown>
                      </Space>
                  }>
                <List<Participant>
                    className="participants-list"
                    loading={loading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={filteredParticipants}
                    header={
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>Participant</span>
                            <span>Pending habits</span>
                        </div>
                    }
                    renderItem={(participant: Participant) => (
                        <List.Item
                            actions={[
                                <Button key="list-participant-pending-habits"
                                        onClick={() => showModal(participant.id)}>
                                    Manage
                                </Button>,
                                <span key="list-participant-pending-habits-count">
                                {participant.totalUserHabitsCount - participant?.approvedUserHabitsCount}
                            </span>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={participant.profilePictureUrl}/>}
                                title={
                                    <Link to={`/users/${participant?.id}`}>
                                        {participant?.lastName} {participant?.firstName}
                                    </Link>
                                }
                                description={participant?.teamName}
                            />
                        </List.Item>
                    )}
                />
            </Card>
            <ChallengeParticipantLinkedUserHabitsModal
                challengeId={challengeId}
                userId={selectedParticipantId}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            />
        </>
    )
}
export default LeaderboardCard