import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Space, Table, Badge, Typography, Tag} from 'antd';
import {
    DownloadOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import type {InputRef} from 'antd';
import type {ColumnsType} from 'antd/es/table';

// ----- Local Calls -----
import {Firmware, Hardware} from "types";
import {FirmwaresService} from "services";
import {history} from 'utils/history'
import {getColumnSearchProps} from "components/TableSearch";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {getRandomColorNameByIndex} from "utils/colors";

// ----- Variables -----
const {Paragraph} = Typography;

// ----- Component -----
const FirmwaresListPage: React.FC = () => {
    // ----- Data -----
    const [firmwares, setFirmwares] = useState<Firmware[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    // ----- End Data -----

    // ----- Table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End Table -----

    // ----- Init -----
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        FirmwaresService.getAllFirmwares()
            .then(res => {
                const firmwaresResponse = res?.data?.firmwares || []
                const firmwaresList = firmwaresResponse?.map((item: Firmware, index: number) => ({
                    ...item,
                    key: `firmware-${item.id}`
                }))
                setFirmwares(firmwaresList || [])
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    // ----- End Handlers -----

    const columns: ColumnsType<Firmware> = [
        {
            title: 'Version',
            dataIndex: 'versionString',
            key: 'versionString',
            sorter: (a, b) => a?.versionString?.localeCompare(b?.versionString), // sort string in ascending order
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'versionString'
            }),
        },
        {
            title: 'Body',
            dataIndex: 'body',
            key: 'body',
            render: (value) => (
                <Paragraph ellipsis={{rows: 3, expandable: true, symbol: 'more'}}>
                    {value}
                </Paragraph>
            ),
        },
        {
            title: 'Draft',
            dataIndex: 'test',
            key: 'test',
            filters: [
                {
                    text: 'Draft',
                    value: true,
                }, {
                    text: 'Live',
                    value: false,
                }
            ],
            onFilter: (value: boolean | string | number, record: Firmware) => record.test === value,
            render: (value) => value ? <Badge status="error" text="Draft"/> : <Badge status="success" text="Live"/>
        },
        {
            title: 'Hardware',
            dataIndex: 'hardwares',
            key: 'hardwares',
            filters: [
                {
                    text: 'Pavlok 3',
                    value: 'Pavlok 3',
                },
                {
                    text: 'Pavlok 1',
                    value: 'Pavlok 1',
                },
                {
                    text: 'Pavlok 1 - in between',
                    value: 'Pavlok 1 - in between',
                },
                {
                    text: 'Shock Clock MAX',
                    value: 'Shock Clock MAX',
                },
                {
                    text: 'Pavlok 2 / Shock Clock 2',
                    value: 'Pavlok 2 / Shock Clock 2',
                },
                {
                    text: 'Pavlok prototype',
                    value: 'Pavlok prototype',
                },
                {
                    text: 'Shock Clock 1',
                    value: 'Shock Clock 1',
                },
            ],
            onFilter: (value: boolean | string | number, record: Firmware) => record.hardwares.some((item: Hardware) => item.convenienceName === value),
            render: (value: Hardware[]) => value?.map(hardware => <Tag
                color={getRandomColorNameByIndex(hardware.versionNumber)} key={hardware.versionNumber}>{hardware.convenienceName}</Tag>)

        },
        {
            title: 'File',
            dataIndex: 'fileUrl',
            key: 'fileUrl',
            render: (value) => (
                <Button
                    size="small"
                    type="primary"
                    icon={<DownloadOutlined/>}
                    disabled={!value}
                >
                    <a href={value} download>
                        Download
                    </a>
                </Button>)

        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: Firmware, b: Firmware) => a.id - b.id,
            defaultSortOrder: 'descend', // Sort in descending order by default
            render: (value: number) => (
                <Space>
                    <Button
                        type="primary"
                        onClick={() => history?.navigate?.(`/firmwares/edit/${value}`)}
                        icon={<EditOutlined/>}
                    />
                </Space>
            )
        }
    ];

    return (
        <>
            <CustomBreadcrumb list={['Firmwares', 'list',]}/>
            <Card title="Firmwares list"
                  extra={
                      <Space>
                          <Button
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                          <Button onClick={() => history?.navigate?.('/firmwares/create')}
                                  icon={<PlusOutlined/>}>
                              Create firmware
                          </Button>
                      </Space>
                  }
            >
                <Table<Firmware>
                    columns={columns}
                    dataSource={firmwares}
                    loading={loading}
                    size="small"
                    bordered
                    pagination={{
                        showSizeChanger: true,
                        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                    }}
                />
            </Card>
        </>
    )
};

export default FirmwaresListPage;
