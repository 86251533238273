import React, {useEffect, useRef, useState} from 'react';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    RadarChartOutlined,
    ReloadOutlined,
    ThunderboltOutlined
} from '@ant-design/icons';
import type {InputRef} from 'antd';
import {Avatar, Button, Card, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';
import {Link} from "react-router-dom";

import {BaseModel, PokePermission} from "../../types";
import {getColumnSearchProps} from "../TableSearch";
import {PokePermissionsService} from "../../services";


type DataIndex = keyof BaseModel | string;

interface PokePermissionsListProps {
    userId?: string
}

const PokePermissionsList: React.FC<PokePermissionsListProps> = ({userId}: PokePermissionsListProps) => {
    // TODO: Add Granted and Received case switch
    const [pokePermissions, setPokePermissions] = useState<PokePermission[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----


    useEffect(() => {
        fetchData()
    }, [pageSize, currentPage, userId])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        PokePermissionsService.getUserGrantedPokePermissions({userId: userId})
            .then(res => {
                const pokePermissionsResponse = res?.data?.pokePermissions;
                const total = pokePermissionsResponse?.length
                const pokePermissionsList = pokePermissionsResponse?.map((item: PokePermission, index: number) => ({
                    ...item,
                    key: `key-${index}`
                }))
                setPokePermissions(pokePermissionsList || [])
                setTotalItems(total);

            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const columns: ColumnsType<PokePermission> = [
        {
            title: '#',
            dataIndex: 'friend',
            key: 'friend',
            // width: '30%',
            render: (_: string | null, record: PokePermission) =>
                <Link to={'/users/' + record?.friendId} target="_blank">
                    {record?.friend?.profilePictureUrl ?
                        <Avatar src={record?.friend?.profilePictureUrl}/> :
                        // <Avatar style={{backgroundColor: '#1890ff'}}>{user?.email?.at(0) || ''}</Avatar>,
                        <Avatar style={{backgroundColor: '#13c2c2'}} icon={<RadarChartOutlined/>}></Avatar>}
                </Link>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'friend.email',
            // width: '30%',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'friend.email'
            }),
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
            // width: '20%',
            ...getColumnSearchProps({
                searchInput,
                handleSearch,
                handleReset,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'type'
            }),
        },
        {
            title: 'Vibrate',
            dataIndex: 'canVibrate',
            key: 'canVibrate',
            render: value => value ?
                <Tag color="green"><CheckCircleOutlined/></Tag>
                :
                <Tag color="volcano"><CloseCircleOutlined/></Tag>
        },
        {
            title: 'Chime',
            dataIndex: 'canChime',
            key: 'canChime',
            render: value => value ?
                <Tag color="green">
                    <CheckCircleOutlined/>
                </Tag>
                :
                <Tag color="volcano">
                    <CloseCircleOutlined/>
                </Tag>
        },
        {
            title: 'Zap',
            dataIndex: 'canZap',
            key: 'canZap',
            render: value => value ?
                <Tag color="green">
                    <CheckCircleOutlined/>
                </Tag>
                :
                <Tag color="volcano">
                    <CloseCircleOutlined/>
                </Tag>
        },
        {
            title: 'Zap Value',
            dataIndex: 'maxZapValue',
            key: 'maxZapValue',
            render: value => <>
                <Tag color="yellow">{value} <ThunderboltOutlined/></Tag>
            </>
        },
    ];

    return (
        <Card title="Poke permissions list"
              extra={
                  <Button 
                          loading={loading}
                          onClick={() => fetchData()}
                          icon={<ReloadOutlined/>}/>
              }
        >
            <Table<PokePermission>
                columns={columns}
                dataSource={pokePermissions}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    total: totalItems,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
                }}
                onChange={(pagination) => {
                    setPageSize(pagination.pageSize || 10);
                    setCurrentPage(pagination.current || 1);
                }}
                scroll={{x: 400}}
            />
        </Card>
    )

};

export default PokePermissionsList;