import {AxiosResponse} from "axios";

import Api from "./api";
import {Habit, HabitInCreate, HabitInUpdate, HabitWithQuestions} from "../types";

interface GetAllHabitsResponse {
    habits: HabitWithQuestions[]
}

interface GetHabitWithQuestionsResponse {
    habit: HabitWithQuestions
}

export const getAllHabits = (): Promise<AxiosResponse<GetAllHabitsResponse>> => {
    // Get all visible habits
    return Api.get('/admin/habits/')
}


interface GetHabitResponse {
    habit: Habit
}


interface GetHabitWithQuestionsResponse {
    habitWithQuestions: HabitWithQuestions
}


export const getHabitById = (habitId: string): Promise<AxiosResponse<GetHabitWithQuestionsResponse>> => {
    return Api.get(`/admin/habits/${habitId}`)
}

export const createHabit = (habit: HabitInCreate): Promise<AxiosResponse<GetHabitWithQuestionsResponse>> => {
    const request = {
        habit: habit
    }
    return Api.post(`/admin/habits/`, request)
}

export const updateHabit = (habit: HabitInUpdate): Promise<AxiosResponse<GetHabitResponse>> => {
    const request = {
        habit: habit
    }
    return Api.put(`/admin/habits/`, request)
}

export const deleteHabit = (habitId: number): Promise<AxiosResponse> => {
    const request = {
        params: {habit_id: habitId},
    }
    return Api.delete(`/admin/habits/`, request)
}

export default {
    getAllHabits, getHabitById, createHabit
}
