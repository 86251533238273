import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Spin} from "antd";

import {history} from 'utils/history';
import AppRoutes from "./routes";
import AppLayout from "./components/Layout";
import LoginPage from "./pages/auth/LoginPage";
import {AuthService} from "./services"
import {login, logout} from "./redux/authSlice";
import LocalStorage from "./utils/local-storage";
import PrivateRoute from "./components/PrivateRoute";
import './App.css';


function App() {
    // init custom history object to allow navigation from
    // anywhere in the React app (inside or outside components)
    // todo: use the shared one
    history.navigate = useNavigate();
    history.location = useLocation();

    const dispatch = useDispatch();
    // const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    // we added this is auth check to fix the issue of calling other page e.g: /users from a new tab
    const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);  // to handle the first load
    // get the current auth state from the store
    const isAuthenticated = useSelector((state: any) => state?.auth?.isAuthenticated);
    // const [isAuth, setIsAuth] = useState<boolean>(false)

    /**
     * Get current user from server and update the store
     */
    const getCurrentAuthenticatedUser = async () => {
        setLoading(true)
        await AuthService.verify()
            .then(res => {
                const user = res?.data?.user
                if (!user) {
                    LocalStorage.deleteItem('token')
                    // logout()= setIsAuthenticated(false)
                    dispatch(logout())
                    throw new Error('User not found')
                }
                dispatch(login({authenticatedUser: user, token: user.token || ''}))
                // setIsAuthenticated(true)
            })
            .catch(err => {
                console.error("Error getting current authenticated user. message: ", err)
            })
            .finally(() => {
                setLoading(false)
                setIsAuthChecked(true);
            })
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        // if no token no login
        if (token) {
            getCurrentAuthenticatedUser()
        } else {
            setIsAuthChecked(true)
        }
    }, []);

    const Dashboard = () => (
        <AppLayout>
            <AppRoutes/>
        </AppLayout>
    )


    const Loading = () => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Spin size="large"/>
        </div>
    )

    return (
        <>
            {
                loading ? <Loading/> :
                    isAuthChecked ?  // render routes only if isAuthChecked is true
                        <Routes>
                            <Route path="/login" element={isAuthenticated ? <Navigate to="/"/> : <LoginPage/>}/>
                            <Route path="/*"
                                   element={
                                       <PrivateRoute>
                                           <Dashboard/>
                                       </PrivateRoute>
                                   }
                            />
                        </Routes>
                        : null
            }
        </>
    );
}

export default App;
