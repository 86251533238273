import React, {useState} from "react";
import {Form, message, Modal} from "antd";


// ----- Local calls -----
import UsersSelect from "../../../components/UsersSelect";
import {assignCoachesToChallenge} from "../../../services/challenges";

// ----- Global variables -----
const formLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
};

// ----- Types -----
interface AssignCoachToChallengeModalProps {
    challengeId: number | null
    open: boolean
    onOk?: () => void
    onCancel?: () => void
}

// ----- Components -----
const AssignCoachToChallengeModal: React.FC<AssignCoachToChallengeModalProps> = (
    {challengeId, open, onOk, onCancel}
) => {
    // ----- Form -----
    const [form] = Form.useForm();
    // ----- Data -----
    const [loading, setLoading] = useState(false);

    // ----- Init -----

    // ----- Handlers -----
    const handleSubmit = async () => {
        if (!challengeId) return
        setLoading(true);
        const users =form.getFieldValue("users")
        console.log("values", users)
        try {
            const repsonse = await assignCoachesToChallenge(challengeId, users)
            if (repsonse.status === 201) {
                message.success("Coachs assigned successfully")
                onOk?.()
                return
            }
            message.error("Please try again later!!")
        } catch (e) {
            message.error("Please try again later!!")
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal title="Assign coach to challenge "
               open={open}
               onOk={handleSubmit}
               onCancel={onCancel}
               okText="Assign"
               confirmLoading={loading}
        >
            <Form form={form}
                  onFinish={handleSubmit}
                  {...formLayout}
            >
                <Form.Item
                    label="User"
                    name="users"
                >
                    <UsersSelect onlyStaff={true} roles={["coach"]} multiple={true}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AssignCoachToChallengeModal
