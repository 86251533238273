import React, {useState} from 'react';
import {
    AppstoreOutlined,
    CalendarOutlined,
    LinkOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {Divider, Menu, Switch} from 'antd';
import type {MenuProps, MenuTheme} from 'antd/es/menu';

type MenuItem = Required<MenuProps>['items'][number];

export function getMenuItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

// todo: add Menu props
interface CustomMenuProps {
    items: MenuItem[]
    defaultSelectedKeys?: string[]
    handleClick?: (key: string) => void
}

const CustomMenu: React.FC<CustomMenuProps> = ({items, handleClick, defaultSelectedKeys}) => {
    return <Menu
        style={{borderRadius: 7}}
        defaultSelectedKeys={defaultSelectedKeys}
        mode="vertical"
        theme="light"
        items={items}
        onClick={({key}) => handleClick?.(key)}
    />
}

export default CustomMenu