import React, {useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart, Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeSeriesScale,
    Title,
    Tooltip
} from "chart.js";
import {ReloadOutlined} from "@ant-design/icons";
import {Button, Card} from "antd";
import dayjs, {Dayjs} from 'dayjs';
import 'chartjs-adapter-date-fns';


import {DeviceEngagementRateData} from "../../../../types";
import {getAnalyticDeviceEngagementRate} from "../../../../services/analytics";
import {ChartOptions} from "chart.js/dist/types";
import moment from 'moment';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, TimeSeriesScale, Legend);

// Define these outside of the component to avoid recreating them on each render
const thirteenMonthsAgo = dayjs().subtract(13, 'month').startOf('month');
const twelveMonthsAgo = dayjs().subtract(12, 'month').startOf('month');
const twentyFourMonthsAgo = dayjs().subtract(24, 'month');

const DeviceEngagementRateMetrics: React.FC = () => {
    // ----- data -----
    const [data, setDate] = useState<DeviceEngagementRateData[]>([]);
    // const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setLoading(true)
        getAnalyticDeviceEngagementRate()
            .then(res => {
                const responseData = res?.data?.data || []
                setDate(responseData)
            })
            .catch(err => {
                console.log("Error fetching data, message: ", err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    // Filter last 24 months
    const last24MonthsData = data.filter(d => dayjs(d.date).isAfter(twentyFourMonthsAgo));
    // Filter for the last 12 months
    const last12MonthsData = last24MonthsData.filter(d => dayjs(d.date).isAfter(thirteenMonthsAgo));


    function generateMonths(start: Dayjs, end: Dayjs): string[] {
        const months = [];
        let month = start.startOf('month');

        while (month.isBefore(end)) {
            months.push(month.format('YYYY-MM-DD'));
            month = month.add(1, 'month');
        }

        return months;
    }

    const historicalMonths = generateMonths(twentyFourMonthsAgo, twelveMonthsAgo);
    const historicalData = historicalMonths.map(month => {
        const monthYearMonth = dayjs(month).format('YYYY-MM');
        const dataForMonth = data.find(d => dayjs(d.date).format('YYYY-MM') === monthYearMonth);
        return dataForMonth ? {
            ...dataForMonth,
            displayDate: dayjs(dataForMonth.date).add(1, 'year').format('YYYY-MM-DD'),
            deviceEngagementRate: dataForMonth.deviceEngagementRate || 0,
        } : {
            calculationDate: month,
            displayDate: dayjs(month).add(1, 'year').format('YYYY-MM-DD'),
            deviceEngagementRate: 0,
        };
    });



    const chartData = {
        labels: last12MonthsData.map(d => d.date),
        datasets: [
            {
                label: 'Weekly Device Engagement Rate',
                data: last12MonthsData.map(d => d.deviceEngagementRate),
                borderColor: 'rgba(145,56,236, 1)',
                fill: false,
                borderWidth: 2,
            },
            /*
            {
                label: 'Previous Period',
                data: historicalData.map(d => d.deviceEngagementRate),
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
                borderWidth: 2,
                borderDash: [10, 5], // Adjust the dash length and spacing here as well, if needed
            }, */
        ],
    };


    const options: ChartOptions<any> = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                    displayFormats: {
                        quarter: 'MMM YYYY'
                    }
                },
            },
            y: {
                
                title: {
                    display: true,
                    text: 'Engagement Rate'
                }, 
                ticks: {
                    callback: function (value: any) {
                        return `${value*100}%`;
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top', // or 'top', 'left', 'right'
                labels: {
                    color: 'rgb(1, 1, 1)', // or any other css color
                },
            },
            tooltip: {
                callbacks: {
                    title: function(context: any) {
                        const date = moment(context[0].label, 'MMM D, YYYY, h:mm:ss a');
                        const week = date.week();
                        return `Week ${week}, ${date.format('YYYY')}`;
                    },
                    label: function(context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${100*context.parsed.y.toFixed(2)}%`  ; 
                        }
                        return label;
                    }
                }
            }
        },
    };
    return (
        <Card title="Device Engagement rate"
              loading={loading}
              extra={<Button 
                             loading={loading}
                             onClick={fetchData}
                             icon={<ReloadOutlined/>}/>
              }>
                <p>Device Engagement rate is the percentage of users who have used their Pavlok device at least once in a given week.</p>
            <Line data={chartData} options={options}/>
        </Card>
    )
}

export default DeviceEngagementRateMetrics