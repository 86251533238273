import React from 'react';
import {Button, Form, Input, notification, Select, Space, Spin, Switch} from "antd";

// ----- Local Calls -----
import {Review} from "../../../../../../types";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {updateReviewTopics} from "../../../../../../services/analytics";

// ----- Global variable -----
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {
        span: 24, offset: 0
    },
};

// ----- Types -----
interface EditTopicsProps {
    topics: string[];
    review: Review | null;
    onCancel: () => void;
}

// ----- Component -----
const EditTopics: React.FC<EditTopicsProps> = ({review, topics, onCancel}) => {
    // ----- Local State -----
    const [loading, setLoading] = React.useState(false);

    // ----- Form -----
    const [form] = Form.useForm();

    // ----- Handlers -----
    const onFinish = async (values: any) => {
        if (!review) return
        setLoading(true)
        try {
            const response = await updateReviewTopics({id: review.id, topics: values.topics})
            if (response && response.status === 200) {
                notification.success({message: 'Success', description: 'Review topics updated successfully'})
                return
            } else {
                notification.error({message: 'Error', description: 'Failed to update review topics'})
            }
        } catch (e) {
            console.error(e)
            notification.error({message: 'Error', description: 'Failed to update review topics'})
        } finally {
            setLoading(false)
        }
    }

    const initialValues = {
        topics: review?.topics || []
    }

    return (
        <Spin spinning={loading}>
            <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish} {...formLayout}>
                <Form.List name="topics">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}, index) =>
                                (
                                    <Space className="mb-2 w-100" wrap={true}>
                                        <Form.Item
                                            // noStyle={}
                                            {...restField}
                                            name={[name, 'topic']}
                                            // label="Topic"
                                            style={{width: '240px'}}
                                            className="mb-0"
                                            rules={[{required: true, message: 'Please input topic!'}]}
                                        >
                                            <Select className="w-100" placeholder="topic">
                                                {topics.map(topic => (
                                                    <Select.Option key={topic} value={topic}>{topic}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'impression']}
                                            // label="Impression"
                                            style={{width: '240px'}}
                                            className="mb-0"
                                            rules={[{required: true, message: 'Please select impression!'}]}
                                            valuePropName="checked"
                                            initialValue={false}
                                        >
                                            <Switch checkedChildren="Good" unCheckedChildren="Bad"/>
                                        </Form.Item>
                                        <Button type="default"
                                                danger
                                                onClick={() => remove(name)}
                                                icon={<MinusCircleOutlined/>}
                                        />
                                    </Space>

                                ))}

                            <Form.Item style={{width: '240px'}}>
                                <Button type="dashed"
                                        onClick={() => add()}
                                        block icon={<PlusOutlined/>}>
                                    Add topic
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Space>
                    <Button type="primary" onClick={() => form.submit()} disabled={loading}>Save</Button>
                    <Button type="default" onClick={onCancel}>Cancel</Button>
                </Space>
            </Form>

        </Spin>
    )
        ;
};

export default EditTopics;