import {AxiosResponse} from "axios";

import Api from "./api";
import {Banner} from "../types";

interface GetAllBannersResponse {
    banners: Banner[];
}

export interface BannerInCreate {
    title: string;
    url: string;
    text: string;
    live: boolean;
    audience: string;
    appName: string;
    expiryTime: string;
    storeItemId?: number;
    isOnboarding?: boolean;
    relativeExpiry?: number;
    backgroundColor?: string;
    image?: string;
}

export interface BannerInUpdate extends BannerInCreate {
    bannerId: number
}

export interface CreateBannerRequest {
    banner: BannerInCreate
}

interface UpdateBannerRequest {
    banner: BannerInUpdate;
}

interface GetBannerByIdResponse {
    banner: Banner;
}

export const getAllBanners = (): Promise<AxiosResponse<GetAllBannersResponse>> => {
    return Api.get("/admin/banners/all");
}

export const createBanner = (banner: BannerInCreate): Promise<AxiosResponse<GetBannerByIdResponse>> => {
    const request = {
        banner: banner,
    };

    return Api.post("/admin/banners/", request);
}

export const updateBanner = (banner: BannerInUpdate): Promise<AxiosResponse<GetBannerByIdResponse>> => {
    const request: UpdateBannerRequest = {
        banner: banner,
    };

    // todo: on backend change url to respect convention
    return Api.put(`/admin/banners/`, request);
}

export const getBannerById = (bannerId: number): Promise<AxiosResponse<GetBannerByIdResponse>> => {
    return Api.get(`/admin/banners/${bannerId}`);
}

export default {
    getAllBanners,
    createBanner,
    updateBanner,
    getBannerById,
};
