import React from 'react';
import {Modal, Button, Form, InputNumber, Popconfirm, notification, Spin} from 'antd';
import {addVolts} from "../../services/wallet";

// ------ Local calls ------

// ------ Global variables ------
const formLayout = {
    // labelCol: {span: 6},
    // wrapperCol: {span: 8},
};

// ------ Interfaces ------
interface AddVoltsModalProps {
    userId: string | undefined;
    open: boolean
    onOk?: () => void
    onCancel?: () => void
    onSuccess?: () => void
}

interface AddVoltsFormValues {
    volts: number
}

const AddVoltsModal: React.FC<AddVoltsModalProps> = ({userId, onCancel, onOk, open, onSuccess}) => {
    // ------ Form ------
    const [form] = Form.useForm();

    // ------ Data ------
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleFinish = async (values: AddVoltsFormValues) => {
        if (!userId) return;
        setLoading(true);
        try {
            const req = {
                user_id: Number(userId),
                volts: values.volts
            }
            const response = await addVolts(req);
            if (response.status === 200) {
                notification.success({message: 'Success', description: 'Volts added successfully'});
                onSuccess && onSuccess();
            } else {
                notification.error({message: 'Error', description: 'Failed to add volts'});
                return;
            }
        } catch (err) {
            console.error(err);
            notification.error({message: 'Error', description: 'Failed to add volts'});
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title="Add Volts" open={open} onCancel={onCancel}  footer={CancelBtn => <>{CancelBtn}</>}>
            <Spin spinning={loading}>
                <Form form={form} onFinish={handleFinish} {...formLayout} layout="inline">
                    <Form.Item name="volts" label="Volts">
                        <InputNumber min={0} max={100_000} style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item>
                        <Popconfirm
                            title="Are you sure you want to submit?"
                            onConfirm={() => form.submit()}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary">
                                Submit
                            </Button>
                        </Popconfirm>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default AddVoltsModal;