import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Upload
} from "antd";
import {useParams} from "react-router-dom";
import {DeleteOutlined, PlusCircleFilled, PlusOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload";

import CustomBreadcrumb from "components/CustomBreadcrumb";
import {HabitsService} from "services";
import {Habit, HabitInUpdate, HabitType, HabitWithQuestions} from "../../../types";
import {history} from "../../../utils/history";
import ImgCrop from "antd-img-crop";
import {getBase64, handleBeforeUpload} from "../../../utils/upload";
import UploadImageButton from "../../../components/UploadImageButton";
import ContentCategorySelect from "../../../components/ContentCategorySelect";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import strings_utils from "../../../utils/strings_utils";
import EditHabitQuestions from "./EditHabitQuestions";

const {Option} = Select

type RouteParams = {
    habitId?: string
}

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};

const EditHabitPage: React.FC = () => {
    // ----- params -----
    const {habitId} = useParams<RouteParams>();

    // ----- data -----
    const [habit, setHabit] = useState<HabitWithQuestions | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    // ----- form -----
    const [form] = Form.useForm();
    const [showHasDailyRecords, setShowHasDailyRecords] = useState(false)

    // ----- upload image -----
    // Image is set from the server = image Url
    const [iconImage, setIconImage] = useState<string | null>(null);
    const [iconGoalImage, setIconGoalImage] = useState<string | null>(null);
    // Icon base 64 is what the user is putting
    const [iconBase64, setIconBase64] = useState<string | null>(null);
    const [iconGoalBase64, setIconGoalBase64] = useState<string | null>(null);

    // ----- Init -----
    useEffect(() => {
        fetchHabit()
    }, [])

    useEffect(() => {
        const initialValues = {
            name: habit?.name,
            description: habit?.description,
            type: habit?.type,
            unit: habit?.unit,
            goalDefaultValue: habit?.goalDefaultValue,
            goalValueIncrements: habit?.goalValueIncrements,
            interfaceKey: habit?.interfaceKey,
            hasDailyRecords: habit?.hasDailyRecords,
            hidden: habit?.hidden,
            icon: habit?.iconUrl || null,
            iconGoal: habit?.iconGoalUrl || null,
            contentsCategoryId: habit?.contentsCategoryId,
        }
        // set the images
        setIconImage(habit?.iconUrl || null)
        setIconGoalImage(habit?.iconGoalUrl || null)
        if (habit?.type === HabitType.Positive) {
            setShowHasDailyRecords(true)
        }
        form.setFieldsValue(initialValues)
    }, [habit])

    const fetchHabit = () => {
        if (!habitId) return
        setLoading(true)
        HabitsService.getHabitById(habitId)
            .then(res => {
                const habitWithQuestions = res?.data?.habitWithQuestions || null
                setHabit(habitWithQuestions)
            })
            .catch(err => {
                console.error(err)
                message.success('Error getting habit! Check logs.')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // ----- Handlers -----
    const handleFinish = (values: any) => {
        if (!habit) return
        setLoading(true)
        const habitUpdate: HabitInUpdate = {
            id: Number(habitId),
            name: values.name,
            description: values.description,
            type: values.type,
            unit: values.unit,
            goalDefaultValue: values.goalDefaultValue,
            goalValueIncrements: values.goalValueIncrements,
            interfaceKey: values.interfaceKey,
            hasDailyRecords: values.hasDailyRecords,
            hidden: values.hidden,
            contentsCategoryId: values.contentsCategoryId,

            ...(iconBase64 ? {icon: iconBase64} : {}),
            ...(iconGoalBase64 ? {iconGoal: iconGoalBase64} : {}),
        }
        HabitsService.updateHabit(habitUpdate)
            .then(res => {
                const responseHabit = res?.data?.habit
                if (!responseHabit) {
                    throw Error('Error')
                    return
                }
                message.success('Updated')
                // history.navigate?.('/habits')
            })
            .catch(err => {
                console.error(err)
                message.error('Error creating habit! check logs.')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleDelete = () => {
        if (!habitId) return
        setLoading(true)
        HabitsService.deleteHabit(Number(habitId))
            .then(res => {
                const status = res?.status
                if (status === 204) {
                    message.success('Item deleted successfully')
                    history.navigate?.('/habits')
                    return
                }
                message.error('Error deleting item')
            })
            .catch(err => {
                console.error(err)
                message.error('Error deleting item')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleImageChange = (fieldName: string): UploadProps['onChange'] => (e: UploadChangeParam<UploadFile>) => {
        const file = e.file
        if (!file) return

        getBase64(file as RcFile, (img: string) => {
            console.log('handleImageChange', fieldName)
            form.setFieldValue(fieldName, img)
        })
    };

    const handleHabitTypeChange = (value: string) => {
        setShowHasDailyRecords(value === HabitType.Positive)
    }

    const handleAddQuestion = () => {
        // call the model
    };

    // ----- /end Handlers -----

    const generateInterfaceKey = () => {
        const name = form.getFieldValue('name')
        let key = ''
        key = name ? strings_utils.generateSlug(name) : strings_utils.generateRandomString();
        form.setFieldValue('interfaceKey', key)
    }



    return (
        <>
            <CustomBreadcrumb list={['Habits', 'Edit']}/>
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title={`Edit Habit #${habitId}`} loading={loading}>
                        <Form form={form}
                              onFinish={handleFinish}
                            // initialValues={initialValues}
                              {...formLayout}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{required: true, message: 'Please input the name!'}]}
                            >
                                <Input placeholder="Name"/>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={[{required: true, message: 'Please select the type!'}]}
                            >
                                <Select placeholder="Select type" onChange={handleHabitTypeChange}>
                                    <Option value="Positive">Positive</Option>
                                    <Option value="Neutral">Neutral</Option>
                                    <Option value="Negative">Negative</Option>
                                    <Option value="Saving">Saving</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{required: true, message: 'Please add description!'}]}
                            >
                                <Input.TextArea placeholder="Description"/>
                            </Form.Item>
                            <Form.Item
                                name="interfaceKey"
                                label="Interface key"
                                // rules={[{required: true, message: 'Please input the interface key!'}]}
                            >
                                <Input placeholder="Interface Key" suffix={
                                    <Button type="primary" htmlType="button" onClick={() => generateInterfaceKey()}>
                                        Generate
                                    </Button>
                                }/>
                            </Form.Item>
                            <Form.Item
                                name="hidden"
                                label="Hidden"
                                valuePropName="checked"
                            >
                                <Switch/>
                            </Form.Item>
                            <Form.Item label="Icon" name="icon" rules={[{required: true}]}>
                                <ImgCrop showGrid showReset aspect={1 / 1}>
                                    <Upload
                                        beforeUpload={handleBeforeUpload({
                                            config: {allowOnlyImage: true},
                                            callback: setIconBase64
                                        })}
                                        onChange={handleImageChange('icon')}
                                        accept="image/*"
                                        showUploadList={false}
                                        onRemove={() => console.log('Remove')}
                                        listType="picture-card"
                                        disabled={loading}
                                    >
                                        {
                                            (iconBase64 || iconImage) ?
                                                <img src={iconBase64 || iconImage || ''}
                                                     alt="Preview"
                                                     style={{maxWidth: "100%", borderRadius: 7}}/> :
                                                <UploadImageButton loading={loading}/>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item
                                name="contentsCategoryId"
                                label="Content Category ID"
                                // rules={[{required: true, message: 'Please input the content category ID!'}]}
                            >
                                <ContentCategorySelect placeholder="Content Category"/>
                            </Form.Item>
                            <Form.Item name="iconGoal" label="Icon Goal" rules={[{required: true}]}>
                                <ImgCrop showGrid showReset aspect={1 / 1}>
                                    <Upload
                                        beforeUpload={handleBeforeUpload({
                                            config: {allowOnlyImage: true},
                                            callback: setIconGoalBase64
                                        })}
                                        onChange={handleImageChange('iconGoal')}
                                        accept="image/*"
                                        showUploadList={false}
                                        onRemove={() => console.log('Remove')}
                                        listType="picture-card"
                                        disabled={loading}
                                    >
                                        {
                                            (iconGoalBase64 || iconGoalImage) ?
                                                <img src={iconGoalBase64 || iconGoalImage || ''}
                                                     alt="Preview"
                                                     style={{maxWidth: "100%", borderRadius: 7}}/> :
                                                <UploadImageButton loading={loading}/>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item
                                name="unit"
                                label="Unit"
                                rules={[{required: true}]}
                            >
                                <Input placeholder="Unit"/>
                            </Form.Item>
                            <Form.Item
                                name="goalDefaultValue"
                                label="Goal default value"
                                rules={[{required: true}]}
                            >
                                <InputNumber className="w-50" placeholder="Goal Default Value" min={0}/>
                            </Form.Item>
                            <Form.Item
                                name="goalValueIncrements"
                                label="Goal value increments"
                                rules={[{required: true}]}
                            >
                                <InputNumber className="w-50" placeholder="Goal Default Value" min={0}/>
                            </Form.Item>
                            {
                                showHasDailyRecords ?
                                    <Form.Item
                                        name="hasDailyRecords"
                                        label="Has daily records"
                                        rules={[{required: true}]}
                                        valuePropName="checked"
                                    >
                                        <Switch/>
                                    </Form.Item>
                                    : null
                            }
                            <Form.Item>
                                <Space align="end">
                                    <Popconfirm
                                        title="Delete habit"
                                        description="Are you sure you want to delete this item!!"
                                        onConfirm={() => handleDelete()}
                                        onCancel={() => console.log('Item is safe')}
                                        okText="Yes"
                                        cancelText="Cancel"
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            danger
                                            icon={<DeleteOutlined/>}
                                        >
                                            Delete
                                        </Button>
                                    </Popconfirm>

                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>

                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={18}>
                    <Card title="Questions" extra={<Space>
                        <Button
                            type="primary"
                            onClick={handleAddQuestion}
                            icon={<PlusOutlined/>}
                        >Add Question</Button>
                    </Space>}>
                        <EditHabitQuestions habit={habit} loading={loading}/>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default EditHabitPage