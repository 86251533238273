import React from 'react';
import {Route, Routes} from 'react-router-dom';


import UsersListPage from "../pages/users/UsersListPage";
import UserDetailsPage from "../pages/users/UserDetailsPage";
import HomePage from "../pages/HomePage";
import DeviceDetailsPage from "../pages/devices/DeviceDetailsPage";
import PageNotFound from "../pages/errors/NotFoundPage";
import BannersListPage from "../pages/banners/BannersListPage";
import CreateBannerPage from "../pages/banners/CreateBannerPage";
import DevicesListPage from "../pages/devices/DevicesListPage";
import BadgesListPage from "../pages/badges/BadgesListPage";
import CreateBadgePage from "../pages/badges/CreateBadgePage";
import VoltsActionsListPage from "../pages/volts-actions/VoltsActionsListPage";
import CreateVoltsActionPage from "../pages/volts-actions/CreateVoltsActionPage";
import ChallengesListPage from "../pages/challenges/ChallengesListPage";
import CreateChallengePage from "../pages/challenges/CreateChallengesPage";
import CreateHabitPage from "../pages/habits/CreateHabitPage";
import HabitsListPage from "../pages/habits/HabitsListPage";
import EditHabitPage from "../pages/habits/EditHabitPage";
import EditUserPage from "../pages/users/EditUserPage";
import FirmwaresListPage from "../pages/firmwares/FirmwaresListPage";
import CreateFirmwarePage from "../pages/firmwares/CreateFirmwarePage";
import UserLogsListPage from "../pages/logs/UserLogsListPage";
import DiagnosticLogsListPage from "../pages/logs/DiagnosticLogsListPage";
import LogoutPage from "../pages/auth/LogoutPage";
import MetricsPage from "../pages/metrics";
import VoltsTransactionsListPage from "../pages/VoltsTransactionsPage";
import CampaignsListPage from "../pages/campaigns/CampaignsListPage";
import CreateCampaignPage from "../pages/campaigns/CreateCampaignPage";
import ChallengeDetailsPage from "../pages/challenges/ChallengeDetailsPage";

interface AppRoutesProps {
    isLoggedIn?: boolean
    handleLogin?: () => void
}

const AppRoutes: React.FC<AppRoutesProps> = () => {

    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/dashboard" element={<HomePage/>}/>

            <Route path="/users" element={<UsersListPage/>}/>
            <Route path="/users/:userId" element={<UserDetailsPage/>}/>
            <Route path="/users/edit/:userId" element={<EditUserPage/>}/>

            <Route path="/devices" element={<DevicesListPage/>}/>
            <Route path="/devices/:deviceId" element={<DeviceDetailsPage/>}/>

            <Route path="/banners" element={<BannersListPage/>}/>
            <Route path="/banners/create" element={<CreateBannerPage/>}/>
            <Route path="/banners/edit/:bannerId" element={<CreateBannerPage/>}/>

            <Route path="/badges" element={<BadgesListPage/>}/>
            <Route path="/badges/create" element={<CreateBadgePage/>}/>
            <Route path="/badges/edit/:badgeId" element={<CreateBadgePage/>}/>

            <Route path="/volts-actions" element={<VoltsActionsListPage/>}/>
            <Route path="/volts-actions/create" element={<CreateVoltsActionPage/>}/>
            <Route path="/volts-actions/edit/:voltsActionId" element={<CreateVoltsActionPage/>}/>

            <Route path="/challenges" element={<ChallengesListPage/>}/>
            <Route path="/challenges/create" element={<CreateChallengePage/>}/>
            <Route path="/challenges/edit/:challengeId" element={<CreateChallengePage/>}/>
            <Route path="/challenges/:challengeId" element={<ChallengeDetailsPage/>}/>

            <Route path="/habits" element={<HabitsListPage/>}/>
            <Route path="/habits/create" element={<CreateHabitPage/>}/>
            <Route path="/habits/edit/:habitId" element={<EditHabitPage/>}/>

            <Route path="/firmwares" element={<FirmwaresListPage/>}/>
            <Route path="/firmwares/create" element={<CreateFirmwarePage/>}/>
            <Route path="/firmwares/edit/:firmwareId" element={<CreateFirmwarePage/>}/>

            <Route path="/campaigns" element={<CampaignsListPage/>}/>
            <Route path="/campaigns/create" element={<CreateCampaignPage/>}/>

            <Route path="/user-logs" element={<UserLogsListPage/>}/>
            <Route path="/diagnostic-logs" element={<DiagnosticLogsListPage/>}/>

            <Route path="/volts-transactions/:userId" element={<VoltsTransactionsListPage/>}/>

            <Route path="/logout" element={<LogoutPage/>}/>
            <Route path="/metrics" element={<MetricsPage/>}/>


            <Route path="*" element={<PageNotFound/>}/>
        </Routes>
    );
};

export default AppRoutes;