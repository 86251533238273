import React, {useEffect, useRef, useState} from 'react';
import {
    ControlOutlined,
    ReloadOutlined, UserAddOutlined,
} from '@ant-design/icons';
import type {InputRef} from 'antd';
import {Button, Card, Space, Table, Tag, Tooltip} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link} from "react-router-dom";
import dayjs from "dayjs";

// ----- Local calls -----
import {Device, SleepDataset, UserLogsRecord} from "../../types";
import {getColumnSearchProps} from "../TableSearch";
import {SleepDatasetService, UserLogsService} from "../../services";
import {addKeyToObjectArray} from "../../utils/array.utils";
import {
    GetUserLogsRecordsByUserIdParams,
} from "../../services/user-logs";

// ----- Types -----
interface SleepDatasetsListProps {
    userId?: string
}

type CombinedType = {
    key?: string
    userLogId: number | null,
    sleepDatasetId: number | null,
    night?: Date;
    wakeUp?: Date;
    device?: Device | null;
    hidden?: boolean | null;
    date?: Date;
    trackingType?: string;
    userLogDuration?: string;
};

// ----- Components -----
const SleepDatasetsList: React.FC<SleepDatasetsListProps> = ({userId}) => {
    // ----- States -----
    const [data, setData] = useState<CombinedType[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- table -----
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    // ----- End table -----

    // ----- Effects -----
    useEffect(() => {
        fetchData()
    }, [userId])

    // ----- Functions -----
    const fetchData = async () => {
        if (!userId && Number(userId)) return
        setLoading(true)
        // Param to get user logs data
        const userLogsParams: GetUserLogsRecordsByUserIdParams = {
            user_id: Number(userId),
            // mac_address: 'FDC6E5B9CFD1', // "FDC6E5B9CFD1",
            types: ['SleepRecord'],
            // ...(filters?.startDate ? {start_date: filters?.startDate} : {}),
            // ...(filters?.endDate ? {end_date: filters?.endDate} : {}),
            page: 1,
            page_size: 500,
        }
        try {

            // Get  the user logs and sleep datasets
            const [userLogsResponse, sleepDatasetsResponse] = await Promise.all([
                UserLogsService.getUserLogsRecordsByUserId(userLogsParams),
                SleepDatasetService.getAllSleepDatasets(Number(userId))
            ]);

            const sleepDatasets = sleepDatasetsResponse.data.sleepDatasets;
            const userLogs: UserLogsRecord[] = userLogsResponse.items;
            const joinedDataset: CombinedType[] = userLogs.reduce((acc: CombinedType[], userLog: UserLogsRecord) => {
                const match = userLog.reason.match(/from (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}) to (\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}) \((\d{1,2}:\d{2}:\d{2})\)/);
                // if no match, skip to the next element
                if (!match) return acc;

                const userLogNight = new Date(match[1]);
                const userLogWakeUp = new Date(match[2]);
                const userLogDuration = match[3];

                // Search for the matching dataset and remove the element from the sleepDatasets.
                const matchingDataset: SleepDataset | undefined = sleepDatasets.find((dataset: SleepDataset, datasetIndex) => {
                    const datasetNight = dataset.night ? new Date((dataset.night + "").replace('T', ' ').replace('Z', '')) : undefined;
                    const datasetWakeUp = dataset.wakeUp ? new Date((dataset.wakeUp + "").replace('T', ' ').replace('Z', '')) : undefined;

                    // Remove elment from sleepDatasetsResponse
                    if (datasetNight && datasetWakeUp && datasetNight.getTime() === userLogNight.getTime() && datasetWakeUp.getTime() === userLogWakeUp.getTime()) {
                        sleepDatasets.splice(datasetIndex, 1);
                        return true;
                    }
                    return false
                });

                if (matchingDataset) {
                    acc.push({
                        // User log
                        userLogId: userLog.id,
                        // sleepDuration: userLogDuration,
                        // Sleep dataset
                        sleepDatasetId: matchingDataset.id,
                        date: matchingDataset.userDate,
                        night: new Date(dayjs(matchingDataset.night).format('YYYY-MM-DDTHH:mm:ssZ')),
                        wakeUp: new Date(dayjs(matchingDataset.wakeUp).format('YYYY-MM-DDTHH:mm:ssZ')),
                        device: matchingDataset.device,
                        hidden: matchingDataset.hidden,
                        trackingType: matchingDataset.trackingType
                    });
                } else {
                    acc.push({
                        // User log
                        userLogId: userLog.id,
                        // sleepDuration: userLogDuration,
                        sleepDatasetId: null,
                        date: new Date(dayjs(userLogNight).format('YYYY-MM-DD')),
                        night: new Date(dayjs(userLogNight).format('YYYY-MM-DD HH:mm:ss')),
                        wakeUp: new Date(dayjs(userLogWakeUp).format('YYYY-MM-DD HH:mm:ss')),
                        device: null,
                        hidden: null,
                        userLogDuration: userLogDuration,
                    });
                }
                return acc
            }, []);
            const results = sleepDatasets.map((dataset: SleepDataset) => ({
                userLogId: null,
                sleepDuration: null,
                sleepDatasetId: dataset.id,
                date: dataset.userDate,
                night: dataset.night,
                wakeUp: dataset.wakeUp,
                device: dataset.device,
                hidden: dataset.hidden,
                trackingType: dataset.trackingType
            }))

            const mergedData: CombinedType[] = [...joinedDataset, ...results]
            const responseFormatted = addKeyToObjectArray<CombinedType>(mergedData, 'key', 'sleep-dataset')
            setData(responseFormatted)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    // ----- Columns -----
    const columns: ColumnsType<CombinedType> = [

        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'date',
                dataType: "date"
            }),
        },
        {
            title: 'Night',
            dataIndex: 'night',
            key: 'night',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'night',
                dataType: "datetime"
            }),
            render: (text) => dayjs(text).format('HH:mm'),
        },
        {
            title: 'Wake Up',
            dataIndex: 'wakeUp',
            key: 'wakeUp',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'wakeUp',
                dataType: "datetime"
            }),
            render: (text, record) => {
                const wakeUpDate = dayjs(text);
                const nightDate = dayjs(record.night);
                if (wakeUpDate.isAfter(nightDate, 'day')) {
                    return (
                        <>
                            {wakeUpDate.add(1, 'day').format('HH:mm')}
                            <Tag color="green" style={{marginLeft: 8}}>+1</Tag>
                        </>
                    );
                }
                return wakeUpDate.format('HH:mm');
            },
        },
        {
            title: 'Sleep Duration',
            key: 'sleepDuration',
            sorter: (a, b) => {
                const wakeUpDateA = dayjs(a.wakeUp);
                const nightDateA = dayjs(a.night);
                const durationInMinutesA = wakeUpDateA.diff(nightDateA, 'minute');

                const wakeUpDateB = dayjs(b.wakeUp);
                const nightDateB = dayjs(b.night);
                const durationInMinutesB = wakeUpDateB.diff(nightDateB, 'minute');

                return durationInMinutesA - durationInMinutesB;
            },
            render: (text, record) => {
                const wakeUpDate = dayjs(record.wakeUp);
                const nightDate = dayjs(record.night);
                const durationInMinutes = wakeUpDate.diff(nightDate, 'minute');
                const hours = Math.floor(durationInMinutes / 60);
                const minutes = durationInMinutes % 60;
                return `${hours}h ${minutes}min`;
            },
        },
        {
            title: 'Device',
            dataIndex: 'device.name',
            key: 'wakeUp',
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'device.name',
                dataType: "string"
            }),
            render: (text, record) => {
                return (
                    <Space>
                        {
                            record.device &&
                            <Link type="link" to={`/devices/${record.device?.id}`}>{record.device?.name}</Link>
                        }
                        {
                            record.trackingType === "automatic" && (
                                <Tooltip title="Automatically started"><ControlOutlined/></Tooltip>
                            )
                        }
                        {
                            record.trackingType === "manual"
                            && (
                                <Tooltip title="Manually started"><UserAddOutlined color="#124142"/></Tooltip>
                            )
                        }
                    </Space>
                )
            }

        },
        {
            title: 'Hidden',
            dataIndex: 'hidden',
            key: 'hidden',
            render: (text, record) => {
                if (record.hidden === null) return '-'
                return record.hidden ? 'Yes' : 'No'
            },
            filters: [
                {
                    text: 'True',
                    value: true,
                }, {
                    text: 'False',
                    value: false,
                },
            ],
            onFilter: (value: string | number | boolean, record: CombinedType) => {
                return record.hidden === value
            },
        },
        {
            title: '#userLogId',
            dataIndex: 'userLogId',
            key: 'userLogId',
            sorter: (a, b) => {
                if (a.userLogId === null && b.userLogId === null) {
                    return 0;
                } else if (a.userLogId === null) {
                    return -1;
                } else if (b.userLogId === null) {
                    return 1;
                } else {
                    return a.userLogId - b.userLogId;
                }
            },
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'userLogId',
                // dataType: "number"
            }),
        },
        {
            title: '#sleepDatasetId',
            dataIndex: 'sleepDatasetId',
            key: 'sleepDatasetId',
            sorter: (a, b) => {
                if (a.sleepDatasetId === null && b.sleepDatasetId === null) {
                    return 0;
                } else if (a.sleepDatasetId === null) {
                    return -1;
                } else if (b.sleepDatasetId === null) {
                    return 1;
                } else {
                    return a.sleepDatasetId - b.sleepDatasetId;
                }
            },
            ...getColumnSearchProps({
                searchInput,
                setSearchText,
                setSearchedColumn,
                searchedColumn,
                searchText,
                dataIndex: 'sleepDatasetId',
                // dataType: "number"
            }),
        },
    ];

    return (
        <Card title="Sleep Datasets"
              extra={
                  <Button
                      loading={loading}
                      onClick={() => fetchData()}
                      icon={<ReloadOutlined/>}/>
              }
        >
            <Table<CombinedType>
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{x: 400}}
                bordered
                size="small"
            />
        </Card>
    )

};

export default SleepDatasetsList;