import {AiChatThread} from "../types";
import {isIsoDateString} from "./datetime";

export function toLowerCamelCase(str: string): string {
    // convert this to_lower_dash_case to toLowerDashCase
    return str.replace(/_([a-z])/g, g => g[1].toUpperCase());
}

export function deepCamelCaseKeys<T>(obj: any): T {
    if (Array.isArray(obj)) {
        return obj.map(deepCamelCaseKeys) as unknown as T;
    } else if (obj !== null && typeof obj === "object") {
        return Object.keys(obj).reduce((acc, key) => {
            acc[toLowerCamelCase(key) as keyof typeof acc] = deepCamelCaseKeys(obj[key]);
            return acc;
        }, {} as Partial<T>) as T;
    }
    return obj as T;
}


export function isAiChatThread(obj: any): obj is AiChatThread {
    return obj &&
        typeof obj.id === 'number' &&
        typeof obj.userId === 'number' &&
        typeof obj.title === 'string' &&
        typeof obj.gptModel === 'string' &&
        typeof obj.hidden === 'boolean' &&
        // Assuming BaseModel and DateTimeModelMixin have specific properties to check
        isIsoDateString(obj.createdAt) &&
        isIsoDateString(obj.updatedAt) &&
        (isIsoDateString(obj.deletedAt) || obj.deletedAt === null)
}