import React from "react"
import {Button, Form, Spin} from "antd";

import {CampaignInCreate} from "types";
import FcmTopicsSelect from "components/FcmTopicsSelect";
import {FormInstance} from "antd/es/form/hooks/useForm";

// ----- Variables -----
const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};

// ----- Types -----
interface TargetStepProps {
    form: FormInstance
    campaign: CampaignInCreate | null
    loading: boolean
    setCampaign: (step: any) => void
    handleNext?: () => void
    handlePrev?: () => void
    currentStep?: number
    handleSubmit?: () => Promise<any>
}

const TargetStep: React.FC<TargetStepProps> = ({campaign, form, loading, setCampaign, handleSubmit}) => {
    // ----- form -----
    // const [form] = Form.useForm();

    // ----- Handlers -----
    const handleFinish = () => {
        handleSubmit?.()
    }

    const handleTopicsNamesChange = (value: string) => {
        setCampaign((currentValue: CampaignInCreate | null) => ({
            ...(currentValue ? currentValue : {}),
            topicsNames: [value]
        }))
    }

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={handleFinish}
                {...formLayout}
            >
                <Form.Item
                    label="Select Topic"
                    name="topicsNames"
                    rules={[{required: true, message: 'Please select a topic!'}]}
                >
                    <FcmTopicsSelect onSelect={(value: string) => handleTopicsNamesChange(value)}/>
                </Form.Item>
                <div style={{textAlign: 'right'}}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Spin>
    )
}

export default TargetStep