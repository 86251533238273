import React, {Fragment} from "react"
import {Descriptions, Modal, Typography} from "antd";
import {AndroidOutlined, AppleOutlined} from "@ant-design/icons";
import {Tooltip} from "antd/lib";

import {StimulusMetaDeviceItem} from "../../../../../types";

// ----- Types -----
interface StimulusMetaModalProps {
    meta: any
    open: boolean
    onOk?: () => void
    onCancel?: () => void
}

const StimulusMetaModal: React.FC<StimulusMetaModalProps> = ({meta, onCancel, onOk, open}) => {
    // todo: parse outside of this component
    const parsedResponse = meta && JSON.parse(meta)
    return (
        <Modal title="Delivery status by phone device" open={open} onOk={onOk} onCancel={onCancel} width={800}>
            <Descriptions column={3}>
                {
                    parsedResponse && parsedResponse?.status?.devices
                        ?.sort((a: StimulusMetaDeviceItem, b: StimulusMetaDeviceItem) => {
                            // compare two boolean
                            if (a.delivered === b.delivered) {
                                return 0;
                            }
                            return a.delivered ? -1 : 1;
                        })
                        ?.map((item: StimulusMetaDeviceItem) => (
                            <Fragment key={`token-${item.fcm_token}`}>
                                <Descriptions.Item label="Device name">
                                    {item?.os_name === "ios" ? <AppleOutlined/> : <AndroidOutlined/>}
                                    {item?.device_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="Delivered">
                                    {item?.delivered ? '🟢' : '🔴'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Token">
                                    <Tooltip title={item?.fcm_token}>
                                        <Typography.Paragraph ellipsis={{rows: 1}} copyable={{text: item?.fcm_token}}>
                                            {item?.fcm_token.slice(0, 20)}...
                                        </Typography.Paragraph>
                                    </Tooltip>
                                </Descriptions.Item>
                            </Fragment>
                        ))
                }
            </Descriptions>
        </Modal>
    )
}

export default StimulusMetaModal