import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Select, Space, Switch, Upload} from "antd";

import {HabitInCreate} from "types";
import strings_utils, {generateRandomString, generateSlug} from "../../../../utils/strings_utils";
import ContentCategorySelect from "../../../../components/ContentCategorySelect";
import ImgCrop from "antd-img-crop";
import {getBase64, handleBeforeUpload} from "../../../../utils/upload";
import UploadImageButton from "../../../../components/UploadImageButton";
import {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import {UploadChangeParam} from "antd/es/upload";


const {Option} = Select;

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 12},
};

interface HabitSetupProps {
    habit: HabitInCreate | null
    loading: boolean
    setHabit: (step: any) => void
    handleNext?: () => void
    handlePrev?: () => void
    currentStep?: number
}

const HabitSetup: React.FC<HabitSetupProps> = ({habit, loading, setHabit, handleNext}) => {
    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        form.setFieldsValue(habit)
        const icon = habit?.icon || null
        setImage(icon)
    }, [habit])

    const handleFinish = (values: any) => {
        console.log(values)
        setHabit((currentHabit: HabitInCreate | null) => ({
            ...(currentHabit ? currentHabit : {}),
            ...values
        }))
        handleNext?.()
    }

    const handleSave = () => {
        const values = form.getFieldsValue()
        setHabit((currentHabit: HabitInCreate | null) => ({
            ...(currentHabit ? currentHabit : {}),
            ...values
        }))
        message.success('Item saved locally')
    }

    const generateInterfaceKey = () => {
        const name = form.getFieldValue('name')
        let key = ''
        if (name) {
            // convert name to key
            key = strings_utils.generateSlug(name)
        } else {
            // generate random key string
            key = strings_utils.generateRandomString()
        }
        form.setFieldValue('interfaceKey', key)
    }

    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam<UploadFile>) => {
        console.log('image changed', e)
        const file = e.file
        if (!file) return

        getBase64(file as RcFile, (img: string) => {
            form.setFieldValue('icon', img)
        })
    };

    const initialValues = {
        hidden: false
    }

    return (
        <>
            <Form form={form}
                  onFinish={handleFinish}
                  initialValues={initialValues}
                  {...formLayout}>
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{required: true, message: 'Please input the name!'}]}
                >
                    <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{required: true, message: 'Please select the type!'}]}
                >
                    <Select placeholder="Select type">
                        <Option value="Positive">Positive</Option>
                        <Option value="Neutral">Neutral</Option>
                        <Option value="Negative">Negative</Option>
                        <Option value="Saving">Saving</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{required: true, message: 'Please add description!'}]}
                >
                    <Input.TextArea placeholder="Description"/>
                </Form.Item>
                <Form.Item
                    name="interfaceKey"
                    label="Interface key"
                    // rules={[{required: true, message: 'Please input the interface key!'}]}
                >
                    <Input placeholder="Interface Key" suffix={
                        <Button type="primary" htmlType="button" onClick={() => generateInterfaceKey()}>
                            Generate
                        </Button>
                    }/>
                </Form.Item>
                <Form.Item
                    name="hidden"
                    label="Hidden"
                    valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>
                <Form.Item label="Icon" name="icon" rules={[{required: true}]}>
                    <ImgCrop showGrid showReset aspect={1 / 1}>
                        <Upload
                            beforeUpload={handleBeforeUpload({
                                config: {allowOnlyImage: true},
                                callback: setImageBase64
                            })}
                            onChange={handleImageChange}
                            accept="image/*"
                            showUploadList={false}
                            onRemove={() => console.log('Remove')}
                            listType="picture-card"
                            disabled={loading}
                        >
                            {
                                (imageBase64 || image) ?
                                    <img src={imageBase64 || image || ''}
                                         alt="Preview"
                                         style={{maxWidth: "100%", borderRadius: 7}}/> :
                                    <UploadImageButton loading={loading}/>
                            }
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    name="contentsCategoryId"
                    label="Content Category ID"
                    // rules={[{required: true, message: 'Please input the content category ID!'}]}
                >
                    <ContentCategorySelect placeholder="Content Category"/>
                </Form.Item>
                <div>
                </div>
                <Form.Item>
                    <Space>
                        <Button hidden type="primary" htmlType="button" onClick={() => handleSave()}>
                            Save
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default HabitSetup