import React, {useEffect, useState} from "react";
import {Form, Input, Button, Card, Typography, Alert} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";

import LocalStorage from "utils/local-storage";
import {login} from 'redux/authSlice';
import {AuthService} from "services";
import config from "config";
import bgLogin from 'assets/images/bg-login.jpg'
import "./style.css";
import {AxiosError} from "axios";
import {set} from "lodash";

interface LoginValues {
    username: string;
    password: string;
}

type LoginPageProps = {
    onLogin?: () => void
}

const ADMIN_AUTHORIZED_ROLES = [
    'root', 'super-admin', 'support'
]

const LoginPage: React.FC<LoginPageProps> = () => {
    // ----- Navigation -----
    const navigate = useNavigate();
    // ----- Store -----
    const dispatch = useDispatch();
    // ----- Data -----
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        console.log('LoginPage')
    }, [])

    const onFinish = async (values: LoginValues) => {
        setLoading(true);
        setError(null)
        try {
            const response = await AuthService.login(values)
            const user = response?.data?.user;
            const token = response?.data?.user?.token
            if (!token || !user) {
                setError('Token non valid')
                return
            }
            if (!ADMIN_AUTHORIZED_ROLES.includes((user?.role || '').toLowerCase())) {
                setError('Not authorized user')
                return
            }
            // Dispatch login action
            dispatch(login({token, authenticatedUser: user}));
            LocalStorage.storeItem("token", token)
            navigate("/dashboard");
            // onLogin()
        } catch (error) {
            console.error("Login failed:", error);
            let errorMessage = "Login failed!!"
            if (error instanceof AxiosError) {
                errorMessage = error?.response?.data?.errors?.[0] || errorMessage
            }
            setError(errorMessage)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container" style={{backgroundImage: `url(${bgLogin})`}}>
            <Card className="login-card">
                <Typography.Title level={3} style={{textAlign: "center"}}>
                    Admin Dashboard
                </Typography.Title>
                <Typography.Paragraph style={{textAlign: "center"}}>
                    You are using <Typography.Text code>{config.app.environment}</Typography.Text> environment
                </Typography.Paragraph>
                <Form onFinish={onFinish} initialValues={{environment: 'dev'}}>
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: "Please input your username!"}]}
                    >
                        <Input
                            placeholder="Username"
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: "Please input your password!"}]}
                    >
                        <Input.Password
                            placeholder="Password"
                            iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                            prefix={<LockOutlined/>}
                        />
                    </Form.Item>

                    {
                        error &&
                        <Form.Item>
                            <Alert
                                message="Error"
                                description={error}
                                type="error"
                            />
                        </Form.Item>
                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginPage;
