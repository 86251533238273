import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    Space,
    Tabs,
    Typography
} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import type {TabsProps} from 'antd';

// ----- local calls -----
import {ChallengesService} from "services";
import {Leaderboard, Participant} from "../../../../../types";
import TeamsRanking from "./TeamsRanking";
import GlobalRanking from "./GlobalRanking";


// ----- Global variables -----
const {Title} = Typography

// ----- Types -----
interface LeaderboardCardProps {
    challengeId: number
}

// ----- Components -----
const LeaderboardCard: React.FC<LeaderboardCardProps> = (
    {challengeId}
) => {
    // ----- data -----
    const [leaderboard, setLeaderboard] = useState<Leaderboard | null>(null)
    const [loading, setLoading] = useState(false); // for all the fetches

    // ----- Search -----

    // ----- Init -----
    useEffect(() => {
        // if challenge id then Edit case
        if (challengeId) {
            fetchData()
        }
    }, [challengeId])

    const fetchData = () => {
        if (!challengeId) return
        setLoading(true)
        ChallengesService.getChallengeLeaderboard(challengeId)
            .then(res => {
                const leaderboardResponse = res?.data
                setLeaderboard(leaderboardResponse)
            })
            .catch(err => {
                console.error('Error fetching leaderboard, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onLoadMore = () => {
        console.log("onLoadMore",)
    }

    const TABS_ITEMS: TabsProps['items'] = [
        {
            label: 'Global Ranking',
            key: 'global-ranking',
            children: <GlobalRanking teams={leaderboard?.teams || null} loading={loading}/>,
        },
        {
            label: 'Teams Ranking',
            key: 'teams-ranking',
            children: <TeamsRanking teams={leaderboard?.teams || null} loading={loading}/>,
        },
    ]
    return (
        <Card title="Leaderboard"
              loading={loading}
              extra={
                  <Space>
                      <Button 
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                  </Space>
              }>
            {/*<Title level={3}>All Leaderboard Ranking</Title>*/}
            <Tabs
                defaultActiveKey="1"
                items={TABS_ITEMS}
            />
        </Card>
    )
}
export default LeaderboardCard