import React from "react"
import {Image} from "antd";

import {Challenge} from "types";
import PhonePreviewLayout from "components/PhonePreviewLayout";
import DefaultChallenge from "assets/images/challenge/default.png";
import Phone1 from "assets/images/banner/phone-1.png";
import Phone2 from "assets/images/banner/phone-2.png";

interface PhonePreviewProps {
    challenge: Challenge | null
    loading: boolean
}

const ChallengePhonePreview: React.FC<PhonePreviewProps> = ({challenge,loading}) => {
    return (
        <PhonePreviewLayout loading={loading}>
            <Image src={Phone1} alt="Phone" width="100%" preview={false}/>
            <div className="container-center-x" style={{marginTop: 10, marginBottom: 22,}}>
                {challenge?.iconUrl ? <Image src={challenge.iconUrl || ""}
                                             alt="Preview"
                                             preview={false}
                                             height={100}
                                             width="100%"
                                             wrapperStyle={{
                                                 paddingLeft: 10,
                                                 paddingRight: 10,
                                             }}
                                             style={{
                                                 minWidth: '100%',
                                                 borderRadius: 16,
                                                 boxShadow: "0px 8px 20px #BDACFB, inset 0px -3px 10px rgba(0, 0, 0, 0.37), inset 3px 1px 15px rgba(255, 255, 255, 0.5)"
                                             }}
                    />
                    : <Image src={DefaultChallenge || ""}
                             alt="Preview"
                             preview={false}
                             height={100}
                             wrapperStyle={{
                                 paddingLeft: 10,
                                 paddingRight: 10,
                             }}
                             style={{
                                 minWidth: '100%',
                                 borderRadius: 16,
                                 boxShadow: "0px 8px 20px #BDACFB, inset 0px -3px 10px rgba(0, 0, 0, 0.37), inset 3px 1px 15px rgba(255, 255, 255, 0.5)"
                             }}/>
                }
            </div>
            <Image src={Phone2} width="100%" height="100%" preview={false}
                   style={{borderBottomLeftRadius: 30, borderBottomRightRadius: 30, height: '55%'}}/>
        </PhonePreviewLayout>
    )
}

export default ChallengePhonePreview