import {AxiosResponse} from "axios";

import Api from "./api";
import {Campaign, CampaignInCreate, FcmTopicsResponse} from "types";

interface GetCampaignByIdResponse {
    campaign: Campaign;
}

interface GetAllCampaignsResponse {
    campaigns: Campaign[];
}

// Data structure for FCM topics


export const getCampaignById = (campaignId: number): Promise<AxiosResponse<GetCampaignByIdResponse>> => {
    return Api.get(`/admin/campaigns/${campaignId}`);
}

export const getAllCampaigns = (): Promise<AxiosResponse<GetAllCampaignsResponse>> => {
    return Api.get("/admin/campaigns/");
}

export const getAllTopics = (): Promise<AxiosResponse<FcmTopicsResponse>> => {
    return Api.get("/admin/fcm-topics/");
}

export const createCampaignNotification = (campaignInCreate: CampaignInCreate): Promise<AxiosResponse<any>> => {
    const request = {
        notification: campaignInCreate,
    };

    return Api.post("/admin/campaigns/", request);
}

export default {
    getCampaignById,
    getAllCampaigns,
    createCampaignNotification,
    getAllTopics
};
