/*
* Handle local storage
* */

export const storeItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
}

export const getItem = (key: string): string | null => {
    return localStorage.getItem(key)
}

export const deleteItem = (key: string): void => {
    localStorage.removeItem(key)
}

export default {
    storeItem, getItem, deleteItem
}
