import React, {useCallback, useEffect, useState} from 'react';
import {Form, Image, Input, InputNumber, Popconfirm, Switch, Table, Tag, Typography, Upload} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

import {HabitWithQuestions, PossibleAnswer, Question} from "../../../../types";
import ImgCrop from "antd-img-crop";
import {handleBeforeUpload} from "../../../../utils/upload";
import UploadImageButton from "../../../../components/UploadImageButton";


interface EditHabitQuestionsProps {
    habit: HabitWithQuestions | null
    loading: boolean
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'boolean' | 'image';
    record: Question | PossibleAnswer;
    handleSave: (record: Question | PossibleAnswer) => void;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = (
    {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        handleSave,
        index,
        children,
        ...restProps
    }
) => {
    let inputNode = null
    switch (inputType) {
        case "number":
            inputNode = <InputNumber/>
            break
        case "text":
            inputNode = <Input/>
            break
        case "boolean":
            inputNode = <Switch/>
            break
        case "image":
            inputNode = <ImgCrop showGrid showReset aspect={1 / 1}>
                <Upload
                    beforeUpload={handleBeforeUpload({
                        config: {allowOnlyImage: true},
                        callback: () => ({})
                    })}
                    // onChange={handleImageChange('icon')}
                    accept="image/*"
                    showUploadList={false}
                    onRemove={() => console.log('Remove')}
                    listType="picture-card"
                    // disabled={loading}
                >
                    {
                        /*(iconBase64 || iconImage) ?
                            <img src={iconBase64 || iconImage || ''}
                                 alt="Preview"
                                 style={{maxWidth: "100%", borderRadius: 7}}/> :*/
                        <UploadImageButton
                            loading={false}
                        />
                    }
                </Upload>
            </ImgCrop>
            break
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                    {...(inputType === 'boolean' ? {valuePropName: 'checked'} : {})}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditHabitQuestions: React.FC<EditHabitQuestionsProps> = ({habit, loading}) => {
    // ----- Form -----
    const [form] = Form.useForm();
    // ----- Data -----
    const [data, setData] = useState<Question[]>(habit?.questions || []);
    const [editingId, setEditingId] = useState<number>();
    const [answerEditingId, setAnswerEditingId] = useState<number>();

    useEffect(() => {
        setData(habit?.questions || [])
    }, [habit?.questions])

    const isEditing = (record: Question) => record.id === editingId;

    //----- Answers -----
    const isAnswerEditing = (record: PossibleAnswer) => record.id === answerEditingId;

    const editAnswer = (record: PossibleAnswer) => {
        console.log(record)
        form.setFieldsValue({...record});
        setAnswerEditingId(record.id);
    }

    const cancelEditAnswer = () => {
        setAnswerEditingId(undefined);
    };

    const saveAnswer = (record: PossibleAnswer) => {
        console.log(record)
    }

    //----- Answers -----

    //----- Questions actions -----
    const editQuestion = (record: Question) => {
        form.setFieldsValue({...record});
        setEditingId(record.id);
    };

    const cancelEditQuestion = () => {
        setEditingId(undefined);
    };

    const saveQuestion = async (record: Question) => {
        try {
            const row = (await form.validateFields()) as Question;

            const newData = [...data];
            const index = newData.findIndex((item) => record.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingId(undefined);
            } else {
                newData.push(row);
                setData(newData);
                setEditingId(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    //----- End Questions actions -----


    const columns = [
        {
            title: 'Question',
            dataIndex: 'text',
            editable: true,
        },
        {
            title: 'Multiselect',
            dataIndex: 'multiselect',
            editable: true,
            render: (value: boolean) => value ?
                <Tag color="green"><CheckCircleOutlined/></Tag>
                :
                <Tag color="volcano"><CloseCircleOutlined/></Tag>
        },
        {
            title: 'Possible Answers',
            dataIndex: 'possibleAnswers',
            render: (possibleAnswers: PossibleAnswer[]) => (<>{possibleAnswers?.length} answers</>),
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_: any, record: Question) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => saveQuestion(record)} style={{marginRight: 8}}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancelEditQuestion}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingId !== undefined} onClick={() => editQuestion(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Question) => ({
                record,
                inputType: col.dataIndex === 'text' ? 'text' : 'boolean',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const generateTableRowKey = (record: Question | PossibleAnswer) => (record?.id || '').toString();

    const a = (record: Question) => {
        console.log("A called", record.id)
        const answersColumns = [
            {
                title: 'Answer',
                dataIndex: 'text',
                key: 'text',
                editable: true,
            },
            {
                title: 'Icon',
                dataIndex: 'iconUrl',
                key: 'iconUrl',
                editable: true,
                render: (value: string) =>
                    <Image
                        src={value}
                        width={35}
                        fallback={""}
                    />
            },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (_: any, record: PossibleAnswer) => {
                    const editable = isAnswerEditing(record);
                    return editable ? (
                        <span>
                        <Typography.Link onClick={() => saveAnswer(record)} style={{marginRight: 8}}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancelEditAnswer}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                    ) : (
                        <Typography.Link disabled={editingId !== undefined} onClick={() => editAnswer(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            },
        ];

        const mergedAnswersColumns = answersColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: PossibleAnswer) => ({
                    record,
                    inputType: col.dataIndex === 'text' ? 'text' : 'image',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isAnswerEditing(record),
                }),
            };
        });

        return <Table
            columns={mergedAnswersColumns}
            dataSource={record.possibleAnswers}
            rowKey={generateTableRowKey}
            pagination={false}/>;
    }
    const expandedRowRender = useCallback(a, [answerEditingId]);


    return (
        <Form form={form} component={false}>
            <Table
                loading={loading}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancelEditQuestion,
                }}
                expandable={{expandedRowRender}}
                rowKey={generateTableRowKey}
            />
        </Form>
    );
}

export default EditHabitQuestions