import React, {useEffect, useState} from "react";
import {Affix, Col, Empty, Row, Space} from "antd";
import {useSearchParams} from "react-router-dom";

import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import AppVersionsMetrics from "./components/AppVersionsMetrics";
import CustomMenu, {getMenuItem} from "../../components/CostomMenu";
import FirmwareAdoptionMetrics from "./components/FirmwareAdoptionMetrics";
import FeaturesUsageMetrics from "./components/FeaturesUsageMetrics";
import AppActiveUsersMetric from "./components/AppActiveUsersMetric";
import ReviewsMetrics from "./components/ReviewsMetrics";
import RatingsMetrics from "./components/RatingsMetrics";
import DeviceEngagementRateMetrics from "./components/DeviceEngagementRateMetrics";

import {history} from "../../utils/history";
import './style.css';

// ----- Global Variables -----
const DEFAULT_TAB = 'app-versions'
const MENU_ITEMS = [
    getMenuItem('App Versions (GA)', 'app-versions'),
    //getMenuItem('Active Users', 'active-users-metrics'),
    getMenuItem('Firmware Versions', 'firmware-versions'),
    getMenuItem('Features Usage', 'features-usage'),
    getMenuItem('App Active users (GA)', 'app-active-users'),
    getMenuItem('Customer Reviews', 'reviews'),
    getMenuItem('Ratings', 'ratings'),
    getMenuItem('Device Engagement rate', 'device-engagement-rate'),
];

const MetricsPage = () => {
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState<string>(decodeURIComponent(searchParams.get('tab') || ''))

    useEffect(() => {
        if (!searchParams.get('tab'))
            onTabChange(DEFAULT_TAB)
    }, [])

    const ActiveMetric = () => {
        switch (tab) {
            case 'app-versions':
                return <AppVersionsMetrics/>
            case 'firmware-versions':
                return <FirmwareAdoptionMetrics/>
            case 'features-usage':
                return <FeaturesUsageMetrics/>
            case 'app-active-users':
                    return <AppActiveUsersMetric/>
            case 'reviews':
                return <ReviewsMetrics/>
            case 'ratings':
                return <RatingsMetrics/>
            case 'device-engagement-rate':
                return <DeviceEngagementRateMetrics/>
            default:
                return <Empty/>
        }
    }

    function onTabChange(tab: string) {
        setTab(tab)
        history.navigate?.(`?tab=${encodeURIComponent(tab)}`);
    }

    return (
        <>
            <CustomBreadcrumb list={['Metrics']}/>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Affix offsetTop={24}>
                        <CustomMenu
                            defaultSelectedKeys={[tab]}
                            items={MENU_ITEMS}
                            handleClick={(tab) => onTabChange(tab)}
                        />
                    </Affix>
                </Col>
                <Col span={18}>
                    <Space direction="vertical" style={{display: 'flex'}} size="middle">
                        <ActiveMetric/>
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default MetricsPage;
