import React from "react";
import {Button, DatePicker, Input, InputNumber, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {FilterConfirmProps, FilterDropdownProps} from "antd/es/table/interface";
import {get} from "lodash";
import type {InputRef} from 'antd';
import type {ColumnType} from 'antd/es/table';

import {BaseModel} from "../../types";
import dayjs from "dayjs";

type DataIndex = keyof BaseModel | string;
type ColumnDataType = 'date' | 'datetime' | 'string' | 'number' | 'boolean';

interface ColumnSearchPropsOptions {
    searchInput: React.RefObject<InputRef>;
    handleSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => void;
    handleReset?: (clearFilters: () => void) => void;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setSearchedColumn: React.Dispatch<React.SetStateAction<DataIndex>>;
    searchedColumn: DataIndex;
    searchText: string;
    dataIndex: string
    dataType?: ColumnDataType;
}

export const getColumnSearchProps = (
    {
        searchInput,
        setSearchText,
        setSearchedColumn,
        searchedColumn,
        searchText,
        dataIndex,
        dataType = 'string',
    }: ColumnSearchPropsOptions
): ColumnType<any> => {
    // ----- Handlers -----
    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    // ----- End Handlers -----

    const showText = (record: any, dataIndex: DataIndex) => {
        const value = get(record, dataIndex)?.toString();
        if (dataType === 'date' || dataType === 'datetime') {
            // Convert value to a Date object using dayjs
            const date = dayjs(value);
            // Check if the date is valid
            if (date.isValid()) {
                // Format date to locale string using dayjs
                if (dataType === 'date') {
                    return date.format('YYYY-MM-DD');
                }
                return date.format('YYYY-MM-DD HH:mm');
            }
        }
        // Return the value as a string
        return get(record, dataIndex)?.toString();
    }

    const FilterDropdown = ({setSelectedKeys, selectedKeys, confirm}: FilterDropdownProps) => {
        switch (dataType) {
            case 'date':
                return (
                    <DatePicker
                        onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
                        style={{width: '100%', marginBottom: 8}}
                    />
                );
            case 'datetime':
                return (
                    <DatePicker
                        onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
                        style={{width: '100%', marginBottom: 8}}
                    />
                );
            case 'boolean':
                return null
            default:
                return (
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{marginBottom: 8, display: 'block'}}
                    />
                );
        }
    }
    return (
        {
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                                 close,
                                 prefixCls,
                                 visible
                             }: FilterDropdownProps) => {
                switch (dataType) {
                    case 'boolean':
                        // Return the default filter dropdown for boolean data type
                        // todo:...
                        return undefined;
                    default:
                        return (
                            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                                {
                                    FilterDropdown({
                                        setSelectedKeys,
                                        selectedKeys,
                                        confirm,
                                        clearFilters,
                                        close,
                                        prefixCls,
                                        visible
                                    })
                                }
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                                        icon={<SearchOutlined/>}
                                        size="small"
                                        style={{width: 90}}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        onClick={() => clearFilters && handleReset(clearFilters)}
                                        size="small"
                                        style={{width: 90}}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                            close();
                                        }}
                                    >
                                        close
                                    </Button>
                                </Space>
                            </div>
                        )
                }
            },
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
            ),
            onFilter: (dataType === "date" || dataType === "datetime") ?
                (value, record) => {
                    // Todo: Adjust this code to support date and datetime filtering
                    const recordDate = get(record, dataIndex)?.toString();
                    let filterDate: Date | null = null;

                    // Check if 'value' is not a boolean before proceeding.
                    // React.Key could be string or number, both acceptable by Date constructor.
                    if (typeof value === 'string' || typeof value === 'number') {
                        filterDate = new Date(value);
                    } else {
                        // Handle the boolean case or any other unexpected case.
                        // Since 'value' being boolean doesn't make sense for date comparison, you might log an error or handle it as a special case.
                        return false;
                    }

                    // Proceed only if both dates are valid.
                    if (recordDate && filterDate) {
                        // Parse 'recordDate' as a Date object to compare with 'filterDate'.
                        // It's crucial here to handle any potential parsing errors or ensure 'recordDate' is in a format compatible with the Date constructor.
                        return new Date(recordDate).toDateString() === filterDate.toDateString();
                    }

                    return false;
                } :
                (value, record) => {
                    return get(record, dataIndex)
                        ?.toString?.()
                        ?.toLowerCase?.()
                        ?.includes?.((value as string).toLowerCase()) || false
                },
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            filters: dataType === 'boolean' ? [
                {
                    text: 'True',
                    value: true,
                }, {
                    text: 'False',
                    value: false,
                },
            ] : undefined,
            render: (_, record) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={showText(record, dataIndex) || ''}
                    />
                ) : (
                    showText(record, dataIndex)
                ),
        }
    );
}

// export const getColumnSearchProps