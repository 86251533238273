import {message} from "antd";
import type {RcFile} from 'antd/es/upload/interface';

import {DEFAULT_ALLOWED_FILE_SIZE_MB} from "../constants";

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result as string);
    reader.readAsDataURL(img);
};


interface HandleBeforeUploadConfig {
    allowAll?: boolean
    allowOnlyImage?: boolean
    maxFileSizeM?: number
}

interface HandleBeforeUploadProps {
    config?: HandleBeforeUploadConfig
    callback?: (url: string) => void
}

export const handleBeforeUpload = ({config, callback}: HandleBeforeUploadProps) => (file: RcFile) => {
    if (config?.allowOnlyImage) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false
        }
    }
    const isLt2M = file.size / 1024 / 1024 < (config?.maxFileSizeM || DEFAULT_ALLOWED_FILE_SIZE_MB);
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
        return false
    }
    if (callback)
        getBase64(file as RcFile, callback)
    return false; // Prevent default upload behavior
};

export default {
    getBase64,
    handleBeforeUpload
}