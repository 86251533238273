import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    message,
    Card,
    Upload,
    Switch,
    DatePicker,
    Col,
    Row,
    InputNumber
} from "antd";
import {useParams} from "react-router-dom";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import ImgCrop from "antd-img-crop";
import dayjs from 'dayjs';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';


// ---- local calls
import {ChallengesService} from "services";
import {ChallengeInCreate, ChallengeInUpdate} from "services/challenges";
import {getBase64} from "utils/upload";
import {Challenge} from "types";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import {DEFAULT_DATE_FORMAT} from "utils/datetime";
import ChallengePhonePreview from "./ChallengePhonePreview";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/state";
import EntitlementsSelect from "../../../components/EntitlementsSelect";


type RouteParams = {
    challengeId?: string
}

const formLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 8},
};

const {TextArea} = Input;
const {RangePicker} = DatePicker;

const CreateChallengePage: React.FC = () => {
    // ----- Store -----
    const authenticatedUser = useSelector((state: RootState) => state?.auth?.authenticatedUser);

    // ----- params -----
    const {challengeId} = useParams<RouteParams>();

    // ----- data -----
    const [challenge, setChallenge] = useState<Challenge | null>(null)
    const [loading, setLoading] = useState(false);

    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);

    // ----- Init -----
    useEffect(() => {
        // if challenge id then Edit case
        if (challengeId) {
            fetchChallenge()
        }
    }, [challengeId])

    useEffect(() => {
        const initialValues = {
            name: challenge?.name || "",
            type: challenge?.type || "HABITS",
            description: challenge?.description || "",
            startDate: challenge?.startDate && dayjs(challenge?.startDate, DEFAULT_DATE_FORMAT) || null,
            endDate: challenge?.startDate && dayjs(challenge?.endDate, DEFAULT_DATE_FORMAT) || null,
            ownerId: authenticatedUser?.id,
            entitlements: challenge?.entitlements || [],
            chatGroupLink: challenge?.chatGroupLink || "",
            maxPoints: challenge?.maxPoints || 3,
            personalHabitChallenge: challenge?.personalHabitChallenge || false,
            finalGoal: challenge?.finalGoal || null,
            globalChallenge: challenge?.globalChallenge || true,
            icon: challenge?.iconUrl || "",
            dateRange: [dayjs(challenge?.startDate), dayjs(challenge?.endDate)]
        };
        form.setFieldsValue(initialValues)
    }, [challenge])

    const fetchChallenge = () => {
        if (!challengeId) return
        setLoading(true)
        ChallengesService.getChallengeById(Number(challengeId))
            .then(res => {
                const challengeResponse = res?.data?.challenge
                if (challengeResponse) {
                    setChallenge(challengeResponse)
                    // we set image which is different from base64
                    setImage(challengeResponse.iconUrl)
                }
            })
            .catch(err => {
                console.error('Error fetching challenge, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = async (values: ChallengeInCreate) => {
        setLoading(true);

        try {
            const challenge: ChallengeInCreate = {
                name: values.name,
                type: values.type,
                description: values?.description,
                startDate: values.dateRange?.[0] && dayjs(values.dateRange[0]).format(DEFAULT_DATE_FORMAT) || "",
                endDate: values.dateRange?.[1] && dayjs(values.dateRange[1]).format(DEFAULT_DATE_FORMAT) || "",
                ownerId: values.ownerId,
                entitlements: values.entitlements,
                chatGroupLink: values.chatGroupLink,
                maxPoints: values.maxPoints,
                personalHabitChallenge: values.personalHabitChallenge,
                finalGoal: values.finalGoal,
                globalChallenge: values.globalChallenge,
                ...(imageBase64 ? {icon: imageBase64} : {})// Use the base64 image value
            };

            // Make the API request to create or update the challenge
            if (challengeId) {
                const challengeUpdate: ChallengeInUpdate = {
                    challengeId: Number(challengeId),
                    ...challenge
                }
                await ChallengesService.updateChallenge(challengeUpdate);
            } else {
                await ChallengesService.createChallenge(challenge);
                // Reset form fields and image state
                form.resetFields();
                setImageBase64(null);
                setImage(null);
            }
            // Show success message
            message.success(`Challenge ${challengeId ? 'updated' : 'created'} successfully`);
        } catch (error) {
            // Show error message
            message.error(`Failed to ${challengeId ? 'update' : 'create'} challenge`);
        }
        setLoading(false);
    };

    const handleBeforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
            return false
        }
        getBase64(file as RcFile, setImageBase64)
        return false; // Prevent default upload behavior
    };

    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam<UploadFile>) => {
        console.log('image changed', e)
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const cardTitle = challengeId ? "Edit challenge" : "Create challenge";
    // console.log('initialValues', initialValues)

    return (
        <>
            <CustomBreadcrumb list={['Challenges', 'create']}/>
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title={cardTitle}>
                        <Form
                            form={form}
                            onFinish={handleSubmit}
                            {...formLayout}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{required: true, message: 'Please input the name!'}]}
                            >
                                <Input placeholder="Enter name"/>
                            </Form.Item>
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{required: true, message: 'Please input the type!'}]}
                            >
                                <Input placeholder="Enter type" disabled/>
                            </Form.Item>

                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea placeholder="Challenge description"/>
                            </Form.Item>

                            <Form.Item
                                label="Start and End Date"
                                name="dateRange"
                                rules={[{required: true, message: 'Please select the date range!'}]}
                            >
                                <RangePicker/>
                            </Form.Item>

                            <Form.Item
                                label="Owner ID"
                                name="ownerId"
                                rules={[{required: true, message: 'Please input the owner ID!'}]}
                            >
                                <Input type="number" placeholder="Enter owner ID" disabled/>
                            </Form.Item>

                            <Form.Item
                                label="Entitlements"
                                name="entitlements"
                            >
                                <EntitlementsSelect mode="multiple"  placeholder="Select entitlements"/>
                            </Form.Item>
                            <Form.Item
                                label="Chat Group Link"
                                name="chatGroupLink"
                            >
                                <Input placeholder="Enter chat group link" disabled/>
                            </Form.Item>

                            <Form.Item
                                label="Max Points"
                                name="maxPoints"
                                rules={[{required: true, message: 'Please input the max points!'}]}
                            >
                                <InputNumber placeholder="Enter max points"/>
                            </Form.Item>
                            <Form.Item
                                label="Personal Habit Challenge (Under construction)"
                                name="personalHabitChallenge"
                                valuePropName="checked"
                            >
                                <Switch disabled/>
                            </Form.Item>
                            <Form.Item
                                label="Final Goal"
                                name="finalGoal"
                            >
                                <InputNumber placeholder="Enter max points" disabled/>
                            </Form.Item>
                            <Form.Item
                                label="Global Challenge"
                                name="globalChallenge"
                                valuePropName="checked"
                            >
                                <Switch disabled />
                            </Form.Item>

                            <Form.Item label="Image">
                                <ImgCrop showGrid showReset aspect={344 / 234}>
                                    <Upload
                                        beforeUpload={handleBeforeUpload}
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        showUploadList={false}
                                        listType="picture-card"
                                        disabled={loading}
                                    >
                                        {
                                            (imageBase64 || image) ?
                                                <img src={imageBase64 || image || ''}
                                                     alt="Preview"
                                                     style={{marginTop: 10, maxWidth: "100%"}}/> :
                                                uploadButton
                                        }
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    {challengeId ? "Update Challenge" : "Create Challenge"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={6} className="container-center-x">
                    <ChallengePhonePreview challenge={challenge} loading={loading}/>
                </Col>
            </Row>
        </>

    );
};

export default CreateChallengePage;
