import React, {useState} from 'react';
import {
    Steps, Card, Row, Col, message
} from 'antd';

import {HabitInCreate} from "types";
import CustomBreadcrumb from "components/CustomBreadcrumb";
import PhonePreviewLayout from "components/PhonePreviewLayout";
import {HabitsService} from "services";
import {history} from "../../../utils/history";
import HabitSetup from "./steps/HabitSetup";
import HabitsQuestionsSetup from "./steps/HabitsQuestionsSetup";
import HabitGoalSetup from "./steps/HabitGoalSetup";
import config from "../../../config";


const CreateHabitPage: React.FC = () => {
    const [formData, setFormData] = useState<any>({});
    const [habit, setHabit] = useState<HabitInCreate | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    //--------
    const [currentStep, setCurrentStep] = useState<number>(0);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = (values: any) => {
        setFormData({...formData, ...values});
        handleNext();
    };

    const handleSubmit = () => {
        if (!habit) return
        setLoading(true)
        HabitsService.createHabit(habit)
            .then(res => {
                const responseHabit = res?.data?.habit
                if (!responseHabit) {
                    throw Error('Error')
                    return
                }
                message.success('Created')
                history.navigate?.('/habits')
            })
            .catch(err => {
                console.error(err)
                message.success('Error creating habit! check logs.')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onStepChange = (value: number) => {
        // todo: activate this on local
        if (config.app.environment === 'dev') {
            setCurrentStep(value);
        }
    };

    const steps = [
        {
            title: 'Habit Setup',
            content: <HabitSetup
                habit={habit}
                loading={loading}
                setHabit={setHabit}
                handleNext={handleNext}
                handlePrev={handlePrev}
                currentStep={currentStep}
            />,
        },
        {
            title: 'Habit Goal Setup',
            content: <HabitGoalSetup
                habit={habit}
                loading={loading}
                setHabit={setHabit}
                handleNext={handleNext}
                handlePrev={handlePrev}
                currentStep={currentStep}
            />,
        },
        {
            title: 'Questions',
            content: <HabitsQuestionsSetup
                habit={habit}
                loading={loading}
                setHabit={setHabit}
                handleNext={handleNext}
                handlePrev={handlePrev}
                currentStep={currentStep}
                handleSubmit={handleSubmit}
            />,
        },
    ];

    return (
        <div>
            <CustomBreadcrumb list={['Habits', 'Create']}/>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <Steps onChange={onStepChange} current={currentStep} items={steps.map(step => step)}/>
                    </Card>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={16}>
                            <Card loading={loading}>
                                {steps[currentStep].content}
                            </Card>
                        </Col>
                        <Col span={8} className="container-center-x">
                            <PhonePreviewLayout loading={loading}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default CreateHabitPage;
