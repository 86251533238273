import React, {useEffect, useState} from 'react';
import {Button, Card, Space, Table, Tooltip} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import type {ColumnsType} from 'antd/es/table';
import dayjs from "dayjs";


// ----- Types -----
import {UserEntitlement} from "../../types";
import {getUserEntitlements} from "../../services/entitlements";

// ----- Types -----
interface SubscriptionListProps {
    userId?: string
}

const UserEntitlementsList: React.FC<SubscriptionListProps> = ({userId}) => {
    // ----- Data -----
    const [userEntitlements, setUserEntitlements] = useState<UserEntitlement[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    // ----- Effects -----
    useEffect(() => {
        fetchData()
    }, [userId])

    const fetchData = () => {
        if (!userId) return
        setLoading(true)
        getUserEntitlements(Number(userId))
            .then(res => {
                const entitlements = res?.data?.revenueCatUserEntitlements;
                if (!entitlements) {
                    throw Error('userEntitlements not found');
                    return;
                }
                const subscriptionList = entitlements?.map((item: UserEntitlement, index: number) => ({
                    ...item,
                    key: `key-${index}`
                }));
                setUserEntitlements(subscriptionList || []);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const columns: ColumnsType<UserEntitlement> = [
        {
            title: 'Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Stared On',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value) => value && dayjs(value).format('YYYY-MM-DD HH:MM')
        },
        {
            title: 'Expires Date',
            dataIndex: 'expiresAt',
            key: 'expiresAt',
            render: (value) => value && dayjs(value).format('YYYY-MM-DD HH:MM')
        },
    ];

    return (
        <Card title="Subscription list"
              extra={
                  <Space>
                      <Tooltip title="Reload">
                          <Button
                              loading={loading}
                              onClick={() => fetchData()}
                              icon={<ReloadOutlined/>}/>
                      </Tooltip>
                      <Tooltip title="Navigate to Revenue-cat">
                          <Button
                              type="link"
                              href={`https://app.revenuecat.com/customers/3e3f08a5/${userId}`}
                              target="_blank"
                          >
                              Revenue-cat
                          </Button>
                      </Tooltip>
                  </Space>
              }
        >
            <Table<UserEntitlement>
                columns={columns}
                dataSource={userEntitlements}
                loading={loading}
                // ... (rest remains the same for table settings and props)
                scroll={{x: 500}}
            />
        </Card>
    )
};

export default UserEntitlementsList;
