import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    message,
    Card,
    Upload,
    Switch,
    DatePicker,
    Select, Col, Row,
    Image
} from "antd";
import {useParams} from "react-router-dom";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadProps} from 'antd/es/upload/interface';
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";

// ---- local calls
import {BannersService} from "services";
import {BannerInCreate, BannerInUpdate} from "services/banners";
import {getBase64, handleBeforeUpload} from "utils/upload";
import {Banner} from "types";
import DefaultBanner from "assets/images/banner/default.png";
import BannerPhonePreview1 from "assets/images/banner/BannerPhonePreview1.png";
import BannerPhonePreview2 from "assets/images/banner/BannerPhonePreview2.png";
import PhonePreviewLayout from "components/PhonePreviewLayout";
import CustomBreadcrumb from "components/CustomBreadcrumb";


type RouteParams = {
    bannerId?: string
}

const formLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 8},
};

const CreateBannerPage: React.FC = () => {
    // ----- params -----
    const {bannerId} = useParams<RouteParams>();

    // ----- data -----
    const [banner, setBanner] = useState<Banner | null>(null)
    const [loading, setLoading] = useState(false);
    // ----- form -----
    const [form] = Form.useForm();

    // ----- upload image -----
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);

    // ----- color picker -----
    /*const [colorHex, setColorHex] = useState<Color | string>('#1677ff');
    const [formatHex, setFormatHex] = useState<ColorPickerProps['format']>('hex');
    const hexString = useMemo(
        () => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()),
        [colorHex],
    );
     */

    // ----- Init -----
    useEffect(() => {
        // if banner id then Edit case
        if (bannerId) {
            fetchBanner()
        }
    }, [bannerId])

    useEffect(() => {
        const initialValues = {
            expiryTime: banner?.expiryTime ? dayjs(banner?.expiryTime) : null,
            image: banner?.backgroundImageUrl || "",
            isOnboarding: banner?.isOnboarding || false,
            live: banner?.live || false,
            relativeExpiry: banner?.relativeExpiry || 0,
            storeItemId: banner?.storeItemId || 0,
            text: banner?.text || "",
            url: banner?.url || "",
            title: banner?.title || "",
            backgroundColor: banner?.backgroundColor || "",
            audience: banner?.audience || 'all',
            appName: banner?.appName || 'core',
        };
        // set the form values
        form.setFieldsValue(initialValues)
        // set the image
        setImage(banner?.backgroundImageUrl || null)
    }, [banner])

    const fetchBanner = () => {
        if (!bannerId) return
        setLoading(true)
        BannersService.getBannerById(Number(bannerId))
            .then(res => {
                const bannerResponse = res.data.banner
                if (bannerResponse) {
                    setBanner(bannerResponse)
                    setImage(bannerResponse.backgroundImageUrl)
                    // setColorHex(bannerResponse.backgroundColor)
                }
            })
            .catch(err => {
                console.error('Error fetching banner, message: ', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSubmit = async (values: BannerInCreate) => {
        setLoading(true);

        try {
            // Is Onboarding, Relative Expiry, Store Item ID, Background Color,
            const banner: BannerInCreate = {
                title: values.title,
                url: values.url,
                text: values.text,
                live: values.live,
                audience: values.audience,
                appName: 'all',
                expiryTime: values.expiryTime && dayjs(values.expiryTime).format("YYYY-MM-DD"),
                ...(imageBase64 ? {image: imageBase64} : {})
            };

            // Make the API request to create or update the banner
            if (bannerId) {
                const bannerUpdate: BannerInUpdate = {
                    bannerId: Number(bannerId),
                    ...banner
                }
                await BannersService.updateBanner(bannerUpdate);
            } else {
                await BannersService.createBanner(banner);
                // Reset form fields and image state
                form.resetFields();
                // setImageFile(null);
                setImageBase64(null);
            }
            // Show success message
            message.success(`Banner ${bannerId ? 'updated' : 'created'} successfully`);


        } catch (error) {
            // Show error message
            message.error(`Failed to ${bannerId ? 'update' : 'create'} banner`);
        }
        setLoading(false);
    };

    const handleImageChange: UploadProps['onChange'] = (e: UploadChangeParam) => {
        const file = e.file
        if (!file) return

        getBase64(file as RcFile, (img: string) => {
            form.setFieldValue('image', img)
        })
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const cardTitle = bannerId ? "Edit banner" : "Create banner";
    // console.log('initialValues', initialValues)

    return (
        <>
            <CustomBreadcrumb list={['Banners', 'create']}/>
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title={cardTitle}>
                        <Form
                            form={form}
                            onFinish={handleSubmit}
                            {...formLayout}
                            // initialValues={initialValues}
                        >
                            <Form.Item name="title" label="Title" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name="url" label="URL" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item name="text" label="Text" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                            {/*<Form.Item name="storeItemId" label="Store Item ID" rules={[{required: true}]}>
                                <Input type="number"/>
                            </Form.Item>*/}
                           {/* <Form.Item name="backgroundColor" label="Background Color">
                                <Space>
                                    <ColorPicker
                                        format={formatHex}
                                        value={colorHex}
                                        onChange={setColorHex}
                                        onFormatChange={setFormatHex}
                                    />
                                    <div>HEX: <span>{hexString}</span></div>
                                </Space>
                            </Form.Item>*/}
                            <Form.Item name="live" label="Live" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item name="audience" label="Audience" rules={[{required: true}]}>
                                <Select
                                    // defaultValue="core"
                                    style={{width: 120}}
                                    // onChange={handleChange}
                                    options={[
                                        {value: 'all', label: 'All'},
                                        {value: 'subscriber', label: 'Subscriber'},
                                        {value: 'non-subscriber', label: 'Non subscriber'},
                                        {value: 'amazon', label: 'Amazon'},
                                        {value: 'non-amazon', label: 'Non amazon'},
                                        {value: 'ua_only_users', label: 'UA only users'},
                                        {value: 'android', label: 'Android'},
                                        {value: 'ios', label: 'Ios'},
                                        {value: 'PV2', label: 'PV2'},
                                        {value: 'PV3', label: 'PV3'},
                                        {value: 'SC2', label: 'SC2'},
                                        {value: 'SC3', label: 'SC3'},
                                    ]}
                                />
                            </Form.Item>
                            {/*<Form.Item name="appName" label="App Name" rules={[{required: true}]}>
                                <Select
                                    // defaultValue="all"
                                    style={{width: 120}}
                                    // onChange={handleChange}
                                    options={[
                                        {value: 'all', label: 'All'},
                                        {value: 'core', label: 'Core'},
                                        {value: 'gratitude', label: 'Gratitude'},
                                        {value: 'pavlok3', label: 'Pavlok3'},
                                    ]}
                                />
                            </Form.Item>*/}
                            <Form.Item name="expiryTime" label="Expiry Time" rules={[{required: true}]}>
                                <DatePicker/>
                            </Form.Item>
                            {/*<Form.Item name="isOnboarding" label="Is Onboarding" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item name="relativeExpiry" label="Relative Expiry" rules={[{required: true}]}>
                                <Input type="number"/>
                            </Form.Item>*/}
                            <Form.Item name="image" label="Image" rules={[{required: true}]}>
                                <ImgCrop showGrid showReset aspect={444 / 208}>
                                    <Upload
                                        beforeUpload={handleBeforeUpload({
                                            config: {allowOnlyImage: true},
                                            callback: setImageBase64
                                        })}
                                        onChange={handleImageChange}
                                        accept="image/*"
                                        showUploadList={false}
                                        listType="picture-card"
                                        disabled={loading}
                                    >
                                        {(imageBase64 || image) ?
                                            <img src={imageBase64 || image || ''}
                                                 alt="Preview"
                                                 style={{
                                                     marginTop: 10,
                                                     maxWidth: "100%"
                                                 }}/> :
                                            uploadButton
                                        }
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    {bannerId ? "Update Banner" : "Create Banner"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={6} className="container-center-x">
                    <PhonePreviewLayout loading={loading}>
                        <div className="container-center-x"
                             style={{
                                 marginTop: 10,
                                 marginBottom: 22,
                             }}
                        >
                            <div className="d-flex align-items-center justify-content-center"
                                 style={{
                                     flexDirection: "column"
                                 }}
                            >
                                <Image src={BannerPhonePreview1}
                                       wrapperStyle={{
                                           paddingLeft: 10,
                                           paddingRight: 10,
                                       }}
                                       preview={false}/>
                                <Image src={imageBase64 || image || ''}
                                       alt="Preview"
                                       preview={false}
                                    // height={100}
                                    // width="100%"
                                       fallback={DefaultBanner}
                                       wrapperStyle={{
                                           paddingLeft: 10,
                                           paddingRight: 10,
                                       }}
                                       style={{
                                           // minWidth: '100%',
                                           borderRadius: 16,
                                           aspectRatio: 444 / 208,
                                           // boxShadow: "0px 8px 20px #BDACFB, inset 0px -3px 10px rgba(0, 0, 0, 0.37), inset 3px 1px 15px rgba(255, 255, 255, 0.5)"
                                       }}
                                />
                                <Image src={BannerPhonePreview2} preview={false}/>
                            </div>
                        </div>
                    </PhonePreviewLayout>
                </Col>
            </Row>
        </>

    );
};

export default CreateBannerPage;
