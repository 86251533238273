import Api from "./api";
import {User} from "../types";
import {AxiosResponse} from "axios";

interface LoginParams {
    username: string;
    password: string;
}

interface LoginResponse {
    user: User;
}

interface VerifyResponse {
    user: User;
}

export const login = (params: LoginParams): Promise<AxiosResponse<LoginResponse>> => {
    return Api.post(
        '/users/login',
        {
            user: {
                email: params.username,
                password: params.password,
            }
        }
    )
}


export const verify = (): Promise<AxiosResponse<VerifyResponse>> => {
    return Api.get('/user/')
}

export default {
    login, verify
}