import React from "react";
import {Card, Image, Typography} from "antd";

// ----- Local calls -----
import {Content} from "types";
import PavlokLogo from 'assets/images/pavlok-logo.svg';

// ----- Global variables -----
// ----- Types -----
interface ArticlesProps {
    articles: Content[] | null
}

// ----- Components -----
const Articles: React.FC<ArticlesProps> = ({articles}) => {
    return (
        <div className="articlesContainer">
            {
                articles?.map((item: Content, index) => (
                    <div className="articleItem" key={`article${item.id}-${index}`}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <Card
                                hoverable
                                style={{width: 240}}
                                cover={
                                    <Image alt="Pavlok"
                                           src={item?.image}
                                           preview={false}
                                           fallback={PavlokLogo}
                                    />
                                }
                                bodyStyle={{paddingLeft: 7, paddingRight: 7}}
                            >
                                <Typography.Paragraph ellipsis={{ rows: 2, expandable: false, symbol: 'more' }}>
                                    {item?.title}
                                </Typography.Paragraph>
                            </Card>
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

export default Articles
